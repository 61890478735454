import { ProductsTable } from './ProductsTable'

export const Products = () => {
    return (
        <div className=''>
            <section>
                <h1 className='text-2xl text-pink-900 font-bold'>Products</h1>
            </section>
            <ProductsTable />
        </div>
    )
}
