import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { AxiosError } from 'axios'
import { AppButton } from 'components/AppButton'
import { CreateMenuCategoryType } from 'pages/Schemas/menu-category'
import { DraggableHandle } from 'pages/Web/Partnerships/parnership-item'
import React, { useMemo } from 'react'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { UpdateMenuCategoryApi } from 'services/requests/menu-category'
import { ObjectMenuCategoryType } from 'services/requests/menu-category/schema'
import { twMerge } from 'tailwind-merge'

interface ISortableItemCategoryProps {
    id: string
    item: ObjectMenuCategoryType
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    setIdToProceed: React.Dispatch<React.SetStateAction<string>>
}

const SortableItemCategory: React.FunctionComponent<ISortableItemCategoryProps> = (props) => {
    const navigate = useNavigate()
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props?.id ?? '',
    })
    const queryClient = useQueryClient()

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }
    const { mutate: updateCategory } = useMutation<unknown, AxiosError, CreateMenuCategoryType>(
        (data) =>
            UpdateMenuCategoryApi(props?.id, {
                description: data.description,
                status: data.status,
                icon: data.icon,
                name: data.name,
                styles: data.styles,
                position: data.position,
                iconAltText: data.iconAltText,
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['menu-category'])
            },
            onError: (error) => console.log(error),
        },
    )

    const isActive = useMemo(() => props?.item?.status === 'ACTIVE', [props?.item?.status])
    return (
        <div
            className='flex flex-col gap-2 relative'
            ref={setNodeRef}
            style={style}
            {...attributes}
        >
            <DraggableHandle
                handleListeners={listeners}
                className='absolute z-30 p-1 bg-white rounded-full -right-3 -top-3 shadow-xl border-2 border-[#861F41] w-7 h-7'
            />
            <div className='relative'>
                <AppButton
                    variant='secondary'
                    type='button'
                    className='absolute -bottom-2 -right-3 rounded-full p-0'
                    onClick={() => {
                        if (props?.item?.icon) {
                            updateCategory({
                                ...props?.item,
                                status: !isActive ? 'ACTIVE' : 'INACTIVE',
                            })
                        } else {
                            props.setIsOpen(true)
                            props.setIdToProceed(props?.id)
                        }
                    }}
                >
                    {isActive ? (
                        <AiFillEye className='w-8 h-8 text-[#861F41]' />
                    ) : (
                        <AiFillEyeInvisible className='w-8 h-8 text-gray-500' />
                    )}
                </AppButton>
                <button
                    onClick={() => navigate(`update/${props?.id}`)}
                    key={props.id}
                    className={twMerge(
                        isActive ? 'bg-[#861F41]' : 'bg-gray-500',
                        'text-white h-[62px] min-w-[177px] flex items-center gap-4 px-2 rounded-[5px]',
                    )}
                >
                    <img src={props?.item?.icon} className='w-10 object-cover' alt='' />
                    <h1 className='font-bold'>{props?.item?.name}</h1>
                    <h1 className='font-light'>{props?.item?.products.length}</h1>
                </button>
            </div>
        </div>
    )
}

export default SortableItemCategory
