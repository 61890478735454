import { Switch } from '@headlessui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { AxiosError } from 'axios'
import { AppButton } from 'components/AppButton'
import { AppCard } from 'components/AppCard'
import { AppRichTextBox } from 'components/AppRichTextBox'
import { AppSelectMenu } from 'components/AppSelectMenu'
import { AppTextBox } from 'components/AppTextBox'
import { useAtom } from 'jotai'
import { manageRewardSchema, ManageRewardType, RewardType, TierType } from 'pages/Schemas/tier'
import { useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { BsGift, BsPercent } from 'react-icons/bs'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
    createReward,
    getPointingTierList,
    getSpecificReward,
    updateReward,
} from 'services/requests/account'
import { selectedRewardAtom } from 'store/rewardsAtom'
import { tiersAtom } from 'store/tierAtom'
import EditImage from './EditImage'
import UploadImage from './Modals/UploadImage'
import { ManageRewardLoading } from './SkeletonLoading'

const ManageReward = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const [isImageUploadOpen, setImageUploadOpen] = useState(false)
    const [tiersState, setTierAtom] = useAtom(tiersAtom)
    const [selectedRewardsAtom, setSelectedRewardsAtom] = useAtom(selectedRewardAtom)
    const { data: pointingTierList } = useQuery<TierType[]>('pointingTierList', getPointingTierList)
    const { pointingTierId: selectedReward } = useParams()
    const { data: pointingRewardTier, isLoading: isPointingRewardLoading } = useQuery<
        string,
        AxiosError,
        RewardType
    >(
        'specificReward',
        () => (selectedReward as string) && getSpecificReward(selectedReward as string),
        {
            onSuccess: (data) => {
                if (data) {
                    setValue('percent', data.percent * 100)
                    setValue('points', data.points)
                    setValue('rewardTitle', data.rewardTitle)
                    setValue('rewardDesc', data.rewardDesc)
                    setValue('rewardImage', data.rewardImage)
                    setValue('visibility', data.visibility)
                    setValue('pointingSystemTierId', selectedRewardsAtom.pointingSystemTier.id)
                    setValue('altText', data.altText)
                }
            },
            staleTime: Infinity,
        },
    )

    const { mutate: createRewardsMu, isLoading: isCreateLoading } = useMutation<
        unknown,
        AxiosError,
        ManageRewardType
    >((data) => createReward(data), {
        onSuccess: () => {
            toast.success('Loyalty reward added successfully')
            navigate(-1)
        },
    })

    const { mutate: updateRewardMu, isLoading: isUpdateLoading } = useMutation<
        unknown,
        AxiosError,
        ManageRewardType
    >(
        (data) =>
            updateReward(
                {
                    pointingSystemTierId: data.pointingSystemTierId,
                    tierName: data.tierName,
                    points: data.points,
                    percent: data.percent / 100,
                    rewardTitle: data.rewardTitle,
                    rewardDesc: data.rewardDesc,
                    rewardImage: data.rewardImage,
                    visibility: data.visibility as boolean,
                    altText: data.altText,
                },
                selectedReward as string,
            ),
        {
            onSuccess: () => {
                toast.success('Loyalty reward updated successfully')
                navigate(-1)
            },
        },
    )

    const methods = useForm<ManageRewardType>({
        mode: 'onBlur',
        resolver: zodResolver(manageRewardSchema),
        defaultValues: {
            pointingSystemTierId: tiersState[0].id,
            tierName: tiersState[0].tierName,
            visibility: false,
        },
    })

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
        watch,
    } = methods
    const formValues = watch()

    const handleSelectChange = (value: string) => {
        const selectedOption = tiersState.find((option) => option.id === value)
        setValue('tierName', String(selectedOption && selectedOption.tierName))
        if (selectedOption) {
            setValue('pointingSystemTierId', value)
        }
    }

    const onSubmit = (data: ManageRewardType) => {
        if (pointingRewardTier && pointingRewardTier.pointingSystemTierId !== '') {
            return updateRewardMu(data)
        }
        createRewardsMu({
            pointingSystemTierId: data.pointingSystemTierId,
            tierName: data.tierName,
            points: data.points,
            percent: data.percent / 100,
            rewardTitle: data.rewardTitle,
            rewardDesc: data.rewardDesc,
            rewardImage: data.rewardImage,
            visibility: data.visibility,
            altText: data.altText,
        })
        return
    }

    useEffect(() => pointingTierList && setTierAtom(pointingTierList), [pointingTierList])

    useEffect(() => {
        //para mag reset yung cache ng specificReward pag nag unmount yung managetier page
        return () => {
            queryClient.resetQueries('specificReward')
        }
    }, [])

    if (isPointingRewardLoading) return <ManageRewardLoading />

    return (
        <FormProvider {...methods}>
            <h1 className='text-base text-gray-600 font-bold'>
                <Link
                    className='text-red-800 hover:text-red-600'
                    to={'/mobile/offersanddeals/rewards'}
                >
                    Offers And Deals
                </Link>{' '}
                &gt;{' '}
                {pointingRewardTier && pointingRewardTier?.pointingSystemTierId !== ''
                    ? 'Update Reward'
                    : 'Add New Reward'}
            </h1>

            <div className='flex items-center justify-between py-3 mb-5 mt-4 border-b-4 border-slate-400'>
                <h1 className='text-4xl text-[#831843] font-bold text'>
                    {' '}
                    {pointingRewardTier && pointingRewardTier.pointingSystemTierId !== ''
                        ? pointingRewardTier?.pointingSystemTier?.tierName
                        : 'Add New Reward'}
                </h1>
            </div>

            <AppCard className='shadow-md'>
                <div className='px-5 lg:px-10'>
                    <div className='flex justify-between flex-wrap xl:flex-nowrap border-slate-300 '>
                        <div className='text-black font-bold text-3xl flex w-full '>
                            <div className='pt-7 xl:pt-12 xl:pb-10'>
                                <div>
                                    {' '}
                                    {pointingRewardTier &&
                                    pointingRewardTier?.pointingSystemTierId !== ''
                                        ? 'Update Reward'
                                        : 'Create New Reward'}{' '}
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <AppButton
                                variant='secondary'
                                type='button'
                                className='min-w-[150px] mt-12 h-[48px] bg-white text-red-900 rounded-md mr-7 border-2 border-red-900'
                                onClick={() => {
                                    navigate(-1)
                                }}
                            >
                                Cancel
                            </AppButton>
                            <AppButton
                                variant='primary'
                                type='submit'
                                className='min-w-[150px] mt-12 h-[48px] bg-[#861F41] text-white rounded-md'
                                onClick={handleSubmit(onSubmit)}
                                disabled={isCreateLoading || isUpdateLoading}
                                isLoading={isCreateLoading || isUpdateLoading}
                            >
                                Save
                            </AppButton>
                        </div>
                    </div>
                </div>
            </AppCard>

            <div className='flex-1 2xl:flex 2xl:space-x-5 mt-5'>
                <AppCard className='basis-full space-y-5 2xl:basis-3/4 shadow-md'>
                    <div className='flex flex-wrap px-5 2xl:px-10 space-y-7'>
                        <div className='basis-full lg:basis-1/2 lg:pr-4 mt-7'>
                            <div className='text-xl font-bold mb-3 text-gray-700'> Tier Type</div>
                            <Controller
                                control={control}
                                name='pointingSystemTierId'
                                render={({ field: { name, onChange, value } }) => (
                                    <AppSelectMenu
                                        name={name}
                                        classname='w-full h-[51px] bg-white border border-gray-300'
                                        options={
                                            tiersState && [
                                                ...tiersState.map((rewards) => ({
                                                    value: rewards?.id,
                                                    label: rewards?.tierName,
                                                })),
                                            ]
                                        }
                                        value={value}
                                        defaultValue={value}
                                        onChange={(value) => handleSelectChange(value)}
                                    />
                                )}
                            />
                        </div>
                        <div className='basis-full lg:basis-1/2'>
                            <Controller
                                control={control}
                                name='points'
                                render={({
                                    field: { name, value, onChange },
                                    fieldState: { error },
                                }) => (
                                    <div className='relative'>
                                        <span className='absolute left-0 top-10 p-2.5 rounded-tl-md rounded-bl-md border-2 border-gray-300 bg-gray-200 text-gray-400 '>
                                            <BsGift size={28} />
                                        </span>
                                        <AppTextBox
                                            label={'Points'}
                                            value={value}
                                            name={name}
                                            labelClassname='font-bold text-xl mb-3'
                                            inputClassname='border pr-10 pl-16 text-lg rounded border-[#D8D8D8] w-full min-h-[50px] focus:outline-none focus:shadow-outline text-xxs shadow-none'
                                            errorMessage={error?.message}
                                            inputContainerClassname='shadow-none'
                                            placeholder='Enter points'
                                            isNumberRestricted
                                            type={'number'}
                                            onChange={(value) => onChange(parseFloat(value))}
                                        />
                                    </div>
                                )}
                            />
                        </div>

                        <div className='basis-full lg:basis-1/2 lg:pr-4'>
                            <Controller
                                control={control}
                                name='percent'
                                render={({
                                    field: { name, onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <div className='relative'>
                                        <span className='absolute left-0 top-10 p-2.5 rounded-tl-md rounded-bl-md border-2 border-gray-300 bg-gray-200 text-gray-400 '>
                                            <BsPercent size={26} />
                                        </span>
                                        <AppTextBox
                                            type='number'
                                            name={name}
                                            isNumberRestricted
                                            label='Discount'
                                            labelClassname='text-xl font-bold mb-3'
                                            inputClassname='w-full pr-10 pl-16 border-[#D8D8D8] text-lg min-h-[50px] border-2'
                                            value={value}
                                            placeholder='Enter Discount'
                                            errorMessage={error?.message}
                                            onChange={(value) => onChange(parseFloat(value))}
                                        />
                                    </div>
                                )}
                            />
                        </div>

                        <div className='basis-full'>
                            <div className='text-xl font-bold text-gray-700 pb-3'>
                                {' '}
                                Reward Title
                            </div>
                            <Controller
                                control={control}
                                name='rewardTitle'
                                render={({
                                    field: { name, onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <div>
                                        <div className='w-full bg-white '>
                                            <AppRichTextBox
                                                name={name}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        </div>
                                        {error && (
                                            <p className='text-red-500 text-sm mt-1 bg-blue-200+++++++'>
                                                {error.message === 'Required'
                                                    ? 'Reward title is a required field.'
                                                    : ''}
                                            </p>
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                        <div className='basis-full'>
                            <div className='text-xl font-bold text-gray-700 pb-3'>
                                {' '}
                                Reward Description
                            </div>
                            <Controller
                                control={control}
                                name='rewardDesc'
                                render={({
                                    field: { name, onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <div>
                                        <div className='w-full bg-white'>
                                            <AppRichTextBox
                                                name={name}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        </div>
                                        {error && (
                                            <p className='text-red-500 text-sm mt-1 bg-blue-200+++++++'>
                                                {error.message === 'Required'
                                                    ? 'Reward description is a required field.'
                                                    : ''}
                                            </p>
                                        )}
                                    </div>
                                )}
                            />
                        </div>

                        <div className='basis-full bg-white '>
                            <p className='mt-3 text-gray-70 text-xl font-bold'> Redeem Button </p>
                            <div className='mt-5 p-6 w-56 border-2 border-dashed border-red-900'>
                                <AppButton
                                    variant='secondary'
                                    type='button'
                                    className='w-full h-[48px] bg-[#861F41] text-white rounded-md'
                                >
                                    Redeem
                                </AppButton>
                            </div>
                        </div>
                    </div>
                </AppCard>

                <AppCard className='basis-full mt-5 2xl:mt-0 2xl:basis-1/4 h-1/2 shadow-md'>
                    <div className='text-xl font-bold pt-10 px-5 text-gray-700'>Reward Image</div>
                    <div>
                        <EditImage
                            imageUrl={formValues.rewardImage}
                            altText={formValues.altText}
                            setImageUploadOpen={setImageUploadOpen}
                        />
                    </div>

                    <div className='flex justify-start mt-10'>
                        <div className='text-xl font-bold pt-10 px-5 pl-10 text-gray-700'>
                            Show reward on the list:
                        </div>
                        <div className='text-xl font-bold pt-10 px-0'>
                            <Controller
                                name='visibility'
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <Switch
                                        checked={value}
                                        onChange={onChange}
                                        className={`${
                                            value ? 'bg-[#861F41]' : 'bg-gray-400'
                                        } relative inline-flex h-[20px] w-[40px] shrink-0 cursor-pointer rounded-full 
                                                              border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none 
                                                              focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                                    >
                                        <span className='sr-only'>Toggle Status</span>
                                        <span
                                            aria-hidden='true'
                                            className={`${
                                                value ? 'translate-x-[1.2rem]' : 'translate-x-[2px]'
                                            } pointer-events-none inline-block h-[15px] w-[15px] transform rounded-full 
                                                                  bg-white shadow-lg ring-0 transition duration-200 ease-in-out top-[50%] 
                                                                  translate-y-[0%] `}
                                        />
                                    </Switch>
                                )}
                            />
                        </div>
                    </div>
                </AppCard>
                <UploadImage onClose={setImageUploadOpen} open={isImageUploadOpen} />
            </div>
        </FormProvider>
    )
}
export default ManageReward
