import { AppButton } from 'components/AppButton'
import { useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

interface AppPaginationProps {
    total: number
    perPage: number
    currentPage: number
    onPageChange: (page: number) => void
    className?: string
}

export const AppPagination = (props: AppPaginationProps) => {
    const totalPage = Math.ceil(props.total / props.perPage)

    const handlePageChange = (page: number) => {
        props.onPageChange(page)
    }

    const handlePreviousPage = () => {
        if (props.currentPage > 0) {
            handlePageChange(props.currentPage - 1)
        }
    }

    const handleNextPage = () => {
        if (props.currentPage < props.total) {
            handlePageChange(props.currentPage + 1)
        }
    }

    useEffect(() => {
        if (props.currentPage + 1 > totalPage) {
            handlePageChange(0)
        }
    }, [props.total])

    return (
        <nav
            className={twMerge(
                'flex items-center justify-between rounded border border-gray-200 bg-white px-4 py-3 sm:px-6',
            )}
            aria-label='Pagination'
        >
            <div className='hidden sm:block'>
                <p className='text-sm text-gray-700'>
                    Page <span className='font-medium'>{props.currentPage + 1}</span> of{' '}
                    <span className='font-medium'>{totalPage}</span>
                </p>
            </div>
            <div className='flex flex-1 justify-between sm:justify-end'>
                <AppButton
                    variant='secondary'
                    onClick={handlePreviousPage}
                    type='button'
                    disabled={props.currentPage === 0}
                    className={twMerge(
                        'relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0',
                        props.currentPage === 0 && 'opacity-50 cursor-not-allowed',
                    )}
                >
                    Previous
                </AppButton>
                <AppButton
                    variant='secondary'
                    onClick={handleNextPage}
                    disabled={props.currentPage === totalPage - 1}
                    type='button'
                    className={twMerge(
                        'relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0',
                        props.currentPage === totalPage - 1 && 'opacity-50 cursor-not-allowed',
                    )}
                >
                    Next
                </AppButton>
            </div>
        </nav>
    )
}
