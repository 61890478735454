import { CreateHomeContentType, UpdateHomeContentType } from 'pages/Schemas/home-content'
import { axiosInstance } from 'services/axios'
import { CreateHomeSectionType, HomeContentType, HomeSectionType, UpdateSectionBg } from './schema'

//@SECTIONS
export const getHomeSectionByTitleApi = async (title: string) => {
    const response = await axiosInstance.get(`/home?sectionTitle=${title}`)

    return response.data as HomeSectionType
}
export const getHomeContentByTitleApi = async (title: string) => {
    const response = await axiosInstance.get(`/home/content/external?title=${title}`)

    return response.data as HomeContentType
}
export const CreateHomeSectionApi = async (data: CreateHomeSectionType) => {
    const response = await axiosInstance.post('/home', data)

    return response.data as unknown
}

export const CreateHomeContentApi = async (data: CreateHomeContentType) => {
    const formData = new FormData()

    formData.append('title', data.title)
    formData.append('subtitle', data.subtitle)
    formData.append('buttonLabel', data.buttonLabel)
    formData.append('buttonRedirection', data.buttonRedirection)
    formData.append('floatingImage', data.floatingImage)
    formData.append('icon', data.icon)
    formData.append('position', data.position.toString())
    formData.append('visibility', data.visibility)
    formData.append('externalTitle', data.externalTitle)
    formData.append('file', data.file)
    formData.append('imageAltText', data.imageAltText)

    const response = await axiosInstance.post(`/home/content/${data.homeId}/image`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })

    return response.data as unknown
}
export const UpdateHomeContentApi = async (id: string, data: CreateHomeContentType) => {
    const formData = new FormData()

    formData.append('title', data.title)
    formData.append('subtitle', data.subtitle)
    formData.append('buttonLabel', data.buttonLabel)
    formData.append('buttonRedirection', data.buttonRedirection)
    formData.append('floatingImage', data.floatingImage)
    formData.append('icon', data.icon)
    formData.append('position', data.position.toString())
    formData.append('visibility', data.visibility)
    formData.append('externalTitle', data.externalTitle)
    formData.append('file', data.file)
    formData.append('imageAltText', data.imageAltText)

    const response = await axiosInstance.patch(`/home/content/${id}/image`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })

    return response.data as unknown
}
export const CreateInitialContentApi = async (data: UpdateHomeContentType) => {
    const formData = new FormData()

    formData.append('title', data.title ?? '')
    formData.append('subtitle', data.subtitle ?? '')
    formData.append('buttonLabel', data.buttonLabel ?? '')
    formData.append('buttonRedirection', data.buttonRedirection ?? '')
    formData.append('floatingImage', data.floatingImage ?? '')
    formData.append('icon', data.icon ?? '')
    formData.append('position', (data.position ?? '').toString())
    formData.append('visibility', data.visibility ?? '')
    formData.append('externalTitle', data.externalTitle ?? '')
    // formData.append('file', data.file)

    console.log('HOME', data?.homeId)
    const response = await axiosInstance.post(`/home/content/${data?.homeId}/image`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    return response.data as unknown
}
// export const UpdateHomeContentApi = async (id: string, data: CreateHomeContentType) => {
//     const response = await axiosInstance.put(`/home/content/update/${id}`, data)

//     return response.data as unknown
// }
export const UpdateButtonLabelHomeContentApi = async (id: string, buttonLabel: string) => {
    const response = await axiosInstance.put(`/home/content/update/${id}`, {
        buttonLabel,
    })

    return response.data as unknown
}

export const UpdateVisibilityHomeContentsApi = async (id: string, visibility: string) => {
    console.log(visibility)
    const response = await axiosInstance.put(
        `/home/content/visibility?id=${id}&visibility=${visibility}`,
    )

    return response.data as unknown
}
export const UpdatePositionHomeContentsApi = async (id: string, position: number) => {
    if (id === '') return null
    const response = await axiosInstance.put(`/home/content/arrange/${position}?id=${id}`)

    return response.data as unknown
}

export const GetHomeContentById = async (id: string) => {
    const response = await axiosInstance.get(`/home/content?id=${id}`)

    return response.data as HomeContentType
}
export const DeleteHomeContentByIdApi = async (id: string) => {
    const response = await axiosInstance.delete(`/home/content?id=${id}`)

    return response.data
}

export const UpdateSectionBackgroundApi = async (data: UpdateSectionBg) => {
    if (data?.id === '') return null
    const { bgColor, bgOpacity } = data
    const response = await axiosInstance.put(`/home/background?id=${data.id}`, {
        bgColor,
        bgOpacity,
    })

    return response.data as unknown
}
