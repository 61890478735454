import { zodResolver } from '@hookform/resolvers/zod'
import axios, { AxiosError } from 'axios'
import { AppButton } from 'components/AppButton'
import AppDatePicker from 'components/AppDatePicker'
import { AppListBox, ListBoxOptions } from 'components/AppListBox/index'
import { AppTextBox } from 'components/AppTextBox'
import { useAtom } from 'jotai'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { BiArrowBack } from 'react-icons/bi'
import { useMutation, useQuery } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { CreateUserApi, getUserByIdApi, UpdateUserApi } from 'services/requests/users'
import { CreateUserSchema, CreateUserType, UpdateUserSchema } from 'services/requests/users/schema'
import { userErrorAtom } from 'store/userAtom'
import { twMerge } from 'tailwind-merge'

const userTypeOptions: ListBoxOptions[] = [
    { value: 'CUSTOMER', label: 'Customer' },
    { value: 'ADMIN', label: 'Admin' },
]

export const UserForm = () => {
    const [errBanner, setErrBanner] = useAtom(userErrorAtom)
    const [error, setError] = useState<string>('')
    const navigate = useNavigate()
    const params = useParams()
    const id = params.id ?? ''

    const {
        control,
        handleSubmit,
        setValue,
        formState: { isDirty },
    } = useForm<CreateUserType>({
        mode: 'all',
        resolver: zodResolver(id ? UpdateUserSchema : CreateUserSchema),
        defaultValues: {
            userType: 'Customer',
        },
    })

    useQuery('getUserById', () => getUserByIdApi(id), {
        enabled: !!id,
        onSuccess: (data) => {
            setValue('firstName', data.firstName)
            setValue('lastName', data.lastName)
            data?.birthday && setValue('birthday', new Date(data?.birthday))
            setValue('gender', data.gender ?? '')
            setValue('email', data.email ?? '')
            setValue(
                'phone',
                data.phone !== undefined && data.phone !== null ? data.phone : undefined,
            )
            setValue('userType', data.userType ?? '')
        },
    })

    const { mutate: createUser } = useMutation<unknown, AxiosError, CreateUserType>(
        (data) => {
            if (id !== '') {
                return UpdateUserApi(data, id)
            } else {
                return CreateUserApi(data)
            }
        },
        {
            onSuccess: () => {
                toast.success(
                    id ? 'User has been successfully updated' : 'User has successfully been added',
                )
                navigate(-1)
            },
            onError: (error: AxiosError) => {
                if (axios.isAxiosError(error)) {
                    setErrBanner(true)
                    if (error.response?.status === 409) {
                        setError('Email address is already taken.')
                    }
                }
                console.log(error.message)
            },
        },
    )

    const onSubmit = async (data: CreateUserType) => {
        setErrBanner(false)
        createUser(data)
        console.log('Submitting : ', data)
    }

    return (
        <div className='space-y-4 '>
            <div className='w-full flex justify-center p-1'>
                <form onSubmit={handleSubmit(onSubmit)} className='w-9/12 shadow-sm bg-white '>
                    <div
                        className={twMerge(
                            'absolute bg-white ml-2 mr-4 mt-2 border border-gray-200 rounded p-4 w-[10px] h-1 inline-flex justify-center items-center',
                        )}
                    >
                        <Link to='/global-settings/user-management' className='inline'>
                            <BiArrowBack className='text-2xl text-gray-500' />
                        </Link>
                    </div>
                    <div className='text-xl text-[#861f41] font-bold bg-[#E5DEE0] pl-12 py-3'>
                        {id ? 'Edit Account' : 'Create New User Account'}
                    </div>
                    <div className='p-5 gap-3 flex flex-col'>
                        <Controller
                            control={control}
                            name='firstName'
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <div className='w-full'>
                                    <AppTextBox
                                        name={name}
                                        type='text'
                                        label='First Name'
                                        maxLength={50}
                                        value={value}
                                        onChange={onChange}
                                        placeholder='First Name'
                                        labelClassname='font-bold'
                                        inputContainerClassname='border'
                                        errorMessage={error?.message}
                                    />
                                </div>
                            )}
                        />

                        <Controller
                            control={control}
                            name='lastName'
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <div className='w-full'>
                                    <AppTextBox
                                        name={name}
                                        type='text'
                                        label='Last Name'
                                        value={value}
                                        onChange={onChange}
                                        placeholder='Last Name'
                                        maxLength={50}
                                        labelClassname='font-bold'
                                        inputContainerClassname='border'
                                        errorMessage={error?.message}
                                    />
                                </div>
                            )}
                        />

                        <h1 className='font-bold text-[#374151] text-sm'>Birthday: </h1>
                        <Controller
                            control={control}
                            name='birthday'
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <AppDatePicker
                                    name={name}
                                    onChange={onChange}
                                    value={value || undefined}
                                    errorMessage={error?.message}
                                    inputClassName='block w-full appearance-none rounded-md px-3 py-2 border border-gray-100 placeholder-gray-500 shadow-sm focus:outline-none text-xs disabled:bg-gray-100'
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name='gender'
                            render={({ field: { value, name, onChange } }) => (
                                <div className='flex gap-2 flex-col text-gray-700'>
                                    <div className='text-sm font-bold'> Gender </div>
                                    <div>
                                        <input
                                            type='radio'
                                            id='male'
                                            name={name}
                                            onChange={onChange}
                                            checked={value === 'Male'}
                                            value='Male'
                                            className='mr-2'
                                        />
                                        <label htmlFor='male'>Male</label>
                                    </div>
                                    <div>
                                        <input
                                            type='radio'
                                            id='female'
                                            name={name}
                                            onChange={onChange}
                                            checked={value === 'Female'}
                                            value='Female'
                                            className='mr-2'
                                        />
                                        <label htmlFor='female'>Female</label>
                                    </div>
                                    <div>
                                        <input
                                            type='radio'
                                            id='others'
                                            name={name}
                                            onChange={onChange}
                                            checked={value === 'Others'}
                                            value='Others'
                                            className='mr-2'
                                        />
                                        <label htmlFor='others'>Others</label>
                                    </div>
                                </div>
                            )}
                        />

                        <Controller
                            control={control}
                            name='email'
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <AppTextBox
                                    label='Email'
                                    name={name}
                                    type='text'
                                    value={value}
                                    onChange={onChange}
                                    placeholder='Email Address'
                                    maxLength={50}
                                    labelClassname='font-bold'
                                    inputContainerClassname='border'
                                    disabled={id ? true : false}
                                    errorMessage={error?.message}
                                />
                            )}
                        />
                        {errBanner ? <p className=' text-red-600 text-sm'>{error}</p> : null}
                        <Controller
                            control={control}
                            name='phone'
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <AppTextBox
                                    label='Phone Number'
                                    name={name}
                                    type='number'
                                    value={value}
                                    onChange={onChange}
                                    placeholder='Phone Number (Optional)'
                                    labelClassname='font-bold'
                                    inputContainerClassname='border'
                                    errorMessage={error?.message}
                                />
                            )}
                        />

                        <div>
                            <div className='w-52 text-sm text-gray-700'>
                                <Controller
                                    control={control}
                                    name='userType'
                                    render={({ field: { onChange, value } }) => (
                                        <AppListBox
                                            onChange={(data) => onChange(String(data))}
                                            selectedOption={value}
                                            options={userTypeOptions}
                                            label='User Type'
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-end gap-2 p-4'>
                        <AppButton
                            variant='secondary'
                            type='button'
                            onClick={() => navigate('../user-management')}
                        >
                            Cancel
                        </AppButton>
                        <AppButton variant='primary' type='submit' disabled={!isDirty}>
                            {id ? 'Update' : 'Add'}
                        </AppButton>
                    </div>
                </form>
            </div>
        </div>
    )
}
