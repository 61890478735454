import { AppModal } from 'components/AppModal'
import * as React from 'react'
import NewsCard from '../../news-card'

interface IPreviewNewsModalProps {
    isOpen: boolean
    onClose: () => void
    date: string | number
    image: string
    category: string
    title: string
    description: string
    id: string
}

const PreviewNewsModal: React.FunctionComponent<IPreviewNewsModalProps> = ({
    isOpen,
    onClose,
    date,
    image,
    category,
    title,
    id,
    description,
}) => {
    return (
        <AppModal isOpen={isOpen} onClose={onClose} closeIcon>
            <NewsCard
                // file={watch().coverImage}
                imageAltText={title}
                id={id}
                status={category}
                description={description}
                image={image}
                publishDate={date}
                title={title}
            />
        </AppModal>
    )
}

export default PreviewNewsModal
