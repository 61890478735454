import { Dialog, Popover, Transition } from '@headlessui/react'
import { AxiosError } from 'axios'
import React, { Fragment, useState } from 'react'
import { AiTwotoneDelete } from 'react-icons/ai'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { DeleteJobDetailApi, GetJobApi } from 'services/requests/jobs'
import { JobsDetailsType } from 'services/requests/jobs/schema'
import { twMerge } from 'tailwind-merge'
import AddDetails from './AddDetails'

const JobsDescriptions: React.FunctionComponent = () => {
    const [selectedDetails, setSelectedDetails] = useState<JobsDetailsType[] | []>([])
    const closeModal = () => {
        setIsOpen(false)
        refetch()
    }
    const openModal = () => {
        setIsOpen(true)
    }
    const params = useParams()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const id: string = params?.id ?? ''
    const { data, refetch } = useQuery(['jobDescriptions'], () => GetJobApi(id))
    const { mutate: deleteDetail } = useMutation<unknown, AxiosError, string>(
        (id) => DeleteJobDetailApi(id),
        {
            onSuccess: (data) => {
                console.log(data)
                refetch()
            },
            onError: (error) => {
                console.log('ERROR', error)
            },
        },
    )

    // const Requirements = useMemo(
    //     () =>
    //         data?.jobDetails.filter(
    //             (item) => item.detailsType === 'Requirements'
    //         ),
    //     [data]
    // )
    return (
        <div className='h-screen py-10'>
            <div className='flex items-center justify-between'>
                <h1 className='text-xs text-gray-400 font-bold underline'>
                    {' '}
                    Web &gt; Jobs &gt; Descriptions
                </h1>
            </div>
            <div className='flex justify-between items-center py-10'>
                <h1 className='font-bold text-xl text-[#862F4C]'>
                    {' '}
                    Description for {`"${data?.jobTitle}"`}
                </h1>
                <button
                    onClick={() => openModal()}
                    className='min-w-[166px] h-[48px] bg-[#861F41] text-white rounded-md'
                >
                    Add More
                </button>
            </div>
            <div className='mt-8 flow-root'>
                <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 min-h-[50rem] '>
                    <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                        <div className='relative'>
                            <table className='min-w-full bg-white table-fixed divide-y divide-gray-300'>
                                <thead>
                                    <tr>
                                        <th scope='col' className='relative px-7 sm:w-12 sm:px-6'>
                                            <input
                                                type='checkbox'
                                                className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 accent-[#861F41] cursor-pointer'
                                                onChange={(e) =>
                                                    e.target.checked
                                                        ? setSelectedDetails(data?.jobDetails ?? [])
                                                        : setSelectedDetails([])
                                                }
                                            />
                                        </th>
                                        <th
                                            scope='col'
                                            className='min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900'
                                        >
                                            Title & Type
                                        </th>
                                        <th
                                            scope='col'
                                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                        >
                                            Description
                                        </th>
                                        <th
                                            scope='col'
                                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                        >
                                            Last Modified
                                        </th>

                                        <th
                                            scope='col'
                                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                        >
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='divide-y divide-gray-200'>
                                    {data?.jobDetails.length ?? 0 > 0 ? (
                                        data?.jobDetails.map(
                                            (item: JobsDetailsType, index: number) => (
                                                <tr
                                                    key={item.detailsId}
                                                    className={twMerge(
                                                        index % 2 === 0
                                                            ? 'bg-[#EFEDF0]'
                                                            : 'bg-slate-50',
                                                    )}
                                                >
                                                    <td className='relative px-7 sm:w-12 sm:px-6'>
                                                        <input
                                                            type='checkbox'
                                                            className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 accent-[#861F41]'
                                                            checked={selectedDetails.some(
                                                                (job) =>
                                                                    item.detailsId ===
                                                                    job.detailsId,
                                                            )}
                                                            onChange={(e) =>
                                                                setSelectedDetails(
                                                                    e.target.checked
                                                                        ? [...selectedDetails, item]
                                                                        : selectedDetails.filter(
                                                                              (job) =>
                                                                                  job.detailsId !==
                                                                                  item.detailsId,
                                                                          ),
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                    <td
                                                        className={twMerge(
                                                            'whitespace-nowrap py-4 pr-3 text-sm font-medium',
                                                            'text-gray-900 truncate flex flex-col',
                                                        )}
                                                    >
                                                        <span>{data.jobTitle}</span>
                                                        <span className='capitalize font-semibold text-[#751132]'>
                                                            {item?.detailsType}
                                                        </span>
                                                    </td>

                                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                                                        {item?.detailsDescription}
                                                    </td>

                                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 '>
                                                        {item?.whenUpdated ?? 'not yet edited'}
                                                    </td>
                                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 '>
                                                        <Popover>
                                                            {({ close }) => (
                                                                /* Use the `open` state to conditionally change the direction of the chevron icon. */
                                                                <div className='relative'>
                                                                    <Popover.Button>
                                                                        <BsThreeDotsVertical />
                                                                    </Popover.Button>
                                                                    <Popover.Panel className='absolute right-0 z-20 bg-white p-4 flex flex-col gap-2 text-[#862F4C] font-[500] rounded-md'>
                                                                        <button
                                                                            onClick={() => {
                                                                                close()
                                                                                deleteDetail(
                                                                                    item.detailsId,
                                                                                )
                                                                            }}
                                                                            className='inline-flex items-center gap-2'
                                                                        >
                                                                            <AiTwotoneDelete />
                                                                            Delete
                                                                        </button>
                                                                    </Popover.Panel>
                                                                </div>
                                                            )}
                                                        </Popover>
                                                    </td>
                                                </tr>
                                            ),
                                        )
                                    ) : (
                                        <div className='flex flex-col justify-center items-center w-full h-full absolute min-h-[30rem]'>
                                            <h1 className='text-xs font-bold'>
                                                ...No Details to display
                                            </h1>
                                        </div>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as='div' className='relative z-10' onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div className='fixed inset-0 bg-black bg-opacity-25' />
                    </Transition.Child>

                    <div className='fixed inset-0 overflow-y-auto'>
                        <div className='flex min-h-full items-center justify-center p-4 text-center'>
                            <Transition.Child
                                as={Fragment}
                                enter='ease-out duration-300'
                                enterFrom='opacity-0 scale-95'
                                enterTo='opacity-100 scale-100'
                                leave='ease-in duration-200'
                                leaveFrom='opacity-100 scale-100'
                                leaveTo='opacity-0 scale-95'
                            >
                                <Dialog.Panel className='max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                                    <AddDetails onClose={closeModal} id={id} />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    )
}

export default JobsDescriptions
