import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { Popover } from '@headlessui/react'
import { AxiosError } from 'axios'
import ConfirmationModal from 'components/ActionConfirmation'
import { AppModal } from 'components/AppModal'
import { useAtom } from 'jotai'
import DisplayContent from 'pages/Web/Home/HOC/DisplayContent'
import * as React from 'react'
import { toast } from 'react-hot-toast'
import { AiOutlineDrag } from 'react-icons/ai'
import { BiSolidEdit } from 'react-icons/bi'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FiTrash } from 'react-icons/fi'
import { IoPersonOutline } from 'react-icons/io5'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { DeletePartnershipApi, UpdatePartnershipApi } from 'services/requests/partnerships'
import {
    ListPartnershipType,
    ObjectPartnershipType,
    UpdatePartnershipsType,
} from 'services/requests/partnerships/schema'
import { PartnershipsFetchingDataAtom, UpdatePartnershipAtom } from 'store/partnershipAtom'
import { twMerge } from 'tailwind-merge'

interface IPartnershipItemProps {
    item: ObjectPartnershipType
    checked: boolean
    setMoved: React.Dispatch<React.SetStateAction<number>>
    handleSelect: (e: boolean, item: ObjectPartnershipType) => void
    listeners?: SyntheticListenerMap | undefined
    setSelectedPartnerships: React.Dispatch<React.SetStateAction<ListPartnershipType | []>>
}
export const DraggableHandle = ({
    handleListeners,
    className,
}: {
    handleListeners: SyntheticListenerMap | undefined
    className: string
}) => {
    return (
        <button tabIndex={0} {...handleListeners} className={className}>
            <AiOutlineDrag className='h-full text-gray-500 max-h-5 max-w-5' />
        </button>
    )
}
const PartnershipItem: React.FunctionComponent<IPartnershipItemProps> = ({
    item,
    handleSelect,
    checked,
    listeners,
    setMoved,
    setSelectedPartnerships,
}) => {
    const [isOpenConfirmationModal, setIsOpenConfirmationModal] = React.useState(false)
    const [, setUpdatePartnership] = useAtom(UpdatePartnershipAtom)
    const [IsLoadingPartnership] = useAtom(PartnershipsFetchingDataAtom)
    const { mutate: deleteParnerships, isLoading: deleteLoading } = useMutation<
        unknown,
        AxiosError,
        string
    >((id) => DeletePartnershipApi(id), {
        onSuccess: () => {
            setSelectedPartnerships((prevSelectedPartnerships) =>
                prevSelectedPartnerships.filter((partnership: any) => partnership.id !== item.id),
            )
            setMoved((prev) => prev + 1)
            setIsOpenConfirmationModal(false)
            toast.success('Success! The item has been deleted.')
        },
        onError: (error) => {
            console.log('ERROR', error)
        },
    })

    const { mutate: updatePartnership, isLoading: updateLoading } = useMutation<
        unknown,
        AxiosError,
        UpdatePartnershipsType
    >((data) => UpdatePartnershipApi(item.id, data), {
        onSuccess: () => {
            toast.success('Success! Partnership updated!')
            setMoved((prev) => prev + 1)
        },
        onError: (error) => console.log(error),
    })
    const navigate = useNavigate()
    return (
        <>
            <DraggableHandle
                handleListeners={listeners}
                className='col-span-1 grid place-items-center'
            />
            <div className='col-span-1 flex items-center justify-center] min-h-[84px]'>
                <input
                    type='checkbox'
                    checked={checked}
                    className={'h-4 w-4 m-auto rounded border-gray-300 accent-[#861F41]'}
                    onChange={(e) => handleSelect(e.target.checked, item)}
                />
            </div>
            <div className='col-span-5 flex items-center min-h-[84px] gap-5 py-2'>
                <img className='w-[54px] h-[54px]' src={item?.image} alt='partnership image' />
                <div className='flex flex-col overflow-hidden w-full'>
                    <p className='leading-tight mb-2 font-bold text-[15px] text-start'>
                        {item?.title}
                    </p>
                    {/* <p className='text-[13px] text-start' style={UseTruncate(2)}>
                        {item?.description}
                    </p> */}
                    <div className='text-start w-full flex'>
                        <DisplayContent
                            isLoading={IsLoadingPartnership}
                            contentDescription={item?.description}
                        />
                    </div>
                </div>
            </div>
            {/* <div className='col-span-3 flex items-center justify-center min-h-[84px] '>
                {useFormatDate(item?.updatedAt)}
            </div> */}
            <div className='col-span-3 flex items-center justify-center min-h-[84px] capitalize'>
                {item?.status.toLowerCase()}
            </div>
            <Popover className='w-full col-span-2 flex items-center justify-center z-100'>
                {({ close }) => (
                    /* Use the `open` state to conditionally change the direction of the chevron icon. */
                    <div className='w-1/4 relative'>
                        <Popover.Button>
                            <BsThreeDotsVertical />
                        </Popover.Button>
                        <Popover.Panel className='absolute right-12 top-0 w-[12rem] bg-white shadow-md py-3 px-3 flex flex-col gap-2 font-[500] rounded-md z-30'>
                            <button
                                onClick={() => {
                                    close()
                                    setUpdatePartnership(item)
                                    navigate(`edit/${item.id}`)
                                }}
                                className='inline-flex items-center gap-2'
                            >
                                <BiSolidEdit className={twMerge('text-gray-600', 'w-5 h-5')} />
                                Update
                            </button>
                            <button
                                onClick={() => {
                                    close()
                                    setIsOpenConfirmationModal(true)
                                }}
                                className='inline-flex items-center gap-2'
                            >
                                <FiTrash
                                    className={twMerge(
                                        !deleteLoading ? 'text-gray-600' : 'text-[#751132]',
                                        'w-5 h-5',
                                    )}
                                />
                                Delete
                            </button>
                            <button
                                onClick={() => {
                                    updatePartnership({
                                        description: item.description,
                                        title: item.title,
                                        status: item.status !== 'DRAFT' ? 'DRAFT' : 'ACTIVE',
                                    })
                                    close()
                                }}
                                className='inline-flex items-center gap-2'
                            >
                                <IoPersonOutline
                                    className={twMerge(
                                        !updateLoading ? 'text-gray-600' : 'text-[#751132]',
                                        'w-5 h-5',
                                    )}
                                />
                                {`Move to ${item.status !== 'DRAFT' ? 'draft' : 'active'}`}
                            </button>
                        </Popover.Panel>
                    </div>
                )}
            </Popover>
            <AppModal
                closeIcon={false}
                isOpen={isOpenConfirmationModal}
                onClose={() => setIsOpenConfirmationModal(false)}
                title=''
                containerClassName='h-fit p-0 my-0'
            >
                <ConfirmationModal
                    handle={() => deleteParnerships(item.id)}
                    onClose={() => setIsOpenConfirmationModal(false)}
                    yesLabel='Delete'
                    noLabel='Cancel'
                    title='Delete Item'
                    isLoading={deleteLoading}
                    subtitle='Are you sure you want to delete this item?'
                />
            </AppModal>
        </>
    )
}

export default PartnershipItem
