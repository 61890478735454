export const StatusOptions = ['ACTIVE', 'INACTIVE']
export const UseTruncate = (number: number) => {
    const styles: object = {
        display: '-webkit-box',
        WebkitLineClamp: number,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
    }
    return styles
}

export const VisibilityStatus = Object.freeze({
    active: 'ACTIVE',
    inactive: 'INACTIVE',
})
export const homeContents = Object.freeze({
    giftCardTitle: 'gift-card-title',
    giftCardDescription: 'gift-card-description',
    homeMenuTitle: 'home-menu-title',
    homeMenuDescription: 'home-menu-description',
    homeMobileAppTitle: 'home-mobile-app-title',
    homeMobileAppDescription: 'home-mobile-app-description',
    logoGalleryTitle: 'logo-gallery-title',
    logoGalleryDescription: 'logo-gallery-description',
})

export const homeCorousel = Object.freeze({
    title: 'home-carousel',
})
export const homeMenuContent = Object.freeze({
    title: 'content-menu-home',
})
export const appContent = Object.freeze({
    title: 'content-app-home',
})
export const partnersContent = Object.freeze({
    title: 'content-partners-home',
})
export const homeMenuContents = Object.freeze({
    firstCategory: 'first-category',
    secondCategory: 'second-category',
    thirdCategory: 'third-category',
    fourthCategory: 'fourth-category',
})

export const homeAppContents = Object.freeze({
    first: 'first-mob-app',
    second: 'second-mob-app',
    third: 'third-mob-app',
})
export const partnersAppContents = Object.freeze({
    first: 'first-partner',
    second: 'second-partner',
    third: 'third-partner',
    fourth: 'fourth-partner',
})

export const badRequestError =
    'Something went wrong; perhaps the file exceeds the maximum file size limit or has an unsupported format.'

export const uuidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/

export const errorWhiteSpaces = 'Invalid: Avoid consecutive or leading/trailing white spaces.'
