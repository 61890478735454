import React from 'react'
import { BiDollar } from 'react-icons/bi'
import { twMerge } from 'tailwind-merge'

interface GiftCardPriceInputProps {
    isReadOnly?: boolean
    giftCard: {
        price: number
    }
    handlePriceChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const GiftCardPriceInput = (props: GiftCardPriceInputProps) => {
    return (
        <>
            <div className='pt-4 pb-4 pl-10 sm:pl-auto'>
                <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-2'>
                        <BiDollar className='h-5 w-5 mt-0 text-gray-600' />
                    </div>
                    <input
                        className={twMerge(
                            props.isReadOnly
                                ? 'cursor-default'
                                : ' focus:ring-red-700 focus:border-red-700',
                            'pl-8 pr-3 py-2 focus:outline-none text-left border border-gray-300 rounded-md  sm:text-sm w-36',
                        )}
                        type='text'
                        readOnly={props.isReadOnly}
                        name='faceAmount'
                        value={props.giftCard.price.toFixed(2)}
                        onChange={props.handlePriceChange}
                    />
                </div>
            </div>
        </>
    )
}
