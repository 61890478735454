import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'

interface IProps {
    displayMinMax?: boolean
    min?: string
    max?: string
    name: string
    disabled?: boolean
    required?: boolean
    value?: string
    onChange: (value: string) => void
    placeholder?: string
    label?: string
    labelPosition?: 'top' | 'left'
    containerClassname?: string
    inputClassname?: string
    labelClassname?: string
    errorMessage?: string
    setCriteria?: React.Dispatch<React.SetStateAction<boolean>>
}
const AppSlider: React.FC<IProps> = (props) => {
    const [, setThumbPosition] = useState(parseInt(props?.value ?? '0') ?? 0)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value
        props?.onChange(newValue)
        const target = event.target as HTMLInputElement
        const { clientWidth, value, min, max } = target
        const percent = ((Number(value) - Number(min)) / (Number(max) - Number(min))) * 100
        const thumbWidth = 16 // Adjust this value based on your thumb width
        const thumbPosition = (percent * (clientWidth - thumbWidth)) / 100
        setThumbPosition(thumbPosition)
    }
    return (
        <div className={twMerge('relative', props?.containerClassname)}>
            {props.label && (
                <label htmlFor={props?.name} className={props.labelClassname}>
                    {props.label}
                </label>
            )}
            <span className='text-xs'>{`${props?.value}%`}</span>
            <input
                id={props.name}
                name={props.name}
                type='range'
                min={props.min ?? '0'}
                max={props.max ?? '100'}
                value={props.value}
                disabled={props.disabled}
                onChange={handleChange}
                className={twMerge(
                    'w-full h-4 bg-blue-200 appearance-none rounded-full focus:outline-none focus:bg-blue-500 transition-all',
                    props?.inputClassname,
                )}
            />
            {/* <span>{props.max ?? 100}</span> */}
            {/* { <div
				className={twMerge(props.disabled&&'hidden','absolute -top-7 left-[10px] transform translate-x-1/2 -translate-y-full text-xxs border-2')}
				style={{ transform: `translateX(${thumbPosition}px)` }}
				
			>
				<p className='bg-white px-2 py-1 rounded text-center'>
					{props.value ?? 50}
				</p>
				</div>
			} */}
            {props.errorMessage && <p className='text-red-900 text-[12px]'>{props.errorMessage}</p>}
        </div>
    )
}

export default AppSlider
