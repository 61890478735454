export const convertTime = (militaryTime: string) => {
    if (militaryTime) {
        militaryTime.replace(':', '')
        // Extract hours and minutes from militaryTime
        let hours = parseInt(militaryTime.substring(0, 2))
        let minutes = militaryTime.substring(2)

        // Determine AM/PM and adjust hours accordingly
        const ampm = hours >= 12 ? 'PM' : 'AM'
        hours = hours % 12
        hours = hours ? hours : 12

        // Add leading zero to minutes if necessary
        minutes = minutes.length === 1 ? '0' + minutes : minutes

        // Return standard time string
        return hours + '' + minutes + ' ' + ampm
        // return militaryTime
    }
    return ''
}

export const useFormatDate = (date: any) => {
    const raw = new Date(date)
    const split = raw.toDateString().split(' ')
    // return `${split[1]??''} ${split[2]??''}, ${split[3]??''} - ${convertTime(`${raw.getHours().toLocaleString().length === 1 ? '0'+ raw.getHours()??'' : raw.getHours()??''}:${raw.getMinutes().toLocaleString().length === 1 ? '0'+ raw.getMinutes()??'' : raw.getMinutes()??''}`)}`
    return `${split[1] ?? ''} ${split[2] ?? ''}, ${split[3] ?? ''}`
}

export const truncateString = (inputString: string, numberOfWords = 30) => {
    // Split the string into an array of words
    const words = inputString.split(/\s+/)

    // Check if the number of words is greater than 30
    if (words.length > numberOfWords) {
        // If yes, truncate the array to the first 30 words and join them back into a string
        return words.slice(0, numberOfWords).join(' ') + '...'
    } else {
        // If no, return the original string
        return inputString
    }
}

export const useFormatDateSlash = (date: number) => {
    const raw = new Date(date)
    const split = raw.toDateString().split(' ')
    return `${split[2]}/${raw.getMonth()}/${split[3]}`
}
export const useKnowDaysAgo = (timestamp: number) => {
    const millisecondsPerDay = 86400000

    const daysAgo = Math.round((Date.now() - timestamp) / millisecondsPerDay)

    const rtf = new Intl.RelativeTimeFormat('en', {
        style: 'long',
        numeric: 'auto',
    })

    return rtf.format(-daysAgo, 'day')
}
