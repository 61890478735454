import { ChangeEvent } from 'react'

interface AppTimePickerProps {
    name: string
    value: string
    onChange: (value: string) => void
}

export const AppTimePicker = ({ name, value, onChange }: AppTimePickerProps) => {
    const handleTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value)
    }

    return (
        <div className='border-2 border-gray-200 p-2 rounded-md'>
            <input
                name={name}
                type='time'
                value={value}
                onChange={handleTimeChange}
                className='w-full px-3 placeholder-gray-600 rounded-lg outline-none'
            />
        </div>
    )
}
