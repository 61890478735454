import { Popover } from '@headlessui/react'
import { AxiosError } from 'axios'
import ConfirmationModal from 'components/ActionConfirmation'
import AppAutoComplete from 'components/AppAutocomplete'
import { AppButton } from 'components/AppButton'
import AppEmptyTable from 'components/AppEmptyTable'
import { AppModal } from 'components/AppModal'
import AppPageName from 'components/AppPageName'
import AppSkeletonLoadingNews from 'components/AppSkeletonLoaders/AppSkeletonLoadingNews'
import { useAtom } from 'jotai'
import React, { useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { BiSolidEdit } from 'react-icons/bi'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FiTrash } from 'react-icons/fi'
import { MdPublish } from 'react-icons/md'
import { RiDraftFill } from 'react-icons/ri'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { DeleteNewsApi, getNewsApi, UpdateNewsToDraftApi } from 'services/requests/news'
import { NewsResponseType } from 'services/requests/news/schema'
import { NewsToUpdateAtom } from 'store/newsAtom'
import { twMerge } from 'tailwind-merge'
import { tableItemStyle, tableWebHeader } from 'utils/styles'
const newscategories = [
    {
        name: 'all',
        label: 'All',
    },
    {
        name: 'Published',
        label: 'Published',
    },
    {
        name: 'Draft',
        label: 'Draft',
    },
]
const TableHeading = ['Title', 'Author', 'Category', 'Action']
const News: React.FunctionComponent = () => {
    //@Constants
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    //@STATES
    const [selectedFilter, setSelectedFilter] = React.useState<string>('all')
    const [selectedNews, setSelectedNews] = useState<NewsResponseType[] | []>([])
    const [category, setcategory] = useState<string>('All categories')
    const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false)
    const [isOpenBulkConfirmationModal, setIsOpenBulkConfirmationModal] = useState(false)
    const [idToDelete, setIdToDelete] = useState<string | null>(null)
    const [, setNewsToUpdate] = useAtom(NewsToUpdateAtom)
    //@API CALLS
    const { data, isLoading: isNewsLoading } = useQuery(['news'], () => getNewsApi())
    const { mutate: deleteNews, isLoading: deleteNewsLoading } = useMutation<
        unknown,
        AxiosError,
        string
    >((id) => DeleteNewsApi(id), {
        onSuccess: () => {
            queryClient.invalidateQueries('news')
            toast.success(
                <div className='space-y-2'>
                    <span className='font-bold'>Deleted Successfully!</span>
                    <p>News has been deleted successfully.</p>
                </div>,
            )
            setIsOpenBulkConfirmationModal(false)
            setIsOpenConfirmationModal(false)
        },
        onError: (error) => {
            console.log('ERROR', error)
        },
    })
    interface IVisibility {
        id: string
        visibility: string
    }
    const { mutate: toDraft } = useMutation<unknown, AxiosError, IVisibility>(
        ({ id, visibility }) => UpdateNewsToDraftApi(id, visibility),
        {
            onSuccess: (data, item) => {
                if (item.visibility == 'Published') {
                    toast.success(
                        <div className='space-y-2'>
                            <span className='font-bold'>Article published successfully!</span>
                            <p>The article is now live and can be accessed by users.</p>
                        </div>,
                    )
                }
                if (item.visibility == 'Draft') {
                    toast.success(
                        <div className='space-y-2'>
                            <span className='font-bold'>Draft saved successfully!</span>
                            <p>The news article is now securely stored in draft.</p>
                        </div>,
                    )
                }

                queryClient.invalidateQueries('news')
            },
            onError: (error: AxiosError) => console.log(error),
        },
    )

    //@Functions
    const filteredArray = React.useMemo(() => {
        if (!data) return []

        const lowerCaseSelectedFilter = selectedFilter.toLowerCase()
        const lowerCaseCategory = category.toLowerCase()

        return data.filter((item: NewsResponseType) => {
            const itemVisibilityMatches = item.visibility.toLowerCase() === lowerCaseSelectedFilter
            const categoryMatches =
                lowerCaseCategory === item.category.toLowerCase() || category === 'All categories'

            if (selectedFilter === 'all') {
                return categoryMatches
            } else {
                return itemVisibilityMatches && categoryMatches
            }
        })
    }, [data, selectedFilter, category])

    const handleBulkItemsVisibility = (visibility: 'Draft' | 'Published') => {
        if (selectedNews.length > 0) {
            selectedNews.forEach((element) => {
                if (element.visibility !== visibility)
                    toDraft({
                        id: element.newsId,
                        visibility,
                    })
            })
        }
        setSelectedNews([])
    }
    const handleBulkToDelete = () => {
        if (selectedNews.length > 0) {
            selectedNews.forEach((element) => {
                deleteNews(element.newsId)
            })
        }
        setSelectedNews([])
    }
    const knowLength = (array: NewsResponseType[] | undefined, type: string) => {
        if (!array) return 0
        return array
            .slice()
            .filter(
                (item: NewsResponseType) =>
                    item?.visibility.toLocaleLowerCase() === type?.toLocaleLowerCase() ||
                    type === 'all',
            ).length
    }

    useMemo(() => {
        setSelectedNews([])
        queryClient.invalidateQueries('news')
    }, [selectedFilter])

    const isSelectedMorethanOne = React.useMemo(() => selectedNews.length > 1, [selectedNews])

    const handlecategoryChange = (value: string) => {
        setcategory(value)
    }
    const countNewsByVisibility = (visibility: 'Published' | 'Draft') =>
        (selectedNews as NewsResponseType[]).reduce(
            (acc: number, item: NewsResponseType) => acc + (visibility === item.visibility ? 1 : 0),
            0,
        )

    const isPublishedMore = useMemo(
        () => countNewsByVisibility('Published') >= countNewsByVisibility('Draft'),
        [selectedNews],
    )

    if (isNewsLoading) return <AppSkeletonLoadingNews />
    return (
        <div>
            <div className='flex items-center justify-between'></div>
            <div className='flex flex-col w-full gap-5'>
                <AppPageName pageName='News' />
                <div className='flex flex-col md:flex-row justify-center md:justify-start gap-5 border-b pb-1 border-[#862F4C] border-opacity-50'>
                    {newscategories.map((item, index) => (
                        <button
                            className='inline-flex gap-1 text-[#861F41] hover:opacity-80 relative group transition-all px-3 justify-center'
                            key={index}
                            onClick={() => setSelectedFilter(item.name)}
                        >
                            <span>{item.label}</span>
                            <span>{`
                            (${knowLength(data, item.name)})`}</span>
                            <div
                                className={twMerge(
                                    'absolute block -bottom-2 opacity-0 min-w-full h-2 bg-[#862F4C] rounded-full group-hover:opacity-100 transition-all',
                                    selectedFilter === item.name && 'opacity-100 block',
                                )}
                            />
                        </button>
                    ))}
                </div>
                <div className='flex flex-col w-full h-full bg-white py-4 shadow-lg border'>
                    <div className='inline-flex gap-2 w-full p-4 justify-between'>
                        <AppAutoComplete
                            value={category}
                            containerClassname='my-0'
                            name={'category'}
                            list={['All categories', 'Latest News', 'Featured', 'More']}
                            placeholder='categories'
                            onChange={handlecategoryChange}
                        />
                        <div className='flex flex-col gap-5 items-end relative w-[300px]'>
                            <AppButton
                                variant='primary'
                                type='button'
                                onClick={() => navigate('add')}
                                className='text-[14px] px-6'
                            >
                                Add New Post
                            </AppButton>
                            <div
                                className={twMerge(
                                    selectedNews.length > 0
                                        ? ' text-sm inline-flex gap-5 text-[#861F41] font-bold flex-row-reverse absolute -bottom-8 right-0'
                                        : 'hidden',
                                )}
                            >
                                <button
                                    onClick={() => {
                                        close()
                                        setIsOpenBulkConfirmationModal(true)
                                    }}
                                    className='inline-flex items-center gap-2'
                                >
                                    <FiTrash className='h-5 w-5' />
                                    Delete
                                </button>
                                <button
                                    onClick={() => {
                                        close()
                                        handleBulkItemsVisibility(
                                            isPublishedMore ? 'Draft' : 'Published',
                                        )
                                    }}
                                    className={twMerge('inline-flex items-center gap-2')}
                                >
                                    {isPublishedMore ? (
                                        <>
                                            <RiDraftFill /> Move to Draft
                                        </>
                                    ) : (
                                        <>
                                            <MdPublish /> Publish
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='mt-8 flow-root'>
                        <div className=' md:min-h-[50rem] w-full'>
                            <div className='inline-block py-2 align-middle min-w-full'>
                                <div className='relative'>
                                    <table className='w-full table-fixed'>
                                        <thead
                                            className={twMerge(
                                                tableWebHeader,
                                                'bg-[#EDDFE2] h-[55px]',
                                            )}
                                        >
                                            <tr className='font-bold'>
                                                <th
                                                    scope='col'
                                                    className='relative px-7 sm:w-12 sm:px-6'
                                                >
                                                    <input
                                                        type='checkbox'
                                                        checked={
                                                            filteredArray?.length ===
                                                                selectedNews?.length &&
                                                            selectedNews.length > 0
                                                        }
                                                        className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 accent-[#861F41] hover:cursor-pointer'
                                                        onChange={(e) =>
                                                            e.target.checked
                                                                ? setSelectedNews(filteredArray)
                                                                : setSelectedNews([])
                                                        }
                                                    />
                                                </th>
                                                {TableHeading.map((header, index) => (
                                                    <th
                                                        key={index}
                                                        scope='col'
                                                        className={twMerge(
                                                            'py-3.5 pr-3 w-4/12 text-sm text-left',
                                                            header === 'Action'
                                                                ? 'w-1/6 px-3 text-center'
                                                                : '',
                                                            header === 'Category' ? 'w-1/6' : '',
                                                        )}
                                                    >
                                                        {header}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody className=''>
                                            {filteredArray.length > 0 ? (
                                                filteredArray.map((item, index) => (
                                                    <tr
                                                        key={item.newsId}
                                                        className={twMerge(
                                                            tableItemStyle(index),
                                                            'text-[14px]',
                                                        )}
                                                    >
                                                        <td className='relative px-7 sm:w-12 sm:px-6'>
                                                            <input
                                                                type='checkbox'
                                                                className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 accent-[#861F41] hover:cursor-pointer'
                                                                checked={selectedNews.some(
                                                                    (news) =>
                                                                        item.newsId === news.newsId,
                                                                )}
                                                                onChange={(e) =>
                                                                    setSelectedNews(
                                                                        e.target.checked
                                                                            ? [
                                                                                  ...selectedNews,
                                                                                  item,
                                                                              ]
                                                                            : selectedNews.filter(
                                                                                  (news) =>
                                                                                      news.newsId !==
                                                                                      item.newsId,
                                                                              ),
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                        <td
                                                            className={twMerge(
                                                                'whitespace-nowrap py-4 pr-3 font-medium',
                                                                'text-gray-900 flex flex-col',
                                                            )}
                                                        >
                                                            <span className='truncate text-[15px]'>
                                                                {item?.title}
                                                            </span>
                                                            <span className='capitalize font-semibold text-[#751132] text-[13px]'>
                                                                {item?.visibility}
                                                            </span>
                                                        </td>

                                                        <td className='whitespace-nowrap px-3 py-4 text-gray-500 truncate'>
                                                            {item?.author}
                                                        </td>
                                                        <td className='whitespace-nowrap px-3 py-4 text-gray-500 truncate'>
                                                            {item?.category}
                                                        </td>

                                                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
                                                            {useFormatDateSlash(item?.whenUpdated) ??
                                                                'not yet edited'}
                                                        </td> */}
                                                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                                            <Popover>
                                                                {({ close }) => (
                                                                    /* Use the `open` state to conditionally change the direction of the chevron icon. */
                                                                    <div className='w-1/2 relative flex items-end justify-end'>
                                                                        <Popover.Button>
                                                                            <BsThreeDotsVertical />
                                                                        </Popover.Button>
                                                                        <Popover.Panel className='absolute right-8 top-0 z-20 bg-white p-2 flex flex-col gap-2 text-[#862F4C] font-[500] rounded-md min-w-[10rem] shadow-lg justify-center'>
                                                                            <button
                                                                                onClick={() => {
                                                                                    setNewsToUpdate(
                                                                                        item,
                                                                                    )
                                                                                    navigate(
                                                                                        `edit/${item.newsId}`,
                                                                                    )
                                                                                }}
                                                                                type='button'
                                                                                className='inline-flex gap-2 items-center p-1'
                                                                            >
                                                                                <BiSolidEdit className='h-5 w-5' />
                                                                                <span className=''>
                                                                                    Update
                                                                                </span>
                                                                            </button>
                                                                            <button
                                                                                onClick={() => {
                                                                                    close()
                                                                                    setIdToDelete(
                                                                                        item?.newsId,
                                                                                    )
                                                                                    setIsOpenConfirmationModal(
                                                                                        true,
                                                                                    )
                                                                                }}
                                                                                className='inline-flex items-center gap-2 p-1'
                                                                            >
                                                                                <FiTrash className='h-5 w-5' />
                                                                                Delete
                                                                            </button>
                                                                            <button
                                                                                onClick={() => {
                                                                                    close()
                                                                                    toDraft({
                                                                                        id: item.newsId,
                                                                                        visibility:
                                                                                            item.visibility ===
                                                                                            'Published'
                                                                                                ? 'Draft'
                                                                                                : 'Published',
                                                                                    })
                                                                                }}
                                                                                className='inline-flex items-center gap-2 p-1'
                                                                            >
                                                                                {item.visibility ===
                                                                                'Published' ? (
                                                                                    <>
                                                                                        <RiDraftFill className='h-5 w-5' />{' '}
                                                                                        Move to
                                                                                        Draft
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <MdPublish className='h-5 w-5' />{' '}
                                                                                        Publish
                                                                                    </>
                                                                                )}
                                                                            </button>
                                                                        </Popover.Panel>
                                                                    </div>
                                                                )}
                                                            </Popover>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <AppEmptyTable
                                                    isEmptyState={!data}
                                                    isHideDescription
                                                    containerClassName='flex flex-col justify-center items-center w-full h-full absolute min-h-[20rem]'
                                                />
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AppModal
                closeIcon={false}
                isOpen={isOpenConfirmationModal}
                onClose={() => setIsOpenConfirmationModal(false)}
                title=''
                containerClassName='h-fit p-0 my-0'
            >
                <ConfirmationModal
                    handle={() => idToDelete && deleteNews(idToDelete)}
                    onClose={() => setIsOpenConfirmationModal(false)}
                    yesLabel='Delete'
                    noLabel='Cancel'
                    title='Delete News'
                    isLoading={deleteNewsLoading}
                    subtitle='Are you sure you want to delete this item?'
                />
            </AppModal>
            <AppModal
                closeIcon={false}
                isOpen={isOpenBulkConfirmationModal}
                onClose={() => setIsOpenBulkConfirmationModal(false)}
                title=''
                containerClassName='h-fit p-0 my-0'
            >
                <ConfirmationModal
                    handle={() => handleBulkToDelete()}
                    onClose={() => setIsOpenBulkConfirmationModal(false)}
                    yesLabel='Delete'
                    noLabel='Cancel'
                    title='Delete Selected News'
                    isLoading={deleteNewsLoading}
                    subtitle={`Are you sure you want to delete ${
                        isSelectedMorethanOne ? 'these items' : 'this item'
                    }?`}
                />
            </AppModal>
        </div>
    )
}

export default News
