import { z } from 'zod'
import { ListProductsSchema } from '../menu-category/schema'

export const StoreHoursSchema = z.object({
    openTime: z.string(),
    closeTime: z.string(),
    dayOfWeek: z.number(),
    active: z.boolean(),
})

export const StoreDirectionSchema = z.object({
    description: z.string(),
    storeId: z.string(),
})

export const UpdateStoreSchema = z.object({
    externalId: z.number().or(z.string()),
    shopName: z.string().min(2),
    shopAddress: z.string(),
    latitude: z.number(),
    longitude: z.number(),
    status: z.string(),
    description: z.string(),
    restricted: z.boolean(),
    restrictNote: z.string().nullish(),
    file: z.instanceof(File).or(z.string()).nullish(),
    storeHours: z.array(StoreHoursSchema),
})

export const MenuSchema = z.object({
    id: z.string(),
    externalId: z.number(),
    productId: z.string(),
    originalPrice: z.number(),
    price: z.number(),
    priceType: z.string(),
    name: z.string(),
    coverPhoto: z.string(),
    pictureNutrient: z.string(),
    allergenPhoto: z.string(),
    description: z.string(),
    promotionEndDate: z.string(),
    promotionPrice: z.string(),
    promotionStartDate: z.string(),
    stockRemain: z.number(),
    status: z.string(),
})

export const LocationSchema = z.object({
    id: z.string(),
    shopId: z.string(),
    shopName: z.string(),
    shopAddress: z.string(),
    latitude: z.number(),
    longitude: z.number(),
    status: z.string(),
    createdBy: z.string(),
    updatedBy: z.string(),
    createdAt: z.number(),
    updatedAt: z.number(),
    menu: z.array(MenuSchema),
    externalId: z.number(),
    restricted: z.boolean(),
    restrictNote: z.string(),
    tax: z.number(),
    imageName: z.string(),
    machineLocationDescription: z.string(),
    direction: z.object({
        id: z.string(),
        description: z.string(),
    }),
    storeHours: z.array(StoreHoursSchema),
})

export const PageListLocationSchema = z.object({
    content: z.array(LocationSchema),
    pageable: z.object({
        sort: z.object({
            empty: z.boolean(),
            sorted: z.boolean(),
            unsorted: z.boolean(),
        }),
        offset: z.number(),
        pageNumber: z.number(),
        pageSize: z.number(),
        unpaged: z.boolean(),
        paged: z.boolean(),
    }),
    last: z.boolean(),
    totalElements: z.number(),
    totalPages: z.number(),
    size: z.number(),
    number: z.number(),
    sort: z.object({
        empty: z.boolean(),
        sorted: z.boolean(),
        unsorted: z.boolean(),
    }),
    first: z.boolean(),
    numberOfElements: z.number(),
    empty: z.boolean(),
})

export const StoreSchema = z.object({
    id: z.string(),
    externalId: z.number(),
    shopName: z.string(),
    status: z.string(),
    machineLocationDescription: z.string(),
    restrictNote: z.string(),
    storeHours: z.array(StoreHoursSchema),
    direction: z.object({
        id: z.string(),
        description: z.string(),
    }),
    restricted: z.boolean(),
    createdAt: z.number(),
    updatedAt: z.number(),
})

export const MenuItemListSchema = z.array(
    z.object({
        id: z.string(),
        externalId: z.string(),
        product: z.array(ListProductsSchema),
        store: z.array(StoreSchema),
    }),
)

export type LocationType = z.infer<typeof LocationSchema>
export type PageListLocationType = z.infer<typeof PageListLocationSchema>
export type UpdateStoreType = z.infer<typeof UpdateStoreSchema>
export type StoreDirectionType = z.infer<typeof StoreDirectionSchema>
export type MenuItemsType = z.infer<typeof MenuSchema>
export type StoreHoursType = z.infer<typeof StoreHoursSchema>
