import { Menu, Transition } from '@headlessui/react'
import AppSkeletonLoadingTable from 'components/AppSkeletonLoaders/AppSkeletonLoadingTable'
import { EmptyTableState } from 'components/EmptyState/table-empty-state'
import { Fragment } from 'react'
import { BsChevronDown } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { OrderType } from 'services/requests/orders/schema'
import { twMerge } from 'tailwind-merge'
import { numberFormatter } from 'utils'
import { convertUnixTimestamp } from 'utils/time'

interface OrderTableProps {
    orders: OrderType[] | undefined
    isLoading: boolean
    handleDescending?: () => void
    handleAscending?: () => void
}

export const OrderTable = (props: OrderTableProps) => {
    const navigate = useNavigate()

    const handleNavigate = (id: string) => {
        navigate(`/order/${id}`)
    }

    const handleStatus = (status: string) => {
        switch (status) {
            case 'ORDER_CREATED':
                return 'Order Placed'
            case 'PENDING':
                return 'Pick-Up Pending'
            case 'COOKING':
                return 'Cooking'
            case 'DONE':
                return 'Complete'
            case 'REFUND_INITIATED':
                return 'Refund Initiated'
            case 'REFUNDED':
                return 'Refunded'
            case 'PARTIALLY_REFUNDED':
                return 'Partially Refunded'
            case 'REFUND_FAILED':
                return 'Refund Failed'
            case 'PARTIALLY_FAILED':
                return 'Partially Failed'
            default:
                return 'Failed'
        }
    }

    if (props.isLoading) return <AppSkeletonLoadingTable />

    if (!props.orders || props.orders.length === 0) {
        return <EmptyTableState />
    }

    return (
        <div className='mt-8 flow-root'>
            <div className='-mx-4 -my-2 overflow-x-auto lg:-mx-8'>
                <div className='inline-block min-w-full py-2 align-middle w-full px-4 sm:px-4 lg:px-8 xl:px-8'>
                    <div className='overflow-x-scroll border shadow ring-1 ring-black ring-opacity-5 rounded-lg 2xl:overflow-hidden'>
                        <table className='min-w-full divide-y divide-gray-300'>
                            <thead className='bg-red-100 w-full'>
                                <tr>
                                    <th
                                        scope='col'
                                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-red-800 sm:pl-6'
                                    >
                                        Order #
                                    </th>
                                    <th
                                        scope='col'
                                        className='relative px-3 py-3.5 text-left text-sm font-semibold text-red-800'
                                    >
                                        <Menu as='div'>
                                            <div>
                                                <Menu.Button className='px-3 py-3.5 text-left text-sm font-semibold text-red-800 flex'>
                                                    Date
                                                    <BsChevronDown
                                                        className='ml-2 mt-1 h-3 w-3 text-black'
                                                        aria-hidden='true'
                                                    />
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter='transition ease-out duration-100'
                                                enterFrom='transform opacity-0 scale-95'
                                                enterTo='transform opacity-100 scale-100'
                                                leave='transition ease-in duration-75'
                                                leaveFrom='transform opacity-100 scale-100'
                                                leaveTo='transform opacity-0 scale-95'
                                            >
                                                <Menu.Items className='absolute left-[1%] z-10 mt-0 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                                    <div className='py-1'>
                                                        <div className='px-4 py-2'>
                                                            <p className='text-xs text-gray-400'>
                                                                ORDER
                                                            </p>
                                                        </div>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    href='#'
                                                                    className={twMerge(
                                                                        active
                                                                            ? 'bg-gray-100 text-gray-900'
                                                                            : 'text-gray-700',
                                                                        'block px-4 py-2 text-xs',
                                                                    )}
                                                                    onClick={props.handleDescending}
                                                                >
                                                                    Descending
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    href='#'
                                                                    className={twMerge(
                                                                        active
                                                                            ? 'bg-gray-100 text-gray-900'
                                                                            : 'text-gray-700',
                                                                        'block px-4 py-2 text-xs',
                                                                    )}
                                                                    onClick={props.handleAscending}
                                                                >
                                                                    Ascending
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </th>
                                    <th
                                        scope='col'
                                        className='px-3 py-3.5 text-left text-sm font-semibold text-red-800'
                                    >
                                        Store
                                    </th>
                                    <th
                                        scope='col'
                                        className='px-3 py-3.5 text-left text-sm font-semibold text-red-800'
                                    >
                                        Customer
                                    </th>
                                    <th
                                        scope='col'
                                        className='px-3 py-3.5 text-left text-sm font-semibold text-red-800'
                                    >
                                        Type
                                    </th>
                                    <th
                                        scope='col'
                                        className='px-3 py-3.5 text-left text-sm font-semibold text-red-800'
                                    >
                                        Payment
                                    </th>
                                    <th
                                        scope='col'
                                        className='px-3 py-3.5 text-left text-sm font-semibold text-red-800'
                                    >
                                        Status
                                    </th>

                                    <th
                                        scope='col'
                                        className='px-3 py-3.5 text-left text-sm font-semibold text-red-800'
                                    >
                                        Items
                                    </th>
                                    <th
                                        scope='col'
                                        className='px-3 py-3.5 text-left text-sm font-semibold text-red-800'
                                    >
                                        Total
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='divide-y divide-gray-200 bg-white'>
                                {props.orders.map((order) => (
                                    <tr
                                        key={order.id}
                                        onClick={() => handleNavigate(order.id)}
                                        className='hover:bg-gray-100 hover:cursor-pointer'
                                    >
                                        <td className='whitespace-nowrap py-4 pl-4 pr-4 lg:pr-6 2xl:pr-3 text-sm font-bold text-gray-800 sm:pl-6'>
                                            {order.externalId.replace(/DT/g, '')}
                                        </td>
                                        <td className='whitespace-nowrap px-3 py-4 lg:pr-6 xl:pr-[5vw] 2xl:pr-0 text-sm text-gray-500'>
                                            {convertUnixTimestamp(order.orderDate)}
                                        </td>
                                        <td className='whitespace-nowrap px-3 py-4 lg:pr-6 xl:pr-[5vw] 2xl:pr-0 text-sm text-gray-500'>
                                            {order?.store?.shopName ?? 'N/A'}
                                        </td>
                                        <td className='whitespace-nowrap px-3 py-4 md:pr-4 lg:pr-6 xl:pr-[5vw] 2xl:pr-0 text-sm text-gray-500'>
                                            {order.customerName}
                                        </td>
                                        <td className='whitespace-nowrap px-3 py-4 pr-[8vw] md:pr-14 xl:pr-[4.5vw] 2xl:pr-0 text-sm text-gray-500'>
                                            {order.type}
                                        </td>
                                        <td className='whitespace-nowrap px-3 py-4 pr-[8vw] xl:pr-[6.3vw] 2xl:pr-0 text-sm text-gray-500'>
                                            {order.cardType}
                                        </td>
                                        <td className='whitespace-nowrap px-3 py-4 pr-[8vw] xl:pr-[6vw] 2xl:pr-0 text-sm text-gray-500'>
                                            {handleStatus(order.status)}
                                        </td>
                                        <td className='whitespace-nowrap px-3 py-4 pr-[8vw] xl:pr-[6vw] 2xl:pr-0 text-sm text-gray-500'>
                                            {order.items.length}
                                        </td>
                                        <td className='whitespace-nowrap px-3 py-4 pr-[8vw] xl:pr-[4vw] 2xl:pr-0 text-sm text-gray-500'>
                                            ${numberFormatter.format(order.orderTotal)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
