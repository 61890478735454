import {
    closestCenter,
    DndContext,
    DragEndEvent,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { AxiosError } from 'axios'
import { AppButton } from 'components/AppButton'
import React from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { UpdatePositionHomeContentsApi } from 'services/requests/home'
import { HomeContentType } from 'services/requests/home/schema'
import CarouselItem from './CarouselItem'

interface IUpdatePos {
    id: string
    pos: number
}
interface ISortableCarouselProps {
    array: HomeContentType[] | []
    isLoading: boolean
    setSortedData: React.Dispatch<React.SetStateAction<HomeContentType[] | null>>
    sortedData: HomeContentType[] | null
}
const SortableCarouselItems: React.FunctionComponent<ISortableCarouselProps> = (
    props: ISortableCarouselProps,
) => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const pointerSensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 10,
        },
    })
    const sensors = useSensors(pointerSensor)

    const { mutate: savePosition } = useMutation<unknown, AxiosError, IUpdatePos>(
        (data) => {
            return UpdatePositionHomeContentsApi(data.id, data.pos)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('content-carousel')
            },
        },
    )
    const handleDrag = (event: DragEndEvent) => {
        const { active, over } = event
        if (props.array) {
            if (active.id !== over?.id) {
                const activeIndex = props.array.map((arr) => arr.id).indexOf(active.id.toString())
                const overIndex = props.array
                    .map((arr) => arr.id)
                    .indexOf((over?.id ?? '').toString())

                const array = arrayMove(props.array, activeIndex, overIndex)
                array.forEach((item, index) => {
                    savePosition({ id: item?.id, pos: index + 1 })
                })
                props.setSortedData(array)
                setTimeout(
                    () => toast.success('Success! Home carousel banners arrangement changed.'),
                    300,
                )
            }
        }
    }

    if (props.array.length === 0)
        return (
            <div className='flex flex-col w-full h-full justify-center items-center max-h-[408px] py-1'>
                <AppButton variant='primary' type='button' onClick={() => navigate('addContent')}>
                    Add New Item
                </AppButton>
            </div>
        )
    props.array.sort((a, b) => a.position - b.position)
    return (
        <div className='flex flex-col w-full h-full max-h-[408px] overflow-auto py-1'>
            <DndContext {...sensors} collisionDetection={closestCenter} onDragEnd={handleDrag}>
                <SortableContext
                    items={props.array.map((item) => item.id)}
                    strategy={verticalListSortingStrategy}
                >
                    {props.sortedData
                        ? props.sortedData.map((item, index) => (
                              <React.Fragment key={index}>
                                  <CarouselItem
                                      item={item}
                                      alias={item?.externalTitle}
                                      isLoading={props.isLoading}
                                      visibility={item?.visibility}
                                      id={item.id}
                                      title={item?.title}
                                      image={item?.image}
                                      description={item?.subtitle}
                                  />
                              </React.Fragment>
                          ))
                        : props.array.map((item, index) => (
                              <React.Fragment key={index}>
                                  <CarouselItem
                                      alias={item?.externalTitle}
                                      isLoading={props.isLoading}
                                      visibility={item?.visibility}
                                      id={item.id}
                                      title={item?.title}
                                      image={item?.image}
                                      description={item?.subtitle}
                                      item={item}
                                  />
                              </React.Fragment>
                          ))}
                </SortableContext>
            </DndContext>
        </div>
    )
}

export default SortableCarouselItems
