import { AxiosError, isAxiosError } from 'axios'
import { useAtom } from 'jotai'
import React, { useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { CreateHomeSectionApi, getHomeSectionByTitleApi } from 'services/requests/home'
import { CreateHomeSectionType } from 'services/requests/home/schema'
import { appHomeContentIdAtom } from 'store/homeAtom'
import { appContent, homeAppContents } from 'utils/constants'
import HomeContentImage from '../HomeContentImage'

const MobileAppSection: React.FunctionComponent = () => {
    const [appId, setID] = useAtom(appHomeContentIdAtom)

    const queryClient = useQueryClient()
    const { error: queryError } = useQuery({
        queryKey: 'content-menu-home',
        queryFn: () => getHomeSectionByTitleApi(appContent.title),
        onSuccess: (data) => {
            setID(data?.homeId)
        },
    })

    const { mutate: saveContent } = useMutation<unknown, AxiosError, CreateHomeSectionType>(
        (data) => {
            return CreateHomeSectionApi(data)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(appContent?.title)
            },
        },
    )

    useEffect(() => {
        if (isAxiosError(queryError) && queryError?.response?.status === 404)
            saveContent({
                bgColor: '',
                buttonLabel: 'Check Menu',
                duration: 8,
                visibility: 'ACTIVE',
                localization: 'en',
                sectionTitle: appContent?.title,
            })
    }, [queryError])
    if (appId)
        return (
            <div className='flex flex-wrap gap-5 justify-center'>
                <HomeContentImage title={homeAppContents.first} type={'mob'} sectiontId={appId} />
                <HomeContentImage title={homeAppContents.second} type={'mob'} sectiontId={appId} />
                <HomeContentImage title={homeAppContents.third} type={'mob'} sectiontId={appId} />
            </div>
        )

    return <></>
}

export default MobileAppSection
