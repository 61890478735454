import { AppAvatar } from 'components/AppAvatar'
import { EmptyTableState } from 'components/EmptyState/table-empty-state'
import Loading from 'components/loading'
import { useAtom } from 'jotai'
import { BiSolidEdit } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { UserResponseType } from 'services/requests/users/schema'
import { twMerge } from 'tailwind-merge'
import { convertUnixToDate } from 'utils/time'
import { searchMode, selectedUsers } from '../index'

type UserTableProps = {
    tableHead: string[]
    users: UserResponseType[]
    isLoading: boolean
}

const TD_CLASS = 'border-b-2 border-gray-300 py-4 px-3 text-sm whitespace-nowrap truncate '

export const UserTable = (props: UserTableProps) => {
    const [selected, setSelected] = useAtom(selectedUsers)
    const [isSearching] = useAtom(searchMode)

    const navigate = useNavigate()

    const handleShowTable = (user: UserResponseType) => {
        const timezoneOffset = new Date().getTimezoneOffset() * 60
        const date = convertUnixToDate(
            user.createdAt ? user.createdAt - timezoneOffset : user.createdAt - timezoneOffset,
        )

        return (
            <div className='grid grid-cols-8 bg-white rounded-sm'>
                <div className='text-center flex py-2 border-b-2 border-gray-300'>
                    <div className='w-1/2'>
                        <input
                            type='checkbox'
                            className='mt-4 h-4 w-4 accent-[#861F41]'
                            checked={selected.some((item) => item === user)}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setSelected([...selected, user])
                                } else {
                                    setSelected(selected.filter((item) => item !== user))
                                }
                            }}
                        />
                    </div>
                    <div className='w-1/2 text-left'>
                        <AppAvatar avatar='' name={user?.firstName + '' + user?.lastName} />
                    </div>
                </div>
                <div className={TD_CLASS}>{user.firstName}</div>
                <div className={TD_CLASS}>{user.lastName}</div>
                <div className={twMerge(TD_CLASS, 'col-span-2')}>{user.email}</div>
                <div className={TD_CLASS}>{date}</div>
                <div className={TD_CLASS}>{user.status}</div>
                <div className={TD_CLASS}>
                    {user.status != 'DELETED' && user.userType != 'GUEST' ? (
                        <button
                            onClick={() => navigate(`${user.id}`)}
                            className='hover:text-[#9D174D] ml-2'
                        >
                            <BiSolidEdit className='h-5 w-5' />
                        </button>
                    ) : (
                        <> </>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div>
            {' '}
            {/* table parent */}
            <div className='bg-[#EDDFE2] text-[#861F41] font-bold grid grid-cols-8 sticky top-0 z-20 rounded-sm'>
                {' '}
                {/* table heading */}
                {props.users?.length >= 1 && (
                    <div className='text-center flex py-2 border-b-2 border-gray-300'>
                        <div className='w-1/2'>
                            <input
                                type='checkbox'
                                className='mt-4 h-4 w-4 rounded border-gray-300 accent-[#861F41]'
                                onChange={(e) => {
                                    e.target.checked ? setSelected(props.users) : setSelected([])
                                }}
                                checked={selected?.length === props.users?.length}
                            />
                        </div>
                        <div className='w-1/2'></div>
                    </div>
                )}
                {props.users?.length === 0 && ( // ---> if the table is empty, I render an empty div so the header doesn't move
                    <div className='text-center flex py-2 border-b-2 border-gray-300'>
                        <div className='w-1/2'></div>
                        <div className='w-1/2'></div>
                    </div>
                )}
                {props.tableHead.map((tableheader) => (
                    <div
                        key={tableheader}
                        className={twMerge(
                            'w-full border-b-2 border-gray-300 py-5 px-3 text-xs font-medium text-[#861F41]',
                            tableheader === 'Email Address' ? 'col-span-2' : '',
                        )}
                    >
                        {tableheader}
                    </div>
                ))}
            </div>
            {props.isLoading && (
                <div className='py-72 bg-white'>
                    <Loading className='h-full w-full' />
                </div>
            )}
            {!props.isLoading && !props.users?.length && isSearching && (
                <div className='py-44 bg-white'>
                    <EmptyTableState
                        title={'No Results Found'}
                        message={
                            'We could not find any search results matching your search. Please try a different keyword or phrasing'
                        }
                    />
                </div>
            )}
            {!props.isLoading && !props.users?.length && !isSearching && (
                <div className='py-44 bg-white'>
                    <EmptyTableState
                        title={'No Data Available.'}
                        message={
                            'Currently no data available, try refreshing the page to make sure it loads properly'
                        }
                    />
                </div>
            )}
            {!props.isLoading && !!props?.users?.length && (
                <div>{props.users?.map((user) => handleShowTable(user))}</div>
            )}
        </div>
    )
}
