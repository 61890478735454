import { AxiosError } from 'axios'
import { AppFileUpload } from 'components/AppFileUpload'
import { useAtom } from 'jotai'
import { CreateHomeContentType } from 'pages/Schemas/home-content'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { BiPhotoAlbum } from 'react-icons/bi'
import { useMutation, useQueryClient } from 'react-query'
import { CreateHomeContentApi } from 'services/requests/home'
import { LogoGalleryLastPositionAtom } from 'store/homeAtom'
import { twMerge } from 'tailwind-merge'
import { badRequestError } from 'utils/constants'
import { v4 as uuidv4 } from 'uuid'

interface IAddPartnersProps {
    AppId: string
}

const AddPartners: React.FunctionComponent<IAddPartnersProps> = (props) => {
    const [image, setImage] = useState<File | null>(null)
    const [lastPosLogoGallery] = useAtom(LogoGalleryLastPositionAtom)
    const queryClient = useQueryClient()
    const [errorUpload, setErrorUpload] = useState<string | null>(null)
    const [isRefreshed, setIsRefreshed] = useState(false)
    const externalTitle = uuidv4()
    const [imageFromS3, setImageFromS3] = useState<string | null>(null)
    const { mutate: saveContent } = useMutation<unknown, AxiosError, CreateHomeContentType>(
        (data) => {
            return CreateHomeContentApi(data)
        },
        {
            onSuccess: () => {
                setErrorUpload(null)
                setImage(null)
                setImageFromS3(null)
                setErrorUpload(null)
                setIsRefreshed(true)
                toast.success('Success! Home content saved.')
                queryClient.invalidateQueries('content-partners-home')
            },
            onError: () => {
                setErrorUpload(null)
                setImage(null)
                setImageFromS3(null)
                setErrorUpload(null)
                setIsRefreshed(true)
                toast.error(badRequestError)
            },
        },
    )
    // const { mutate: uploadImage, isLoading: isUploading } = useMutation<
    //     IUploadNewsResponse[],
    //     AxiosError,
    //     IUploadRequest
    // >((data) => UploadNewsImageApi(data), {
    //     onSuccess: (data) => {

    //     },
    //     onError: (error) => setErrorUpload(error?.message),
    // })

    // const handleFileUpload = (uploadedFile: File) => {
    //     if (uploadedFile) {
    //         uploadImage({ file: uploadedFile, type: 'image' })
    //     }
    // }
    return (
        <div
            className={twMerge(
                'w-[208px] h-[178px]',
                'border-dotted border-[#861F41] border-2 p-1 relative flex items-center justify-center mt-auto',
            )}
        >
            <AppFileUpload
                existingImage={''}
                isRefreshed={isRefreshed}
                toastOnError={true}
                maxSize={25}
                onFileChange={(file) => {
                    setIsRefreshed(false)
                    saveContent({
                        externalTitle: externalTitle + file?.name,
                        title: '{}',
                        floatingImage: '',
                        homeId: props?.AppId,
                        buttonLabel: '',
                        visibility: 'ACTIVE',
                        icon: '',
                        buttonRedirection: '',
                        position: lastPosLogoGallery,
                        image: '',
                        file: file ?? '',
                        subtitle: '',
                        imageAltText: '',
                    })
                }}
                IconComponent={BiPhotoAlbum}
                className='h-[90%] object-cover w-full'
            />
        </div>
    )
}

export default AddPartners
