interface GiftCardTableHeaderProps {
    optionCount: number
}

export const GiftCardTableHeader = (props: GiftCardTableHeaderProps) => {
    return (
        <>
            <thead>
                <tr>
                    <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                    >
                        Options ({props.optionCount})
                    </th>
                    <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-0'>
                        <span className='sr-only'>Delete</span>
                    </th>
                </tr>
            </thead>
        </>
    )
}
