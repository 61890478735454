import { Link, useNavigate } from 'react-router-dom'

interface BreadcrumbsProps {
    name: string | undefined
    activeTab?: number
}

export const Breadcrumbs = (props: BreadcrumbsProps) => {
    const navigate = useNavigate()
    const renderTabName = (key: number) => {
        switch (key) {
            case 0:
                return 'Store'
            case 1:
                return 'Products'
            case 2:
                return 'Direction'
            default:
                return ''
        }
    }

    const navigateToStore = () => {
        navigate('/global-settings/stores/')
    }

    if (!props.name) {
        return <> name not provided </>
    }

    return (
        //<h1 className='text-base text-gray-600 font-bold'>
        // <a className='text-red-800 hover:text-red-600' onClick={navigateToStore}>
        //     Store
        // </a>
        // <span className='-translate-y-[1px]'>&gt;</span>
        // <span className='text-black'>{props.name ? props.name : 'Unnamed store'}</span>
        // <span className='-translate-y-[1px]'>&gt;</span>
        // <span className='text-black'>{renderTabName(props?.activeTab ?? 0)}</span>
        //</h1>
        <h1 className='text-base text-gray-600 font-bold'>
            <Link className='text-red-800 hover:text-red-600' to={'/global-settings/stores/'}>
                Store
            </Link>{' '}
            &gt; {props.name ? props.name : 'Unnamed store'} &gt;{' '}
            {renderTabName(props?.activeTab ?? 0)}
        </h1>
    )
}
