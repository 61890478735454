import { AppCard } from 'components/AppCard'

export const ManageRewardLoading = () => {
    return (
        <div className='animate-pulse'>
            <div className='h-5 w-3/12 bg-slate-300 rounded-md font-bold'>
                <span className='sr-only'>Cancel</span>
            </div>

            <div className='flex items-center justify-between py-3 mb-5 mt-4 border-b-4 border-slate-300 rounded-md'>
                <div className='h-10 w-3/12 bg-slate-300 rounded-md font-bold text'></div>
            </div>

            <AppCard className='shadow-md'>
                <div className='px-5 lg:px-10 py-5'>
                    <div className='flex justify-between flex-wrap xl:flex-nowrap border-slate-300 '>
                        <div className='h-10 w-3/12 bg-slate-300 rounded-md '>
                            <div className='pt-7 xl:pt-12 xl:pb-10'>
                                <span className='sr-only'>Cancel</span>
                            </div>
                        </div>
                        <div className='flex'>
                            <div className='min-w-[150px] h-[48px] rounded-md mr-7 bg-slate-300'>
                                <span className='sr-only'>Cancel</span>
                            </div>
                            <div className='min-w-[150px] h-[48px] rounded-md mr-7 bg-slate-300 '>
                                <span className='sr-only'>save</span>
                            </div>
                        </div>
                    </div>
                </div>
            </AppCard>

            <div className='flex-1 2xl:flex 2xl:space-x-5 mt-5'>
                <AppCard className='basis-full space-y-5 2xl:basis-3/4 shadow-md'>
                    <div className='flex flex-wrap px-5 2xl:px-10 space-y-7'>
                        <div className='basis-full lg:basis-1/2 lg:pr-4 mt-7'>
                            <div className='w-full min-h-[50px] bg-slate-300 rounded-md border border-gray-300'></div>
                        </div>
                        <div className='basis-full lg:basis-1/2'>
                            <div className='w-full min-h-[50px] bg-slate-300 rounded-md border border-gray-300'></div>
                        </div>

                        <div className='basis-full lg:basis-1/2 lg:pr-4'>
                            <div className='w-full min-h-[50px] bg-slate-300 rounded-md border border-gray-300'></div>
                        </div>

                        <div className='basis-full'>
                            <div className='h-5 w-3/12 font-bold bg-slate-300 rounded-md pb-3'>
                                {' '}
                            </div>
                        </div>
                        <div className='basis-full'>
                            <div className='h-5 w-3/12 font-bold bg-slate-300 rounded-md pb-3'>
                                {' '}
                            </div>
                        </div>

                        <div className='basis-full  '>
                            <div className='mt-5 p-6 w-56 border-2 border-dashed border-red-900'>
                                <div className='min-w-[150px] h-[48px] rounded-md bg-slate-300'>
                                    <span className='sr-only'>save</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </AppCard>

                <AppCard className='basis-full mt-5 2xl:mt-0 2xl:basis-1/4 max-h-[500px] shadow-md'>
                    <div className='h-64 bg-slate-300 rounded-md w-full'></div>
                </AppCard>
            </div>
        </div>
    )
}
