import { AppButton } from 'components/AppButton'
import { AppFileUpload } from 'components/AppFileUpload'
import { AppModal } from 'components/AppModal'
import { AppTextBox } from 'components/AppTextBox'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { BiPhotoAlbum } from 'react-icons/bi'

interface IUploadImageProps {
    open: boolean
    onClose: React.Dispatch<React.SetStateAction<boolean>>
}

const UploadImage = ({ open, onClose }: IUploadImageProps) => {
    const [selectedImage, setSelectedImage] = useState<File | null>(null)

    const { control, watch, setValue } = useFormContext()
    const formValues = watch()

    const handleSave = () => {
        if (selectedImage) {
            setValue('rewardImage', selectedImage)
            onClose(false)
            return
        }
        onClose(false)
    }

    return (
        <AppModal
            isOpen={open}
            onClose={() => onClose(false)}
            containerClassName='min-w-[750px]'
            closeIcon
        >
            <div className='space-y-7 mx-5'>
                <div>
                    <label className='font-bold text-2xl -pt-2'>Image</label>
                </div>
                <div>
                    <label className='font-bold text-xl '>Preview</label>

                    <AppFileUpload
                        existingImage={
                            typeof formValues.rewardImage === 'string'
                                ? formValues.rewardImage
                                : formValues.rewardImage &&
                                  URL.createObjectURL(formValues.rewardImage)
                        }
                        onFileChange={(val) => setSelectedImage(val)}
                        IconComponent={BiPhotoAlbum}
                        className='h-64 w-full'
                    />
                </div>
                <div>
                    <div className='mb-3.5'>
                        <label className='font-bold text-xl '>
                            {'Alternative text (Alt text)'} <span className='text-red-600'>*</span>
                        </label>
                        <p className='text-lg text-gray-400 '>
                            Write an image description for the visual impaired.
                        </p>
                    </div>

                    <Controller
                        control={control}
                        name='altText'
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <AppTextBox
                                type='text'
                                name={name}
                                value={value}
                                errorMessage={error?.message}
                                labelClassname='font-bold text-xl mb-3'
                                placeholder='Describe this image...'
                                inputClassname='border rounded border-[#D8D8D8] text-2xl w-full min-h-[50px] px-1 focus:outline-none focus:shadow-outline text-xxs shadow-none'
                                onChange={onChange}
                            />
                        )}
                    />
                </div>
                <div className='flex justify-end gap-4 mt-5 px-5'>
                    <AppButton
                        variant='secondary'
                        type='button'
                        className='p-4 w-3/12'
                        onClick={() => onClose(false)}
                    >
                        Cancel
                    </AppButton>
                    <AppButton
                        variant='primary'
                        type={'button'}
                        className='p-4 w-3/12'
                        onClick={handleSave}
                        disabled={
                            (selectedImage || formValues.rewardImage) && formValues.altText
                                ? false
                                : true
                        }
                    >
                        Save
                    </AppButton>
                </div>
            </div>
        </AppModal>
    )
}
export default UploadImage
