import { AppGoogleMap } from 'components/AppGoogleMap'
import { AppSwitch } from 'components/AppSwitch'
import { AppTimePicker } from 'components/AppTimePicker'
import { Controller, useFormContext } from 'react-hook-form'
import { UpdateStoreType } from 'services/requests/store/schema'

const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const BusinessHourSection = () => {
    const { control } = useFormContext<UpdateStoreType>()

    const storeLocation = {
        lat: control?._defaultValues?.latitude || 37.7749,
        lng: control?._defaultValues?.longitude || -122.4194,
    }

    return (
        <>
            <section>
                <h1 className='text-2xl  font-bold'>Business Hours</h1>
            </section>
            <section>
                <div className='space-y-2'>
                    <div className='mt-8 flow-root'>
                        <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                            <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                                <table className='min-w-full divide-y divide-gray-300'>
                                    <thead>
                                        <tr>
                                            <th
                                                scope='col'
                                                className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                                            >
                                                <span className='sr-only'>Active</span>
                                            </th>
                                            <th
                                                scope='col'
                                                className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                            >
                                                Weekdays
                                            </th>
                                            <th
                                                scope='col'
                                                className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                            >
                                                &nbsp;
                                            </th>
                                            <th
                                                scope='col'
                                                className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                            >
                                                Time Opening
                                            </th>
                                            <th
                                                scope='col'
                                                className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                            >
                                                Time Closing
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='divide divide-y divide-gray-200'>
                                        {weekDays.map((weekDays, index) => (
                                            <tr key={index}>
                                                <td className='whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                                                    <Controller
                                                        name={`storeHours.${index}.active`}
                                                        control={control}
                                                        render={({
                                                            field: { value, onChange },
                                                        }) => (
                                                            <AppSwitch
                                                                isEnabled={value ? true : false}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                    />
                                                </td>
                                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                                    {weekDays}
                                                </td>
                                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                                    &nbsp;
                                                </td>
                                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                                    <Controller
                                                        name={`storeHours.${index}.openTime`}
                                                        control={control}
                                                        render={({
                                                            field: { value, onChange, name },
                                                        }) => (
                                                            <AppTimePicker
                                                                name={name}
                                                                value={value}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                    />
                                                </td>
                                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                                    <Controller
                                                        name={`storeHours.${index}.closeTime`}
                                                        control={control}
                                                        render={({
                                                            field: { value, onChange, name },
                                                        }) => (
                                                            <AppTimePicker
                                                                name={name}
                                                                value={value}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <h1 className='text-2xl font-bold my-5'>Store Location</h1>
                                <AppGoogleMap center={storeLocation}></AppGoogleMap>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
