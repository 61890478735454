import { useAtom } from 'jotai'
import debounce from 'lodash/debounce'
import { ChangeEvent, FC, useEffect } from 'react'
import { FaSearch } from 'react-icons/fa'
import { orderKeywordSearch } from 'store/paginationAtom'

interface AppSearchProps {
    onSearch: (searchTerm: string) => void
}

export const AppSearch: FC<AppSearchProps> = ({ onSearch }) => {
    const [searchTerm, setSearchTerm] = useAtom(orderKeywordSearch)

    const debouncedSearch = debounce(onSearch, 0)

    useEffect(() => {
        debouncedSearch(searchTerm)
    }, [searchTerm])

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
    }

    return (
        <div className='w-full 2xl:w-64 flex items-center justify-center border border-gray-200 p-2 '>
            <FaSearch className='mr-2 text-[#861F41]' />
            <input
                type='text'
                value={searchTerm}
                onChange={handleInputChange}
                placeholder='Search...'
                className='outline-none bg-transparent placeholder-gray-500 w-full'
            />
        </div>
    )
}
