import { AxiosError } from 'axios'
import { AppButton } from 'components/AppButton'
import { AppCard } from 'components/AppCard'
import { AppRichTextBox } from 'components/AppRichTextBox'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { createOrUpdateStoreDirectionsApi } from 'services/requests/store'
import { LocationType, StoreDirectionType } from 'services/requests/store/schema'

interface DirectionTabContentProps {
    store: LocationType
}

export const DirectionTabContent = (props: DirectionTabContentProps) => {
    const queryClient = useQueryClient()

    const { control, handleSubmit } = useForm<StoreDirectionType>({
        defaultValues: {
            storeId: props.store.id,
            description: props.store.direction?.description,
        },
    })

    const createOrUpdateStoreDirection = useMutation<unknown, AxiosError, StoreDirectionType>(
        (data) =>
            createOrUpdateStoreDirectionsApi(
                props.store.direction != null ? props.store.direction.id : crypto.randomUUID(),
                data,
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['getLocationsByIdApi', props.store.id])
                toast.success('Store direction updated successfully')
            },
            onError: (error) => {
                toast.error(error.message)
            },
        },
    )

    const onSubmit = (data: StoreDirectionType) => {
        createOrUpdateStoreDirection.mutate(data)
    }

    return (
        <AppCard className='mb-10 space-y-5 shadow-md'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <section className='flex justify-end'></section>
                <section>
                    <div className='mt-3'>
                        <Controller
                            name='description'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <AppRichTextBox
                                    name={name}
                                    onChange={onChange}
                                    className='h-56 rounded-md'
                                    value={value ? value : ''}
                                />
                            )}
                        />
                    </div>
                </section>
                <section className='mt-5 flex flex-row items-center justify-end'>
                    <AppButton className='px-5 py-1.5' type='submit' variant='primary'>
                        Update
                    </AppButton>
                </section>
            </form>
        </AppCard>
    )
}
