const AppSkeletonLoadingTable = () => {
    const skeletonRows = Array.from({ length: 9 }, (_, index) => (
        <div key={index} className='flex flex-row justify-between items-center w-full py-3 h-12'>
            <div className='h-2 w-[40px] bg-slate-200 rounded'></div>
            <div className='h-2 w-[150px] bg-slate-200 rounded'></div>
            <div className='h-2 w-[50px] ml-12 mr-3 bg-slate-200 rounded'></div>
            <div className='h-2 w-[64px] mr-8 bg-slate-200 rounded'></div>
            <div className='h-2 w-[64px] ml-5 bg-slate-200 rounded'></div>
            <div className='h-2 w-[40px] ml-20 bg-slate-200 rounded'></div>
            <div className='h-2 w-[40px] ml-5 bg-slate-200 rounded'></div>
            <div className='h-2 w-[40px] ml-20 bg-slate-200 rounded'></div>
            <div className='h-2 w-[40px] bg-slate-200 rounded'></div>
        </div>
    ))
    return (
        <div className='justify-center items-center text-center mt-8'>
            <div className='mt-8 overflow-x-auto'>
                <div className='align-middle'>
                    <div className='overflow-hidden shadow-lg rounded-t-lg '>
                        <table className='w-full divide-y divide-gray-300 table-auto h-[75px]'>
                            <thead className='bg-red-100'>
                                <tr>
                                    <th
                                        scope='col'
                                        className='w-1/6 px-2 py-2 sm:w-1/12 sm:px-4 sm:py-3 text-center text-sm font-semibold text-red-800'
                                    >
                                        Order#
                                    </th>
                                    <th
                                        scope='col'
                                        className='w-2/6 px-2 py-2 sm:w-1/4 sm:px-4 sm:py-3 text-left text-sm font-semibold text-red-800'
                                    >
                                        Date
                                    </th>
                                    <th
                                        scope='col'
                                        className='w-1/6 px-2 py-2 sm:w-1/12 sm:px-4 sm:py-3 text-left text-sm font-semibold text-red-800'
                                    >
                                        Store
                                    </th>
                                    <th
                                        scope='col'
                                        className='w-1/6 px-2 py-2 sm:w-1/6 sm:px-4 sm:py-3 text-left text-sm font-semibold text-red-800'
                                    >
                                        Customer
                                    </th>
                                    <th
                                        scope='col'
                                        className='w-1/6 px-2 py-2 sm:w-1/8 sm:px-4 sm:py-3 text-left text-sm font-semibold text-red-800'
                                    >
                                        Type
                                    </th>
                                    <th
                                        scope='col'
                                        className='w-1/6 px-2 py-2 sm:w-1/12 sm:px-4 sm:py-3 text-left text-sm font-semibold text-red-800'
                                    >
                                        Payment
                                    </th>
                                    <th
                                        scope='col'
                                        className='w-1/6 px-2 py-2 sm:w-1/4 sm:px-4 sm:py-3 text-left text-sm font-semibold text-red-800'
                                    >
                                        Status
                                    </th>
                                    <th
                                        scope='col'
                                        className='w-1/6 px-2 py-2 sm:w-1/12 sm:px-4 sm:py-3 text-left text-sm font-semibold text-red-800'
                                    >
                                        Items
                                    </th>
                                    <th
                                        scope='col'
                                        className='w-1/6 px-2 py-2 sm:w-1/4 sm:px-4 sm:py-3 text-left text-sm font-semibold text-red-800'
                                    >
                                        Total
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>

            <div className='border border-gray-300 border-t-0 shadow rounded-b-md p-4 w-full'>
                <div className='animate-pulse flex flex-col '>{skeletonRows}</div>
            </div>
        </div>
    )
}
export default AppSkeletonLoadingTable
