import { AppTabs, TabProps } from 'components/AppTabs'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { getLocationsByIdApi } from 'services/requests/store'
import { Breadcrumbs } from './Breadcrumbs'
import { DirectionTabContent } from './DirectionTabContent'
import { ProductTabContent } from './ProductTabContent'
import { StoreTabContent } from './StoreTabContent'

export const StoreMenu = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [selectedTab, setSelectedTab] = useState(0)

    const storeId = params.id

    const { data: store } = useQuery(
        ['getLocationsByIdApi', storeId],
        () => getLocationsByIdApi(storeId),
        {
            enabled: !!storeId,
        },
    )

    if (!store) {
        navigate('/global-settings/store')
        return <></>
    }

    const tabs: TabProps[] = [
        {
            name: 'Store',
            content: <StoreTabContent store={store} />,
        },
        {
            name: 'Products',
            content: <ProductTabContent store={store} />,
        },
        {
            name: 'Direction',
            content: <DirectionTabContent store={store} />,
        },
    ]

    return (
        <div className='space-y-4'>
            <Breadcrumbs name={store?.shopName} activeTab={selectedTab} />
            <h1 className='text-2xl text-red-900 font-bold'>{store?.shopName}</h1>
            <AppTabs tabs={tabs} onChange={(e) => setSelectedTab(typeof e !== 'number' ? 0 : e)} />
        </div>
    )
}
