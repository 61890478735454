import { axiosInstance } from 'services/axios'
import { EmailReceiverResponseSchemaType, EmailReceiverSchemaType } from './schema'

export const createEmailReceiverApi = async (data: EmailReceiverSchemaType) => {
    const payload = {
        key: 'email_receiver',
        value: data.email,
    }

    const response = await axiosInstance.post('/platform-setting', payload)

    return response.data
}

export const getPlatformSettingsApi = async (key: string) => {
    const response = await axiosInstance.get('/platform-setting', {
        params: {
            key,
        },
    })

    return response.data as EmailReceiverResponseSchemaType
}
