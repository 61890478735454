import { axiosInstance } from 'services/axios'
import { CreateFaqType, FaqType, UpdateFaqType } from './schema'

export const getFaqApi = async () => {
    const response = await axiosInstance.get('/faq')

    return response.data as FaqType
}
export const getFaqApiByStatus = async (status?: string) => {
    const response = await axiosInstance.get('/faq/status', { params: { status } })

    return response.data as FaqType
}
export const CreateFaqApi = async (data: CreateFaqType) => {
    const response = await axiosInstance.post('/faq', data)

    return response.data
}

export const DeleteFaqApi = async (id: string) => {
    if (id) {
        const response = await axiosInstance.delete('/faq', {
            params: {
                faqId: id,
            },
        })

        return response
    }
}

export const UpdateFaqApi = async (id: string, data: UpdateFaqType) => {
    const response = await axiosInstance.put(`/faq?faqId=${id}`, data)

    return response.data
}
