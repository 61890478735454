import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { AiOutlineDown } from 'react-icons/ai'
import ReactInputMask from 'react-input-mask'
import { twMerge } from 'tailwind-merge'

interface IAppDatePickerProps {
    onChange: (date: Date) => void
    label?: string
    labelClassName?: string
    value?: Date
    containerClassName?: string
    arrow?: boolean
    errorMessage?: string
    errorClassName?: string
    inputClassName?: string
    name?: string
    ariaLabel?: string
}

const AppDatePicker: React.FunctionComponent<IAppDatePickerProps> = (props) => {
    const handleChange = (date: Date) => {
        props.onChange(date || undefined)
    }

    return (
        <div className={twMerge('relative hover:cursor-pointer', props.containerClassName)}>
            {props.label ?? <h1 className={props?.labelClassName}>{props.label}</h1>}
            <DatePicker
                className={twMerge(
                    props?.inputClassName
                        ? props?.inputClassName
                        : 'block w-full appearance-none rounded-md px-3 py-2 border border-gray-100 placeholder-gray-500 shadow-sm focus:outline-none text-xs disabled:bg-gray-100',
                    props.errorMessage && 'border-error',
                )}
                selected={props?.value}
                onChange={handleChange}
                customInput={
                    <ReactInputMask
                        mask='99/99/9999'
                        placeholder='MM/DD/YYYY'
                        aria-label={props.ariaLabel ?? 'Date'}
                    />
                }
                placeholderText='MM/DD/YYYY'
                id={props?.name}
                name={props?.name}
                aria-label={props.ariaLabel ?? 'Calendar modal'}
            />

            <label htmlFor={props?.name} className={twMerge(props.arrow ? 'block' : 'hidden')}>
                <AiOutlineDown className='text-primary w-35 h-4 absolute right-2 top-[50%] translate-y-[-50%]' />
            </label>
            {props.errorMessage && (
                <p
                    className={twMerge('text-[12px] text-red-500 absolute', props?.errorClassName)}
                    id={`${props.name}-error`}
                >
                    {props.errorMessage}
                </p>
            )}
        </div>
    )
}

export default AppDatePicker
