import React, { useEffect, useState } from 'react'

import TextColor from '@tiptap/extension-color'
import FontFamily from '@tiptap/extension-font-family'
import Link from '@tiptap/extension-link'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import { Editor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Loading from 'components/loading'
import FontSize from 'tiptap-extension-font-size'
interface IDisplayContentProps {
    contentDescription: string | null
    isLoading?: boolean
}

const DisplayContent: React.FunctionComponent<IDisplayContentProps> = (props) => {
    const [editor, setEditor] = useState<Editor | null>(null)
    useEffect(() => {
        if (props?.contentDescription && !editor) {
            const newEditor = new Editor({
                content: JSON.parse(props.contentDescription),
                extensions: [
                    StarterKit,
                    TextStyle,
                    FontFamily,
                    Link,
                    TextColor,
                    TextAlign.configure({
                        types: ['heading', 'paragraph'],
                    }),
                    FontSize,
                ],
                editable: false,
            })
            setEditor(newEditor)
        } else if (props?.contentDescription && editor) {
            editor.chain().setContent(JSON.parse(props.contentDescription)).run()
        }
        return () => {
            editor?.destroy()
        }
    }, [props?.contentDescription, editor, props?.isLoading])

    if (props?.isLoading) return <Loading />
    if (editor)
        return (
            <div data-aos='fade' className='max-w-[1166px] flex flex-col gap-6'>
                <div
                    style={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                    }}
                    className='line-clamp-3 flex flex-col gap-2 w-full'
                >
                    <EditorContent editor={editor} />
                </div>
            </div>
        )
    return <></>
}

export default DisplayContent
