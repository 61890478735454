import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Popover } from '@headlessui/react'
import { AxiosError } from 'axios'
import ConfirmationModal from 'components/ActionConfirmation'
import { AppModal } from 'components/AppModal'
import { useAtom } from 'jotai'
import { DraggableHandle } from 'pages/Web/Partnerships/parnership-item'
import * as React from 'react'
import { toast } from 'react-hot-toast'
import { BiSolidEdit } from 'react-icons/bi'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FiTrash } from 'react-icons/fi'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { UpdateDeleteJobsApi } from 'services/requests/jobs'
import { JobsType } from 'services/requests/jobs/schema'
import { twMerge } from 'tailwind-merge'
import { tableItemStyle } from 'utils/styles'
import { JobsEditAtom } from '..'
interface IJobsTableItemProps {
    id: string
    index: number
    selectedJobs: JobsType[] | []
    item: JobsType
    setSelectedJobs: React.Dispatch<React.SetStateAction<JobsType[] | []>>
    handleDelete: (id: string) => void
}

const JobsTableItem: React.FunctionComponent<IJobsTableItemProps> = (props) => {
    const navigate = useNavigate()
    const [, setEditJobs] = useAtom(JobsEditAtom)
    const [isOpenConfirmationModal, setIsOpenConfirmationModal] = React.useState(false)
    const queryClient = useQueryClient()
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props.id,
    })
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }
    const { mutate: deleteJob, isLoading: deleteLoading } = useMutation<
        unknown,
        AxiosError,
        string
    >((id) => UpdateDeleteJobsApi(id), {
        onSuccess: () => {
            props.setSelectedJobs((prevSelectedJobs) =>
                prevSelectedJobs.filter((job) => job.jobId !== props.id),
            )
            queryClient.invalidateQueries(['jobs'])
            setIsOpenConfirmationModal(false)
            toast.success('Success! The item has been deleted.')
        },
        onError: (error) => {
            console.log('ERROR', error)
        },
    })
    return (
        <tr
            ref={setNodeRef}
            style={style}
            {...attributes}
            className={twMerge(tableItemStyle(props.index))}
        >
            <td className='flex justify-center items-center relative translate-y-3 translate-x-2 max-w-fit w-fit pl-2'>
                <DraggableHandle handleListeners={listeners} className='w-fit' />
            </td>
            <td className='relative px-7 sm:w-12 sm:px-6'>
                <input
                    type='checkbox'
                    className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 accent-[#861F41] cursor-pointer'
                    checked={props.selectedJobs.some((job) => props?.item.jobId === job.jobId)}
                    onChange={(e) =>
                        props.setSelectedJobs(
                            e.target.checked
                                ? [...props.selectedJobs, props.item]
                                : props.selectedJobs.filter(
                                      (job) => job.jobId !== props?.item?.jobId,
                                  ),
                        )
                    }
                />
            </td>
            <td
                className={twMerge(
                    'whitespace-nowrap py-4 pr-3 text-[15px]',
                    'text-gray-900 truncate flex flex-col',
                )}
            >
                <span className='w-[90%] xl:w-full truncate'>{props?.item?.jobTitle}</span>
                <span className='capitalize text-[13px] font-semibold text-[#751132]'>
                    {props?.item?.jobType}
                </span>
            </td>

            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                {props?.item?.jobEmployment}
            </td>
            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                {props?.item?.jobState}
            </td>

            {/* <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 '>
                {useFormatDateSlash(props?.item?.whenUpdated) ?? 'not yet edited'}
            </td> */}
            <td className='whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500 '>
                <Popover>
                    {({ close }) => (
                        /* Use the `open` state to conditionally change the direction of the chevron icon. */
                        <div className='relative w-1/2 flex items-end justify-end'>
                            <Popover.Button>
                                <BsThreeDotsVertical />
                            </Popover.Button>
                            <Popover.Panel className='absolute right-7 top-0 z-20 bg-white p-4 flex flex-col gap-2 text-[#862F4C] font-[500] rounded-md'>
                                <button
                                    onClick={() => {
                                        close()
                                        setEditJobs(props?.item)
                                        navigate(`edit/${props?.item.jobId}`)
                                    }}
                                    className='inline-flex items-center gap-2 p-1'
                                >
                                    <BiSolidEdit className='h-5 w-5' />
                                    Edit
                                </button>
                                <button
                                    onClick={() => {
                                        close()
                                        setIsOpenConfirmationModal(true)
                                    }}
                                    className='inline-flex items-center gap-2 p-1'
                                >
                                    <FiTrash className='h-5 w-5' />
                                    Delete
                                </button>
                                {/* <button
                  onClick={() => {
                      close()
                      navigate(
                          `descriptions/${item.jobId}`
                      )
                  }}
                  className="inline-flex items-center gap-2"
              >
                  <RiDraftFill />
                  View
                  Details
              </button> */}
                            </Popover.Panel>
                        </div>
                    )}
                </Popover>
            </td>
            <AppModal
                closeIcon={false}
                isOpen={isOpenConfirmationModal}
                onClose={() => setIsOpenConfirmationModal(false)}
                title=''
                containerClassName='h-fit p-0 my-0'
            >
                <ConfirmationModal
                    handle={() => deleteJob(props?.item?.jobId)}
                    onClose={() => setIsOpenConfirmationModal(false)}
                    yesLabel='Delete'
                    noLabel='Cancel'
                    title='Delete Job Role'
                    isLoading={deleteLoading}
                    subtitle='Are you sure you want to delete this job role?'
                />
            </AppModal>
        </tr>
    )
}

export default JobsTableItem
