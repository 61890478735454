import { OrderTableSection } from './OrderTableSection'

export const Order = () => {
    return (
        <div className='divide-y divide-gray-200 space-y-4'>
            <section>
                <h1 className='text-red-900 text-3xl font-bold'>Order Management</h1>
            </section>
            <OrderTableSection />
        </div>
    )
}
