import { zodResolver } from '@hookform/resolvers/zod'
import { AxiosError } from 'axios'
import { AppButton } from 'components/AppButton'
import { AppCard } from 'components/AppCard'
import AppSkeletonLoadingSettings from 'components/AppSkeletonLoaders/AppSkeletonLoadingSettings'
import { AppTextBox } from 'components/AppTextBox'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { createEmailReceiverApi, getPlatformSettingsApi } from 'services/requests/settings'
import { emailReceiverSchema, EmailReceiverSchemaType } from 'services/requests/settings/schema'

export const Settings = () => {
    const queryClient = useQueryClient()
    const { data: emailReceiverData, isLoading: emailReceiverLoading } = useQuery(
        'email-receiver',
        () => getPlatformSettingsApi('email_receiver'),
        {
            onSuccess: (data) => {
                console.log(data)
            },
        },
    )

    const saveEmailReceiver = useMutation<unknown, AxiosError, EmailReceiverSchemaType>(
        (data) => createEmailReceiverApi(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('email_receiver')
                toast.success('Email Receiver Saved Successfully')
            },
        },
    )

    const { control, handleSubmit, setValue, formState } = useForm<EmailReceiverSchemaType>({
        mode: 'onBlur',
        defaultValues: {
            email: emailReceiverData?.value,
        },
        resolver: zodResolver(emailReceiverSchema),
    })

    const onSubmit = (data: EmailReceiverSchemaType) => {
        saveEmailReceiver.mutate(data)
    }

    useEffect(() => {
        setValue('email', emailReceiverData?.value || '')
    }, [emailReceiverData])

    if (emailReceiverLoading) return <AppSkeletonLoadingSettings />

    return (
        <div className='space-y-4'>
            <div>
                <h1 className='text-2xl text-pink-900 font-bold'>Settings</h1>
            </div>
            <AppCard>
                <div className='divide-y divide-white/5'>
                    <div className='grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8'>
                        <div>
                            <h2 className='text-base font-semibold leading-7 text-red-900'>
                                Email Receiver
                            </h2>
                            <p className='mt-1 text-sm leading-6 text-gray-400'>
                                Where you can receive email from customers.
                            </p>
                        </div>

                        <form className='md:col-span-2' onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name='email'
                                control={control}
                                render={({
                                    field: { name, onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <AppTextBox
                                        name={name}
                                        type='email'
                                        onChange={onChange}
                                        value={value}
                                        errorMessage={error?.message}
                                        label='Email'
                                    />
                                )}
                            />

                            <AppButton
                                type='submit'
                                variant='primary'
                                isLoading={saveEmailReceiver.isLoading}
                                disabled={
                                    saveEmailReceiver.isLoading ||
                                    formState.isSubmitting ||
                                    !formState.isValid
                                }
                                className='mt-4'
                            >
                                Save
                            </AppButton>
                        </form>
                    </div>
                </div>
            </AppCard>
        </div>
    )
}
