const TierSkeletonLoading = () => {
    return (
        <div className='grid grid-cols-3 w-full gap-14 px-20 pb-20 pt-10 animate-pulse'>
            <div className='col-span-2 flex-1 space-y-7'>
                <div className='basis-full'>
                    <div className='space-y-2.5 m-0.5'>
                        <div className='bg-gray-200 h-[28px] rounded-md w-1/3'></div>

                        <div className=''>
                            <div className='space-y-4'>
                                <div className='h-auto flex'>
                                    <div className='px-2 w-full h-[50px] bg-gray-200 flex justify-center items-center  border  rounded-md'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='basis-full flex gap-10'>
                    <div className='basis-6/12'>
                        <div className='space-y-3 m-0.5'>
                            <div className='bg-gray-200 h-[28px]  rounded-md w-1/2'></div>

                            <div className=' '>
                                <div className='space-y-4'>
                                    <div className='h-auto flex'>
                                        <div className='px-2 w-full h-[50px] bg-gray-200 flex justify-center items-center  border  rounded-md'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='basis-6/12'>
                        <div className='space-y-2.5 m-0.5'>
                            <div className='bg-gray-200 h-[28px]  rounded-md w-1/2'></div>

                            <div className=''>
                                <div className='space-y-4'>
                                    <div className='h-auto flex'>
                                        <div className='px-2 w-full h-[50px] bg-gray-200 flex justify-center items-center  border  rounded-md'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='basis-full flex flex-col '>
                    <div className='space-y-2.5 m-0.5'>
                        <div className='bg-gray-200 h-[28px]  rounded-md w-1/3'></div>

                        <div className=''>
                            <div className='space-y-4'>
                                <div className='h-auto flex'>
                                    <div className='px-2 w-full h-[50px] bg-gray-200 flex justify-center items-center  border  rounded-md'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='basis-full w-1/3 '>
                    <div className='bg-gray-200 h-[24px] rounded-md'></div>
                </div>
            </div>
            <div className='col-span-1'>
                <div>
                    <div className='space-y-2.5 m-0.5'>
                        <div className='bg-gray-200 h-[28px] rounded-md w-1/2'></div>

                        <div className='h-[350px]  '>
                            <div className='space-y-4'>
                                <div className='h-auto flex'>
                                    <div className='px-2 w-full h-[50px] bg-gray-200 flex justify-center items-center  border  rounded-md'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TierSkeletonLoading
