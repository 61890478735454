const AppSkeletonLoadingJobs = () => {
    return (
        <div className='flex flex-col w-full my-4 gap-3 animate-pulse py-10'>
            <div className='flex w-full justify-between'>
                <div className='h-5 w-[55px] bg-slate-200 rounded-xl'></div>
                <div className='h-12 w-[170px] bg-slate-200 rounded-xl'></div>
            </div>
            <div className='mt-8 flow-root'>
                <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                    <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                        <div className='relative'>
                            <table className='min-w-full bg-white table-fixed divide-y divide-gray-300'>
                                <thead>
                                    <tr>
                                        <th
                                            scope='col'
                                            className='w-[44.5%] py-3.5 pl-[87px] text-left text-sm font-semibold text-gray-900'
                                        >
                                            Title
                                        </th>
                                        <th
                                            scope='col'
                                            className='w-[22%] pl-2.5 py-3.5 text-left text-sm font-semibold text-gray-900'
                                        >
                                            Type
                                        </th>
                                        <th
                                            scope='col'
                                            className='py-3.5 pl-1 text-left text-sm font-semibold text-gray-900'
                                        >
                                            Address
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                            <div className='flex h-[73px] items-center bg-[#EFEDF0]'>
                                <div className='flex flex-col w-[47%] pl-[85px]'>
                                    <div className='h-3 w-[150px] bg-slate-200 rounded-xl mb-2'></div>
                                    <div className='h-3 w-[55px] bg-slate-200 rounded-xl'></div>
                                </div>
                                <div className='w-[22%]'>
                                    <div className='h-3 w-[85px] bg-slate-200 rounded-xl'></div>
                                </div>
                                <div className='w-[14%]'>
                                    <div className='h-3 w-[130px] bg-slate-200 rounded-xl'></div>
                                </div>
                                <div className='flex justify-center w-[20%]'>
                                    <div className='h-3 w-[15px] bg-slate-200 rounded-xl ml-24'></div>
                                </div>
                            </div>
                            <div className='flex h-[73px] items-center bg-slate-50'>
                                <div className='flex flex-col w-[47%] pl-[85px]'>
                                    <div className='h-3 w-[150px] bg-slate-200 rounded-xl mb-2'></div>
                                    <div className='h-3 w-[55px] bg-slate-200 rounded-xl'></div>
                                </div>
                                <div className='w-[22%]'>
                                    <div className='h-3 w-[85px] bg-slate-200 rounded-xl'></div>
                                </div>
                                <div className='w-[14%]'>
                                    <div className='h-3 w-[130px] bg-slate-200 rounded-xl'></div>
                                </div>
                                <div className='flex justify-center w-[20%]'>
                                    <div className='h-3 w-[15px] bg-slate-200 rounded-xl ml-24'></div>
                                </div>
                            </div>
                            <div className='flex h-[73px] items-center bg-[#EFEDF0]'>
                                <div className='flex flex-col w-[47%] pl-[85px]'>
                                    <div className='h-3 w-[150px] bg-slate-200 rounded-xl mb-2'></div>
                                    <div className='h-3 w-[55px] bg-slate-200 rounded-xl'></div>
                                </div>
                                <div className='w-[22%]'>
                                    <div className='h-3 w-[85px] bg-slate-200 rounded-xl'></div>
                                </div>
                                <div className='w-[14%]'>
                                    <div className='h-3 w-[130px] bg-slate-200 rounded-xl'></div>
                                </div>
                                <div className='flex justify-center w-[20%]'>
                                    <div className='h-3 w-[15px] bg-slate-200 rounded-xl ml-24'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppSkeletonLoadingJobs
