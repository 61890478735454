import AppSkeletonLoadingOrderDetails from 'components/AppSkeletonLoaders/AppSkeletonLoadingOrderDetails'
import { useQuery } from 'react-query'
import { Link, Navigate, useParams } from 'react-router-dom'
import { getOrderById } from 'services/requests/orders'
import { CustomerDetail } from './CustomerDetail'
import { OrderHistory } from './OrderHistory'
import { OrderSummary } from './OrderSummary'
import { OrderTitle } from './OrderTitle'

export const OrderDetail = () => {
    const { id } = useParams<{ id: string }>()

    if (!id) {
        return <Navigate to={'/order'} />
    }

    const { data: orderData, isLoading: orderLoading } = useQuery(
        ['order', id],
        () => getOrderById(id),
        {
            enabled: !!id,
            onSuccess: (data) => {
                console.log(data)
            },
        },
    )

    if (orderLoading) return <AppSkeletonLoadingOrderDetails />

    if (!orderData) return <>no data</>

    return (
        <div className='space-y-4'>
            <h1 className='text-base text-gray-600 font-bold'>
                <Link className='text-red-800 hover:text-red-600' to={'/order'}>
                    Order List
                </Link>{' '}
                &gt; {orderData?.externalId?.replace('DT', ' #')}{' '}
            </h1>
            <h1 className='text-red-900 text-3xl font-bold'>Order Details</h1>
            <section>
                <div className='mt-10'>
                    <OrderTitle orderData={orderData} />
                    <div className='mt-2 grid grid-cols-3 gap-2 auto-rows-max lg:grid-cols-4'>
                        <OrderSummary orderData={orderData} />
                        <OrderHistory orderData={orderData} />
                        <CustomerDetail orderData={orderData} />
                    </div>
                </div>
            </section>
        </div>
    )
}
