import { AppCard } from 'components/AppCard'
import { AppPagination } from 'components/AppPagination'
import { useAtom } from 'jotai'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { getAllOrders } from 'services/requests/orders'
import { paginationAtom } from 'store/paginationAtom'
import { FilterHeader } from './FilterHeader'
import { OrderTable } from './OrderTable'

export const OrderTableSection = () => {
    const [status, setStatus] = useState<string | number>('')
    const [store, setStore] = useState<string | number>('')
    const [keyword, setKeyword] = useState<string | number>('')
    const [platform, setPlatform] = useState<string | number>('')
    const [page, setPage] = useAtom(paginationAtom)
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
    const pageSize = 20

    const { data: ordersData, isLoading: ordersLoading } = useQuery(
        ['orders', status, store, keyword, platform, page, sortOrder],
        () =>
            getAllOrders({
                keyword: String(keyword),
                locationId: String(platform === 'web' ? '' : store),
                platform: String(platform),
                status: String(status),
                page: page,
                sort: `orderDate,${sortOrder}`,
            }),
    )

    const handleSearch = (value: string | number) => {
        setKeyword(value)
    }

    const handleStatus = (value: string | number) => {
        setStatus(value)
    }

    const handleStore = (value: string | number) => {
        setStore(value)
    }

    const handlePlatform = (value: string | number) => {
        setPlatform(value)
        setPage(0)
    }

    const handlePageChange = (page: number) => {
        setPage(page)
    }

    const handleDescending = () => {
        setSortOrder('desc')
    }

    const handleAcending = () => {
        setSortOrder('asc')
    }

    return (
        <section>
            <AppCard className='mt-10'>
                <FilterHeader
                    searchOnChange={handleSearch}
                    statusOnChange={handleStatus}
                    storeOnChange={handleStore}
                    platformOnChange={handlePlatform}
                />
                <OrderTable
                    orders={ordersData?.content}
                    isLoading={ordersLoading}
                    handleAscending={handleAcending}
                    handleDescending={handleDescending}
                />
                {ordersData && ordersData?.totalElements !== 0 && (
                    <section className='mt-4'>
                        <AppPagination
                            currentPage={page}
                            onPageChange={setPage}
                            perPage={pageSize}
                            total={ordersData?.totalElements ?? 0}
                        />
                    </section>
                )}
            </AppCard>
        </section>
    )
}
