import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { extractNameInitials } from 'utils'

interface UserImageAvatarProps {
    name: string
    avatar?: string
    className?: string
}

export const AppAvatar = (props: UserImageAvatarProps) => {
    const [initials, setInitials] = useState('')

    useEffect(() => {
        if (props.name) {
            setInitials(extractNameInitials(props.name.toUpperCase()))
        } else {
            setInitials('')
        }
    }, [props.name])

    if (props.avatar) {
        return (
            <img
                className={twMerge('inline-block h-10 w-10 rounded-full', props.className)}
                src={props.avatar}
                alt=''
            />
        )
    }

    return (
        <span
            className={twMerge(
                'inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500 relative',
                props.className,
            )}
        >
            <span className='font-medium leading-none text-white'>{initials}</span>
        </span>
    )
}
