import { AppBackButton } from 'components/AppBackButton'
import { AppButton } from 'components/AppButton'
import { AppCard } from 'components/AppCard'
import { useAtomValue } from 'jotai'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { OrderType } from 'services/requests/orders/schema'
import { processRefundByOrderLineIds } from 'services/requests/refund'
import { refundAtom } from 'store/refundAtom'
import { convertUnixTimestampThird } from 'utils/time'

interface OrderTitleProps {
    orderData: OrderType
    onLoad: React.Dispatch<React.SetStateAction<boolean>>
}

export const OrderTitle = (props: OrderTitleProps) => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const refundData = useAtomValue(refundAtom)

    const refund = useMutation(() => processRefundByOrderLineIds(refundData), {
        onSuccess: () => {
            console.log('success')
            queryClient.invalidateQueries(['order', props.orderData.id])
            toast.success(
                ToastMessage(
                    'Refund Successfully Requested',
                    'Refund request has been successfully processed.',
                ),
            )
            navigate(`/order/${props.orderData.id}`)
        },
        onError: () => {
            toast.error(ToastMessage('Refund Failed', 'Refund Process Unsuccessful'))
        },
    })

    const isSubmitButtonDisabled = !refundData?.orderLineIds || refundData.orderLineIds.length === 0

    const handleRefund = () => {
        refund.mutate()
    }

    const handleCancel = () => {
        navigate(`/order/${props.orderData.id}`)
    }

    const ToastMessage = (title: string, description: string) => {
        return (
            <div>
                <p className='font-bold space-y-1'>{title}</p>
                <p>{description}</p>
            </div>
        )
    }

    useEffect(() => {
        props.onLoad(refund.isLoading)
    }, [refund.isLoading])

    return (
        <AppCard className='space-y-3'>
            <div className='flex items-start justify-between'>
                <div className='flex flex-row items-center'>
                    <AppBackButton className='mr-4' />
                    <div>
                        <h1 className='text-black text-2xl'>
                            Order{' '}
                            <span className='text-red-900'>
                                #{props.orderData.externalId.replace('DT', '')}
                            </span>
                        </h1>
                        <p className='text-gray-700 text-sm'>
                            {convertUnixTimestampThird(props.orderData.orderDate)}{' '}
                            <span className='font-bold capitalize'>
                                &bull; {props.orderData.type}
                            </span>
                        </p>
                    </div>
                </div>
                <div className='flex items-center space-x-2'>
                    <AppButton type='button' variant='secondary' onClick={handleCancel}>
                        Cancel
                    </AppButton>
                    <AppButton
                        type='button'
                        variant='primary'
                        onClick={handleRefund}
                        disabled={isSubmitButtonDisabled}
                    >
                        Process Refund
                    </AppButton>
                </div>
            </div>
        </AppCard>
    )
}
