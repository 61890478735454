export const getLocalStorageItem = (storageKey: string) => {
    const savedState = localStorage.getItem(storageKey)
    try {
        if (!savedState) {
            return undefined
        }
        return JSON.parse(savedState ?? {})
    } catch (e) {
        return undefined
    }
}

export const WhiteSpaceDetect = (val: string) =>
    !/\s{2,}/.test(val) && !/^\s*$/.test(val) && !/^\s/.test(val)
