import { errorWhiteSpaces } from 'utils/constants'
import { WhiteSpaceDetect } from 'utils/helpers'
import { z } from 'zod'

export const CreatePartnershipSchema = z.object({
    title: z
        .string({ required_error: 'Title is required.' })
        .min(1, 'Title is required.')
        .refine((value: string) => WhiteSpaceDetect(value), { message: errorWhiteSpaces }),
    description: z
        .string({ required_error: 'Description is required.' })
        .min(79, 'Description is required.')
        .refine((value: string) => WhiteSpaceDetect(value), { message: errorWhiteSpaces }),
    status: z.string(),
    image: z.string({ required_error: 'Featured image is required.' }),
    imageAltText: z
        .string({ required_error: 'Alt text is required.' })
        .min(1, 'Alt text is required.')
        .refine((value: string) => WhiteSpaceDetect(value), { message: errorWhiteSpaces }),
})

export type CreatePartnershipType = z.infer<typeof CreatePartnershipSchema>
