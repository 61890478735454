import { AppFileUpload } from 'components/AppFileUpload'
import { AppRichTextBox } from 'components/AppRichTextBox'
import AppTextArea from 'components/AppTextArea'
import { AppTextBox } from 'components/AppTextBox'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { BiPhotoAlbum } from 'react-icons/bi'
import { UpdateStoreType } from 'services/requests/store/schema'

export const StoreDetailSection = () => {
    const { control } = useFormContext<UpdateStoreType>()
    const watchRestrict = useWatch({ name: 'restricted' })

    return (
        <section className='grid gap-4 grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3'>
            <div className='col-span-2 xl:col-span-1'>
                <Controller
                    name='file'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <AppFileUpload
                            existingImage={typeof value === 'string' ? value : undefined}
                            onFileChange={onChange}
                            IconComponent={BiPhotoAlbum}
                            className='h-72 w-full'
                        />
                    )}
                />
            </div>
            <div className='col-span-2 xl:col-span-1'>
                <div className='space-y-2'>
                    <Controller
                        name='externalId'
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <AppTextBox
                                name={name}
                                type='text'
                                label='External ID'
                                onChange={(data) => onChange(Number(data))}
                                disabled
                                value={value}
                                errorMessage={error?.message}
                            />
                        )}
                    />
                    <Controller
                        name='shopName'
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <AppTextBox
                                name={name}
                                type='text'
                                label='Shop Name'
                                autoFocus
                                onChange={onChange}
                                value={value}
                                errorMessage={error?.message}
                            />
                        )}
                    />
                    <Controller
                        name='shopAddress'
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <AppTextBox
                                name={name}
                                type='text'
                                label='Shop Address'
                                onChange={onChange}
                                value={value}
                                errorMessage={error?.message}
                                disabled
                            />
                        )}
                    />

                    <Controller
                        name='description'
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <AppTextArea
                                name={name}
                                label='Shop Description'
                                onChange={onChange}
                                value={value}
                                placeholder='*Add Description'
                                labelHelper=' (For website only)'
                                errorMessage={error?.message}
                            />
                        )}
                    />
                </div>
            </div>
            <div className='col-span-2 my-10 2xl:my-0 2xl:col-span-1'>
                <Controller
                    name='restrictNote'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <AppRichTextBox
                            name={name}
                            onChange={onChange}
                            className='h-56'
                            value={value ?? ''}
                            label='Restrict store description'
                            disabled={!watchRestrict}
                        />
                    )}
                />
            </div>
        </section>
    )
}
