import { Dialog, Transition } from '@headlessui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { AxiosError } from 'axios'
import AppAutoComplete from 'components/AppAutocomplete'
import { AppButton } from 'components/AppButton'
import { AppRichTextBox } from 'components/AppRichTextBox'
import { AppTextBox } from 'components/AppTextBox'
import { useAtom } from 'jotai'
import { CreateJobsSchema, CreateJobsType } from 'pages/Schemas/jobs'
import React, { Fragment, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { CreateJobApi, UpdateJobApi } from 'services/requests/jobs'
import { JobsEditAtom } from '..'
import JobsCards from '../JobsCards'

const AddJob: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const params = useParams()
    const [job] = useAtom(JobsEditAtom)
    const id: string = params?.id ?? ''

    const {
        control,
        handleSubmit,
        watch,
        formState: { isDirty },
    } = useForm<CreateJobsType>({
        mode: 'onBlur',
        resolver: zodResolver(CreateJobsSchema),
        defaultValues: {
            jobType: job?.jobType ?? 'Full Time',
            jobEmployment: job?.jobEmployment ?? '100% Remote',
            jobDescription: job?.jobDescription,
            jobState: job?.jobState,
            jobTitle: job?.jobTitle,
            jobLongDescription: job?.jobLongDescription,
        },
    })
    const closeModal = () => {
        setIsOpen(false)
    }
    const openModal = () => {
        setIsOpen(true)
    }
    const onSubmit = (data: CreateJobsType) => {
        mutate(data)
    }

    const { mutate, isLoading } = useMutation<unknown, AxiosError, CreateJobsType>(
        (data) => {
            if (id !== '') return UpdateJobApi(id, data)
            else return CreateJobApi(data)
        },
        {
            onSuccess: () => {
                navigate(-1)
            },
            onError: (error) => console.log(error.message),
        },
    )
    return (
        <div className='py-10 bg-[#EFEDF0] p-5'>
            <h1 className='text-base text-gray-600 font-bold'>
                <Link className='text-red-800 hover:text-red-600' to={'/web/jobs'}>
                    Jobs
                </Link>{' '}
                &gt; {id ? 'Update' : 'Add'}
            </h1>
            <div className='w-full flex flex-col justify-center items-center my-4 text-gray-800'>
                <div className='inline-flex w-full justify-between'>
                    <h1 className='font-bold text-[20px] text-[#861F41]'>Create New Job</h1>
                    <div className='inline-flex text-[#861F41] gap-3 text-[14px]'>
                        <AppButton
                            variant='secondary'
                            type='button'
                            onClick={() => navigate(-1)}
                            className='px-5 h-[48px] bg-transparent border-none shadow-none font-bold text-[#751132]'
                        >
                            Back
                        </AppButton>
                        <AppButton
                            variant='secondary'
                            type='button'
                            disabled={!isDirty && !id}
                            onClick={() => openModal()}
                            className='px-5 h-[48px] bg-transparent border-none shadow-none font-bold'
                        >
                            Preview
                        </AppButton>
                        <AppButton
                            variant='primary'
                            onClick={handleSubmit(onSubmit)}
                            type='submit'
                            isLoading={isLoading}
                            className='w-[88px] font-bold  block text-white bg-[#751132] rounded-md py-3'
                        >
                            Save
                        </AppButton>
                    </div>
                </div>
                <div className='w-full grid grid-cols-12 gap-6 my-4'>
                    <div className='col-span-12 '>
                        <Controller
                            control={control}
                            name='jobTitle'
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <AppTextBox
                                    type='text'
                                    value={value}
                                    autoFocus
                                    name={name}
                                    containerClassname='p-2 shadow-md bg-white'
                                    inputContainerClassname=''
                                    labelPosition='left'
                                    placeholder='Job Title'
                                    inputClassname='w-full min-h-[31px] px-1 focus:outline-none shadown-none basis-9/10 shadow-none placeholder:text-2xl sm:text-2xl'
                                    onChange={onChange}
                                    errorMessage={error?.message}
                                />
                            )}
                        />
                    </div>
                    <div className='col-span-12 '>
                        <Controller
                            control={control}
                            name='jobState'
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <AppTextBox
                                    type='text'
                                    value={value}
                                    name={name}
                                    placeholder='Address:'
                                    containerClassname='bg-white p-2 shadow-none'
                                    labelPosition='left'
                                    inputContainerClassname='shadow-none'
                                    labelClassname='text-sm font-bold shadow-none'
                                    inputClassname='w-full min-h-[31px] px-1 focus:outline-none shadow-sm'
                                    onChange={onChange}
                                    errorMessage={error?.message}
                                />
                            )}
                        />
                    </div>
                    <Controller
                        control={control}
                        name='jobDescription'
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <div className='flex flex-col w-full col-span-12 space-y-1 my-2 relative'>
                                    <label
                                        tabIndex={0}
                                        className='text-xxs'
                                        htmlFor={name}
                                        id='description'
                                    >
                                        {'Description'}
                                    </label>
                                    <textarea
                                        tabIndex={0}
                                        onChange={onChange}
                                        id='canhelp'
                                        cols={3}
                                        maxLength={500}
                                        value={value}
                                        className='h-[134px] block resize-none w-full p-2 pb-10 rounded shadow focus:outline-none focus:shadow-outline text-xxs'
                                    ></textarea>
                                    <div className='flex flex-row justify-between w-full  absolute bottom-1 translate-y-[3px]'>
                                        <div className='flex flex-row justify-between mx-auto w-[99%] items-center bg-secondary px-2 pt-1'>
                                            {' '}
                                            <p
                                                tabIndex={0}
                                                className=' text-[10px] text-gray-500'
                                            >{`${value?.length ?? 0} / 500`}</p>
                                        </div>
                                    </div>
                                    {error && (
                                        <p
                                            className='text-xxs text-red-500 text-sm absolute -bottom-5'
                                            id={`${name}-error`}
                                        >
                                            {error?.message}
                                        </p>
                                    )}
                                </div>
                            </>
                        )}
                    />
                    <Controller
                        control={control}
                        name='jobLongDescription'
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <div className='col-span-12 flex flex-col'>
                                <label tabIndex={0} className='text-xxs py-2' htmlFor={name}>
                                    {'Job Requirements'}
                                </label>
                                <div className='w-full bg-white py-7 p-3 flex flex-col justify-center relative'>
                                    <AppRichTextBox name={name} onChange={onChange} value={value} />
                                    {error && (
                                        <p
                                            className='text-xxs text-red-500 text-sm absolute bottom-2'
                                            id={`${name}-error`}
                                        >
                                            {error?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                        )}
                    />

                    <div className='col-span-12 md:col-span-6'>
                        <Controller
                            control={control}
                            name='jobType'
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <AppAutoComplete
                                    name={name}
                                    list={['Full Time', 'Part Time', 'On Call']}
                                    onChange={onChange}
                                    label='Job Type:'
                                    labelClassname='text-xxs font-normal py-2'
                                    containerClassname='w-full mx-auto'
                                    value={value}
                                    inputClassname='border-none'
                                    errorMessage={error?.message}
                                    placeholder={'Select Job Type'}
                                />
                            )}
                        />
                    </div>
                    <div className='col-span-12 md:col-span-6'>
                        <Controller
                            control={control}
                            name='jobEmployment'
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <AppAutoComplete
                                    name={name}
                                    list={['100% Remote', 'Hybrid', 'Onsite']}
                                    onChange={onChange}
                                    label='Employment Type:'
                                    containerClassname='w-full mx-auto'
                                    labelClassname='text-xxs  py-2 font-normal'
                                    value={value}
                                    inputClassname='border-none'
                                    errorMessage={error?.message}
                                    placeholder={'Select Job Employment Type'}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as='div' className='relative z-10' onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div className='fixed inset-0 bg-black bg-opacity-25' />
                    </Transition.Child>

                    <div className='fixed ml-72 mt-10 inset-0 overflow-y-auto'>
                        <div className='flex min-h-full items-center justify-center p-4 text-center'>
                            <Transition.Child
                                as={Fragment}
                                enter='ease-out duration-300'
                                enterFrom='opacity-0 scale-95'
                                enterTo='opacity-100 scale-100'
                                leave='ease-in duration-200'
                                leaveFrom='opacity-100 scale-100'
                                leaveTo='opacity-0 scale-95'
                            >
                                <Dialog.Panel className='max w-[60rem] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                                    <JobsCards
                                        description={watch().jobDescription}
                                        employment={watch().jobEmployment}
                                        location={watch().jobState}
                                        name={watch().jobTitle}
                                        type={watch().jobType}
                                        onClose={closeModal}
                                    />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    )
}

export default AddJob
