import { zodResolver } from '@hookform/resolvers/zod'
import { AxiosError } from 'axios'
import { AppBanner } from 'components/AppBanner'
import { AppButton } from 'components/AppButton'
import { AppTextBox } from 'components/AppTextBox'
import { useSetAtom } from 'jotai'
import jwtDecode from 'jwt-decode'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { loginApi } from 'services/requests/auth'
import { LoginResponseType, loginSchema, LoginType } from 'services/requests/auth/schema'
import { UserAccessTokenType } from 'services/requests/user/schema'
import { authAtom } from 'store/authAtom'

export const LoginPanel = () => {
    const setAuth = useSetAtom(authAtom)
    const [errBanner, setErrBanner] = useState<boolean>(false)

    const { control, handleSubmit } = useForm<LoginType>({
        mode: 'onBlur',
        resolver: zodResolver(loginSchema),
    })

    const { mutate: loginMu, isLoading: loginLoading } = useMutation<
        LoginResponseType,
        AxiosError,
        LoginType
    >((data) => loginApi(data), {
        onSuccess: (data) => {
            const user: UserAccessTokenType = jwtDecode(data.accessToken)
            if (user.userType.toLowerCase() !== 'admin') {
                setErrBanner(true)
                return
            } else {
                setAuth(data)
            }
        },
        onError: () => {
            setErrBanner(true)
        },
    })

    const onSubmit = (data: LoginType) => {
        loginMu(data)
    }

    return (
        <div className='flex flex-1 flex-col justify-center py-12 px-4 sm:px-12 lg:flex-none lg:px-24 xl:px-28'>
            <div className='w-full md:w-96 flex flex-col items-center justify-center'>
                <div>
                    <h2 className='mt-6 text-xl font-extrabold text-gray-900'>
                        Yo-Kai Express App Manager
                    </h2>
                </div>
                <div className='mt-4 space-y-3'>
                    <AppBanner show={errBanner} setShow={setErrBanner}>
                        The email and/or password you entered is incorrect.
                    </AppBanner>
                    <h5 className='text-xs text-gray-500 mb-4'>
                        Please enter your details to login to your account
                    </h5>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex flex-col space-y-6'>
                            <Controller
                                control={control}
                                name='email'
                                render={({ field: { name, onChange }, fieldState: { error } }) => (
                                    <AppTextBox
                                        type='text'
                                        name={name}
                                        maxLength={50}
                                        placeholder='Email'
                                        onChange={onChange}
                                        errorMessage={error?.message}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name='password'
                                render={({ field: { name, onChange }, fieldState: { error } }) => (
                                    <AppTextBox
                                        type='password'
                                        name={name}
                                        maxLength={50}
                                        placeholder='Password'
                                        onChange={onChange}
                                        errorMessage={error?.message}
                                    />
                                )}
                            />

                            <div>
                                <AppButton
                                    variant='primary'
                                    type='submit'
                                    isLoading={loginLoading}
                                    className='w-full block text-white bg-red-900 hover:bg-red-600 rounded-md py-2 px-4'
                                >
                                    Sign in
                                </AppButton>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='flex flex-1 justify-end'>
                    <p className='mt-6 text-sm text-gray-400'>
                        2023 Yo-Kai Express all rights reserved
                    </p>
                </div>
            </div>
        </div>
    )
}
