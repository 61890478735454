import ScrollToTop from 'components/scroll-to-top'
import { Outlet } from 'react-router'
import { Sidebar } from './Sidebar'
import MobileMenu from './Sidebar/MobileMenu'
import { Topbar } from './Topbar'

export const AppLayout = () => {
    return (
        <>
            <div>
                <ScrollToTop />
                <MobileMenu />

                <Sidebar />
                <div className='min-h-screen bg-slate-100 lg:pl-72'>
                    <Topbar />
                    <main className='py-10'>
                        <div className='px-4 sm:px-6 lg:px-8'>
                            <Outlet />
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}
