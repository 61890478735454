import { zodResolver } from '@hookform/resolvers/zod'
import { AxiosError } from 'axios'
import { AppButton } from 'components/AppButton'
import { AppTextBox } from 'components/AppTextBox'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useMutation } from 'react-query'
import { qaMockPickupOrder } from 'services/requests/qa'
import { QaMockPickupOrderSchema, QaMockPickupOrderType } from 'services/requests/qa/schema'

export const QaOrder = () => {
    const { control, handleSubmit } = useForm<QaMockPickupOrderType>({
        mode: 'onBlur',
        defaultValues: { code: '', status: '' },
        resolver: zodResolver(QaMockPickupOrderSchema),
    })

    const sendNotif = useMutation<unknown, AxiosError, QaMockPickupOrderType>(
        (data) => qaMockPickupOrder(data.code, data.status),
        {
            onSuccess: () => {
                toast.success('notification sent successfully')
            },
            onError: (error) => {
                toast.error(error.message)
            },
        },
    )

    const onSubmit = (data: QaMockPickupOrderType) => {
        console.log('I am sending??')
        sendNotif.mutate(data)
    }

    if (sendNotif.isLoading)
        return (
            <div className='flex items-center justify-center'>
                Loading... Order is commencing, please do not refresh this will update
                automatically...
            </div>
        )

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='space-y-2.5'>
            <Controller
                control={control}
                name='code'
                render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                    <AppTextBox
                        name={name}
                        label='Pick up code'
                        placeholder='012345'
                        type='text'
                        onChange={onChange}
                        value={value}
                        errorMessage={error?.message}
                    />
                )}
            />

            <Controller
                control={control}
                name='status'
                render={({ field: { name, onChange }, fieldState: { error } }) => (
                    <AppTextBox
                        name={name}
                        label='Status'
                        placeholder='65 = success, 69 = random, 66= fail, 67 = out of stock'
                        type='text'
                        onChange={(val) => onChange(val)}
                        errorMessage={error?.message}
                    />
                )}
            />

            <AppButton type='submit' variant='primary'>
                Submit Order
            </AppButton>
        </form>
    )
}
