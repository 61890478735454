import { z } from 'zod'

export const emailReceiverSchema = z.object({
    email: z.string().email(),
})

export const emailReceiverResponseSchema = z.object({
    id: z.string(),
    key: z.string(),
    value: z.string(),
    createdAt: z.number(),
    updatedAt: z.null(),
})

export type EmailReceiverSchemaType = z.infer<typeof emailReceiverSchema>
export type EmailReceiverResponseSchemaType = z.infer<typeof emailReceiverResponseSchema>
