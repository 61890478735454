import { atom } from 'jotai'
import { UserType } from 'services/requests/user/schema'

const initialUser: UserType = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    birthday: '',
    createdAt: 0,
    fromPlatform: '',
    gender: '',
    phone: '',
    status: '',
    updatedAt: 0,
    userType: '',
    isGeneratedPassword: null,
    userImage: '',
    whenAdded: 0,
}

//If no user is found in local storage, return an empty object
const getCurrentUser = (): UserType => {
    const user = localStorage.getItem('user')

    if (user) {
        return JSON.parse(user) as UserType
    }

    return initialUser
}

const currentUserAtom = atom<UserType>(getCurrentUser())

export const userAtom = atom<UserType, [UserType], void>(
    (get) => get(currentUserAtom),
    (get, set, update) => {
        set(currentUserAtom, update)
        localStorage.setItem('user', JSON.stringify(update))
    },
)

export const userErrorAtom = atom<boolean>(false)
