import { AppButton } from 'components/AppButton'
import { AppCard } from 'components/AppCard'
import { AppModal } from 'components/AppModal'
import { AppSkeletonLoadingGiftCard } from 'components/AppSkeletonLoaders/AppSkeletonLoadingGiftCard'
import React from 'react'
import toast from 'react-hot-toast'
import { FiTrash } from 'react-icons/fi'
import { useQuery } from 'react-query'
import { getGiftCardApi } from 'services/requests/gift-card'
import { AddModalContent } from './AddModalContent'
import { DeleteModalContent } from './DeleteModalContent'
import { GiftCardPriceInput } from './GiftCardPriceInput'
import { GiftCardTableHeader } from './GiftCardTableHeader'

export const GiftCard = () => {
    const [openModal, setOpenModal] = React.useState(false)
    const [deleteModal, setDeleteModal] = React.useState(false)
    const [deleteId, setDeleteId] = React.useState('')
    const [, setGiftCard] = React.useState({ price: '' })
    const [optionCount, setOptionCount] = React.useState(() => {
        const savedOptionCount = localStorage.getItem('optionCount')
        return savedOptionCount ? parseInt(savedOptionCount, 10) : 0
    })

    const { data: giftCardData, isLoading } = useQuery({
        queryKey: 'giftCard',
        queryFn: () => getGiftCardApi(),
        refetchOnWindowFocus: false,
    })

    React.useEffect(() => {
        if (giftCardData != null) {
            setOptionCount(giftCardData.content.length)
        }
    }, [optionCount, giftCardData])

    const handleAddOption = () => {
        setOpenModal(true)
    }

    const handleDeleteOption = (giftCardId: string) => {
        setDeleteModal(true)
        setDeleteId(giftCardId)
    }

    const handleCancel = () => {
        setDeleteModal(false)
        setOpenModal(false)
    }

    const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        const parsedValue = parseFloat(value.replace(/[^0-9.]/g, ''))
        const newValue = isNaN(parsedValue) ? '' : parsedValue.toFixed(2)
        setGiftCard((prevGiftCard) => ({
            ...prevGiftCard,
            price: newValue,
        }))
    }

    if (isLoading) return <AppSkeletonLoadingGiftCard />

    if (!giftCardData) return <div>Not found</div>

    return (
        <>
            <div className='space-y-4'>
                <AppCard>
                    <h1 className='text-2xl font-bold text-[#861f41]'>Gift Card</h1>
                    {giftCardData.content.length > 0 && (
                        <div className='flex flex-col px-10 divide-y divide-gray-300'>
                            <GiftCardTableHeader optionCount={optionCount} />
                            {giftCardData.content
                                .sort((a, b) => +new Date(a.whenAdded) - +new Date(b.whenAdded))
                                .map((giftCard) => (
                                    <div key={giftCard.id} className='flex flex-row'>
                                        <GiftCardPriceInput
                                            isReadOnly
                                            giftCard={giftCard}
                                            handlePriceChange={handlePriceChange}
                                        />
                                        <button
                                            onClick={() => handleDeleteOption(giftCard.id)}
                                            className='text-indigo-600 hover:text-indigo-900'
                                        >
                                            <FiTrash className='h-5 w-10 mr-4 text-red-800' />
                                            <span className='sr-only'>
                                                {' '}
                                                Delete, {giftCard.productName}
                                            </span>
                                        </button>
                                    </div>
                                ))}
                        </div>
                    )}
                    <div className='flex items-center justify-between relative pt-6 pl-0 sm:pl-20 '>
                        <AppButton
                            className='flex border border-dashed border-red-800 bg-red-100 text-red-800 w-full sm:w-36 hover:bg-red-300'
                            variant='primary'
                            type='button'
                            onClick={handleAddOption}
                        >
                            + Add
                        </AppButton>
                    </div>

                    {!giftCardData.content.length && (
                        <div className='flex items-center justify-center'>
                            <h1 className='text-xl'>No data</h1>
                        </div>
                    )}
                </AppCard>
            </div>

            <AppModal isOpen={deleteModal} onClose={() => setDeleteModal(false)}>
                <DeleteModalContent
                    id={deleteId}
                    onClose={() => {
                        const isSuccess = Math.random() < 1
                        if (isSuccess) {
                            toast.success('Gift Card deleted successfully')
                        } else {
                            toast.error('Something went wrong. Please try again later.')
                        }
                        setDeleteModal(false)
                    }}
                    onCancel={handleCancel}
                />
            </AppModal>
            <AppModal
                isOpen={openModal}
                onClose={() => setOpenModal(false)}
                title='Add Denomination'
            >
                <AddModalContent
                    onClose={() => {
                        const isSuccess = Math.random() < 1
                        if (isSuccess) {
                            toast.success('Gift Card added successfully')
                        } else {
                            toast.error('Something went wrong. Please try again later.')
                        }
                        setOpenModal(false)
                    }}
                    onCancel={handleCancel}
                />
            </AppModal>
        </>
    )
}
