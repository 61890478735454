import { axiosInstance } from 'services/axios'
import { UserType } from '../user/schema'
import { loginResponseSchema, LoginResponseType, LoginType } from './schema'

export const loginApi = async (data: LoginType) => {
    const response = await axiosInstance.post('/auth/sign-in', data)

    const parsedData = loginResponseSchema.parse(response.data)

    return parsedData
}

export const verifyTokenApi = async () => {
    const response = await axiosInstance.get('/auth/verify/token')

    return response.data as UserType
}

export const refreshTokenApi = async () => {
    const response = await axiosInstance.get('/auth/user/refresh')

    return response.data as LoginResponseType
}

export const logout = () => {
    localStorage.removeItem('token')
    window.location.reload()
}
