import { useAtom } from 'jotai'
import { FaSearch } from 'react-icons/fa'
import { ListProductType } from 'services/requests/menu-category/schema'
import { menuObjectProductArrayAtom, menuSearchKeyWord } from 'store/productAtom'

interface searchProps {
    products: ListProductType | undefined
}

export const CounterAndSearch = (props: searchProps) => {
    const [keyword, setKeyword] = useAtom(menuSearchKeyWord)
    const [productArray, setProductArray] = useAtom(menuObjectProductArrayAtom)

    return (
        <>
            <div className='flex flex-row justify-between'>
                <div>
                    <h1 className='text-2xl text-pink-900 font-bold'>
                        {!props.products?.length ? '' : props.products?.length}
                        &nbsp;Product{!props.products?.length ? '' : 's'}
                    </h1>
                </div>
                <div className='flex items-center justify-center border border-gray-200 p-2 '>
                    <FaSearch className='mr-2 text-gray-500' />
                    <input
                        type='text'
                        value={keyword.text}
                        onChange={(e) => {
                            setKeyword({
                                text: e.target.value,
                            })
                        }}
                        placeholder='Search...'
                        className='outline-none bg-transparent placeholder-gray-500 w-full'
                    />
                </div>
            </div>
        </>
    )
}
