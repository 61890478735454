import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import {
    createContentApi,
    getContentApi,
    updateContentApi,
} from '../../../services/requests/content'
import { ContentType, CreateContentType } from '../../../services/requests/content/schema'

import { AxiosError, isAxiosError } from 'axios'
import toast from 'react-hot-toast'
import { AppButton } from '../../../components/AppButton'
import { AppRichTextBox } from '../../../components/AppRichTextBox'
import { TextHeader } from '../Brandkit/BrandkitHeaders/TextHeader'

interface RichTextEditorWrapperProps {
    title: string
}

export const RichTextEditorWrapper = (props: RichTextEditorWrapperProps) => {
    const {
        isFetching,
        data,
        error: queryError,
    } = useQuery({
        queryKey: ['content', props.title],
        queryFn: () => getContentApi(String(props.title)),
        enabled: Boolean(props.title),
        onSuccess: (data) => {
            setValue('title', data.contentTitle)
            setValue('description', data.contentDescription)
        },
    })

    const { control, handleSubmit, setValue } = useForm<CreateContentType>({
        mode: 'onBlur',
        defaultValues: {
            title: props.title,
            description: data?.contentDescription || '',
        },
    })

    const { mutate: saveContent, isLoading: isContentSaving } = useMutation<
        ContentType,
        AxiosError,
        CreateContentType
    >(
        (data) => {
            if (isAxiosError(queryError) && queryError?.response?.status === 404)
                return createContentApi(data)
            else return updateContentApi(props.title, data)
        },
        {
            onSuccess: () => {
                toast.success('Success! Content saved!')
            },
        },
    )

    const onSubmit = (data: CreateContentType) => {
        saveContent(data)
    }

    if (isFetching) return <></>

    return (
        <div className='space-y-4'>
            <TextHeader value={props.title} className='text-2xl text-pink-900 font-bold' />
            <hr className='h-px my-4 bg-gray-200 border-0 ' />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name='description'
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                        <AppRichTextBox value={value} name={name} onChange={onChange} />
                    )}
                />
                <AppButton
                    variant='primary'
                    type='submit'
                    isLoading={isContentSaving}
                    className='mt-4'
                >
                    Submit
                </AppButton>
            </form>
        </div>
    )
}
