import { zodResolver } from '@hookform/resolvers/zod'
import { AxiosError } from 'axios'
import ConfirmationModal from 'components/ActionConfirmation'
import AppAltTextInput from 'components/AppAltTextInput'
import { AppButton } from 'components/AppButton'
import AppColorPicker, { MyOption } from 'components/AppColorPicker'
import { AppFileUpload } from 'components/AppFileUpload'
import { AppModal } from 'components/AppModal'
import { AppTextBox } from 'components/AppTextBox'
import Loading from 'components/loading'
import { useAtom } from 'jotai'
import { CreateMenuCategorySchema, CreateMenuCategoryType } from 'pages/Schemas/menu-category'
import React, { useCallback, useEffect, useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { BiPhotoAlbum } from 'react-icons/bi'
import { useMutation, useQuery } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
    CreateMenuCategoryApi,
    DeleteMenuCategoryApi,
    MenuCategoryApi,
    UpdateMenuCategoryApi,
} from 'services/requests/menu-category'
import { IUploadMenuCategoryResponse, UploadMenuCategoryImageApi } from 'services/requests/news'
import { MenuCategoryPositionAtom } from 'store/productAtom'
import { twMerge } from 'tailwind-merge'
import { badRequestError } from 'utils/constants'

interface IColor extends MyOption {
    name?: string
    Label?: string
}

const MenuCategoryForm: React.FunctionComponent = () => {
    const initalColors = [
        {
            label: '#861F41',
            name: 'text',
            value: '#861F41',
            customData: '',
            Label: 'Text Color',
        },
        {
            label: '#FFFFFF',
            name: 'bg',
            value: '#FFFFFF ',
            customData: '',
            Label: 'Button Color',
        },
        {
            label: '#FFFFFF',
            name: 'activeText',
            value: '#FFFFFF',
            customData: '',
            Label: 'Active text color',
        },
        {
            label: '#861F41',
            name: 'activeBg',
            value: '#861F41',
            customData: '',
            Label: 'Active button color',
        },
    ]
    const params = useParams()
    const navigate = useNavigate()
    const [changed, setChanged] = React.useState(0)
    const [colors, setColors] = React.useState<IColor[]>(initalColors)
    const [isOpenConfirmationModal, setIsOpenConfirmationModal] = React.useState(false)
    const [badReqError, setBadReqError] = useState<string | null>(null)
    const [HighestMenuCategoryPositon] = useAtom(MenuCategoryPositionAtom)

    const { control, handleSubmit, setValue, watch } = useForm<CreateMenuCategoryType>({
        mode: 'onBlur',
        resolver: zodResolver(CreateMenuCategorySchema),
        defaultValues: {
            description: 'Menu Categories',
            status: 'INACTIVE',
            styles: colors.map((item) => item.value).join(','),
            position: HighestMenuCategoryPositon,
        },
    })

    const { mutate: deleteMenuCategory, isLoading: deleteLoading } = useMutation<
        unknown,
        AxiosError,
        string
    >((id) => DeleteMenuCategoryApi(id), {
        onSuccess: () => {
            toast.success('Success! Menu category deleted')
            navigate(-1)
        },
        onError: (error) => console.log(error),
    })

    const { mutate: createMenuCategory, isLoading: createLoading } = useMutation<
        unknown,
        AxiosError,
        CreateMenuCategoryType
    >(
        (data) =>
            params?.id ? UpdateMenuCategoryApi(params?.id, data) : CreateMenuCategoryApi(data),
        {
            onSuccess: () => {
                navigate(-1)
            },
            onError: (error) => console.log(error),
        },
    )
    const onSubmit = (data: CreateMenuCategoryType) => {
        createMenuCategory(data)
    }
    interface IUploadRequest {
        file: File
        type: string
    }
    const handleFileUpload = (uploadedFile: File) => {
        uploadImage({ file: uploadedFile, type: 'image' })
    }
    const { mutate: uploadImage, isLoading: isUploadingImage } = useMutation<
        IUploadMenuCategoryResponse[],
        AxiosError,
        IUploadRequest
    >((data) => UploadMenuCategoryImageApi(data), {
        onSuccess: (data) => {
            setValue('icon', data[0].sizes[0].objectURL)
            setBadReqError(null)
        },
        onError: (error) => {
            if (error.code === 'ERR_NETWORK') setBadReqError(badRequestError)
        },
    })

    const { data, isLoading: isFetchingData } = useQuery(
        ['productsFromId'],
        () => MenuCategoryApi(params?.id ?? ''),
        {
            enabled: !!params.id,
            onSuccess: (category) => {
                if (params?.id && !isUploadingImage) {
                    setValue('name', category?.name)
                    setValue('icon', category?.icon)
                    setValue('status', category?.status)
                    setValue('styles', category?.styles)
                    setValue('position', category?.position)
                    setValue('iconAltText', category?.iconAltText)
                    if (category.styles) {
                        const arrayColors = category.styles.split(',')
                        setColors(
                            colors.map((color, index) => {
                                return {
                                    ...color,
                                    value: arrayColors[index],
                                    label: arrayColors[index],
                                }
                            }),
                        )
                        setChanged(changed + 1)
                    }
                }
            },
        },
    )
    type IFileUpload = {
        value?: string | null
    }

    const FileUpload = useCallback(
        (props: IFileUpload) => {
            return (
                <AppFileUpload
                    onClose={() => setBadReqError(null)}
                    maxSize={25}
                    errorMessage={badReqError}
                    existingImage={typeof props.value === 'string' ? props.value : undefined}
                    onFileChange={(file) => {
                        file && handleFileUpload(file)
                    }}
                    IconComponent={BiPhotoAlbum}
                    isLoading={isUploadingImage}
                    containerClassName='w-[199px] h-fit'
                    className='w-[199px] h-[162px]'
                />
            )
        },
        [watch().icon, badReqError, isUploadingImage],
    )

    useEffect(() => {
        setValue('styles', colors.map((item) => item.value).join(','))
    }, [changed, colors])

    if (!!params.id && isFetchingData && !data) return <Loading />
    return (
        <div className='flex flex-col w-full'>
            <h1 className='text-base text-gray-600 font-bold'>
                <Link className='text-red-800 hover:text-red-600' to={'/web/menu-category'}>
                    Menu
                </Link>{' '}
                &gt; {'Add Category'}
            </h1>
            <h1 className='py-3 my-3 text-[23px] font-bold text-[#861F41] w-full border-b-gray-500 border-b-2'>
                {params?.id ? 'Update Category' : 'Add New Category'}
            </h1>
            <form className='my-5 p-10 bg-white flex flex-col' onSubmit={handleSubmit(onSubmit)}>
                <div className='flex flex-row w-full gap-11'>
                    <div className='flex flex-col'>
                        <h1 className='text-[14px] font-semibold'>Add Image</h1>
                        <Controller
                            control={control}
                            name='icon'
                            render={({ field: { value }, fieldState: { error } }) => (
                                // <FileUpload
                                //     name={name}
                                //     containerClassname='w-[199px] h-[162px] bg-gray-300 border-dotted border-2 mx-0'
                                //     file={image}
                                //     onFileUpload={handleFileUpload}
                                // >
                                //     {value ? (
                                //         <img
                                //             className='h-[100px] w-[100px] object-contain'
                                //             src={value}
                                //         />
                                //     ) : (
                                //         <>
                                //             <FiImage className='h-full w-full text-gray-500 ' />
                                //             <span className='underline'>
                                //                 Add Category Image Here
                                //             </span>
                                //             <span className='truncate max-w-[180px]'>{value}</span>
                                //         </>
                                //     )}
                                //     {errorUpload && (
                                //         <span className='text-red-500'>Upload Error</span>
                                //     )}
                                //     {/* <Loading className='absolute' /> */}
                                //     {error && <h1 className='text-red-500'>{error.message}</h1>}
                                // </FileUpload>
                                <FileUpload value={value} />
                            )}
                        />
                        <div className='flex flex-col w-fit text-[10px] mt-6'>
                            <Controller
                                control={control}
                                name='iconAltText'
                                render={({
                                    field: { name, onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <AppAltTextInput
                                        name={name}
                                        onChange={onChange}
                                        errorMessage={error?.message ?? ''}
                                        value={value}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className='w-full flex flex-col px-10 gap-5'>
                        <AppButton
                            variant='secondary'
                            type='button'
                            className={twMerge(
                                watch().status !== 'ACTIVE' ? 'text-[#862F4C]' : 'text-gray-500',
                                'inline-flex items-center w-fit shadow-none border-none font-bold text-sm gap-3 ml-auto',
                            )}
                            onClick={() => {
                                if (watch().icon) {
                                    setValue(
                                        'status',
                                        watch()?.status !== 'ACTIVE' ? 'ACTIVE' : 'INACTIVE',
                                    )
                                } else {
                                    toast.error(
                                        'Prior to proceeding, please ensure that you upload a photo first.',
                                    )
                                }
                            }}
                        >
                            {watch().status === 'ACTIVE' ? (
                                <>
                                    <span>Hide Category</span>
                                    <AiFillEyeInvisible className='w-6 h-6' />
                                </>
                            ) : (
                                <>
                                    <span>Show Category</span>
                                    <AiFillEye className='w-6 h-6' />
                                </>
                            )}
                        </AppButton>

                        <Controller
                            control={control}
                            name='name'
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <AppTextBox
                                    type='text'
                                    label={'Category Name'}
                                    maxLength={15}
                                    placeholder='Category Name'
                                    value={value}
                                    name={name}
                                    autoFocus
                                    containerClassname='w-full '
                                    labelClassname='font-bold text-[15px]'
                                    inputClassname='border border-[#707070] rounded-none py-3 w-full min-h-[31px] px-8 focus:outline-none focus:shadow-outline text-xxs shadow-none '
                                    onChange={onChange}
                                    errorMessage={error?.message}
                                />
                            )}
                        />

                        <div className='flex flex-wrap gap-5'>
                            {colors.map((color, index) => (
                                <div
                                    key={index}
                                    className='flex flex-col gap-2 my-3 w-[11rem] mr-auto'
                                >
                                    <h1 className='text-[14px] font-semibold'> {color?.Label}</h1>
                                    <AppColorPicker
                                        onChange={(e) => {
                                            setChanged(changed + 1)
                                            setColors((prev) => {
                                                prev[index] = {
                                                    ...prev[index],
                                                    ...e,
                                                }
                                                return prev
                                            })
                                        }}
                                        value={{
                                            label: color.label,
                                            value: color.value,
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className='flex mx-auto w-full justify-end items-center gap-6 py-7'>
                    {params?.id && (
                        <AppButton
                            variant='secondary'
                            className='w-[129px] mr-auto h-[47px] flex justify-center items-center bg-white border-[#861F41] text-[#861F41] p-3 font-bold'
                            type='button'
                            isLoading={deleteLoading}
                            onClick={() => setIsOpenConfirmationModal(true)}
                        >
                            Delete
                        </AppButton>
                    )}
                    <AppButton
                        variant='secondary'
                        className='min-w-[129px] h-[47px] flex justify-center items-center bg-white border-[#861F41] text-[#861F41] p-3 font-bold'
                        type='button'
                        onClick={() => navigate('/web/menu-category')}
                    >
                        Cancel
                    </AppButton>
                    <AppButton
                        variant='primary'
                        isLoading={createLoading}
                        className='min-w-[129px] h-[47px] flex justify-center items-center text-white  bg-[#861F41] p-3 font-bold'
                        type='submit'
                    >
                        Save
                    </AppButton>
                </div>
            </form>
            <AppModal
                closeIcon={false}
                isOpen={isOpenConfirmationModal}
                onClose={() => setIsOpenConfirmationModal(false)}
                title=''
                containerClassName='h-fit p-0 my-0'
            >
                <ConfirmationModal
                    handle={() => params?.id && deleteMenuCategory(params?.id)}
                    onClose={() => setIsOpenConfirmationModal(false)}
                    yesLabel='Delete'
                    noLabel='Cancel'
                    title='Delete Category'
                    isLoading={deleteLoading}
                    subtitle='Are you sure you want to delete this category?'
                />
            </AppModal>
        </div>
    )
}

export default MenuCategoryForm
