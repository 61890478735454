import { AppLayout } from 'components/AppLayout'
import { useAtomValue } from 'jotai'
import { Navigate } from 'react-router'
import { authAtom } from 'store/authAtom'

const Container = () => {
    const auth = useAtomValue(authAtom)

    if (auth.accessToken === '') {
        return <Navigate to='/login' />
    }

    return <AppLayout />
}

export default Container
