import { axiosInstance } from 'services/axios'
import { GiftCardPaginatedType, GiftCardType } from './schema'

export const getGiftCardApi = async () => {
    const response = await axiosInstance.get('/store/gift-card')
    return response.data as GiftCardPaginatedType
}

export const getGiftCardByIdApi = async (id: string) => {
    const response = await axiosInstance.get(`/store/gift-card/${id}`)
    return response.data as GiftCardType
}

export const deleteGiftCardByIdApi = async (id: string) => {
    const response = await axiosInstance.delete(`/store/gift-card/${id}`)
    return response.data
}

export const createGiftCardApi = async (price: number) => {
    const response = await axiosInstance.post('/store/gift-card', {
        price,
    })

    return response.data as GiftCardType
}
