import {
    closestCenter,
    DndContext,
    DragEndEvent,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { AxiosError } from 'axios'
import ConfirmationModal from 'components/ActionConfirmation'
import { AppButton } from 'components/AppButton'
import { AppModal } from 'components/AppModal'
import AppPageName from 'components/AppPageName'
import AppSkeletonLoadingJobs from 'components/AppSkeletonLoaders/AppSkeletonLoadingJobs'
import { atom, useAtom } from 'jotai'
import { UpdateJobsPosType } from 'pages/Schemas/jobs'
import React, { useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { listJobsApi, UpdateDeleteJobsApi, UpdateJobPosApi } from 'services/requests/jobs'
import { JobsType } from 'services/requests/jobs/schema'
import { twMerge } from 'tailwind-merge'
import { tableWebHeader } from 'utils/styles'
import JobsTableItem from './TableItem'

export const JobsEditAtom = atom<JobsType | null>(null)

const Jobs: React.FunctionComponent = () => {
    const [selectedJobs, setSelectedJobs] = useState<JobsType[] | []>([])
    const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false)
    const navigate = useNavigate()
    //API CALL FOR JOBS
    const { data, isLoading, refetch } = useQuery(
        ['jobs'],
        () => listJobsApi({ page: 0, size: 10, sort: 'position,asc' }),
        {
            onSuccess: () => setSortedData(null),
        },
    )
    const [sortedData, setSortedData] = useState<JobsType[] | null>(null)

    const filteredArray = useMemo(() => sortedData ?? data?.content ?? [], [data, sortedData])

    const [, setEditJobs] = useAtom(JobsEditAtom)
    const { mutate: deleteJob, isLoading: deleteLoading } = useMutation<
        unknown,
        AxiosError,
        string
    >((id) => UpdateDeleteJobsApi(id), {
        onSuccess: (data) => {
            console.log(data)
            refetch()
        },
        onError: (error) => {
            console.log('ERROR', error)
        },
    })
    const handleDelete = (id: string) => {
        setSelectedJobs((prevSelectedJobs) => prevSelectedJobs.filter((job) => job.jobId !== id))
        deleteJob(id)
    }

    const { mutate } = useMutation<unknown, AxiosError, UpdateJobsPosType>(
        (data) => {
            return UpdateJobPosApi(data.id, data.position)
        },
        {
            onSuccess: () => {
                refetch()
            },
            onError: (error) => console.log(error.message),
        },
    )
    const handleDrag = (event: DragEndEvent) => {
        const { active, over } = event
        if (active.id !== over?.id) {
            const activeIndex = filteredArray.map((arr) => arr.jobId).indexOf(active.id.toString())
            const overIndex = filteredArray
                .map((arr) => arr.jobId)
                .indexOf((over?.id ?? '').toString())

            const array = arrayMove(filteredArray, activeIndex, overIndex)
            setSortedData(array)
            array.forEach((element, index) => {
                mutate({ id: element.jobId, position: index + 1 })
            })
            setTimeout(() => toast.success('Success! Jobs arrangement changed.'), 300)
        }
    }

    const pointerSensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 10,
        },
    })
    const sensors = useSensors(pointerSensor)
    const handleDeleteBulk = () => {
        if (selectedJobs.length > 0) {
            selectedJobs.forEach(({ jobId }) => handleDelete(jobId))
            setSelectedJobs([])
            setIsOpenConfirmationModal(false)
            toast.success('Success! Items are deleted.')
        }
    }
    const isSelectedMorethanOne = React.useMemo(() => selectedJobs.length > 1, [selectedJobs])
    if (isLoading) return <AppSkeletonLoadingJobs />

    return (
        <div className='flex flex-col gap-4 text-[#464255]'>
            <div className='flex flex-col w-full gap-4'>
                <AppPageName pageName='Jobs' />
                <div className='bg-white flex flex-col gap-3 py-5 min-w-full'>
                    <div
                        className={twMerge(
                            selectedJobs.length > 0 ? 'flex' : 'hidden',
                            'w-full items-center gap-6 p-6 ',
                        )}
                    >
                        <h1>{`Selected Items(${selectedJobs.length})`}</h1>
                        <AppButton
                            variant='secondary'
                            type='button'
                            className='px-5'
                            onClick={() => setIsOpenConfirmationModal(true)}
                        >
                            Delete Selected
                        </AppButton>
                    </div>
                    <div className='flex w-full justify-end p-6 h-fit items-center'>
                        <AppButton
                            variant='primary'
                            type='button'
                            onClick={() => {
                                setEditJobs(null)
                                navigate('add')
                            }}
                            className='text-[14px] px-6'
                        >
                            Create Job
                        </AppButton>
                    </div>
                    <div className='flow-root'>
                        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 min-h-[50rem] '>
                            <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                                <div className='relative'>
                                    <table className='min-w-full bg-white table-fixed divide-y divide-gray-300'>
                                        <thead
                                            className={twMerge(
                                                tableWebHeader,
                                                'bg-[#EDDFE2] h-[55px] ',
                                            )}
                                        >
                                            <tr>
                                                <th></th>
                                                <th
                                                    scope='col'
                                                    className='relative px-7 sm:w-12 sm:px-6'
                                                >
                                                    <input
                                                        type='checkbox'
                                                        className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 accent-[#861F41] cursor-pointer'
                                                        onChange={(e) =>
                                                            e.target.checked
                                                                ? setSelectedJobs(filteredArray)
                                                                : setSelectedJobs([])
                                                        }
                                                        checked={
                                                            filteredArray.length ===
                                                            selectedJobs.length
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='min-w-[12rem] py-3.5 pr-3 text-left '
                                                >
                                                    Title
                                                </th>
                                                <th scope='col' className='px-3 py-3.5 text-left '>
                                                    Type
                                                </th>
                                                <th scope='col' className='px-3 py-3.5 text-left '>
                                                    Address
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='px-3 py-3.5 text-center '
                                                >
                                                    Action
                                                </th>
                                                {/* <th
                                                scope='col'
                                                className='px-3 py-3.5 text-left '
                                            >
                                                Last Modified
                                            </th> */}
                                            </tr>
                                        </thead>
                                        <tbody className='divide-y divide-gray-200'>
                                            {filteredArray.length > 0 ? (
                                                <React.Fragment>
                                                    <DndContext
                                                        {...sensors}
                                                        collisionDetection={closestCenter}
                                                        onDragEnd={handleDrag}
                                                    >
                                                        <SortableContext
                                                            items={filteredArray.map(
                                                                (item) => item.jobId,
                                                            )}
                                                            strategy={verticalListSortingStrategy}
                                                        >
                                                            {filteredArray.map(
                                                                (item: JobsType, index: number) => (
                                                                    <React.Fragment key={index}>
                                                                        <JobsTableItem
                                                                            id={item.jobId}
                                                                            handleDelete={
                                                                                handleDelete
                                                                            }
                                                                            item={item}
                                                                            selectedJobs={
                                                                                selectedJobs
                                                                            }
                                                                            index={index}
                                                                            setSelectedJobs={
                                                                                setSelectedJobs
                                                                            }
                                                                        />
                                                                    </React.Fragment>
                                                                ),
                                                            )}
                                                        </SortableContext>
                                                    </DndContext>
                                                </React.Fragment>
                                            ) : (
                                                <div className='flex flex-col justify-center items-center w-full h-full absolute min-h-[30rem]'>
                                                    <h1 className='text-xs font-bold'>
                                                        ...No jobs to display
                                                    </h1>
                                                </div>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AppModal
                    closeIcon={false}
                    isOpen={isOpenConfirmationModal}
                    onClose={() => setIsOpenConfirmationModal(false)}
                    title=''
                    containerClassName='h-fit p-0 my-0'
                >
                    <ConfirmationModal
                        handle={handleDeleteBulk}
                        onClose={() => setIsOpenConfirmationModal(false)}
                        yesLabel='Delete'
                        noLabel='Cancel'
                        title={
                            isSelectedMorethanOne
                                ? 'Delete Selected Job Roles'
                                : 'Delete Selected Job Role'
                        }
                        isLoading={deleteLoading}
                        subtitle={`Are you sure you want to delete ${
                            isSelectedMorethanOne ? 'these job roles' : 'job role'
                        }?`}
                    />
                </AppModal>
            </div>
        </div>
    )
}

export default Jobs
