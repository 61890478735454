import * as React from 'react'
import { BsClipboardCheck } from 'react-icons/bs'
import { CiClock2 } from 'react-icons/ci'
import { HiLocationMarker } from 'react-icons/hi'

interface IJobsCardsProps {
    name: string
    description: string
    location: string
    employment: string
    type: string
    onClose: () => void
}

const JobsCards: React.FunctionComponent<IJobsCardsProps> = (props) => {
    const [isModalOpen, setModalOpen] = React.useState(true)

    const closeModal = () => {
        setModalOpen(false)
        props.onClose()
    }

    return (
        <div className='flex justify-center  mt-10 w-full'>
            <div className='flex flex-col lg:flex-row w-full justify-center lg:justify-between px-10 max-w-[70rem] items-center border-b-2'>
                <div className='flex justify-center lg:justify-start flex-col items-center lg:items-start'>
                    <button className='absolute right-6 top-4' onClick={closeModal}>
                        <span aria-hidden='true' className='text-[#751132] font-bold text-3xl'>
                            &times;
                        </span>
                    </button>
                    <h5
                        tabIndex={0}
                        className='text-xl leading-tight mb-2 font-bold text-[#751132]'
                    >
                        {props?.name}
                    </h5>
                    <div className='inline-flex items-center justify-center gap-1'>
                        <HiLocationMarker
                            tabIndex={0}
                            role='icon'
                            aria-label='Location icon'
                            className='text-[#751132] w-6 h-6'
                        />
                        <p tabIndex={0} className=' text-base inline text-gray-500'>
                            {props?.location}
                        </p>
                    </div>
                    <p
                        tabIndex={0}
                        className='text-gray-700 text-base pb-10 pt-5 sm:pr-0 lg: pr-10 lg:pr-16'
                    >
                        {props?.description}
                    </p>

                    <div className='flex flex-row mb-6 gap-4 items-center justify-start'>
                        <div className='flex flex-row rounded-full border-2 border-[#751132] px-4 items-center py-1'>
                            <BsClipboardCheck tabIndex={0} role='icon' className='h-5 w-5 mx-1' />
                            <p tabIndex={0} className='text-gray-700 text-xs'>
                                {props?.employment}
                            </p>
                        </div>
                        <div className='flex flex-row rounded-full border-2 border-[#751132] px-4 items-center py-1'>
                            <CiClock2
                                tabIndex={0}
                                role={'icon'}
                                aria-label='time'
                                className='h-5 w-5 mx-1'
                            />
                            <p tabIndex={0} className='text-gray-700 text-xs'>
                                {props?.type}
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <span className='shadow border py-2 px-20 bg-[#751132] font-bold text-sm rounded-lg text-white whitespace-nowrap my-auto'>
                        See More
                    </span>
                </div>
            </div>
        </div>
    )
}

export default JobsCards
