import { Popover, Switch, Transition } from '@headlessui/react'
import { AxiosError } from 'axios'
import ConfirmationModal from 'components/ActionConfirmation'
import { AppButton } from 'components/AppButton'
import { AppModal } from 'components/AppModal'
import { AppPagination } from 'components/AppPagination'
import AppRenderHtml from 'components/AppRenderHtml'
import { AppSelectMenu } from 'components/AppSelectMenu'
import AppSkeletonLoadingRewards from 'components/AppSkeletonLoaders/AppSkeletonLoadingRewards'
import { EmptyTableState } from 'components/EmptyState/table-empty-state'
import { useAtom } from 'jotai'
import { searchMode } from 'pages/GlobalSettings/UserManagement'
import { TierType } from 'pages/Schemas/tier'
import { Fragment, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { BiDotsVerticalRounded, BiSearch, BiSolidEdit } from 'react-icons/bi'
import { FiTrash } from 'react-icons/fi'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import {
    deletePointingReward,
    getAllPointingRewards,
    getPointingTierList,
    searchRewardsApi,
    updateRewardVisibility,
} from 'services/requests/account'
import { TierRewardsType } from 'services/requests/account/schema'
import { paginationAtom } from 'store/paginationAtom'
import { rewardAtom, selectedRewardAtom } from 'store/rewardsAtom'
import { tiersAtom } from 'store/tierAtom'
import { twMerge } from 'tailwind-merge'
import { debounce } from 'utils/debounce'
import { flexCenter } from 'utils/styles'
import { OffersAndDeals } from '..'

const tableHeaders = ['Title', 'Description', 'Discount', 'Points', 'Visibility', 'Action']

const TD_CLASS = 'py-2 text-gray-500 font-extralight text-base tracking-wide '

const tableActions = [
    {
        name: 'Update',
        icon: <BiSolidEdit />,
    },
    {
        name: 'Delete',
        icon: <FiTrash />,
    },
]

export const Rewards = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const [rewardsAtom, setRewardsAtom] = useAtom(rewardAtom)
    const [selectedRewardsAtom, setSelectedRewardsAtom] = useAtom(selectedRewardAtom)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
    const [tierAtom, setTierAtom] = useAtom(tiersAtom)

    const { data: pointingTierList } = useQuery<TierType[]>('pointingTierList', getPointingTierList)
    const sortedTiers = pointingTierList?.sort((a, b) => a.lowestPoints - b.lowestPoints) || []

    const [groupedPointingRewards, setGroupedPointingRewards] = useState<TierRewardsType[]>([])
    const [selectedRewards, setSelectedRewards] = useState<TierRewardsType>()
    const [, setIsSearching] = useAtom(searchMode)
    const [selectedTier, setSelectedTier] = useState<string>('All')
    const [searchQuery, setSearchQuery] = useState<string>('')
    const DebouncedValue = debounce(searchQuery, 500)
    const [page, setPage] = useAtom(paginationAtom)

    const pageSize = 9
    const sort = 'points,asc'

    const {
        // api call with pagination
        data: pointingRewards,
        isLoading,
        refetch,
    } = useQuery(
        ['allPointingRewards', DebouncedValue, page, pageSize, sort],
        () => {
            if (searchQuery) {
                setIsSearching(true)
                return searchRewardsApi(DebouncedValue, { page: page, size: pageSize, sort })
            } else {
                return getAllPointingRewards({ page: page, size: pageSize, sort: sort })
            }
        },
        {
            onError: (error: AxiosError) => {
                console.log('error: ', error.response?.data)
            },
        },
    ) // end of api call

    useMemo(() => pointingRewards && setRewardsAtom(pointingRewards.content), [pointingRewards])
    useMemo(() => pointingTierList && setTierAtom(pointingTierList), [pointingTierList])

    const { mutate: deleteRewardMu, isLoading: deleteLoading } = useMutation<
        unknown,
        AxiosError,
        string
    >((id) => deletePointingReward(id), {
        onSuccess: () => {
            queryClient.invalidateQueries('faq')
            setIsDeleteModalOpen(false)
            toast.success('Reward has been deleted.')
            refetch()
        },

        onError: (error) => {
            console.log('ERROR', error)
            toast.error('Something went wrong.')
        },
    })

    const { mutate: updateRewardVisibilityMu } = useMutation<
        unknown,
        AxiosError,
        { rewardId: string; visibility: boolean }
    >(
        ({ rewardId, visibility }) => {
            return updateRewardVisibility(rewardId, visibility)
        },

        {
            onSuccess: () => {
                toast.success('Reward has been updated.')

                queryClient.invalidateQueries('allPointingRewards')
            },
            onError: (error) => {
                toast.error('Something went wrong.')
                console.log(error)
            },
        },
    )

    const handlePageChange = (page: number) => {
        setPage(page)
    }

    const handleChangeVisibility = (rewardId: string, visibility: boolean) => {
        updateRewardVisibilityMu({ rewardId, visibility })
    }

    const handleTierSelect = (selectedValue: string) => {
        setSelectedTier(selectedValue)
        setSearchQuery(selectedValue === 'All' ? '' : selectedValue)
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchQuery = event.target.value
        setSearchQuery(newSearchQuery)

        // Check if the search query is empty and update the selectedTier accordingly
        if (newSearchQuery === '') {
            setSelectedTier('All')
        }
    }

    const handleUpdateAction = (reward: TierRewardsType, buttonName: string) => {
        setSelectedRewards(reward)
        setSelectedRewardsAtom(reward)

        if (buttonName === 'Update') {
            navigate(`/mobile/offersanddeals/rewards/manageReward/${reward.id}`)
        } else {
            setIsDeleteModalOpen(true)
            navigate('')
        }
    }

    const renderTableActions = (rewards: TierRewardsType): JSX.Element => {
        return (
            <Popover className='relative'>
                {({ open }) => (
                    <>
                        <Popover.Button className='focus-visible:ring-0 relative'>
                            <BiDotsVerticalRounded className='cursor-pointer' size={35} />
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter='transition ease-out duration-200'
                            enterFrom='opacity-0 translate-y-1'
                            enterTo='opacity-100 translate-y-0'
                            leave='transition ease-in duration-150'
                            leaveFrom='opacity-100 translate-y-0'
                            leaveTo='opacity-0 translate-y-1'
                        >
                            <Popover.Panel className='absolute right-4 top-0 z-10 w-48 px-4'>
                                <div className='overflow-hidden rounded-md shadow-lg ring-1 ring-black/5'>
                                    <div className='flex flex-col'>
                                        {tableActions.map((item, index) => (
                                            <a
                                                key={index}
                                                className='bg-white flex items-center px-1 transition duration-150 ease-in-out hover:bg-gray-50 cursor-pointer'
                                                onClick={() =>
                                                    handleUpdateAction(rewards, item.name)
                                                }
                                            >
                                                <div className='flex h-12 w-12 items-center justify-center'>
                                                    {item.name === 'Update' ? (
                                                        <BiSolidEdit className='h-5 w-5 text-[#861F41]' />
                                                    ) : (
                                                        <FiTrash className='h-5 w-5 text-[#861F41]' />
                                                    )}
                                                </div>
                                                <div className='ml-1 h-6 w-16'>
                                                    <span className='text-sm font-semibold text-[#861F41] tracking-wide'>
                                                        {item.name}
                                                    </span>
                                                </div>
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
        )
    }
    //commenting this out as we already have api in the backend for sorting using dropdown filter
    //const hasMatch = pointingRewards?.content?.some(
    //    (reward) => !selectedTier || selectedTier === 'All' || reward.tierName === selectedTier,
    //)

    return (
        <div>
            <OffersAndDeals />

            <div className='flex items-center justify-between pb-3 relative'>
                <hr className='bg-black text-black w-full absolute top-10' />
                <div className='flex text-red-900 text-2xl font-thin'>
                    <div className='mr-20 flex flex-wrap'>
                        <span> Rewards </span>
                        <div className='w-full bg-red-900 z-10 py-1.5 rounded-full'></div>
                    </div>
                    <button
                        className=' mr-20 z-20'
                        onClick={() => navigate('/mobile/offersanddeals/tier')}
                    >
                        <span className='ml-2'> Tier </span>
                    </button>
                </div>
            </div>

            <div className='h-auto'>
                {' '}
                {/* h-screen w-100 bg-white */}
                <div className=' bg-white shadow-md rounded-md border-[1px] border-gray-300'>
                    <div className='w-full '>
                        <div className='mt-5 mx-5'>
                            <p className='text-2xl text-[#861F41] font-bold'> List of Rewards </p>
                            <p className='mt-1 font-normal text-base text-gray-400'>
                                {' '}
                                Upload more rewards to increase orders{' '}
                            </p>
                        </div>
                        <div className='m-5 grid xl:grid-cols-10 2xl:grid-cols-12 gap-7'>
                            <div className='col-span-3 relative'>
                                <input
                                    type='text'
                                    placeholder='Search'
                                    className='pl-12 w-full h-[45px] text-xl text-gray-400 border border-gray-400 rounded-md'
                                    maxLength={50}
                                    value={searchQuery}
                                    onChange={handleSearchChange} // Handle search input change
                                />
                                <BiSearch className='absolute top-2 left-2 text-4xl font-bold text-[#861F41]' />
                            </div>
                            <div className='col-span-3 '>
                                {/* {sortedTiers && sortedTiers.length > 0 && ( */}
                                <AppSelectMenu
                                    name='font-family'
                                    classname='w-full h-[45px] mt--5 bg-white border border-gray-300'
                                    options={[
                                        { label: 'All', value: 'All' }, // Add the "All" option as the default selection
                                        ...sortedTiers.map((tier) => ({
                                            label: tier?.tierName,
                                            value: tier?.tierName,
                                        })),
                                    ]}
                                    value={selectedTier}
                                    onChange={(selectedValue: string) =>
                                        handleTierSelect(selectedValue)
                                    }
                                />
                                {/* )} */}
                            </div>

                            <div className='2xl:flex 2xl:justify-self-end col-span-1 xl:col-span-2 2xl:col-span-2 xl:col-end-11 2xl:col-end-13'>
                                <AppButton
                                    variant='secondary'
                                    type='button'
                                    className='w-full h-[48px] bg-[#861F41] text-white rounded-md'
                                    onClick={() =>
                                        navigate('/mobile/offersanddeals/rewards/manageReward')
                                    }
                                >
                                    + Add New Reward
                                </AppButton>
                            </div>
                        </div>
                    </div>

                    <div className=''>
                        <div className='py-4 w-full bg-[#EDDFE2] text-[#861F41] font-bold grid grid-cols-8 sticky top-0 z-20'>
                            {tableHeaders.map((headerText, index) => (
                                <div
                                    key={index}
                                    className={twMerge(
                                        headerText === 'Title' ? 'col-span-2 ml-8' : '',
                                        headerText === 'Description' ? 'col-span-2' : '',
                                        headerText === 'Discount' ? 'col-span-1 text-center' : '',
                                        headerText === 'Visibility' ? 'text-center col-span-1' : '',
                                        headerText === 'Action' ? 'text-center col-span-1' : '',
                                        'z-50',
                                    )}
                                >
                                    {headerText}
                                </div>
                            ))}
                        </div>
                        {!pointingRewards?.content?.length &&
                            !isLoading &&
                            searchQuery.trim() !== '' && (
                                <div>
                                    <EmptyTableState
                                        title={'No Results Found'}
                                        message={
                                            'We could not find any search results matching your search. Please try a different keyword or phrasing'
                                        }
                                    />
                                </div>
                            )}

                        {!pointingRewards?.content?.length &&
                            !isLoading &&
                            searchQuery.trim() === '' && (
                                <div>
                                    <EmptyTableState
                                        title={'No Data Available'}
                                        message={
                                            'Currently no data available, try refreshing the page to make sure it loads properly'
                                        }
                                    />
                                </div>
                            )}
                        {isLoading && <AppSkeletonLoadingRewards />}

                        <div className='w-full divide-y-2'>
                            {!!pointingRewards?.content?.length &&
                                pointingRewards?.content?.map((reward) => (
                                    <>
                                        {(!selectedTier ||
                                            selectedTier === 'All' ||
                                            reward?.tierName === selectedTier) && (
                                            <div
                                                key={reward?.rewardTitle + '-' + reward?.id}
                                                className='grid grid-cols-8'
                                            >
                                                <div
                                                    style={{
                                                        display: '-webkit-box',
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                    }}
                                                    className={twMerge(
                                                        TD_CLASS,
                                                        'max-w-xs col-span-2 ml-8 w-9/12 ',
                                                    )}
                                                >
                                                    {reward?.rewardTitle ? (
                                                        <>
                                                            <AppRenderHtml
                                                                containerClassName='line-clamp-2'
                                                                contentDescription={
                                                                    reward?.rewardTitle ?? ''
                                                                }
                                                            />
                                                            <p className='text-base font-bold text-red-900'>
                                                                {
                                                                    reward?.pointingSystemTier
                                                                        ?.tierName
                                                                }
                                                            </p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h1>{`Enjoy ${Math.round(
                                                                reward?.percent * 100,
                                                            )}% Discount
                                                        Discount`}</h1>
                                                            <p className='text-base font-bold text-red-900'>
                                                                {
                                                                    reward?.pointingSystemTier
                                                                        ?.tierName
                                                                }
                                                            </p>
                                                        </>
                                                    )}
                                                </div>
                                                <div
                                                    style={{
                                                        display: '-webkit-box',
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                    }}
                                                    className={twMerge(
                                                        TD_CLASS,
                                                        'max-w-sm col-span-2 flex justify-start items-center',
                                                    )}
                                                >
                                                    {reward.rewardDesc ? (
                                                        <AppRenderHtml
                                                            containerClassName='line-clamp-2'
                                                            contentDescription={
                                                                reward?.rewardDesc ?? ''
                                                            }
                                                        />
                                                    ) : (
                                                        <h1>{`On your next purchase, you will get a\u00A0
                                                        ${Math.round(
                                                            reward?.percent * 100,
                                                        )}% discount`}</h1>
                                                    )}
                                                </div>
                                                <div
                                                    className={twMerge(
                                                        TD_CLASS,
                                                        'col-span-1 text-center flex justify-center items-center',
                                                    )}
                                                >
                                                    <span className='font-semibold'>
                                                        {Math.round(reward?.percent * 100)} %
                                                    </span>
                                                </div>
                                                <div
                                                    className={twMerge(
                                                        TD_CLASS,
                                                        'col-span-1 font-semibold flex justify-start items-center',
                                                    )}
                                                >
                                                    {reward?.points} pts
                                                </div>
                                                <div
                                                    className={twMerge(
                                                        TD_CLASS,
                                                        'col-span-1 flex justify-center',
                                                    )}
                                                >
                                                    <h1
                                                        className={twMerge(
                                                            flexCenter,
                                                            'col-span-2 truncate min-h-[3rem] capitalize',
                                                        )}
                                                    >
                                                        <Switch
                                                            checked={reward?.visibility}
                                                            onChange={(value) =>
                                                                handleChangeVisibility(
                                                                    reward?.id,
                                                                    value,
                                                                )
                                                            }
                                                            className={`${
                                                                reward?.visibility
                                                                    ? 'bg-[#861F41]'
                                                                    : 'bg-gray-400'
                                                            } relative inline-flex h-[20px] w-[40px] shrink-0 cursor-pointer rounded-full 
                                                              border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none 
                                                              focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                                                        >
                                                            <span className='sr-only'>
                                                                Toggle Status
                                                            </span>
                                                            <span
                                                                aria-hidden='true'
                                                                className={`${
                                                                    reward?.visibility
                                                                        ? 'translate-x-[1.2rem]'
                                                                        : 'translate-x-[2px]'
                                                                } pointer-events-none inline-block h-[15px] w-[15px] transform rounded-full 
                                                                  bg-white shadow-lg ring-0 transition duration-200 ease-in-out top-[50%] 
                                                                  translate-y-[0%] `}
                                                            />
                                                        </Switch>
                                                    </h1>
                                                </div>
                                                <div
                                                    className={twMerge(
                                                        TD_CLASS,
                                                        'col-span-1 flex justify-center items-center',
                                                    )}
                                                >
                                                    {renderTableActions(reward)}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ))}
                        </div>
                    </div>
                    {isLoading ||
                        (pointingRewards && pointingRewards?.totalElements !== 0 && (
                            <section>
                                <AppPagination
                                    currentPage={page}
                                    onPageChange={setPage}
                                    perPage={pageSize}
                                    total={pointingRewards?.totalElements ?? 0}
                                />
                            </section>
                        ))}
                </div>
            </div>
            <AppModal
                closeIcon={false}
                isOpen={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                title=''
                containerClassName='h-fit p-0 my-0'
            >
                <ConfirmationModal
                    handle={() => selectedRewards && deleteRewardMu(selectedRewards?.id)}
                    onClose={() => setIsDeleteModalOpen(false)}
                    yesLabel='Delete'
                    noLabel='Cancel'
                    title='Delete Selected'
                    isLoading={deleteLoading}
                    subtitle={'Are you sure you want to delete this item?'}
                />
            </AppModal>
        </div>
    )
}

export default Rewards
