import { GoogleMap, Marker, MarkerF, useLoadScript } from '@react-google-maps/api'
import GPSFixedIndicator from 'assets/images/gpsIndicator.png'
import { useAtom } from 'jotai'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { SearchedCoordinatesAtom, UserAddressAtom, UserLocationGPSAtom } from 'store/locationAtom'
import mapStyles from './mapStyles'
const options: google.maps.MapOptions = {
    disableDefaultUI: true,
    zoomControl: false,
    styles: mapStyles,
}

interface AppGoogleMapProps {
    children?: React.ReactNode
    center?: google.maps.LatLng | google.maps.LatLngLiteral
    isReset?: boolean
}

export const AppGoogleMap = (props: AppGoogleMapProps) => {
    const locatorPath = useLocation()
    const libraries = import.meta.env.VITE_GOOGLE_LIBRARIES
        ? JSON.parse(import.meta.env.VITE_GOOGLE_LIBRARIES)
        : []
    const [mapCenter, setMapCenter] = useState<
        google.maps.LatLng | google.maps.LatLngLiteral | undefined
    >(undefined)
    const [dynamicCenter, setDynamicCenter] = useState<
        google.maps.LatLng | google.maps.LatLngLiteral | undefined
    >(undefined)
    const [userAddress, setUserAddress] = useAtom(UserAddressAtom)

    const [map, setMap] = useState<google.maps.Map | null>(null)

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
        libraries,
    })
    const [searchedCoordinates, setSearchedCoordinates] = useAtom(SearchedCoordinatesAtom)
    const [userLocationFromGps] = useAtom(UserLocationGPSAtom)
    const [dragging, setDragging] = useState<boolean>(false)

    // const handleDragEnd = useCallback(() => {
    //     setDragging(false)
    //     if (map) {
    //         const center = map.getCenter()
    //         setMapCenter(center)
    //         setSearchedCoordinates({ lat: center?.lat() as number, lng: center?.lng() as number })
    //         if (center) getCurrentAddress(center?.lat(), center?.lng())
    //     }
    // }, [map])

    // const handleOnDrag = useCallback(() => {
    //     if (map) {
    //         const center = map.getCenter()
    //         setDynamicCenter(center)
    //     }
    // }, [map])
    const getCurrentAddress = (
        lat: GeolocationPosition['coords']['latitude'],
        lng: GeolocationPosition['coords']['longitude'],
        isClicked?: boolean,
    ) => {
        const currentGeocoder = new google.maps.Geocoder()

        currentGeocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === 'OK') {
                if (results && isClicked) setUserAddress(results[0].formatted_address)
                if (results) {
                    setUserAddress(results[0].formatted_address)
                } else {
                    console.log('Status', status)
                }
            }
        })
    }

    useEffect(() => {
        if (map && props.center) {
            setMapCenter(props.center)
            setDynamicCenter(props.center)
        }
    }, [props.center, map])

    const isLocator = useMemo(
        () => locatorPath.pathname.includes('/locator'),
        [locatorPath.pathname],
    )
    const handleMapClicked = (map: google.maps.MapMouseEvent) => {
        if (isLocator) {
            const center = { lat: map.latLng?.lat() as number, lng: map.latLng?.lng() as number }
            setSearchedCoordinates(center)
            getCurrentAddress(center?.lat, center?.lng)
        }
    }
    if (loadError) return <p>Something Went Wrong</p>
    if (!isLoaded) return <div> ...loading</div>
    return (
        <>
            <GoogleMap
                options={options}
                onLoad={setMap}
                // onDragEnd={handleDragEnd}
                // onDragStart={() => setDragging(true)}
                // onDrag={handleOnDrag}
                onClick={handleMapClicked}
                center={mapCenter || { lat: 37.7749, lng: -122.4194 }} // Use mapCenter, fallback to center prop or default to San Francisco, CA
                zoom={props.isReset ? 13 : 13}
                mapContainerStyle={{
                    height: isLocator ? '80dvh' : '45dvh',
                    width: '100%',
                    borderRadius: '10px',
                }}
            >
                {isLocator && userLocationFromGps && (
                    <MarkerF
                        position={userLocationFromGps}
                        icon={{
                            url: GPSFixedIndicator,
                            scaledSize: new window.google.maps.Size(30, 30), // Decrease the width and height as needed.
                        }}
                    />
                )}
                {dynamicCenter?.lat !== userLocationFromGps?.lat && dynamicCenter && (
                    <Marker
                        position={dynamicCenter}
                        animation={dragging && isLocator ? google.maps.Animation.BOUNCE : undefined}
                    />
                )}
                {props.children}
            </GoogleMap>
        </>
    )
}
