import { axiosInstance } from 'services/axios'
import { OrderType, PageOrderType } from './schema'

export interface getOrdersType {
    keyword?: string
    pageNumber?: number
    status?: string
    locationId?: string
    platform?: string
    size?: number
    sort?: string
    page?: number
}

export const getAllOrders = async (props: getOrdersType) => {
    const response = await axiosInstance.get('/order/all', { params: props })

    return response.data as PageOrderType
}

export const getOrderById = async (id: string) => {
    const response = await axiosInstance.get('/order/admin-view', { params: { id } })

    return response.data as OrderType
}
