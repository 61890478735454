import { Switch } from '@headlessui/react'
import { AxiosError } from 'axios'
import { AppButton } from 'components/AppButton'
import React, { useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
    BulkCategorizeApi,
    BulkRemoveCategorizeApi,
    Categorize,
    listMenuCategoryApi,
    listProductsApi,
    RemoveCategorize,
} from 'services/requests/menu-category'
import {
    ListProductType,
    ObjectMenuCategoryType,
    ObjectProductType,
} from 'services/requests/menu-category/schema'
import { twMerge } from 'tailwind-merge'
import { UseTruncate } from 'utils/constants'

interface ICategorize {
    productId: string
    categoryId: string
    isChecked: boolean
}
const Categories: React.FunctionComponent = () => {
    const { data } = useQuery(['menu-category'], () => listMenuCategoryApi())
    const { data: products } = useQuery(['products'], () => listProductsApi())
    const queryClient = useQueryClient()
    const [switchChecked, setSwitchChecked] = useState(true)
    const [selectedProducts, setSelectedProducts] = useState<ObjectProductType[] | []>([])
    interface IBulkCategorize {
        isPosting?: boolean
        categoryId: string
        productIds: string[]
    }
    const { mutate: bulkPostRelationship } = useMutation<unknown, AxiosError, IBulkCategorize>(
        (data) =>
            data.isPosting
                ? BulkCategorizeApi(data.categoryId, data.productIds)
                : BulkRemoveCategorizeApi(data.categoryId, data.productIds),

        {
            onSuccess: () => {
                queryClient.invalidateQueries('products')
                queryClient.invalidateQueries('productsFromId')
                queryClient.invalidateQueries('menu-category')
                setSwitchChecked((prev) => !prev)
                const isSelectedMoreThanOne = selectedProducts.length > 1
                toast.success(`Success! Product${isSelectedMoreThanOne ? 's' : ''} updated.`)
                setSelectedProducts([])
            },
            onError: (error) => console.log(error),
        },
    )
    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        product: ObjectProductType,
    ) => {
        event.target.checked
            ? setSelectedProducts((prev) => [...prev, product])
            : setSelectedProducts((prev) =>
                  prev.filter((productsIds) => productsIds.id !== product.id),
              )
    }
    const [selectedCategory, setSelectedCategory] = useState<ObjectMenuCategoryType | null>(
        data && data.length > 0 ? data[0] : null,
    )
    const { mutate: postRelationship } = useMutation<unknown, AxiosError, ICategorize>(
        (data) =>
            !data.isChecked
                ? RemoveCategorize(data.categoryId, data.productId)
                : Categorize(data.categoryId, data.productId),

        {
            onSuccess: () => {
                queryClient.invalidateQueries('products')
                queryClient.invalidateQueries('productsFromId')
                queryClient.invalidateQueries('menu-category')
            },
            onError: (error) => console.log(error),
        },
    )
    const handleSwitch = (props: ICategorize) => {
        postRelationship(props)
    }
    useMemo(
        () => data && data.length > 0 && setSelectedCategory((prev) => (prev ? prev : data[0])),
        [data],
    )
    const isAllProductChecked = useMemo(
        () => products?.length === selectedProducts.length,
        [products, selectedProducts],
    )

    const handleSelectAllProducts = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.checked ? setSelectedProducts(products ?? []) : setSelectedProducts([])
    }

    const handleBulkCategorize = (isPosting: boolean) => {
        const productIds = selectedProducts.map((item) => item.id)
        if (selectedCategory && productIds.length > 0) {
            bulkPostRelationship({
                categoryId: selectedCategory?.id,
                productIds,
                isPosting,
            })
        }
    }

    const renderProducts = useCallback(
        (products: ListProductType) => {
            if (products.length > 0) {
                return (
                    products
                        // .filter(
                        //     (products) =>
                        //         (products.productName
                        //             .toLowerCase()
                        //             .includes(
                        //                 (sort.sort ?? '').toLocaleLowerCase()
                        //             ) &&
                        //             sort.filter === 'All') ||
                        //         products.categories.some((category) =>
                        //             category?.name
                        //                 .toLowerCase()
                        //                 .includes((sort.filter ?? '').toLowerCase())
                        //         )
                        // )
                        .map((item, index) => {
                            const checked = item.categories.some(
                                (cat) => cat.id === selectedCategory?.id ?? '',
                            )
                            return (
                                <div
                                    className={twMerge(
                                        'inline-flex items-center w-full px-4 justify-between py-1 border',
                                        index % 2 === 0 ? 'bg-gray-100' : 'bg-white',
                                    )}
                                    key={item.id}
                                >
                                    <div className='flex justify-center items-center w-[10%]'>
                                        <input
                                            checked={selectedProducts.some(
                                                (productIds) => productIds.id === item.id,
                                            )}
                                            type='checkbox'
                                            className='accent-[#861F41]'
                                            onChange={(e) => handleCheckboxChange(e, item)}
                                        />
                                    </div>
                                    <div className='inline-flex items-center gap-3 w-[70%] max-h-[85px] overflow-hidden'>
                                        <img
                                            className='w-[4rem] h-[4rem] object-cover rounded-full mx-9'
                                            src={item?.coverPhoto}
                                            alt=''
                                        />
                                        <div className='max-h-[65px] overflow-hidden'>
                                            <h1 className='text-[14px] font-bold'>
                                                {item?.productName}
                                            </h1>
                                            <h1
                                                style={UseTruncate(2)}
                                                className='text-[12px]'
                                            >{`Description: ${item?.description}`}</h1>
                                        </div>
                                    </div>
                                    <div className='w-[20%] flex gap-4 px-7 items-center'>
                                        <Switch
                                            checked={checked}
                                            onChange={(value: boolean) =>
                                                handleSwitch({
                                                    isChecked: value,
                                                    categoryId: selectedCategory?.id ?? '',
                                                    productId: item.id,
                                                })
                                            }
                                            className={`${
                                                checked ?? ''.toLocaleLowerCase()
                                                    ? 'bg-[#861F41]'
                                                    : 'bg-[#EDDFE2]'
                                            }
          relative inline-flex h-[20px] w-[40px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                                        >
                                            <span className='sr-only'>Toggle Status</span>
                                            <span
                                                aria-hidden='true'
                                                className={`${
                                                    checked
                                                        ? 'translate-x-[1.2rem]'
                                                        : 'translate-x-0'
                                                }
            pointer-events-none inline-block h-[15px] w-[15px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out top-[50%] translate-y-[0%] `}
                                            />
                                        </Switch>
                                    </div>
                                </div>
                            )
                        })
                )
            }
        },
        [products, data, selectedCategory, selectedProducts],
    )
    useMemo(() => setSelectedProducts([]), [selectedCategory])

    const majorityCategorySwitch = useMemo(() => {
        if (selectedCategory) {
            //on
            const selectedCategoryCountInSelected = selectedProducts.filter((item) =>
                item.categories.some((item) => item.id === selectedCategory.id),
            ).length
            //off
            const selectedCategoryCountOfNullInSelected = selectedProducts.filter(
                (item) => !item.categories.some((item) => item.id === selectedCategory.id),
            ).length

            return selectedCategoryCountOfNullInSelected < selectedCategoryCountInSelected
        }
        return false
    }, [selectedProducts, selectedCategory])

    return (
        <div className='w-full flex p-2 flex-col'>
            <div className='inline-flex items-center gap-5'>
                <h1 className='font-medium'>Select Category</h1>

                <div className='inline-flex  gap-2 overflow-auto p-4'>
                    {data &&
                        data.map((category) => (
                            <AppButton
                                variant='secondary'
                                className={twMerge(
                                    selectedCategory?.id === category.id
                                        ? 'bg-[#861F41] text-white'
                                        : 'bg-white text-[#861F41]',
                                    'font-bold whitespace-nowrap',
                                )}
                                key={category.id}
                                type='button'
                                onClick={() => setSelectedCategory(category)}
                            >
                                {`${category.name} ( ${category.products.length} )`}
                            </AppButton>
                        ))}
                </div>
            </div>

            <div className='my-4 py-2'>
                <div className='flex text-[14px] text-[#861F41] bg-[#E3D5DA] font-bold p-4 rounded-t-lg items-center'>
                    <label className='w-[10%] flex gap-1 justify-center items-center'>
                        <input
                            checked={isAllProductChecked}
                            className='accent-[#861F41]'
                            onChange={handleSelectAllProducts}
                            type='checkbox'
                        />
                    </label>
                    <h1 className='w-[70%]'>{`Product Name (${products?.length ?? 0})`}</h1>
                    <h1 className='w-[20%]'>{`Categories-${selectedCategory?.name ?? ''}`}</h1>
                    {selectedProducts.length > 0 && (
                        <Switch
                            checked={majorityCategorySwitch}
                            onChange={(value: boolean) => handleBulkCategorize(value)}
                            className={`${majorityCategorySwitch ? 'bg-[#861F41]' : 'bg-[#EDDFE2]'}
          relative inline-flex h-[20px] w-[40px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                        >
                            <span className='sr-only'>Toggle Status</span>
                            <span
                                aria-hidden='true'
                                className={`${
                                    majorityCategorySwitch
                                        ? 'translate-x-[1.2rem]'
                                        : 'translate-x-0'
                                }
            pointer-events-none inline-block h-[15px] w-[15px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out top-[50%] translate-y-[0%] `}
                            />
                        </Switch>
                    )}
                </div>
                {products && renderProducts(products)}
            </div>
        </div>
    )
}

export default Categories
