import { AppCard } from 'components/AppCard'
import { OrderType } from 'services/requests/orders/schema'
import { twMerge } from 'tailwind-merge'
import { numberFormatter } from 'utils'
import ProductDisplayImage from './ProductDisplayImage'

interface OrderSummaryProps {
    orderData: OrderType
}

export const OrderSummary = (props: OrderSummaryProps) => {
    const statusForDisplay = [
        'REFUND_COMPLETED',
        'REFUND_FAILED',
        'REFUND_INITIATED',
        'OUT_OF_STOCK',
        'FAILED',
        'NOT_STARTED',
        'DONE',
        'COMPLETED',
    ]
    const handleStatusStyles = (status: string) => {
        switch (status) {
            case 'OUT_OF_STOCK':
            case 'REFUND_FAILED':
                return 'bg-red-500'
            case 'REFUND_INITIATED':
                return 'bg-yellow-700'
            case 'REFUND_COMPLETED':
                return 'bg-yellow-700'
            case 'COMPLETED':
                return 'bg-green-500'
            case 'DONE':
                return 'bg-green-500'
            case 'FAILED':
                return 'bg-red-500'
            case 'NOT_STARTED':
                return 'bg-gray-500'
            default:
                return ''
        }
    }

    const handleStatusMessages = (status: string) => {
        switch (status) {
            case 'REFUND_FAILED':
                return 'REFUND FAILED'
            case 'REFUND_INITIATED':
                return 'REFUND PENDING'
            case 'OUT_OF_STOCK':
                return 'OUT OF STOCK'
            case 'REFUND_COMPLETED':
                return 'REFUND COMPLETED'
            case 'DONE':
                return 'DONE'
            case 'COMPLETED':
                return 'DONE'
            case 'FAILED':
                return 'FAILED'
            case 'NOT_STARTED':
                return 'NOT STARTED'
            default:
                return ''
        }
    }

    return (
        <section className='col-span-full md:col-span-2 lg:col-span-4 xl:col-span-3'>
            <AppCard>
                <div className='flex flex-col h-full justify-between space-y-4'>
                    <section className='divide-y divide-gray-200 space-y-4'>
                        <div className='flex items-center justify-start'>
                            <h1 className='text-gray-800 font-bold'>Order Summary</h1>
                        </div>
                        <ol className='pt-4 px-5 space-y-2 divide-y divide-gray-100'>
                            {props.orderData.items.map((item) => (
                                <li
                                    key={item.id}
                                    className={twMerge(
                                        'pt-2 flex items-center justify-between',
                                        statusForDisplay.includes(item.status) &&
                                            `${
                                                item.status == 'DONE' ||
                                                item.status == 'NOT_STARTED'
                                                    ? ''
                                                    : 'opacity-50'
                                            }`,
                                    )}
                                >
                                    <div className='flex items-center space-x-4'>
                                        <ProductDisplayImage
                                            isGiftCard={item.type === 'GIFT_CARD'}
                                            coverPhoto={item.product?.coverPhoto}
                                            price={item?.originalPrice}
                                        />
                                        <div className='flex flex-col'>
                                            <span className='flex flex-col xl:flex-row xl:space-x-2'>
                                                <span
                                                    className={twMerge(
                                                        statusForDisplay.includes(item.status) &&
                                                            `${
                                                                item.status == 'DONE' ||
                                                                item.status == 'NOT_STARTED'
                                                                    ? ''
                                                                    : 'opacity-50 line-through'
                                                            }`,
                                                    )}
                                                >
                                                    {`${
                                                        item.type === 'GIFT_CARD'
                                                            ? 'YO-KAI EXPRESS GIFT CARD $' +
                                                              item.originalPrice
                                                            : item.product.name
                                                    }`}
                                                </span>

                                                {statusForDisplay.includes(item.status) && (
                                                    <span>
                                                        <span
                                                            className={twMerge(
                                                                'bg-slate-500 py-1 px-2 text-sm rounded-md text-white',
                                                                handleStatusStyles(item.status),
                                                            )}
                                                        >
                                                            {handleStatusMessages(item.status)}
                                                        </span>
                                                    </span>
                                                )}
                                            </span>
                                            <span className='text-slate-400 text-sm'>
                                                Order ID:{' '}
                                                {`${
                                                    item.type === 'GIFT_CARD'
                                                        ? props.orderData.externalId.replace(
                                                              'DT',
                                                              '',
                                                          )
                                                        : item.externalOrderId
                                                }`}
                                            </span>
                                        </div>
                                    </div>
                                    <span className='text-center'>
                                        <p
                                            className={twMerge(
                                                statusForDisplay.includes(item.status) &&
                                                    ` ${
                                                        item.status == 'DONE' ||
                                                        item.status == 'NOT_STARTED'
                                                            ? ''
                                                            : 'opacity-50 line-through'
                                                    }`,
                                                item.totalPrice > 0 && 'line-through text-xs',
                                            )}
                                        >
                                            ${numberFormatter.format(item.originalPrice)}
                                        </p>
                                        {item.totalPrice > 0 && (
                                            <p className='text-red-700 font-bold'>
                                                ${numberFormatter.format(item.basePrice)}
                                            </p>
                                        )}
                                    </span>
                                </li>
                            ))}
                        </ol>
                    </section>
                    <section className='border border-gray-300 border-dashed px-5 py-3 divide-y divide-gray-200 space-y-4'>
                        <>
                            <ol className=''>
                                <li className='flex items-center justify-between'>
                                    <span> Subtotal </span>
                                    <span>
                                        {' '}
                                        ${numberFormatter.format(props.orderData.subTotal)}
                                    </span>
                                </li>
                                <li className='flex items-center justify-between'>
                                    <span> Tax & Fees </span>
                                    <span> ${numberFormatter.format(props.orderData.tax)}</span>
                                </li>
                                {props.orderData.discountAmount > 0 ? (
                                    <li className='flex items-center justify-between'>
                                        <span> Discount Amount </span>
                                        <span>
                                            {' '}
                                            -$
                                            {numberFormatter.format(props.orderData.discountAmount)}
                                        </span>
                                    </li>
                                ) : null}
                                {props.orderData.giftCardAmount > 0 ? (
                                    <li className='flex items-center justify-between'>
                                        <span> Gift Card </span>
                                        <span>
                                            {' '}
                                            -$
                                            {numberFormatter.format(props.orderData.giftCardAmount)}
                                        </span>
                                    </li>
                                ) : null}
                            </ol>
                            <ol className='pt-4'>
                                <li className='flex items-center justify-between text-lg font-bold'>
                                    <span> Total </span>
                                    <span>
                                        ${numberFormatter.format(props.orderData.orderTotal)}
                                    </span>
                                </li>
                            </ol>
                        </>
                    </section>
                </div>
            </AppCard>
        </section>
    )
}
