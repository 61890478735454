const AppSkeletonLoadingSettings = () => {
    return (
        <div className='space-y-4'>
            <div className='flex items-center justify-between'>
                <h1 className='text-xs text-gray-400 font-bold underline'>
                    Global-Settings &gt; Settings
                </h1>
            </div>
            <div>
                <h1 className='text-2xl text-pink-900 font-bold'>Settings</h1>
            </div>

            <div className='flex flex-col h-[275px] justify-start border border-gray-300 shadow rounded-md animate-pulse'>
                <div className='flex justify-start w-full mb-5 mt-[84px] '>
                    <div className='h-3 w-[10%] lg:ml-[45px] xl:ml-[75px] 2xl:ml-[85px] bg-slate-200 rounded'></div>
                    <div className='h-3 w-[7%] lg:ml-[155px] xl:ml-[225px] 2xl:ml-[245px]  bg-slate-200 rounded '></div>
                </div>
                <div className='flex justify-center w-full'>
                    <div className='h-3 w-[25%] mr-16 bg-slate-200 rounded'></div>
                    <div className='h-3 w-[55%] bg-slate-200 rounded'></div>
                </div>
            </div>
        </div>
    )
}
export default AppSkeletonLoadingSettings
