import { atom } from 'jotai'
import { TierType } from 'pages/Schemas/tier'

export const initialTier: TierType = {
    id: '',
    tierName: '',
    tierColor: '',
    lowestPoints: 0,
    highestPoints: 0,
    tierDetails: '',
    promotionPercentage: 0.0,
    whoAdded: '',
    whoUpdated: '',
    whenAdded: '',
    whenUpdated: '',
}

export const tierAtom = atom<string>('')

export const tiersAtom = atom<TierType[]>([initialTier])
