import { Listbox, Transition } from '@headlessui/react'
import React, { ReactElement } from 'react'
import { HiChevronDown } from 'react-icons/hi'
import { twMerge } from 'tailwind-merge'

export interface ListBoxOptions {
    value: string | number
    label: string
}

interface AppListBoxProps {
    options: Array<ListBoxOptions>
    selectedOption: string | number
    onChange: (value: string | number) => void
    className?: string
    label?: string
    icon?: ReactElement // added icon prop
    labelClassName?: string
}

export const AppListBox: React.FC<AppListBoxProps> = ({
    options,
    selectedOption,
    onChange,
    className,
    labelClassName,
    label,
    icon,
}) => {
    return (
        <div className={`relative ${className}`}>
            {label && (
                <span
                    className={twMerge('block text-sm font-medium text-gray-700', labelClassName)}
                >
                    {label}
                </span>
            )}
            <Listbox
                value={selectedOption}
                onChange={onChange}
                as={'div'}
                className='border border-gray-200'
            >
                <div className='relative mt-1'>
                    <Listbox.Button className='relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg cursor-default focus:outline-none sm:text-sm'>
                        <span className='flex items-center'>
                            {icon && <span className='mr-3'>{icon}</span>}
                            {/* rendering the passed icon */}
                            <span className='block truncate'>
                                {options.find((option) => option.value === selectedOption)?.label}
                            </span>
                        </span>
                        <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                            <HiChevronDown className='w-5 h-5 text-gray-400' />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as='ul'
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                        className='absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                    >
                        <Listbox.Options>
                            {options.map((option, index) => (
                                <Listbox.Option
                                    key={index}
                                    value={option.value}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 px-2 ${
                                            active ? 'bg-red-900 text-white' : 'text-gray-900'
                                        }`
                                    }
                                >
                                    {({ selected }) => (
                                        <>
                                            <span
                                                className={`block truncate ${
                                                    selected ? 'font-medium' : 'font-normal'
                                                }`}
                                            >
                                                {option.label}
                                            </span>
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    )
}
