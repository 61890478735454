import { z } from 'zod'
export const ObjectProductSchema = z.object({
    id: z.string(),
    externalId: z.number(),
    productName: z.string(),
    coverPhoto: z.string(),
    pictureNutrient: z.string(),
    allergenPhoto: z.string(),
    description: z.string(),
    type: z.string(),
    price: z.null(),
    promotionPrice: z.null(),
    promotionStartDate: z.null(),
    promotionEndDate: z.null(),
    categories: z.array(
        z.object({
            id: z.string(),
            name: z.string(),
            icon: z.string(),
            description: z.string(),
            status: z.string(),
            products: z.array(
                z.object({
                    id: z.string(),
                    externalId: z.number(),
                    productName: z.string(),
                    coverPhoto: z.string(),
                    pictureNutrient: z.string(),
                    allergenPhoto: z.string(),
                    description: z.string(),
                    type: z.string(),
                    price: z.null(),
                    promotionPrice: z.null(),
                    promotionStartDate: z.null(),
                    promotionEndDate: z.null(),
                }),
            ),
        }),
    ),
})

export const ObjectProductSchemaWithNoCategory = z.object({
    id: z.string(),
    externalId: z.number(),
    productName: z.string(),
    coverPhoto: z.string(),
    pictureNutrient: z.string(),
    allergenPhoto: z.string(),
    description: z.string(),
    type: z.string(),
    price: z.null(),
    promotionPrice: z.null(),
    promotionStartDate: z.null(),
    promotionEndDate: z.null(),
})

export const ObjectMenuCategorySchema = z.object({
    id: z.string(),
    name: z.string(),
    icon: z.string(),
    description: z.string(),
    styles: z.string().nullable(),
    status: z.string(),
    products: z.array(ObjectProductSchema),
    position: z.number(),
    iconAltText: z.string(),
})
export const MenuCategorySchema = z.array(ObjectMenuCategorySchema)

export const ListProductsSchema = z.array(
    z.object({
        id: z.string(),
        externalId: z.number(),
        productName: z.string(),
        coverPhoto: z.string(),
        pictureNutrient: z.string(),
        allergenPhoto: z.string(),
        description: z.string(),
        type: z.string(),
        price: z.null(),
        promotionPrice: z.null(),
        promotionStartDate: z.null(),
        promotionEndDate: z.null(),
        categories: z.array(
            z.object({
                id: z.string(),
                name: z.string(),
                icon: z.string(),
                description: z.string(),
                status: z.string(),
                products: z.array(
                    z.object({
                        id: z.string(),
                        externalId: z.number(),
                        productName: z.string(),
                        coverPhoto: z.string(),
                        pictureNutrient: z.string(),
                        allergenPhoto: z.string(),
                        description: z.string(),
                        type: z.string(),
                        price: z.null(),
                        promotionPrice: z.null(),
                        promotionStartDate: z.null(),
                        promotionEndDate: z.null(),
                    }),
                ),
            }),
        ),
    }),
)
export const CreateMenuCategoryResponseSchema = z.object({
    id: z.string(),
    name: z.string(),
    icon: z.string(),
    description: z.string(),
    status: z.string(),
    products: z.null(),
    createdAt: z.number(),
    updatedAt: z.null(),
})

export const UploadResponseSchema = z.array(
    z.object({
        originalFileName: z.string(),
        sizes: z.array(
            z.object({
                s3URI: z.string(),
                fileName: z.string(),
                objectURL: z.string(),
                size: z.string(),
            }),
        ),
    }),
)

export const MenuItemSchema = z.array(
    z.object({
        id: z.string(),
        externalId: z.number(),
        externalProductId: z.string(),
        productId: z.string(),
        originalPrice: z.number(),
        price: z.number(),
        priceType: z.string(),
        name: z.string(),
        coverPhoto: z.string(),
        pictureNutrient: z.string(),
        allergenPhoto: z.string(),
        description: z.string(),
        stockRemain: z.number(),
        status: z.string(),
        promotionPrice: z.null(),
        promotionStartDate: z.null(),
        promotionEndDate: z.null(),
        storeId: z.string(),
        shopName: z.string(),
        machineLocationDescription: z.string(),
        restrictNote: z.string(),
        imageName: z.string(),
        direction: z.object({
            id: z.string(),
            description: z.string(),
        }),
        restricted: z.boolean(),
        createdAt: z.string(),
        updatedAt: z.string(),
    }),
)

export type UploadResponseType = z.infer<typeof UploadResponseSchema>

export type CreateMenuCategoryResponseType = z.infer<typeof CreateMenuCategoryResponseSchema>
export type ListMenuCategoryType = z.infer<typeof MenuCategorySchema>
export type ListProductType = z.infer<typeof ListProductsSchema>
export type ObjectMenuCategoryType = z.infer<typeof ObjectMenuCategorySchema>
export type ObjectProductType = z.infer<typeof ObjectProductSchema>
export type ObjectProductTypeWithNoCategory = z.infer<typeof ObjectProductSchemaWithNoCategory>
export type MenuItemType = z.infer<typeof MenuItemSchema>
