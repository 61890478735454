import { AppButton } from 'components/AppButton'
import { useAtom } from 'jotai'
import React, { useMemo } from 'react'
import { MdAdd } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { LogoGalleryBgColorAtom, LogoGalleryBgOpacityAtom } from 'store/homeAtom'
import { homeContents } from 'utils/constants'
import { hexToRgba } from 'utils/helper'
import CarouselSection from './CarouselSection'
import GiftCardScroll from './GiftCardScroll'
import { RichTextEditorWrapperHome } from './HOC/withRichTextEditor'
import MenuCategorySection from './MenuStaticContents'
import MobileAppSection from './MobileAppContents'
import ParnershipHomeSection from './Partners'

const Home: React.FunctionComponent = () => {
    const [logoGalleryBgColor] = useAtom(LogoGalleryBgColorAtom)
    const [logoGalleryBgOpacity] = useAtom(LogoGalleryBgOpacityAtom)
    const navigate = useNavigate()

    const backgroundStyle = useMemo(
        () =>
            new Object({
                backgroundColor: hexToRgba(
                    logoGalleryBgColor ?? '#ffffff',
                    logoGalleryBgOpacity ?? 100,
                ),
            }),
        [logoGalleryBgColor, logoGalleryBgOpacity],
    )
    return (
        <div className='w-full h-full flex flex-col gap-7'>
            <section className='w-full flex border-b-2 text-[#861F41] text-[25px]'>
                <h1 className='font-bold'>Home</h1>
            </section>
            <div className='w-full flex justify-end h-fit'>
                <AppButton
                    variant='primary'
                    type='button'
                    onClick={() => {
                        close()
                        navigate('addContent')
                    }}
                    className='inline-flex items-center gap-2 whitespace-nowrap py-2'
                >
                    <MdAdd />
                    Add new
                </AppButton>
            </div>
            <CarouselSection />
            <section className='w-full p-5 bg-white'>
                <div className='grid grid-cols-12 gap-3'>
                    <div className='col-span-12 md:col-span-6  flex items-center justify-center'>
                        <GiftCardScroll duration={8000} autoScroll={true} />
                    </div>

                    <div className='w-full col-span-12 md:col-span-6 flex flex-col gap-6'>
                        <RichTextEditorWrapperHome title={homeContents.giftCardTitle} />
                        <RichTextEditorWrapperHome title={homeContents.giftCardDescription} />
                    </div>
                </div>
            </section>
            <section className='flex flex-col gap-11 justify-center items-center w-full bg-white p-5'>
                <RichTextEditorWrapperHome title={homeContents.homeMenuTitle} />
                <RichTextEditorWrapperHome title={homeContents.homeMenuDescription} />
                <MenuCategorySection />
            </section>
            <section className='flex flex-col gap-4 lg:flex-row justify-center items-center w-full bg-white p-5'>
                <div className='flex flex-col gap-10 text-center lg:text-start'>
                    <RichTextEditorWrapperHome title={homeContents.homeMobileAppTitle} />
                    <RichTextEditorWrapperHome title={homeContents.homeMobileAppDescription} />
                </div>
                <div className='w-ful'>
                    <MobileAppSection />
                </div>
            </section>
            <section
                style={backgroundStyle}
                className={`flex  flex-col gap-5 justify-center items-center min-h-[30rem] w-full bg-[${
                    logoGalleryBgColor ?? 'white'
                }] bg-opacity-[${logoGalleryBgOpacity}%] p-5`}
            >
                <RichTextEditorWrapperHome title={homeContents.logoGalleryTitle} />
                <RichTextEditorWrapperHome title={homeContents.logoGalleryDescription} />
                <div className='overflow-auto max-w-full'>
                    <ParnershipHomeSection />
                </div>
            </section>
        </div>
    )
}

export default Home
