import { CreateNewsType } from 'pages/Schemas/news'
import { axiosInstance } from 'services/axios'
import { NewsResponseType } from './schema'

export const getNewsApi = async () => {
    const response = await axiosInstance.get('/news')

    return response.data.content as NewsResponseType[]
}
export const getNewsById = async (id: string) => {
    const response = await axiosInstance.get(`/news?newsId=${id}`)

    return response.data as NewsResponseType
}

export const UpdateNewsToDraftApi = async (id: string, visibility: string) => {
    console.log(visibility, id)
    const response = await axiosInstance.put(`/news/toDraft?id=${id}&visibility=${visibility}`)

    return response.data
}
export const UpdateNewsApi = async (id: string, data: CreateNewsType) => {
    const response = await axiosInstance.put(`/news?newsId=${id}`, {
        ...data,
        publishDate: data.publishDate.getTime(),
    })
    return response.data
}

export const CreateNewsApi = async (data: CreateNewsType) => {
    const response = await axiosInstance.post('/news', {
        ...data,
        publishDate: data.publishDate.getTime(),
    })

    return response
}

export const DeleteNewsApi = async (newsId: string) => {
    if (newsId) {
        const response = await axiosInstance.delete('/news', {
            params: {
                newsId,
            },
        })

        return response
    }
}

interface IUploadRequest {
    file: File
    type: string
}

export interface IUploadPartnershipResponse {
    sizes: {
        objectURL: string
    }[]
}

export interface IUploadNewsResponse {
    sizes: {
        objectURL: string
    }[]
    type: string
}

export interface IUploadMenuCategoryResponse {
    sizes: {
        objectURL: string
    }[]
}

export interface IUploadResponse {
    sizes: {
        objectURL: string
    }[]
}

export const UploadImageApi = async (props: IUploadRequest) => {
    if (!props.file) {
        return [] as IUploadResponse[]
    }

    const formData = new FormData()
    formData.append('file', props.file)
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }

    const response = await axiosInstance.post(
        `/distribution/blogposts/${props.type + props.file.name}/upload`,
        formData,
        config,
    )

    return response.data as IUploadResponse[]
}

export const UploadMenuCategoryImageApi = async (props: IUploadRequest) => {
    if (!props.file) {
        return [] as IUploadMenuCategoryResponse[]
    }

    const formData = new FormData()
    formData.append('file', props.file)
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }

    const response = await axiosInstance.post(
        `/distribution/blogposts/${props.type + props.file.name}/upload`,
        formData,
        config,
    )

    return response.data as IUploadMenuCategoryResponse[]
}

export const UploadNewsImageApi = async (props: IUploadRequest) => {
    if (!props.file) {
        return [] as IUploadNewsResponse[]
    }

    const formData = new FormData()
    formData.append('file', props.file)
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }
    const response = await axiosInstance.post(
        `/distribution/blogposts/${props.type + props.file.name}/upload`,
        formData,
        config,
    )
    return { type: props.type, ...response.data } as IUploadNewsResponse[]
}

export const UploadPartnershipImageApi = async (props: IUploadRequest) => {
    if (!props.file) {
        return [] as IUploadPartnershipResponse[]
    }

    const formData = new FormData()
    formData.append('file', props.file)
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }
    const response = await axiosInstance.post(
        `/distribution/blogposts/${props.type + props.file.name}/upload`,
        formData,
        config,
    )
    return response.data as IUploadPartnershipResponse[]
}
