import * as React from 'react'
import { twJoin } from 'tailwind-merge'
import { useFormatDate } from 'utils/helper/format-time-date'

interface ILatestNewsCardsProps {
    description: string
    title: string
    id: string
    image: string
    publishDate: number | string
    imageAltText: string
    status: string
}

const LatestNewsCards: React.FunctionComponent<ILatestNewsCardsProps> = (props) => {
    return (
        <div
            tabIndex={0}
            className={twJoin('w-[336px] h-[440px] flex flex-col gap-2')}
            aria-label={`${props.title} article`}
        >
            <img
                tabIndex={0}
                src={props?.image}
                alt={props.imageAltText}
                aria-label={props.imageAltText}
                className='w-full h-[280px] aspect-w-10 aspect-h-9 object-scale-down'
            />
            <div className='flex flex-row gap-5 px-2'>
                <p tabIndex={0} className='text-xs text-gray-500'>
                    {useFormatDate(props.publishDate)}
                </p>
                <div className='inline-flex items-center gap-2'>
                    <div className='block bg-[#861F41] w-2 h-2 rounded-full' />
                    <p tabIndex={0} className='text-[#861F41] text-xs'>
                        {props?.status}
                    </p>
                </div>
            </div>
            <span
                tabIndex={0}
                // style={UseTruncate(3)}
                className='font-bold text-[20px] px-2 text-start line-clamp-3'
                role='link'
            >
                {props?.title}
            </span>
        </div>
    )
}

export default LatestNewsCards
