import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

interface AppModalProps {
    isOpen: boolean
    title?: string
    onClose: () => void
    children: React.ReactNode
    closeIcon?: boolean
    containerClassName?: string
}

export const AppModal = ({
    isOpen,
    title,
    onClose,
    closeIcon,
    children,
    containerClassName,
}: AppModalProps) => {
    const [isOpenInternal, setIsOpenInternal] = useState<boolean>(false)

    // Close the modal when the `isOpen` prop changes

    useEffect(() => {
        setIsOpenInternal(isOpen)
    }, [isOpen])

    return (
        <>
            <Transition appear show={isOpenInternal} as={Fragment}>
                <Dialog className='fixed inset-0 z-50 overflow-y-auto' onClose={onClose}>
                    <div className='min-h-screen px-4 text-center'>
                        <Transition.Child
                            as={'div'}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0'
                            enterTo='opacity-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <Dialog.Overlay className='fixed inset-0 bg-gray-500 opacity-20 transition-opacity' />
                        </Transition.Child>
                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className='inline-block h-screen align-middle' aria-hidden='true'>
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 scale-95'
                            enterTo='opacity-100 scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 scale-100'
                            leaveTo='opacity-0 scale-95'
                        >
                            <div
                                className={twMerge(
                                    'inline-block w-full max-w-fit p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-sm',
                                    containerClassName ?? '',
                                )}
                            >
                                <Dialog.Title
                                    as='h3'
                                    className='text-lg font-medium leading-6 text-gray-900'
                                >
                                    {title}
                                </Dialog.Title>
                                {closeIcon && (
                                    <button
                                        onClick={onClose}
                                        className='hover:scale-105 absolute top-4 right-4 z-10 bg-white rounded-full cursor-pointer'
                                    >
                                        <span
                                            aria-hidden='true'
                                            className='text-[#751132] font-bold text-3xl'
                                        >
                                            &times;
                                        </span>
                                    </button>
                                )}
                                <div className='mt-7'>{children}</div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
