import { errorWhiteSpaces } from 'utils/constants'
import { WhiteSpaceDetect } from 'utils/helpers'
import z from 'zod'

export const CreateFaqsSchema = z.object({
    name: z.string({
        required_error: 'Name is required',
        invalid_type_error: 'Name must be a string',
    }),
    email: z
        .string({
            required_error: 'Email is required',
        })
        .email('Please enter a valid email'),
    canhelp: z.string({
        required_error: 'Message is required',
    }),
    specify: z.any(),
    gethere: z.any(),
})

export const UpdateFaqsSchema = z.object({
    title: z
        .string({ required_error: 'Title is required.' })
        .min(1, 'Title is required.')
        .refine((val) => WhiteSpaceDetect(val), {
            message: errorWhiteSpaces,
        }),
    description: z
        .string({ required_error: 'Content is required.' })
        .min(1, 'Content is required.')
        .refine((val) => WhiteSpaceDetect(val), {
            message: errorWhiteSpaces,
        }),
    status: z.string(),
    visibility: z.string(),
})

export type CreateFaqsType = z.infer<typeof UpdateFaqsSchema>

export type ContactUsType = z.infer<typeof CreateFaqsSchema>
