import { BiLocationPlus } from 'react-icons/bi'
import { BsImage } from 'react-icons/bs'
import { MenuItemType, ObjectProductType } from 'services/requests/menu-category/schema'

interface productProps {
    product?: ObjectProductType
    stores?: MenuItemType
}

export const ProductContent = ({ product, stores }: productProps) => {
    return (
        <div className='flex flex-col space-y-4 justify-center mx-auto'>
            {stores ? (
                <>
                    <h2 className='text-lg font-semibold mb-2'>Shop availability</h2>
                    <div className='grid grid-cols-3 gap-4'>
                        {Array.from(new Set(stores?.map((store) => store.shopName))).map(
                            (shopName, index) => (
                                <div key={index} className='flex flex-row space-x-2'>
                                    <BiLocationPlus className='h-5 w-5 text-red-800' />
                                    <div className='text-xs' key={index}>
                                        {shopName}
                                    </div>
                                </div>
                            ),
                        )}
                    </div>
                </>
            ) : (
                <></>
            )}
            <div className='flex flex-col space-x-0 space-y-10 2xl:flex-row 2xl:space-x-40'>
                <div className=''>
                    <h2 className='text-lg font-semibold'>Image Preview</h2>
                    {product?.coverPhoto ? (
                        <img
                            src={product?.coverPhoto}
                            className='max-w-[12rem] max-h-[12rem] object-cover rounded-full mx-9 mt-5'
                        />
                    ) : (
                        <BsImage className='h-full w-full text-red-900' />
                    )}
                </div>
                <div className='mt-0'>
                    <p className='text-lg font-semibold'>Description</p>
                    <p className='text-sm'>{product?.description ? product?.description : 'N/A'}</p>
                    <div>
                        <h2 className='text-lg font-semibold mt-4'>Nutrition Facts</h2>
                        {product?.pictureNutrient ? (
                            <img
                                src={product?.pictureNutrient}
                                className='w-full h-full sm:w-3/4 sm:h-3/4 lg:w-3/4 lg:h-3/4 xl:w-1/2 xl:h-1/2 2xl:w-6/12 2xl:h-6/12 object-cover mt-5'
                            />
                        ) : (
                            <BsImage className='w-full mt-5 text-red-900' />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
