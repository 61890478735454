import { Tab } from '@headlessui/react'
import { Fragment } from 'react'
import { twMerge } from 'tailwind-merge'

export interface TabProps {
    name: string
    content: JSX.Element
}

interface AppTabsProps {
    tabs: TabProps[]
    onChange?: (selectedTab: number) => void
}

export const AppTabs = (props: AppTabsProps) => {
    return (
        <Tab.Group
            onChange={(tab) => {
                if (props?.onChange) {
                    props?.onChange(tab)
                }
            }}
        >
            <Tab.List className={'flex flex-row space-x-8 border-b-2'}>
                {props.tabs.map((tab, index) => (
                    <Tab key={index} as={Fragment}>
                        {({ selected }) => (
                            <div
                                className={twMerge(
                                    'text-red-900 text-xl font-bold py-2 px-3 cursor-pointer',
                                    selected && 'border-b-8 border-red-900',
                                )}
                            >
                                {tab.name}
                            </div>
                        )}
                    </Tab>
                ))}
            </Tab.List>
            <Tab.Panels>
                {props.tabs.map((tab, index) => (
                    <Tab.Panel key={index}>{tab.content}</Tab.Panel>
                ))}
            </Tab.Panels>
        </Tab.Group>
    )
}
