export const SVGMagnifyingGlassEmptyState = (props: any) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='137.581'
        height='137.581'
        viewBox='0 0 137.581 137.581'
        {...props}
    >
        <g
            id='_001-no_results'
            data-name='001-no results'
            transform='translate(0 -0.001)'
            opacity='0.36'
        >
            <g id='Group_14256' data-name='Group 14256' transform='translate(2.939 2.934)'>
                <path
                    id='Path_14128'
                    data-name='Path 14128'
                    d='M206.011,50a43.99,43.99,0,1,0,0,87.979q1.607,0,3.186-.114a6.893,6.893,0,0,1-.127-1.32,7.038,7.038,0,0,1,7.018-7.018H217.6a14.035,14.035,0,0,1,24.085-9.8A43.994,43.994,0,0,0,206.011,50Z'
                    transform='translate(-130.021 -38.27)'
                    fill='#60a4ff'
                    fillRule='evenodd'
                />
                <path
                    id='Path_14129'
                    data-name='Path 14129'
                    d='M132.913,61.739a40.846,40.846,0,1,1,73.972,23.9,13.1,13.1,0,0,1,1.849,2.4,8.427,8.427,0,0,1,8.22,2.189,51.739,51.739,0,1,0-85.009,1.99,52.047,52.047,0,0,0,11.336,11.336,51.789,51.789,0,0,0,54.122,4.218h-14.33a6.546,6.546,0,0,1-6.356-5.291q-1.465.1-2.959.106a40.846,40.846,0,0,1-40.845-40.846Z'
                    transform='translate(-93.787 -10.001)'
                    fill='#ff5d7d'
                    fillRule='evenodd'
                />
                <path
                    id='Path_14130'
                    data-name='Path 14130'
                    d='M55.192,58.223A55.792,55.792,0,0,1,66.255,41.438a9.076,9.076,0,0,0-10.412-4.467,14.037,14.037,0,0,0-26.076,7.216H28.253a7.018,7.018,0,0,0-.046,14.036H55.192Z'
                    transform='translate(-17.946 -24.242)'
                    fill='#a0c8ff'
                    fillRule='evenodd'
                />
                <path
                    id='Path_14131'
                    data-name='Path 14131'
                    d='M345.02,273.32a14.036,14.036,0,0,0-14.036,14.036h-1.514a7.038,7.038,0,0,0-7.018,7.018,6.892,6.892,0,0,0,.127,1.32,7.05,7.05,0,0,0,6.845,5.7h39.091a6.6,6.6,0,0,0,0-13.2,9.071,9.071,0,0,0-11.454-8.055,14.052,14.052,0,0,0-12.042-6.819Z'
                    transform='translate(-243.404 -204.455)'
                    fill='#a0c8ff'
                    fillRule='evenodd'
                />
                <path
                    id='Path_14132'
                    data-name='Path 14132'
                    d='M54.265,363.836,41.556,351.127,12.888,378.6a9.832,9.832,0,0,0,13.9,13.9Z'
                    transform='translate(-10.02 -263.664)'
                    fill='#ff5d7d'
                    fillRule='evenodd'
                />
                <path
                    id='Path_14133'
                    data-name='Path 14133'
                    d='M117.556,323.418l12.709,12.709,11.5-12a56.047,56.047,0,0,1-12.208-12.209Z'
                    transform='translate(-89.322 -232.652)'
                    fill='#ffc144'
                    fillRule='evenodd'
                />
            </g>
            <g id='Group_14257' data-name='Group 14257' transform='translate(0.001 0.001)'>
                <path
                    id='Path_14134'
                    data-name='Path 14134'
                    d='M254.955,195.865a2.933,2.933,0,0,0,0-5.865h0a2.933,2.933,0,1,0,0,5.865Z'
                    transform='translate(-184.554 -139.137)'
                />
                <path
                    id='Path_14135'
                    data-name='Path 14135'
                    d='M304.955,195.865a2.933,2.933,0,0,0,0-5.865h0a2.933,2.933,0,1,0,0,5.865Z'
                    transform='translate(-221.169 -139.137)'
                />
                <path
                    id='Path_14136'
                    data-name='Path 14136'
                    d='M354.955,195.865a2.933,2.933,0,0,0,0-5.865h0a2.933,2.933,0,1,0,0,5.865Z'
                    transform='translate(-257.784 -139.137)'
                />
                <path
                    id='Path_14137'
                    data-name='Path 14137'
                    d='M131.139,84.689a10.912,10.912,0,0,0-1.385-3.008A53.727,53.727,0,0,0,47.236,14.429a11.036,11.036,0,0,0-7.608-3.063q-.493,0-.982.044a15.547,15.547,0,0,0-27.589,6.93A9.117,9.117,0,0,0,12.094,36.51h20.84a53.707,53.707,0,0,0,6.144,46.939L3.461,117.582l-.041.04a11.7,11.7,0,0,0,16.54,16.54l.04-.041L54.131,98.5a53.73,53.73,0,0,0,53.683,3.346h21.034a8.733,8.733,0,0,0,2.291-17.161ZM12.094,31.136a3.743,3.743,0,0,1,.042-7.486h1.387a2.687,2.687,0,0,0,2.687-2.687,10.174,10.174,0,0,1,18.9-5.228,2.687,2.687,0,0,0,3.018,1.207,5.624,5.624,0,0,1,5.362,1.341,53.917,53.917,0,0,0-8.4,12.854h-23Zm4.048,99.245a6.321,6.321,0,0,1-8.94-8.94L31.548,98.109l7.925,7.925Zm27.049-28.228L35.429,94.39,42.3,87.8a54.21,54.21,0,0,0,7.476,7.476ZM35.475,53.741A48.366,48.366,0,1,1,125.7,77.988a10.9,10.9,0,0,0-5.132-1.283q-.189,0-.377.006A43,43,0,0,0,63.82,15.69a2.687,2.687,0,0,0,2.506,4.754,37.627,37.627,0,0,1,49.61,52.931A15.53,15.53,0,0,0,92,83.679a9.136,9.136,0,0,0-7.935,7.68h-.219a37.627,37.627,0,0,1-33.3-55.132,2.687,2.687,0,0,0-4.754-2.506A43,43,0,0,0,83.841,96.733c.4,0,.725,0,1.035-.006a9.121,9.121,0,0,0,6.043,4.873,49.125,49.125,0,0,1-7.078.508A48.421,48.421,0,0,1,35.475,53.741Zm93.374,42.735H93.032a3.743,3.743,0,0,1,.042-7.486h1.387A2.687,2.687,0,0,0,97.148,86.3a10.174,10.174,0,0,1,18.9-5.228,2.686,2.686,0,0,0,3.018,1.207,5.625,5.625,0,0,1,7.1,4.99,2.687,2.687,0,0,0,2.68,2.487,3.359,3.359,0,0,1,0,6.718Z'
                    transform='translate(-0.001 -0.001)'
                />
                <path
                    id='Path_14138'
                    data-name='Path 14138'
                    d='M198.886,89.8a2.933,2.933,0,1,0,0-5.865h0a2.933,2.933,0,0,0,0,5.865Z'
                    transform='translate(-143.495 -61.465)'
                />
            </g>
        </g>
    </svg>
)
