import React, { useCallback } from 'react'
import { twMerge } from 'tailwind-merge'
import { SVGMagnifyingGlassEmptyState } from 'utils/svgs'

interface IAppEmptyTableProps {
    image?: string
    containerClassName?: string
    isEmptyState?: boolean
    title?: string
    description?: string
    titleEmptyState?: string
    descriptionEmptyState?: string
    isHideDescription?: boolean
}

const AppEmptyTable: React.FunctionComponent<IAppEmptyTableProps> = (props) => {
    const RenderText = useCallback(() => {
        if (props.isEmptyState) {
            return (
                <>
                    <h1 className='text-[#861F41] text-[18px] font-semibold'>
                        {props?.titleEmptyState ?? 'It seems that this table has no data yet.'}
                    </h1>
                    <p className='text-gray-500 text-xs leading-6'>
                        {props?.descriptionEmptyState ??
                            'You can start adding data by clicking the add button.'}
                    </p>
                </>
            )
        }
        return (
            <>
                <h1 className='text-[#861F41] text-[18px] font-semibold'>
                    {props?.title ?? 'No results found.'}
                </h1>
                {props?.description ? (
                    <p className='text-gray-500 text-xs leading-6'>{props?.description}</p>
                ) : (
                    <p
                        className={twMerge(
                            props.isHideDescription && 'hidden',
                            'text-gray-500 text-xs leading-6',
                        )}
                    >
                        We couldn&apos;t find any search results matching your search. Please try a
                        different keyword or phrasing
                    </p>
                )}
            </>
        )
    }, [props.isEmptyState])

    return (
        <div
            className={twMerge(
                props?.containerClassName,
                'w-full h-full flex justify-center items-center flex-col gap-3',
            )}
        >
            <SVGMagnifyingGlassEmptyState className='w-[138px] h-[138px] object-contain' />
            <div className='max-w-[414px] flex flex-col gap-2 text-center'>
                <RenderText />
            </div>
        </div>
    )
}

export default React.memo(AppEmptyTable)
