import { AppCard } from 'components/AppCard'
import { OrderType } from 'services/requests/orders/schema'
import { twMerge } from 'tailwind-merge'
import { convertUnixTimestampSecond } from 'utils/time'

interface OrderHistoryProps {
    orderData: OrderType
}

export const OrderHistory = (props: OrderHistoryProps) => {
    const handleStatus = (status: string, productName: string, orderId: number) => {
        switch (status) {
            case 'ORDER_CREATED':
                return 'Order Placed'
            case 'PENDING':
                return 'Pick-Up Pending'
            case 'COOKING':
                return 'Cooking'
            case 'DONE':
                return 'Completed'
            case 'REFUNDED':
                return 'Refunded'
            case 'OUT_OF_STOCK':
                return `Out of stock for ${productName}, #${orderId} `
            case 'RECHARGE_GIFT_CARD':
                return 'Recharge Gift Card'
            case 'REFUND_INITIATED':
                return `Refund Initiated for ${productName}, #${orderId} `
            case 'REFUND_COMPLETED':
                return `Refund Completed for ${productName}, #${orderId} `
            case 'REFUND_FAILED':
                return `Refund Failed for ${productName}, #${orderId} `
            case 'FAILED':
                return `Cooking failed for ${productName}, #${orderId}`
            default:
                return 'Failed'
        }
    }

    return (
        <section className='col-span-full md:col-span-1 lg:col-span-4 xl:col-span-1'>
            <AppCard>
                <div className='divide-y divide-gray-200 space-y-2'>
                    <h1 className='text-gray-800 font-bold'>Order History</h1>
                    <ol role='list' className='pt-2 overflow-hidden'>
                        {props.orderData.orderHistory
                            .map((history, index) => ({ ...history }))
                            .sort((a, b) => a.createdAt - b.createdAt)
                            .map((history, index) => (
                                <li
                                    key={index}
                                    className={twMerge(
                                        index !== props.orderData.orderHistory.length - 1
                                            ? 'pb-10'
                                            : '',
                                        'relative',
                                    )}
                                >
                                    <>
                                        {index !== props.orderData.orderHistory.length - 1 ? (
                                            <div
                                                className='absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 border border-gray-400 border-dashed'
                                                aria-hidden='true'
                                            />
                                        ) : null}
                                        <div className='group relative flex items-start'>
                                            <span className='flex h-9 items-center'>
                                                <span className='relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-red-900'></span>
                                            </span>
                                            <span className='ml-4 flex min-w-0 flex-col'>
                                                <span className='text-sm font-medium'>
                                                    {handleStatus(
                                                        history.status,
                                                        history?.orderLine?.product?.name,
                                                        history?.orderLine?.externalOrderId,
                                                    )}
                                                </span>
                                                <span className='text-sm text-gray-500'>
                                                    {convertUnixTimestampSecond(history.createdAt)}
                                                </span>
                                            </span>
                                        </div>
                                    </>
                                </li>
                            ))}
                    </ol>
                </div>
            </AppCard>
        </section>
    )
}
