import {
    closestCenter,
    DndContext,
    DragEndEvent,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { arrayMove, horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { AxiosError } from 'axios'
import { AppButton } from 'components/AppButton'
import { AppModal } from 'components/AppModal'
import { useAtom } from 'jotai'
import { CreateMenuCategoryType } from 'pages/Schemas/menu-category'
import React, { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { listMenuCategoryApi, UpdatePositionWithIds } from 'services/requests/menu-category'
import { ListMenuCategoryType } from 'services/requests/menu-category/schema'
import { MenuCategoryPositionAtom } from 'store/productAtom'
import { twMerge } from 'tailwind-merge'
import SortableItemCategory from './sortable-category-item'
import './style.css'

interface UpdateCategoryType extends CreateMenuCategoryType {
    id: string
}
const navigations = [
    {
        href: '/web/menu-category',
        label: 'Categories',
    },
    {
        href: '/web/menu-category/products',
        label: 'Products',
    },
]
const MenuCategory: React.FunctionComponent = () => {
    const [sortedData, setSortedData] = useState<ListMenuCategoryType | null>(null)
    const [, setHighestMenuCategoryPositon] = useAtom(MenuCategoryPositionAtom)
    const { data, isLoading } = useQuery(['menu-category'], () => listMenuCategoryApi(), {
        onSuccess: (data) => {
            const sortedArray = data.sort(
                (menuCategoryA, menuCategoryB) => menuCategoryA.position - menuCategoryB.position,
            )
            const lastData = sortedArray[data.length - 1]

            setHighestMenuCategoryPositon(lastData.position + 1)
            setSortedData(null)
        },
    })

    const pointerSensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 10,
        },
    }) //
    const sensors = useSensors(pointerSensor)
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const location = useLocation()
    const path = location.pathname
    const [isOpen, setIsOpen] = useState(false)
    const [idToProceed, setIdToProceed] = useState('')
    const { mutate: updatePositions } = useMutation<unknown, AxiosError, string[]>(
        (data) => UpdatePositionWithIds(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['menu-category'])
                toast.success('Success! Menu categories arrangement changed.')
            },
            onError: (error) => console.log(error),
        },
    )

    const handleDrag = (event: DragEndEvent) => {
        const { active, over } = event

        if (data) {
            if (active.id !== over?.id) {
                const activeIndex = data.map((arr) => arr.id).indexOf(active.id.toString())
                const overIndex = data.map((arr) => arr.id).indexOf((over?.id ?? '').toString())

                const array = arrayMove(data, activeIndex, overIndex)
                setSortedData(array)
                updatePositions(array.map((item) => item.id))
            }
        }
    }
    const RenderCategory = useCallback(
        (categories: ListMenuCategoryType) => {
            if (categories.length > 0) {
                const array = sortedData ?? categories
                return array.map((item) => (
                    <SortableItemCategory
                        item={item}
                        id={item.id}
                        setIdToProceed={setIdToProceed}
                        setIsOpen={setIsOpen}
                        key={item.id}
                    />
                ))
            }
        },
        [data, sortedData],
    )

    if (isLoading) return <></>
    if (data)
        return (
            <div className='p-2 m-2 flex flex-col gap-4 text-[#464255]'>
                <div className='inline-flex w-full justify-between'>
                    <h1 className='font-bold text-[25px] text-[#861F41] w-full border-b-2 py-3'>
                        Menu
                    </h1>
                </div>
                <div className='flex flex-col w-full max-w-[1044px] p-5'>
                    <div className='flex flex-wrap gap-5'>
                        <div className='overflow-auto'>
                            <div className='flex gap-5 justify-center items-center w-fit px-8 py-6'>
                                <DndContext
                                    {...sensors}
                                    collisionDetection={closestCenter}
                                    onDragEnd={handleDrag}
                                >
                                    <SortableContext
                                        items={data?.map((item) => item?.id) ?? []}
                                        strategy={horizontalListSortingStrategy}
                                    >
                                        {data && RenderCategory(data)}
                                        <AppButton
                                            variant='secondary'
                                            className='bg-[#E3D5DA] text-[#861F41] border-[#861F41] border-2 w-[177px] h-[62px] flex justify-center items-center gap-4'
                                            onClick={() => navigate('add')}
                                            type='button'
                                        >
                                            <AiOutlinePlusCircle className='w-7 h-7 mx-1' />
                                            <span>Add Category</span>
                                        </AppButton>
                                    </SortableContext>
                                </DndContext>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col w-full p-2 my-5 gap-5 bg-white'>
                        <div className='inline-flex gap-3 border-b-2'>
                            {navigations.map((nav, index) => (
                                <div
                                    key={index}
                                    className={twMerge(
                                        path === nav.href
                                            ? ' text-[#861F41] font-bold border-b-4 border-[#861F41]'
                                            : 'font-normal text-gray-500',
                                        'w-fit  shadow-none text-[16px] translate-y-1 transition-all',
                                    )}
                                >
                                    <AppButton
                                        variant='secondary'
                                        onClick={() => navigate(nav.href)}
                                        type='button'
                                        className={twMerge(
                                            path === nav.href
                                                ? ' text-[#861F41] font-bold'
                                                : 'font-normal text-gray-500',
                                            'w-fit  shadow-none text-[16px] border-none',
                                        )}
                                    >
                                        {nav.label}
                                    </AppButton>
                                </div>
                            ))}
                        </div>
                        <Outlet />
                    </div>
                    <AppModal
                        containerClassName='rounded-sm'
                        isOpen={isOpen}
                        onClose={() => setIsOpen(false)}
                    >
                        <div className='flex justify-center items-center flex-col gap-6 w-[262px]'>
                            <h1 className='text-center'>
                                To get this category visible in the menu page, upload an Image in
                                the edit category page.
                            </h1>
                            <div className='inline-flex justify-between w-full'>
                                <AppButton
                                    variant='secondary'
                                    onClick={() => setIsOpen(false)}
                                    className='bg-white text-[#862F4C] font-bold py-3 px-8'
                                    type='button'
                                >
                                    Cancel
                                </AppButton>
                                <AppButton
                                    variant='primary'
                                    onClick={() => navigate('update/' + idToProceed)}
                                    className='text-white bg-[#862F4C] font-bold py-3 px-8'
                                    type='button'
                                >
                                    Proceed
                                </AppButton>
                            </div>
                        </div>
                    </AppModal>
                </div>
            </div>
        )
    return <></>
}

export default MenuCategory
