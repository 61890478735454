import Logo from 'assets/images/logo.png'
import { atom } from 'jotai'
import { useState } from 'react'
import { HiOutlineDesktopComputer, HiOutlineDeviceMobile } from 'react-icons/hi'
import { MenuChildren } from './MenuChildren'

export const navigation = [
    {
        name: 'Order',
        icon: HiOutlineDeviceMobile,
        href: '/order',
        restricted: false,
    },
    {
        name: 'Mobile',
        icon: HiOutlineDeviceMobile,
        href: '/mobile/offersanddeals',
        restricted: false,
        children: [
            // { name: 'Dashboard', href: '/mobile/dashboard', restricted: false },
            { name: 'Offers And Deals', href: '/mobile/offersanddeals', restricted: false },
            // { name: 'Menu Settings', href: '/mobile/menu/settings', restricted: false },
            // { name: 'Location', href: '/mobile/location', restricted: false },
            // { name: 'User Management', href: '/mobile/user', restricted: false },
            // { name: 'Push Notification', href: '/mobile/notification', restricted: false },
            // { name: 'Offer & Deals Management', href: '/mobile/deals', restricted: false },
            // { name: 'Loyalty Program Management', href: '/mobile/loyalty', restricted: false },
            // { name: 'Utilities', href: '/mobile/utilities', restricted: false },
        ],
    },
    {
        name: 'Website',
        icon: HiOutlineDesktopComputer,
        href: '/web',
        restricted: false,
        children: [
            { name: 'Home', href: '/web/home', restricted: false },
            { name: 'Menu', href: '/web/menu-category', restricted: false },
            { name: 'News', href: '/web/news', restricted: false },
            // { name: 'Shop', href: '/web/shop', restricted: false },
            { name: 'FAQs', href: '/web/faqs', restricted: false },
            { name: 'Jobs', href: '/web/jobs', restricted: false },
            { name: 'Partnerships', href: '/web/partnerships', restricted: false },
        ],
    },
    {
        name: 'Global Settings',
        icon: HiOutlineDesktopComputer,
        href: '/global-setting/menu',
        restricted: false,
        children: [
            // { name: 'Menu', href: '/global-settings/menu', restricted: false },
            { name: 'Products', href: '/global-settings/products', restricted: false },
            { name: 'Stores', href: '/global-settings/stores', restricted: false },
            // { name: 'Brand Kit', href: '/global-settings/brandkit', restricted: false },
            // { name: 'Offers', href: '/global-settings/offers', restricted: false },
            { name: 'Contact Us', href: '/global-settings/contact-us', restricted: false },
            { name: 'Privacy Policy', href: '/global-settings/privacy-policy', restricted: false },
            {
                name: 'Terms and Conditions',
                href: '/global-settings/terms-and-conditions',
                restricted: false,
            },
            {
                name: 'Accessibility Statement',
                href: '/global-settings/accessibility-statement',
                restricted: false,
            },
            {
                name: 'User Management',
                href: '/global-settings/user-management',
                restricted: false,
            },
            { name: 'Gift Card', href: '/global-settings/gift-card', restricted: false },
            { name: 'Settings', href: '/global-settings/settings', restricted: false },
        ],
    },
    // {
    //     name: 'Settings',
    //     icon: HiOutlineDesktopComputer,
    //     href: '/settings/auditlog',
    //     restricted: false,
    //     children: [
    //         { name: 'Audit Log', href: '/settings/audit-log', restricted: false },
    //         { name: 'Location', href: '/settings/image-gallery', restricted: false },
    //     ],
    // },
]
export const navAtom = atom<boolean>(false)

export const Sidebar = () => {
    const [currentTab, setCurrentTab] = useState(0)
    return (
        <div className='hidden lg:flex lg:flex-col lg:fixed lg:inset-y-0 lg:shadow-lg lg:w-72 lg:shadow-gray-300 lg:z-50'>
            <div
                className={
                    'flex grow flex-col gap-y-5 overflow-y-auto px-3 pb-4 border-gray-200 bg-white'
                }
            >
                <div className='flex item-center justify-center mt-3'>
                    <img src={Logo} />
                </div>
                <nav
                    className='flex-auto pt-4 space-y-2 bg-white overflow-auto no-scrollbar'
                    aria-label='Sidebar'
                >
                    {navigation?.map((item, index) => (
                        <div key={item?.name}>
                            <MenuChildren
                                index={index}
                                isOpen={index === currentTab}
                                item={item}
                                setCurrentTab={setCurrentTab}
                            />
                        </div>
                    ))}
                </nav>
            </div>
        </div>
    )
}
function setState(arg0: number): [any, any] {
    throw new Error('Function not implemented.')
}
