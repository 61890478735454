import { useSetAtom } from 'jotai'
import React, { useMemo } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import InputMask from 'react-input-mask'
import { userErrorAtom } from 'store/userAtom'
import { twMerge } from 'tailwind-merge'

interface AppTextBoxProps {
    type:
        | 'text'
        | 'password'
        | 'email'
        | 'number'
        | 'tel'
        | 'url'
        | 'card'
        | 'dates'
        | 'date'
        | 'otp'
        | 'time'
    name: string
    disabled?: boolean
    value?: string | number
    onChange?: (value: string) => void
    placeholder?: string
    label?: string
    step?: string
    labelPosition?: 'top' | 'left'
    containerClassname?: string
    inputContainerClassname?: string
    inputClassname?: string
    labelClassname?: string
    errorMessage?: string
    errorClassName?: string
    isNumberRestricted?: boolean
    onBlur?: () => void
    onFocus?: () => void
    maxLength?: number
    autoFocus?: boolean
    required?: boolean
}

export const AppTextBox = (props: AppTextBoxProps) => {
    const setUserError = useSetAtom(userErrorAtom)
    const [value, setValue] = React.useState(props.value || '')
    const [showPassword, setShowPassword] = React.useState(true)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        /* if (props.type === 'text' && isNaN(Number(event.target.value))) {
            setUserError(true)
        } else { */
        setUserError(false)
        setValue(event.target.value)
        props.onChange && props.onChange(event.target.value)
        /* } */
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (props.type === 'number' && props.isNumberRestricted) {
            if (event.key !== 'Backspace' && (event.key < '0' || event.key > '9')) {
                event.preventDefault()
            }
        }
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const inputType = showPassword ? props.type : 'text'

    const maxDate = new Date().toISOString().slice(0, 10)
    const toMask = ['card', 'tel', 'dates', 'otp', 'full-date']
    const mask = useMemo(() => {
        // if(props?.type === 'card' ? '9999 9999 9999 9999' : ''
        switch (props?.type) {
            case 'date':
                return '99/99/9999'
            case 'card':
                return '9999 9999 9999 9999'
            case 'tel':
                return props.maxLength === 11 ? '9999-999-9999' : ''
            case 'dates':
                return '99-99'
            case 'otp':
                return '9  9  9  9  9  9'
            default:
                return '999999999'
        }
    }, [])
    React.useEffect(() => {
        setValue(props.value || '')
    }, [props.value])

    return (
        <div className={props.containerClassname}>
            {props.label && (props.labelPosition === 'top' || !props.labelPosition) && (
                <label
                    htmlFor={props.name}
                    className={twMerge(
                        'block text-sm font-medium text-gray-700',
                        props.labelClassname,
                    )}
                >
                    {props.label}
                </label>
            )}
            <div
                className={twMerge(
                    props.labelPosition === 'left'
                        ? 'mt-1 flex space-x-2 items-center rounded-md shadow-sm'
                        : 'mt-1 rounded-md shadow-sm',
                    props.inputContainerClassname,
                )}
            >
                <div
                    className={twMerge(
                        props.type === 'password' ? 'flex  justify-between' : '',
                        'w-full',
                    )}
                    role='container'
                >
                    {props.label && props.labelPosition === 'left' && (
                        <label
                            htmlFor={props.name}
                            className={twMerge(
                                'basis-1/4 block text-sm font-medium text-gray-700',
                                props.labelClassname,
                            )}
                        >
                            {props.label}
                        </label>
                    )}
                    {toMask.includes(props?.type) ? (
                        <InputMask
                            mask={mask}
                            placeholder={props?.placeholder}
                            maxLength={props?.maxLength}
                            className={twMerge(
                                props.inputClassname
                                    ? props.inputClassname
                                    : 'block w-full appearance-none rounded-md border border-gray-200 px-3 py-2 placeholder-gray-700 shadow-sm focus:outline-none sm:text-sm',
                                props.errorMessage && 'border-error',
                            )}
                            onChange={handleChange}
                            onBlur={props?.onBlur && props?.onBlur}
                            id={props.name}
                            onKeyDown={handleKeyDown}
                            name={props.name}
                            type={inputType}
                            autoComplete={props.name}
                            value={value}
                            disabled={props.disabled}
                            tabIndex={0}
                            required={props?.required}
                            autoFocus={props.autoFocus}
                        />
                    ) : (
                        <input
                            id={props.name}
                            name={props.name}
                            type={inputType}
                            autoComplete={props.name}
                            value={value}
                            step={props.step}
                            maxLength={props?.maxLength}
                            max={maxDate}
                            placeholder={props.placeholder}
                            autoFocus={props.autoFocus}
                            disabled={props.disabled}
                            onKeyDown={handleKeyDown}
                            onChange={handleChange}
                            className={twMerge(
                                'w-full appearance-none px-3 py-2 focus:outline-none text-xs disabled:bg-gray-100',
                                props.label && props.labelPosition === 'left' ? 'basis-3/4' : '',
                                props.inputClassname,
                                props.errorMessage && 'text-red-500',
                            )}
                            onBlur={props?.onBlur}
                            onFocus={props?.onFocus}
                        />
                    )}
                    {props.type === 'password' && (
                        <button
                            type='button'
                            className='text-gray-500 mr-2'
                            onClick={toggleShowPassword}
                        >
                            {!showPassword ? (
                                <FaEye aria-label='Crossed Eye icon - Hide Password' />
                            ) : (
                                <FaEyeSlash aria-label='Eye icon - Reveal Password' />
                            )}
                        </button>
                    )}
                </div>
            </div>
            {props.errorMessage && (
                <p
                    className={twMerge('mt-2 text-sm text-red-600', props?.errorClassName)}
                    id={`${props.name}-error`}
                >
                    {props.errorMessage === 'Expected number, received nan'
                        ? 'This input field only accepts numerical values.'
                        : props.errorMessage}
                </p>
            )}
        </div>
    )
}
