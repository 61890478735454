import { z } from 'zod'

export const QaSendNotifcationSchema = z.object({
    order_no: z.string(),
    notification_type: z.string(),
    status: z.string(),
})

export const QaMockPickupOrderSchema = z.object({
    code: z.string(),
    status: z.string(),
})

export type QaSendNotifcationSchemaType = z.infer<typeof QaSendNotifcationSchema>
export type QaMockPickupOrderType = z.infer<typeof QaMockPickupOrderSchema>
