import { zodResolver } from '@hookform/resolvers/zod'
import { AxiosError } from 'axios'
import { AppCard } from 'components/AppCard'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { updateStoreAndHoursApi } from 'services/requests/store'
import {
    LocationType,
    StoreHoursType,
    UpdateStoreSchema,
    UpdateStoreType,
} from 'services/requests/store/schema'
import { BusinessHourSection } from './BusinessHourSection'
import { StoreDetailSection } from './StoreDetailSection'
import { StoreFooter } from './StoreFooter'
import { StoreHeader } from './StoreHeader'

interface StoreTabContentProps {
    store: LocationType
}

const defaultBusinessHours: StoreHoursType[] = [
    {
        dayOfWeek: 0,
        openTime: '07:00',
        closeTime: '16:00',
        active: false,
    },
    {
        dayOfWeek: 1,
        openTime: '07:00',
        closeTime: '16:00',
        active: false,
    },
    {
        dayOfWeek: 2,
        openTime: '07:00',
        closeTime: '16:00',
        active: false,
    },
    {
        dayOfWeek: 3,
        openTime: '07:00',
        closeTime: '16:00',
        active: false,
    },
    {
        dayOfWeek: 4,
        openTime: '07:00',
        closeTime: '16:00',
        active: false,
    },
    {
        dayOfWeek: 5,
        openTime: '07:00',
        closeTime: '16:00',
        active: false,
    },
    {
        dayOfWeek: 6,
        openTime: '07:00',
        closeTime: '16:00',
        active: false,
    },
]

export const StoreTabContent = (props: StoreTabContentProps) => {
    const queryClient = useQueryClient()

    const overwriteHours = (
        defaultHours: StoreHoursType[],
        newHours: StoreHoursType[],
    ): StoreHoursType[] => {
        return defaultHours.map((defaultHour) => {
            const newHour = newHours.find((hour) => hour.dayOfWeek === defaultHour.dayOfWeek)

            return newHour ? newHour : defaultHour
        })
    }

    const updatedDefaultBusinessHours = overwriteHours(defaultBusinessHours, props.store.storeHours)

    const methods = useForm<UpdateStoreType>({
        mode: 'onChange',
        resolver: zodResolver(UpdateStoreSchema),
        defaultValues: {
            externalId: props.store.externalId,
            shopName: props.store.shopName,
            latitude: props.store.latitude,
            longitude: props.store.longitude,
            shopAddress: props.store.shopAddress,
            description: props.store.machineLocationDescription,
            status: props.store.status,
            restricted: props.store.restricted,
            restrictNote: props.store.restrictNote,
            file: props.store.imageName,
            storeHours: updatedDefaultBusinessHours,
        },
    })

    const updateStore = useMutation<unknown, AxiosError, UpdateStoreType>(
        (data) => updateStoreAndHoursApi(data, props.store.id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['getLocationsByIdApi', props.store.id])
                toast.success('Store updated successfully')
            },
        },
    )

    const handleSave = (data: UpdateStoreType) => {
        console.log(data)
        updateStore.mutateAsync(data)
    }

    useEffect(() => {
        console.log(methods.formState.errors)
    }, [methods.formState.errors])

    return (
        <AppCard className='px-10 space-y-8'>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSave)}>
                    <StoreHeader />
                    <StoreDetailSection />
                    <BusinessHourSection />
                    <StoreFooter isSubmitting={updateStore.isLoading} />
                </form>
            </FormProvider>
        </AppCard>
    )
}
