import { CreateJobsType } from 'pages/Schemas/jobs'
import { axiosInstance } from '../../axios'
import { CreateDetailsType, JobsType, PageJobsNewsType } from './schema'

export const listJobsApi = async (params: { page?: number; size?: number; sort?: string }) => {
    const response = await axiosInstance.get('/public/job', { params })

    return response.data as PageJobsNewsType
}
export const GetJobApi = async (jobId: string) => {
    const response = await axiosInstance.get('/public/job', {
        params: { jobId },
    })

    return response.data as JobsType
}

export const CreateJobApi = async (data: CreateJobsType) => {
    const datas = { ...data, status: 'ACTIVE' }
    const response = await axiosInstance.post('/job', datas)

    return response.data
}
export const UpdateJobApi = async (id: string, data: CreateJobsType) => {
    const response = await axiosInstance.put(`/job?jobId=${id}`, data)

    return response.data
}

export const UpdateJobPosApi = async (id: string, position: number) => {
    const response = await axiosInstance.put(`/job?jobId=${id}`, { position })

    return response.data
}
export const DeleteJobsApi = async (id: string) => {
    console.log(id)

    if (id) {
        const response = await axiosInstance.delete(`/job?jobId=${id}`)

        return response
    }
}
export const UpdateDeleteJobsApi = async (id: string) => {
    if (id) {
        const response = await axiosInstance.put(`/job?jobId=${id}`, { status: 'DELETED' })
        return response
    }
}

export const CreateJobsDetailsApi = async (data: CreateDetailsType) => {
    const response = await axiosInstance.post('/job/details', data)
    return response.data
}
export const DeleteJobDetailApi = async (id: string) => {
    if (id) {
        const response = await axiosInstance.delete('/job/details', {
            params: {
                detailsId: id,
            },
        })
        return response
    }
}
