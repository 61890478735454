import { zodResolver } from '@hookform/resolvers/zod'
import { AxiosError } from 'axios'
import { AppButton } from 'components/AppButton'
import AppColorPicker from 'components/AppColorPicker'
import { AppTextBox } from 'components/AppTextBox'
import { useAtom } from 'jotai'
import { AppCheckbox } from 'pages/GlobalSettings/Brandkit/CreateBrandkit/Checkbox'
import { ManageLoyaltyTierType, manageTierSchema, TierType } from 'pages/Schemas/tier'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { BsPercent, BsStarFill } from 'react-icons/bs'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
    createLoyaltyTier,
    getLoyaltyTier,
    getPointingTierList,
    updateLoyaltyTier,
} from 'services/requests/account'
import { tiersAtom } from 'store/tierAtom'
import { formatNumber } from 'utils/helper'
import TierSkeletonLoading from '../TierSkeletonLoading'

interface IErrorMessage {
    httpStatus: string
    message: string
    timestamp: string
}

const ManageTier = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const [tiersState, setTierAtom] = useAtom(tiersAtom)
    const { data: pointingTierList } = useQuery<TierType[]>('pointingTierList', getPointingTierList)
    const [isChecked, setCheck] = useState(false)
    const [errMessage, setErrMessage] = useState<IErrorMessage>()
    const { tierId: selectedTier } = useParams()
    const checkedStatus = tiersState.findIndex((e) => e.id === (selectedTier as string))

    const { control, handleSubmit, setValue, watch, setError, reset } =
        useForm<ManageLoyaltyTierType>({
            mode: 'all',
            defaultValues: {
                tierColor: '#CD7F32',
            },
            resolver: zodResolver(manageTierSchema),
        })

    const formValues = watch()

    const { data: pointingTier, isLoading: isPointerTierLoading } = useQuery<
        string,
        AxiosError,
        TierType
    >('pointingTier', () => (selectedTier as string) && getLoyaltyTier(selectedTier as string), {
        onSuccess: (data) => {
            if (data) {
                setValue('tierName', data.tierName)
                setValue('tierDetails', data.tierDetails)
                setValue('tierColor', data.tierColor)
                setValue('promotionPercentage', data.promotionPercentage * 100)
                setValue('maxPoints', data.highestPoints)
                setValue('firstTier', isChecked)
            }
        },
    })

    const { mutate: createTeirMu, isLoading: isCreateTierLoading } = useMutation<
        unknown,
        AxiosError,
        ManageLoyaltyTierType
    >((data) => createLoyaltyTier(data), {
        onSuccess: () => {
            queryClient.invalidateQueries('pointingTierList')
            toast.success('Loyalty Tier added successfully')
            navigate('/mobile/offersanddeals/tier')
        },
        onError: (err) => {
            setErrMessage(err.response?.data as IErrorMessage)
        },
    })

    const { mutate: updateTierMu, isLoading: isUpdateTierLoading } = useMutation<
        unknown,
        AxiosError,
        ManageLoyaltyTierType
    >((data) => updateLoyaltyTier(selectedTier as string, data), {
        onSuccess: () => {
            queryClient.invalidateQueries('pointingTierList')
            toast.success('Loyalty Tier updated successfully')
            navigate('/mobile/offersanddeals/tier')
        },
        onError: (err: AxiosError) => {
            setErrMessage(err.response?.data as IErrorMessage)
        },
    })
    const checkName = (name1: string, name2: string): boolean => {
        return name1.toLowerCase().replace(/\s/g, '') === name2.toLowerCase().replace(/\s/g, '')
    }

    const onSubmit = (data: ManageLoyaltyTierType) => {
        if (
            tiersState.find(
                (val) => checkName(val.tierName, data.tierName) && val.id != selectedTier,
            )
        ) {
            return setError('tierName', {
                message: 'The tier name is already in use and unavailable for selection.',
            })
        }
        if (selectedTier && selectedTier.length > 0) {
            updateTierMu({ ...data, promotionPercentage: data.promotionPercentage / 100 })
            return
        }
        createTeirMu({ ...data, promotionPercentage: data.promotionPercentage / 100 })
    }

    useEffect(() => {
        setValue('firstTier', isChecked)
    }, [isChecked])

    useEffect(() => {
        if (errMessage?.message) {
            return setError('maxPoints', { message: errMessage?.message })
        }
    }, [errMessage])

    useEffect(() => {
        return () => {
            queryClient.resetQueries('pointingTier')
        }
    }, [])
    useEffect(() => pointingTierList && setTierAtom(pointingTierList), [pointingTierList])

    return (
        <div>
            <h1 className='text-base text-gray-600 font-bold'>
                <Link
                    className='text-red-800 hover:text-red-600'
                    to={'/mobile/offersanddeals/tier'}
                >
                    Offers And Deals
                </Link>{' '}
                &gt; {pointingTier ? 'Update Tier' : 'Create New Tier'}
            </h1>

            <div className='flex items-center justify-between py-3 mb-5 mt-4 border-b-4 border-slate-400'>
                <h1 className='text-4xl text-[#831843] font-bold text'>
                    {pointingTier ? pointingTier.tierName : 'Add New Tier'}
                </h1>
            </div>

            <div className='h-200 w-100 bg-white shadow-md '>
                <div className='px-5 lg:px-20'>
                    <div className='flex justify-between flex-wrap xl:flex-nowrap border-b-2 border-slate-300 '>
                        <div className='text-black font-bold text-3xl flex w-full '>
                            <div className='pt-7 xl:pt-14 xl:pb-10'>
                                <div>{pointingTier ? 'Update Tier' : 'Create New Tier'}</div>
                            </div>
                        </div>
                        <div className='flex flex-wrap-reverse sm:flex-nowrap mt-5 mb-5 xl:mb-0 xl:mt-12'>
                            <AppButton
                                variant='secondary'
                                type='button'
                                className='min-w-full sm:min-w-[150px] h-[48px] bg-white text-red-900 rounded-md mr-7 border-2 border-red-900'
                                onClick={() => {
                                    navigate(-1)
                                    reset()
                                }}
                            >
                                Cancel
                            </AppButton>
                            <AppButton
                                variant='primary'
                                type='submit'
                                className='min-w-full sm:min-w-[150px] h-[48px] mb-3 sm:mb-0 bg-[#861F41] text-white rounded-md'
                                onClick={handleSubmit(onSubmit)}
                                isLoading={isCreateTierLoading || isUpdateTierLoading}
                                disabled={isCreateTierLoading || isUpdateTierLoading}
                            >
                                Save
                            </AppButton>
                        </div>
                    </div>
                </div>
                {isPointerTierLoading ? (
                    <TierSkeletonLoading />
                ) : (
                    <div className='grid grid-cols-3 w-full gap-5 px-5 lg:px-20 pb-20 pt-10'>
                        <div className='col-span-full xl:col-span-2 flex flex-wrap space-y-7'>
                            <div className='basis-full'>
                                <Controller
                                    control={control}
                                    name='tierName'
                                    render={({
                                        field: { name, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <AppTextBox
                                            type='text'
                                            label='Tier Name'
                                            value={value}
                                            name={name}
                                            labelClassname=' text-xl mb-3'
                                            inputClassname='border rounded border-[#D8D8D8] text-2xl w-full min-h-[50px] px-1 focus:outline-none focus:shadow-outline text-xxs shadow-none'
                                            errorMessage={error?.message}
                                            onChange={(val) => onChange(val)}
                                        />
                                    )}
                                />
                            </div>
                            <div className='basis-full'>
                                <Controller
                                    control={control}
                                    name='tierDetails'
                                    render={({
                                        field: { name, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <AppTextBox
                                            type='text'
                                            label='Description'
                                            value={value}
                                            name={name}
                                            labelClassname=' text-xl mb-3'
                                            inputClassname='border rounded border-[#D8D8D8] text-2xl w-full min-h-[50px] px-1 focus:outline-none focus:shadow-outline text-xxs shadow-none'
                                            errorMessage={error?.message}
                                            onChange={(val) => onChange(val)}
                                        />
                                    )}
                                />
                            </div>
                            <div className='basis-full sm:basis-6/12 sm:pr-4'>
                                <div className='space-y-3 m-0.5'>
                                    <div>
                                        <label className='text-xl text-gray-700'>Theme Color</label>
                                    </div>
                                    <AppColorPicker
                                        name={'tierColor'}
                                        value={{
                                            label: formValues.tierColor,
                                            value: formValues.tierColor,
                                        }}
                                        onChange={(val) => setValue('tierColor', val.value)}
                                        placeHolder='#CD7F32'
                                        className='border  border-[#D8D8D8] text-2xl w-full min-h-[50px] px-1 focus:outline-none focus:shadow-outline text-xxs'
                                    />
                                </div>
                            </div>
                            <div className='basis-full sm:basis-6/12 flex-col'>
                                <div className='mb-2.5'>
                                    <label className='text-xl text-gray-700'>
                                        Points Multiplier
                                    </label>
                                </div>
                                <Controller
                                    control={control}
                                    name='promotionPercentage'
                                    render={({
                                        field: { name, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <div className='flex'>
                                            <div className='mt-1 relative border border-[#D8D8D8]  bg-gray-200 w-12 h-[50px] rounded-tl-md rounded-bl-md flex justify-center'>
                                                <div className='absolute top-3'>
                                                    <span className='mt-3 inline-block -translate-y-1/2 text-gray-400'>
                                                        <BsPercent size={28} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='flex-1'>
                                                <AppTextBox
                                                    type='number'
                                                    value={value && value.toFixed(0)}
                                                    name={name}
                                                    isNumberRestricted
                                                    placeholder='%'
                                                    labelClassname='text-xl mb-3.5'
                                                    inputClassname='w-full border border-[#D8D8D8] rounded-tr-md rounded-br-md text-2xl min-h-[50px] p-1 px-3 focus:outline-none focus:shadow-outline'
                                                    errorMessage={error?.message}
                                                    onChange={(val) => {
                                                        onChange(Number(val))
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                />
                            </div>
                            <div className='basis-full flex flex-col '>
                                <label className='text-xl text-gray-700'>Points to unlock</label>

                                <div className='basis-full flex mt-6'>
                                    <div className='pt-3 pr-3 text-xl text-gray-700 text-center'>
                                        <label>Max: </label>
                                    </div>

                                    <Controller
                                        control={control}
                                        name='maxPoints'
                                        render={({
                                            field: { name, onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <div className='flex'>
                                                <div className='mt-1 relative border border-[#D8D8D8]  bg-gray-200  w-1/5 h-[50px] rounded-tl-md rounded-bl-md flex justify-center'>
                                                    <div className='absolute top-3'>
                                                        <span className=' mt-2.5 inline-block border-2 border-red-500 rounded-full p-1 border-r-2 -translate-y-1/2 text-gray-400'>
                                                            <BsStarFill
                                                                size={20}
                                                                style={{ color: 'red' }}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                                <AppTextBox
                                                    type='number'
                                                    value={value}
                                                    name={name}
                                                    errorMessage={error?.message}
                                                    isNumberRestricted
                                                    onChange={(value) => onChange(Number(value))}
                                                    labelClassname='font-bold text-xl mb-3'
                                                    inputClassname='border border-[#D8D8D8] text-2xl w-full min-h-[50px] focus:outline-none focus:shadow-outline text-xxs shadow-none rounded-none rounded-br-md rounded-tr-md'
                                                    placeholder='| pts'
                                                    disabled={false}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>

                            <div className='basis-full -ml-4'>
                                {checkedStatus !== 0 && (selectedTier as string) && (
                                    <AppCheckbox
                                        className='text-gray-700'
                                        id='first-tier'
                                        label='Make this as the first-tier.'
                                        checked={isChecked}
                                        onChange={() => {
                                            setCheck((prev) => !prev)
                                        }}
                                    />
                                )}
                                {!selectedTier && (
                                    <p className='text-gray-500 text-base ml-4'>
                                        Input the points needed to unlock this Tier
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className='col-span-full xl:col-span-1'>
                            <div>
                                <div className='space-y-2.5 m-0.5'>
                                    <div>
                                        <label className='text-gray-700 text-xl'>
                                            Present Tier Points
                                        </label>
                                    </div>

                                    <div className='h-[350px] w-full flex-1 overflow-y-auto overflow-x-hidden px-2 '>
                                        <div className='space-y-4'>
                                            {tiersState.map((tier) => (
                                                <div key={tier.id} className='h-auto flex'>
                                                    <div
                                                        style={{ backgroundColor: tier.tierColor }}
                                                        className={
                                                            'pl-2 w-3/5 h-[50px] flex items-center text-center text-white text-base border border-gray-300 overflow-hidden no-scrollbar rounded-tl-md rounded-bl-md truncate ...'
                                                        }
                                                    >
                                                        <p className='w-11/12 truncate ...'>
                                                            {tier.tierName}
                                                        </p>
                                                    </div>

                                                    <div className='px-2 w-full h-[50px] flex justify-center items-center  border border-gray-300 rounded-br-md rounded-tr-md'>
                                                        <p className='text-center text-gray-500 text-sm font-semibold'>
                                                            {formatNumber(tier.lowestPoints)} -{' '}
                                                            {formatNumber(tier.highestPoints)}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
export default ManageTier
