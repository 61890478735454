import * as React from 'react'

interface IAppPageNameProps {
    pageName?: string
}

const AppPageName: React.FunctionComponent<IAppPageNameProps> = (props) => {
    return (
        <h1 className='text-[25px] font-bold text-[#751132] border-b-gray-200 border-b w-full pb-2'>
            {props.pageName}
        </h1>
    )
}

export default AppPageName
