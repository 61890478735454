import { errorWhiteSpaces } from 'utils/constants'
import { WhiteSpaceDetect } from 'utils/helpers'
import { z } from 'zod'

export const CreateJobsSchema = z.object({
    jobTitle: z
        .string({ required_error: 'Job title is required.' })
        .min(1, 'Job title is required.')
        .refine((val) => WhiteSpaceDetect(val), {
            message: errorWhiteSpaces,
        }),
    jobState: z
        .string({ required_error: 'Address is required.' })
        .min(1, 'Address is required.')
        .refine((val) => WhiteSpaceDetect(val), {
            message: errorWhiteSpaces,
        }),
    jobDescription: z
        .string({ required_error: 'Description is required.' })
        .min(1, 'Description is required.')
        .refine((val) => WhiteSpaceDetect(val), {
            message: errorWhiteSpaces,
        }),
    jobType: z.string({ required_error: 'This field is required.' }),
    jobEmployment: z.string({ required_error: 'This field is required.' }),
    jobLongDescription: z
        .string({ required_error: 'Job requirements is required.' })
        .min(5, 'Job requirements is required.')
        .refine((val) => WhiteSpaceDetect(val), {
            message: errorWhiteSpaces,
        }),
})

export const UpdateJobsPositionSchema = z.object({
    position: z.number(),
    id: z.string(),
})
export type CreateJobsType = z.infer<typeof CreateJobsSchema>
export type UpdateJobsPosType = z.infer<typeof UpdateJobsPositionSchema>
