import { AppPagination } from 'components/AppPagination'
import { AppTextBox } from 'components/AppTextBox'
import React from 'react'
import { BiSearch } from 'react-icons/bi'
import { useQuery } from 'react-query'
import { getLocationsApi } from 'services/requests/store'
import { debounce } from 'utils/debounce'
import { StoreTable } from './StoreTable'

export const Store = () => {
    const [page, setPage] = React.useState(0)
    const [searchQuery, setSearchQuery] = React.useState<string>('')
    const DebouncedValue = debounce(searchQuery, 200)

    const pageSize = 10

    const {
        data: locationData,
        isLoading: dataLoading,
        refetch,
    } = useQuery(
        ['getLocation', page, pageSize, DebouncedValue],
        () => {
            return getLocationsApi({ keyword: DebouncedValue, page: page, size: pageSize })
        },
        {
            onError: (err) => {
                console.log(err)
            },
        },
    )

    return (
        <div className='space-y-4'>
            <div>
                <h1 className='text-2xl text-pink-900 font-bold'>Stores</h1>
            </div>
            <div className='flex justify-between'>
                <div className='relative flex items-center'>
                    <AppTextBox
                        name='search'
                        type='text'
                        placeholder='Search'
                        containerClassname='w-[40vh]'
                        inputClassname='pl-10'
                        maxLength={50}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e)}
                    />
                    <button
                        type='submit'
                        onClick={() => refetch()}
                        className='absolute bottom-[8px] left-3 '
                    >
                        <BiSearch className='text-xl font-bold text-[#861F41]' />{' '}
                    </button>
                </div>
            </div>

            <StoreTable content={locationData?.content} isLoading={dataLoading} />
            {locationData?.content && locationData?.totalElements > 0 && (
                <AppPagination
                    currentPage={page}
                    onPageChange={setPage}
                    perPage={pageSize}
                    total={locationData?.totalElements}
                />
            )}
            {/* (
                <StoreEmptyState />
            ) */}
        </div>
    )
}
