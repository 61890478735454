import { zodResolver } from '@hookform/resolvers/zod'
import { AxiosError } from 'axios'
import { AppButton } from 'components/AppButton'
import { AppListBox } from 'components/AppListBox'
import AppTextArea from 'components/AppTextArea'
import { AppTextBox } from 'components/AppTextBox'
import { useAtom } from 'jotai'
import { CreateFaqsType, UpdateFaqsSchema } from 'pages/Schemas/faqs'
import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { AiOutlineClose } from 'react-icons/ai'
import { RxPencil1 } from 'react-icons/rx'
import { useMutation, useQuery } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { verifyTokenApi } from 'services/requests/auth'
import { CreateFaqApi, UpdateFaqApi } from 'services/requests/faqs'
import { UpdateFaqType } from 'services/requests/faqs/schema'
import { twMerge } from 'tailwind-merge'
import { FaqEditAtom } from '..'
const AddFaqs: React.FunctionComponent = () => {
    const params = useParams()
    const id: string = params?.id ?? ''
    const [EditFaq] = useAtom(FaqEditAtom)

    const navigate = useNavigate()
    const { data: user } = useQuery(['auth'], () => {
        try {
            return verifyTokenApi()
        } catch (error) {
            console.log(error)
        }
    })

    const { mutate: updateFaqs, isLoading } = useMutation<unknown, AxiosError, UpdateFaqType>(
        (data) => {
            if (id !== '') return UpdateFaqApi(id, data)
            else return CreateFaqApi({ ...data, userId: user?.id ?? '' })
        },
        {
            onSuccess: () => {
                navigate('/web/faqs')
            },
            onError: () => {
                toast.error('Error! Something went wrong.')
            },
        },
    )

    const { control, handleSubmit, reset } = useForm<CreateFaqsType>({
        mode: 'onBlur',
        resolver: zodResolver(UpdateFaqsSchema),
        defaultValues: {
            description: EditFaq?.description ?? '',
            title: EditFaq?.title ?? '',
            status: EditFaq?.status ?? 'ACTIVE',
            visibility: EditFaq?.visibility ?? 'VISIBLE',
        },
    })

    const onSubmit = (data: UpdateFaqType) => {
        updateFaqs(data)
    }

    return (
        <div className='flex flex-col w-full h-full rounded-lg p-5'>
            <div className='inline-flex border-b-2 border-[#D8D8D8] py-4 justify-between'>
                <div className='flex flex-col'>
                    <h1 className='text-base text-gray-600 font-bold'>
                        <Link className='text-red-800 hover:text-red-600' to={'/web/faqs'}>
                            FAQs
                        </Link>{' '}
                        &gt; {id ? 'Update' : 'Add'}
                    </h1>
                    <h1 className='text-[25px] font-bold text-[#861F41]'>
                        Frequently Asked Questions
                    </h1>
                </div>
                <button onClick={() => navigate('/web/faqs')}>
                    <AiOutlineClose className='w-7 h-7 text-[#751132]' />
                </button>
            </div>
            <div className='flex flex-col w-full md:flex-row my-2'>
                <div className='flex flex-col w-full mx-auto gap-2 bg-white px-6 py-8  rounded-sm'>
                    <div className='inline-flex justify-between pb-2 border-b-2 border-[#751132] w-fit relative'>
                        <h1 className='text-[#751132]  font-bold text-[20px]'>
                            {id ? 'Update FAQs' : 'Add FAQs'}
                        </h1>
                        <RxPencil1
                            className={twMerge(
                                id
                                    ? 'absolute -right-5 text-[#861F41] w-[26px] h-[26px] -bottom-1'
                                    : 'hidden',
                            )}
                        />
                    </div>
                    <Controller
                        control={control}
                        name='title'
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <AppTextBox
                                type='text'
                                label={'Title'}
                                value={value}
                                name={name}
                                autoFocus
                                labelClassname='font-bold text-[15px]'
                                inputClassname='border border-[#D8D8D8] w-full min-h-[31px] px-1 focus:outline-none focus:shadow-outline text-xxs shadow-none'
                                onChange={onChange}
                                errorMessage={error?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='description'
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <AppTextArea
                                name={name}
                                label='Content'
                                labelClassname='text-[15px] font-bold'
                                onChange={onChange}
                                inputClassname='border-[#D8D8D8] rounded-none shadow-none'
                                value={value}
                                errorMessage={error?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='status'
                        render={({ field: { onChange, value } }) => (
                            <AppListBox
                                label='Status'
                                className='border-[#D8D8D8] max-w-xs'
                                onChange={onChange}
                                selectedOption={value}
                                labelClassName='font-bold'
                                options={[
                                    { label: 'Active', value: 'ACTIVE' },
                                    {
                                        label: 'Inactive',
                                        value: 'INACTIVE',
                                    },
                                ]}
                            />
                        )}
                    />
                    <div className='flex justify-between  w-fit  ml-auto gap-5 py-6'>
                        <AppButton
                            variant='secondary'
                            type='button'
                            className='w-[105px] rounded-md py-2 px-4'
                            onClick={() => (id ? navigate('/web/faqs') : reset())}
                        >
                            {id ? 'Cancel' : 'Reset'}
                        </AppButton>
                        <AppButton
                            variant='primary'
                            type='submit'
                            isLoading={isLoading}
                            onClick={handleSubmit(onSubmit)}
                            className='w-[105px] rounded-md py-2 px-4'
                        >
                            Save
                        </AppButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddFaqs
