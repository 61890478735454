import axios from 'axios'
import { API_URL } from './api'
import { LoginResponseType } from './requests/auth/schema'

export const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
})

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token')

    if (token) {
        const parseToken = JSON.parse(token) as LoginResponseType
        config.headers.Authorization = `Bearer ${parseToken.accessToken}`
        config.headers.set('X-Bearer-Refresh', parseToken.refreshToken)
    }

    return config
})

axiosInstance.interceptors.response.use((response) => response)
