import { AppCard } from 'components/AppCard'
import { AppSelectMenu } from 'components/AppSelectMenu'
import AppTextArea from 'components/AppTextArea'
import { useAtom } from 'jotai'
import { useState } from 'react'
import { OrderType } from 'services/requests/orders/schema'
import { refundAtom } from 'store/refundAtom'
import { AppSelectOption } from 'types/Commons'
import { numberFormatter } from 'utils'

interface OrderRefundReasonProps {
    orderData: OrderType
}

const OrderRefundReason = ({ orderData }: OrderRefundReasonProps) => {
    const [refundData, setRefundData] = useAtom(refundAtom)
    const [selectedReason, setSelectedReason] = useState('Machine Suspended')

    const totalRefund = orderData.items
        .filter((item) => refundData?.orderLineIds?.includes(item?.id))
        .reduce((acc, price) => acc + price.originalPrice, 0)

    const selectedItemTax = orderData.items
        .filter((item) => refundData?.orderLineIds?.includes(item?.id))
        .reduce((acc, price) => acc + price.originalPrice * orderData.store.tax, 0)

    return (
        <section>
            <AppCard>
                <div className='grid grid-cols-2  divide-x-2 divide-dashed divide-gray-200'>
                    <div className=' col-span-1 mx-7'>
                        <div className='mt-3 border-b border-b-gray-200 pb-4 mb-3'>
                            <div className='flex items-center'>
                                <h1 className='text-gray-800 font-bold'>Reason for refund</h1>
                            </div>
                        </div>
                        <div className=' space-y-3'>
                            <AppSelectMenu
                                classname=' mt--5 bg-white border border-gray-300'
                                name='reason-options'
                                onChange={(val) => {
                                    setSelectedReason(val)
                                    if (val !== 'Other') {
                                        return setRefundData({ ...refundData, reason: val })
                                    }
                                }}
                                options={options}
                            />
                            {selectedReason === 'Other' && (
                                <AppTextArea
                                    name='reason'
                                    onChange={(val) => {
                                        setRefundData({ ...refundData, reason: val })
                                    }}
                                />
                            )}
                        </div>
                    </div>

                    <div className='col-span-1 px-7 pt-11'>
                        <div className='grid grid-cols-2'>
                            <div className='col-span-1'>
                                <div className='flex flex-col space-y-4'>
                                    <label> Taxes & Fees</label>
                                    <label> Total Payment</label>
                                    <label className='font-bold py-4 border-t border-gray-400'>
                                        {' '}
                                        Total Refund
                                    </label>
                                </div>
                            </div>
                            <div className='col-span-1 '>
                                <div className='flex flex-col items-end space-y-4'>
                                    <label>
                                        ${numberFormatter.format(orderData.tax - selectedItemTax)}{' '}
                                        USD
                                    </label>
                                    <label>
                                        {' '}
                                        ${numberFormatter.format(
                                            orderData.subTotal - totalRefund,
                                        )}{' '}
                                        USD
                                    </label>
                                    <label className='font-bold py-4 border-t w-full text-end border-gray-400'>
                                        {' '}
                                        $ {numberFormatter.format(
                                            totalRefund + selectedItemTax,
                                        )}{' '}
                                        USD
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AppCard>
        </section>
    )
}

export default OrderRefundReason

const options: Array<AppSelectOption> = [
    {
        label: 'Machine Suspended',
        value: 'Machine Suspended',
    },
    {
        label: 'Out of Stock',
        value: 'Out of Stock',
    },
    {
        label: 'Other',
        value: 'Other',
    },
]
