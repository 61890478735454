import { AppButton } from 'components/AppButton'
import { AppCard } from 'components/AppCard'
import { BiArrowBack } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { OrderType } from 'services/requests/orders/schema'
import { twMerge } from 'tailwind-merge'
import { convertUnixTimestampThird } from 'utils/time'

interface OrderTitleProps {
    orderData: OrderType
}

export const OrderTitle = (props: OrderTitleProps) => {
    const navigate = useNavigate()
    const refundStatuses = ['REFUND_FAILED', 'REFUND_INITIATED', 'REFUND_COMPLETED']

    const hasNoEdible = props.orderData.items.filter((item) => item.type === 'EDIBLE').length <= 0
    const isAllEdiblesHasRefundStatus = props.orderData.items
        .filter((item) => item.type === 'EDIBLE')
        .every((item) => refundStatuses.includes(item.status))

    const handleRefund = () => {
        navigate('refund')
    }

    const goBack = () => {
        navigate('/order')
    }

    return (
        <AppCard className='space-y-3'>
            <div className='flex items-start justify-between'>
                <div className='flex flex-row items-center'>
                    <div
                        className={twMerge(
                            'bg-white border border-gray-200 mr-4 rounded p-5 w-[10px] h-1 flex justify-center items-center',
                        )}
                    >
                        <button onClick={goBack} className='flex items-center'>
                            <BiArrowBack className='text-2xl text-gray-500' />
                        </button>
                    </div>
                    <div>
                        <h1 className='text-black text-2xl'>
                            Order{' '}
                            <span className='text-red-900'>
                                #{props.orderData.externalId.replace('DT', '')}
                            </span>
                        </h1>
                        <p className='text-gray-700 text-sm'>
                            {convertUnixTimestampThird(props.orderData.orderDate)}{' '}
                            <span className='font-bold capitalize'>
                                &bull; {props.orderData.type}
                            </span>
                        </p>
                    </div>
                </div>
                <div className='space-y-2'>
                    <AppButton
                        type='button'
                        variant='primary'
                        onClick={handleRefund}
                        disabled={
                            props.orderData.status === 'REFUNDED' ||
                            props.orderData.status === 'NONE' ||
                            hasNoEdible ||
                            isAllEdiblesHasRefundStatus
                        }
                    >
                        <div className='flex items-center space-x-2'>
                            <div>
                                {props.orderData.status === 'REFUNDED' ? 'Refunded' : 'Refund'}
                            </div>
                        </div>
                    </AppButton>
                </div>
            </div>
        </AppCard>
    )
}
