import { AxiosError } from 'axios'
import { AppButton } from 'components/AppButton'
import { AppTextBox } from 'components/AppTextBox'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { createGiftCardApi } from 'services/requests/gift-card'

interface AddModalContentProps {
    onClose: () => void
    onCancel: () => void
}

export const AddModalContent = ({ onClose, onCancel }: AddModalContentProps) => {
    const queryClient = useQueryClient()
    const [err, setErr] = useState<string | null>('')
    const { control, handleSubmit } = useForm<{ price: string }>({
        mode: 'onBlur',
        defaultValues: {
            price: '',
        },
    })

    const { mutate: createGiftCardMu, isLoading } = useMutation<unknown, AxiosError, number>(
        (price) => createGiftCardApi(price),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('giftCard')
                onClose()
            },
            onError: () =>
                setErr('It seems that the specified quantity is already present in the table.'),
        },
    )

    const onSubmit = (data: { price: string }) => {
        createGiftCardMu(Number(data.price))
    }

    const handleCancel = () => {
        onCancel()
    }

    return (
        <form className='py-5' onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name='price'
                control={control}
                rules={{
                    required: 'Price is required',
                    pattern: {
                        value: /^(?!0(?:\.0{1,2})?$)(?:0|[1-9]\d*|\d*\.\d{0,2})$/,
                        message: 'Must be a valid number for a price',
                    },
                    validate: (value) => {
                        if (value.startsWith('0.') || value.startsWith('.')) {
                            return 'Invalid value. Please enter a different value.'
                        }

                        // Return undefined if validation passes
                        return undefined
                    },
                }}
                render={({ field, fieldState: { error } }) => (
                    <AppTextBox
                        name={field.name}
                        onBlur={() => setErr(null)}
                        onFocus={() => setErr(null)}
                        type='number'
                        label='Price'
                        placeholder='$10.00'
                        labelPosition='top'
                        value={field.value}
                        onChange={field.onChange}
                        errorMessage={error?.message ?? err ?? ''}
                        errorClassName='text-[12px]'
                    />
                )}
            />

            <div className='flex items-center justify-end mt-5'>
                <AppButton variant='primary' type='submit' isLoading={isLoading}>
                    Add
                </AppButton>

                <AppButton
                    variant='secondary'
                    type='button'
                    onClick={handleCancel}
                    className='ml-2'
                    disabled={isLoading}
                >
                    Cancel
                </AppButton>
            </div>
        </form>
    )
}
