import { AppButton } from 'components/AppButton'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import images from 'utils/images'

const Error: React.FunctionComponent = () => {
    const navigate = useNavigate()
    return (
        <div className='w-full h-screen'>
            <div className='w-full h-screen md:max-w-7xl flex justify-around flex-col md:flex-row items-center mx-auto text-lightGray gap-2 px-4'>
                <div className='flex flex-col justify-center items-center gap-7'>
                    <h1 className='font-semibold text-xl'>Sorry we couldn&apos;t find this page</h1>

                    <p className='text-xxs text-center max-w-[258px]'>
                        But don&apos;t worry, you can find plenty of other things on our home page.
                    </p>
                    <AppButton
                        variant='secondary'
                        type='button'
                        onClick={() => navigate('/order')}
                        className='bg-primary hover:bg-primary hover:bg-opacity-90 text-secondary rounded-md max-w-[160px]'
                    >
                        {'Back to homepage'}
                    </AppButton>
                </div>

                <img
                    src={images.Error404}
                    alt='Image of an yo-kai octo chef'
                    aria-label='Image of an yo-kai octo chef'
                    tabIndex={0}
                    role='image'
                    className='md:max-w-[560px] min-h-[2rem]'
                />
            </div>
        </div>
    )
}

export default Error
