import { AxiosError } from 'axios'
import ConfirmationModal from 'components/ActionConfirmation'
import AppAutoComplete from 'components/AppAutocomplete'
import { AppButton } from 'components/AppButton'
import AppEmptyTable from 'components/AppEmptyTable'
import { AppModal } from 'components/AppModal'
import AppPageName from 'components/AppPageName'
import { AppTextBox } from 'components/AppTextBox'
import Loading from 'components/loading'
import { atom, useAtom } from 'jotai'
import * as React from 'react'
import { toast } from 'react-hot-toast'
import { BiSearch } from 'react-icons/bi'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { DeleteFaqApi, getFaqApi, UpdateFaqApi } from 'services/requests/faqs'
import { FaqObjectType, FaqType, UpdateFaqType } from 'services/requests/faqs/schema'
import { twMerge } from 'tailwind-merge'
import { tableItemStyle, tableWebHeader } from 'utils/styles'
import FaqsCard from './faqsCards'

export const FaqEditAtom = atom<FaqObjectType | null>(null)
const Faqs: React.FunctionComponent = () => {
    const [moved, setMoved] = React.useState<number>(0)
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const [selectedFaq, setSelectedFaq] = React.useState<FaqType | []>([])
    const [isOpenConfirmationModal, setIsOpenConfirmationModal] = React.useState(false)
    const [filter, setFilter] = React.useState({
        type: 'All',
        text: '',
    })

    const handleSelect = (checked: boolean, item: FaqObjectType) => {
        checked
            ? setSelectedFaq((prev) => [...prev, item])
            : setSelectedFaq((prev) =>
                  prev.filter((productsIds) => productsIds.faqId !== item.faqId),
              )
    }
    //

    interface ItableFields {
        label: string
        colspan: string
    }
    const tableFields: ItableFields[] = [
        {
            label: '',
            colspan: '1',
        },
        {
            label: 'Title',
            colspan: '5',
        },
        {
            label: 'Searched',
            colspan: '2',
        },
        {
            label: 'Status',
            colspan: '2',
        },
        {
            label: 'Action',
            colspan: '2',
        },
    ]

    const { data, isLoading, refetch } = useQuery('faq', () =>
        // filter !== '' ? getFaqApiByStatus(filter) : getFaqApi()
        getFaqApi(),
    )

    React.useMemo(() => {
        refetch()
    }, [moved, filter])

    const FaqsArray: FaqType = React.useMemo(
        () =>
            data
                ? data.filter(
                      (faq) =>
                          faq.title.toLowerCase().includes(filter.text.toLowerCase()) &&
                          (faq.status === filter.type || filter.type === 'All'),
                  )
                : [],
        [data, filter.text, filter.type],
    )
    const [, setEditFaq] = useAtom(FaqEditAtom)

    interface IUpdateFaqWithId extends UpdateFaqType {
        id: string
    }
    const { mutate: updateFaqs } = useMutation<unknown, AxiosError, IUpdateFaqWithId>(
        (data) => {
            return UpdateFaqApi(data.id, {
                description: data.description,
                status: data.status,
                title: data.title,
                visibility: data.visibility,
            })
        },
        {
            onSuccess: () => {
                setMoved((prev) => prev + 1)
            },
            onError: (error) => console.log(error),
        },
    )

    const { mutate: deleteFaq, isLoading: deleteLoading } = useMutation<
        unknown,
        AxiosError,
        string
    >((id) => DeleteFaqApi(id), {
        onSuccess: () => {
            queryClient.invalidateQueries('faq')
        },
        onError: (error) => {
            console.log('ERROR', error)
        },
    })
    const handleDeleteBulk = () => {
        if (selectedFaq.length > 0) {
            selectedFaq.forEach(({ faqId }) => deleteFaq(faqId))
            toast.success(`Success! Item${selectedFaq.length > 1 ? 's are' : ''}  deleted`)
            setSelectedFaq([])
            setIsOpenConfirmationModal(false)
        }
    }
    const handleBulkChangeStatus = (status: string) => {
        if (selectedFaq.length > 0) {
            selectedFaq.forEach((item) =>
                updateFaqs({
                    ...item,
                    id: item.faqId,
                    status,
                }),
            )
            toast.success('Success! Status updated.')
            setSelectedFaq([])
        }
    }
    const isSelectedMorethanOne = React.useMemo(() => selectedFaq.length > 1, [selectedFaq])

    if (isLoading) return <Loading />

    return (
        <div className='flex flex-col w-full h-full gap-4 justify-center items-center mx-auto'>
            <div className='inline-flex w-full justify-between'>
                <AppPageName pageName='Frequently Asked Questions' />
            </div>
            <div className='bg-white flex flex-col gap-3 py-5 min-w-full'>
                <div
                    className={twMerge(
                        selectedFaq.length > 0 ? 'flex' : 'hidden',
                        'w-full items-center gap-6 p-6',
                    )}
                >
                    <h1>{`Selected Items(${selectedFaq.length})`}</h1>
                    <AppButton
                        variant='secondary'
                        type='button'
                        onClick={() => setIsOpenConfirmationModal(true)}
                    >
                        Delete Selected
                    </AppButton>
                    <AppButton
                        variant='secondary'
                        type='button'
                        onClick={() => handleBulkChangeStatus('ACTIVE')}
                    >
                        Set Visibility On
                    </AppButton>
                    <AppButton
                        variant='secondary'
                        type='button'
                        onClick={() => handleBulkChangeStatus('INACTIVE')}
                    >
                        Set Visibility Off
                    </AppButton>
                </div>
                <div className='inline-flex w-full justify-between p-6'>
                    <div className='inline-flex justify-center items-center  gap-4'>
                        <div className='relative flex justify-center items-center h-fit p-0 max-w-[283px] border-[1px] border-[#C1C1C1] rounded-[5px]'>
                            <AppTextBox
                                name='search'
                                type='text'
                                inputClassname='px-8 rounded-[5px]'
                                onChange={(value) => setFilter({ ...filter, text: value })}
                                placeholder='Search question'
                                containerClassname='-mt-1 rounded-[5px]'
                            />
                            <BiSearch className=' absolute top-[50%] translate-y-[-50%] left-2 text-[#861F41] w-5 h-5' />
                        </div>
                        <AppAutoComplete
                            list={['All', 'ACTIVE', 'INACTIVE']}
                            name='filter'
                            onChange={(value) => {
                                setFilter({ ...filter, type: value })
                                refetch()
                            }}
                            value={filter.type}
                            placeholder='Status'
                            containerClassname='w-[124px] bg-transparent shadow-none'
                            inputClassname='bg-transparent border-[#C1C1C1] rounded-[5px] capitalize'
                        />
                    </div>
                    <AppButton
                        variant='primary'
                        type='button'
                        className='text-[14px] px-6'
                        onClick={() => {
                            setEditFaq(null)
                            navigate('/web/faqs/add')
                        }}
                    >
                        Add new FAQ
                    </AppButton>
                </div>
                <div className='w-full min-h-[70vh] bg-white text-[15px]'>
                    <div className='grid grid-cols-12 w-full text-center overflow-hidden'>
                        <div className='col-span-1 flex items-center justify-center bg-[#EDDFE2]'>
                            <input
                                type='checkbox'
                                className={
                                    'h-4 w-4 m-auto rounded border-gray-300 accent-[#861F41]'
                                }
                                checked={
                                    FaqsArray.length === selectedFaq.length && FaqsArray.length > 0
                                }
                                onChange={(e) =>
                                    e.target.checked
                                        ? setSelectedFaq(FaqsArray)
                                        : setSelectedFaq([])
                                }
                            />
                        </div>

                        {tableFields.splice(1).map((item, index) => (
                            <h1
                                key={index}
                                className={twMerge(
                                    `${'col-span-' + item?.colspan ?? 5}`,

                                    'h-full text-center bg-[#EDDFE2] min-h-[55px] flex justify-center items-center',
                                    item.label === 'Title' && 'justify-start text-start',
                                    tableWebHeader,
                                )}
                            >
                                {item?.label}
                            </h1>
                        ))}
                        <div className='col-span-12 border-y text-[15px]' />
                        {FaqsArray.length > 0 ? (
                            FaqsArray.sort((a, b) => a.createdAt - b.createdAt).map((item, i) => (
                                <div
                                    className={twMerge(
                                        tableItemStyle(i),
                                        'col-span-12 grid grid-cols-12',
                                    )}
                                    key={item.faqId}
                                >
                                    <FaqsCard
                                        checked={selectedFaq.some(
                                            (faq) => faq.faqId === item.faqId,
                                        )}
                                        item={item}
                                        setMoved={setMoved}
                                        handleSelect={handleSelect}
                                        setSelectedFaq={setSelectedFaq}
                                    />
                                </div>
                            ))
                        ) : (
                            <div className='col-span-12 flex w-full h-full min-h-[25rem] items-center justify-center flex-col gap-4'>
                                <AppEmptyTable isEmptyState={filter.text.length === 0} />
                            </div>
                        )}
                    </div>
                </div>
                <AppModal
                    closeIcon={false}
                    isOpen={isOpenConfirmationModal}
                    onClose={() => setIsOpenConfirmationModal(false)}
                    title=''
                    containerClassName='h-fit p-0 my-0'
                >
                    <ConfirmationModal
                        handle={handleDeleteBulk}
                        onClose={() => setIsOpenConfirmationModal(false)}
                        yesLabel='Delete'
                        noLabel='Cancel'
                        title='Delete Selected'
                        isLoading={deleteLoading}
                        subtitle={`Are you sure you want to delete ${
                            isSelectedMorethanOne ? 'these items' : 'this item'
                        }?`}
                    />
                </AppModal>
            </div>
        </div>
    )
}

export default Faqs
