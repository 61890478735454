import { twMerge } from 'tailwind-merge'
import emptyStateSvg from '../../../assets/images/empty-state-table.svg'

interface IProps {
    title?: string | null
    message?: string | null
    className?: string
}

export const EmptyTableState = ({ title, message, className = '' }: IProps) => {
    return (
        <div>
            <div
                className={twMerge(
                    'mt-8 mb-4 h-full flex justify-center justify-items-center tracking-wider',
                    className,
                )}
            >
                <div>
                    <img
                        alt='empty-state-svg'
                        className='mx-auto align-middle mb-5'
                        src={emptyStateSvg}
                    />
                </div>
            </div>
            <p className='text-xl font-bold text-center text-[#861F41] text-md tracking-wider'>
                {title || 'No results Found.'}
            </p>
            <p className='my-6 px-[30%] text-base text-center text-gray-500 text-md tracking-wider '>
                {message ||
                    'We could not find any search results matching your search. Please try a different keyword or phrasing'}
            </p>
        </div>
    )
}
