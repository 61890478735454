export const getLocalStorageItem = (storageKey: string) => {
    const savedState = localStorage.getItem(storageKey)
    try {
        if (!savedState) {
            return undefined
        }
        return JSON.parse(savedState ?? {})
    } catch (e) {
        return undefined
    }
}
