const AppSkeletonLoadingNews = () => {
    return (
        <div>
            <div className='flex flex-col items-center justify-between animate-pulse'>
                <div className='flex flex-col w-full py-4'>
                    <div className='flex w-full justify-between'>
                        <div className='h-6 w-[65px] bg-slate-200 rounded-md mt-1'></div>
                        <div className='h-12 w-[167px] bg-slate-200 rounded-md'></div>
                    </div>
                    <div className='flex flex-col w-full'>
                        <div className='flex flex-row'>
                            <div className='h-4 w-[50px] bg-slate-200 rounded-md ml-2 mr-9'></div>
                            <div className='h-4 w-[100px] bg-slate-200 rounded-md mr-10'></div>
                            <div className='h-4 w-[50px] bg-slate-200 rounded-md'></div>
                        </div>
                        <div className='mt-8'>
                            <div className='h-9 w-[224px] bg-slate-200 rounded-md'></div>
                        </div>
                    </div>
                </div>
                <div className='mt-9 flow-root w-full'>
                    <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                        <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                            <div className='relative'>
                                <table className='min-w-full bg-slate-50 table-fixed divide-y divide-gray-300 rounded-md'>
                                    <thead>
                                        <tr>
                                            <th
                                                scope='col'
                                                className='w-[64%] py-3.5 pl-[50px] text-left text-sm font-semibold text-gray-900'
                                            >
                                                <div className='h-3 w-[55px] bg-slate-200 rounded-xl'></div>
                                            </th>
                                            <th
                                                scope='col'
                                                className='w-[17%] pl-2.5 py-3.5 text-left text-sm font-semibold text-gray-900'
                                            >
                                                <div className='h-3 w-[55px] bg-slate-200 rounded-xl'></div>
                                            </th>
                                            <th
                                                scope='col'
                                                className='py-3.5 pl-1 text-left text-sm font-semibold text-gray-900'
                                            >
                                                <div className='h-3 w-[75px] bg-slate-200 rounded-xl'></div>
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                                <div className='flex h-[73px] items-center bg-slate-50'>
                                    <div className='flex flex-col w-[80%] py-3.5 pl-[50px]'>
                                        <div className='h-3 w-[150px] bg-slate-200 rounded-xl mb-2'></div>
                                        <div className='h-3 w-[55px] bg-slate-200 rounded-xl'></div>
                                    </div>
                                    <div className='w-[20.5%]'>
                                        <div className='h-3 w-[85px] bg-slate-200 rounded-xl'></div>
                                    </div>
                                    <div className='w-[23.5%]'>
                                        <div className='h-3 w-[130px] bg-slate-200 rounded-xl'></div>
                                    </div>
                                </div>
                                <div className='flex h-[73px] items-center bg-slate-50'>
                                    <div className='flex flex-col w-[80%] py-3.5 pl-[50px]'>
                                        <div className='h-3 w-[150px] bg-slate-200 rounded-xl mb-2'></div>
                                        <div className='h-3 w-[55px] bg-slate-200 rounded-xl'></div>
                                    </div>
                                    <div className='w-[20.5%]'>
                                        <div className='h-3 w-[85px] bg-slate-200 rounded-xl'></div>
                                    </div>
                                    <div className='w-[23.5%]'>
                                        <div className='h-3 w-[130px] bg-slate-200 rounded-xl'></div>
                                    </div>
                                </div>
                                <div className='flex h-[73px] items-center bg-slate-50'>
                                    <div className='flex flex-col w-[80%] py-3.5 pl-[50px]'>
                                        <div className='h-3 w-[150px] bg-slate-200 rounded-xl mb-2'></div>
                                        <div className='h-3 w-[55px] bg-slate-200 rounded-xl'></div>
                                    </div>
                                    <div className='w-[20.5%]'>
                                        <div className='h-3 w-[85px] bg-slate-200 rounded-xl'></div>
                                    </div>
                                    <div className='w-[23.5%]'>
                                        <div className='h-3 w-[130px] bg-slate-200 rounded-xl'></div>
                                    </div>
                                </div>
                                <div className='flex h-[73px] items-center bg-slate-50'>
                                    <div className='flex flex-col w-[80%] py-3.5 pl-[50px]'>
                                        <div className='h-3 w-[150px] bg-slate-200 rounded-xl mb-2'></div>
                                        <div className='h-3 w-[55px] bg-slate-200 rounded-xl'></div>
                                    </div>
                                    <div className='w-[20.5%]'>
                                        <div className='h-3 w-[85px] bg-slate-200 rounded-xl'></div>
                                    </div>
                                    <div className='w-[23.5%]'>
                                        <div className='h-3 w-[130px] bg-slate-200 rounded-xl'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AppSkeletonLoadingNews
