import { zodResolver } from '@hookform/resolvers/zod'
import { AxiosError } from 'axios'
import ConfirmationModal from 'components/ActionConfirmation'
import AppAltTextInput from 'components/AppAltTextInput'
import { AppButton } from 'components/AppButton'
import { AppFileUpload } from 'components/AppFileUpload'
import { AppModal } from 'components/AppModal'
import { AppRichTextBox } from 'components/AppRichTextBox'
import { AppTextBox } from 'components/AppTextBox'
import { useAtom } from 'jotai'
import { CreateHomeContentSchema, CreateHomeContentType } from 'pages/Schemas/home-content'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { BiPhotoAlbum } from 'react-icons/bi'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
    CreateHomeContentApi,
    DeleteHomeContentByIdApi,
    GetHomeContentById,
    UpdateHomeContentApi,
} from 'services/requests/home'
import { HomeContentType } from 'services/requests/home/schema'
import { homeCarouseIdAtom } from 'store/homeAtom'
import { twMerge } from 'tailwind-merge'
import { badRequestError, VisibilityStatus } from 'utils/constants'

const AddCarousel: React.FunctionComponent = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { id } = useParams()
    const [homeId] = useAtom(homeCarouseIdAtom)
    const [content, setContent] = useState<HomeContentType | null>(null)
    const [isOpen, setIsOpen] = useState(false)
    const [badReqError, setBadReqError] = useState<string | null>(null)
    const [loaded, setLoaded] = useState(false)
    const { control, handleSubmit, setValue, watch, clearErrors } = useForm<CreateHomeContentType>({
        mode: 'onBlur',
        resolver: zodResolver(CreateHomeContentSchema),
        defaultValues: {
            buttonLabel: '',
            floatingImage: ' ',
            buttonRedirection: 'menu',
            homeId,
            icon: '',
            image: '',
            position: 100,
            visibility: VisibilityStatus.active,
            imageAltText: '',
        },
    })

    const { data } = useQuery(
        `content${id}`,
        () => GetHomeContentById(id ?? ''),

        {
            cacheTime: 100,
            onSuccess: (data) => {
                if (id && !loaded) {
                    setValue('visibility', data?.visibility)
                    setValue('buttonLabel', data?.buttonLabel)
                    setValue('buttonRedirection', data?.buttonRedirection)
                    setValue('floatingImage', data?.floatingImage ?? '')
                    setValue('position', data?.position)
                    setValue('subtitle', data?.subtitle)
                    setValue('title', data?.title)
                    setValue('icon', data?.icon)
                    setValue('image', data?.image)
                    setValue('externalTitle', data?.externalTitle)
                    setValue('imageAltText', data?.imageAltText)
                    setValue('file', '')
                    setContent(data)
                    setLoaded(true)
                }
            },
        },
    )

    const { mutate: saveContent, isLoading: isContentSaving } = useMutation<
        unknown,
        AxiosError,
        CreateHomeContentType
    >(
        (data) => {
            return id ? UpdateHomeContentApi(id, data) : CreateHomeContentApi(data)
        },
        {
            onSuccess: () => {
                toast.success('Success! Home content saved!', { className: 'mt-10' })
                queryClient.invalidateQueries('content-carousel')
                navigate('/web/home')
            },
            onError: (error) => {
                if (error.code === 'ERR_NETWORK') setBadReqError(badRequestError)
            },
        },
    )

    const onSubmit = (data: CreateHomeContentType) => {
        saveContent(data)
    }

    const isUpdating = useMemo(() => {
        if (!id) return true
        if (id && data && content) return true
        else return false
    }, [data, id, content])

    const { mutate: deleteHomeContent, isLoading: deleteLoading } = useMutation<
        unknown,
        AxiosError,
        string
    >((data) => DeleteHomeContentByIdApi(data), {
        onSuccess: () => {
            toast.success('Success! Home content deleted!')
            setIsOpen(false)
            navigate(-1)
        },
        onError: (error) => console.log(error),
    })

    const FileUpload = useCallback(
        (props: { error?: string }) => {
            return (
                <AppFileUpload
                    onClose={() => setBadReqError(null)}
                    maxSize={25}
                    id={'file-name-'}
                    isDisplayCloseError={!!props.error}
                    errorMessage={props.error ?? badReqError}
                    existingImage={typeof watch().image === 'string' ? watch().image : undefined}
                    onFileChange={(file) => {
                        if (file) {
                            setValue('file', file)
                            clearErrors('file')
                        }
                    }}
                    IconComponent={BiPhotoAlbum}
                    className='h-72 w-full'
                />
            )
        },
        [watch().image, badReqError],
    )

    return (
        <div className='w-full h-full flex flex-col gap-4  p-5'>
            <div className='inline-flex items-center'>
                <h1 className='text-base text-gray-600 font-bold'>
                    <Link className='text-red-800 hover:text-red-600' to={'/web/home'}>
                        Home
                    </Link>{' '}
                    &gt; {'Edit Carousel'}
                </h1>
            </div>
            <section className='w-full flex border-b-2 text-[#861F41] text-[25px]'>
                <h1 className='font-bold'>{id ? 'Update Carousel' : 'Add Carousel'}</h1>
            </section>
            <section className='p-8 flex flex-col shadow-md bg-white gap-6'>
                <AppButton
                    variant='secondary'
                    type='button'
                    className={twMerge(
                        'border-none shadow-none bg-transparent flex w-fit',
                        watch().visibility === VisibilityStatus.inactive && 'text-[#861F41]',
                    )}
                    onClick={() =>
                        setValue(
                            'visibility',
                            watch('visibility') === VisibilityStatus.active
                                ? VisibilityStatus.inactive
                                : VisibilityStatus.active,
                        )
                    }
                >
                    {watch('visibility') === VisibilityStatus.active ? (
                        <div className='flex  items-center'>
                            <span className='mx-2'>Hide in the home page</span>
                            <AiFillEyeInvisible className='w-6 h-6' />
                        </div>
                    ) : (
                        <div className=' flex items-center font-bold'>
                            <span className='mx-2'>Show in the home page</span>{' '}
                            <AiFillEye className='w-6 h-6' />
                        </div>
                    )}
                </AppButton>
                <div className='grid grid-cols-12 gap-6'>
                    <div className='col-span-8 flex flex-col gap-7'>
                        <Controller
                            control={control}
                            name='externalTitle'
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <AppTextBox
                                    label='Banner Name:'
                                    labelClassname='font-bold'
                                    placeholder='Enter name'
                                    type='text'
                                    name={name}
                                    autoFocus
                                    onChange={onChange}
                                    inputClassname='shadow-md'
                                    value={value}
                                    errorMessage={error?.message}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name='title'
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <>
                                    {isUpdating && (
                                        <AppRichTextBox
                                            isHome={true}
                                            name={name}
                                            label='Title:'
                                            labelClassname='text-[14px] col-span-12 font-bold'
                                            onChange={onChange}
                                            value={value}
                                            errorMessage={error?.message}
                                        />
                                    )}
                                </>
                            )}
                        />
                        <Controller
                            control={control}
                            name='subtitle'
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <>
                                    {isUpdating && (
                                        <AppRichTextBox
                                            isHome={true}
                                            name={name}
                                            label='Description:'
                                            labelClassname='text-[14px] col-span-12 font-bold'
                                            onChange={onChange}
                                            value={value}
                                            errorMessage={error?.message}
                                        />
                                    )}
                                </>
                            )}
                        />
                    </div>
                    <div className='col-span-4 font-bold text-sm flex flex-col gap-2'>
                        <h1>Image Preview</h1>
                        <Controller
                            control={control}
                            name='file'
                            render={({ fieldState: { error } }) => (
                                <FileUpload
                                    error={
                                        error?.message === 'Input not instance of File'
                                            ? 'Featured image is required.'
                                            : undefined
                                    }
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name='imageAltText'
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <AppAltTextInput
                                    name={name}
                                    onChange={onChange}
                                    errorMessage={error?.message ?? ''}
                                    value={value}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className='flex mx-auto w-full justify-end items-center gap-6 py-7'>
                    {id && (
                        <AppButton
                            variant='secondary'
                            type='button'
                            className='min-w-[129px] h-[47px] flex justify-center items-center bg-white border-[#861F41] text-[#861F41] p-3 font-bold mr-auto'
                            isLoading={deleteLoading}
                            onClick={() => setIsOpen(true)}
                        >
                            Delete
                        </AppButton>
                    )}
                    <AppButton
                        variant='secondary'
                        className='min-w-[129px] h-[47px] flex justify-center items-center bg-white border-[#861F41] text-[#861F41] p-3 font-bold'
                        type='button'
                        onClick={() => navigate(-1)}
                    >
                        Cancel
                    </AppButton>
                    <AppButton
                        variant='primary'
                        isLoading={isContentSaving}
                        onClick={handleSubmit(onSubmit)}
                        className='min-w-[129px] h-[47px] flex justify-center items-center text-white  bg-[#861F41] p-3 font-bold'
                        type='submit'
                    >
                        Save
                    </AppButton>
                </div>
            </section>
            <AppModal
                closeIcon={false}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title=''
                containerClassName='h-fit p-0 my-0'
            >
                <ConfirmationModal
                    handle={() => deleteHomeContent(id ?? '')}
                    onClose={() => setIsOpen(false)}
                    yesLabel='Delete'
                    noLabel='Cancel'
                    title='Delete Content'
                    isLoading={deleteLoading}
                    subtitle='Are you sure you want to delete this content from the carousel?'
                />
            </AppModal>
        </div>
    )
}

export default AddCarousel
