import { AppSkeletonLoadingRefund } from 'components/AppSkeletonLoaders/AppSkeletonLoadingRefund'
import { useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link, Navigate, useParams } from 'react-router-dom'
import { getOrderById } from 'services/requests/orders'
import { refundAtom, RefundProps } from 'store/refundAtom'
import OrderRefundReason from './OrderRefundReason'
import { OrderSummary } from './OrderSummary'
import { OrderTitle } from './OrderTitle'

export const OrderRefund = () => {
    const setRefundList = useSetAtom(refundAtom)
    const { id } = useParams<{ id: string }>()

    const [onLoad, setOnLoad] = useState(false)

    if (!id) {
        return <Navigate to={'/order'} />
    }

    const { data: orderData, isLoading: orderLoading } = useQuery(
        ['order', id],
        () => getOrderById(id),
        { enabled: !!id },
    )

    useEffect(() => {
        setRefundList({} as RefundProps)
    }, [])

    if (orderLoading) return <AppSkeletonLoadingRefund />

    if (!orderData) return <>no data</>

    if (onLoad) return <>Refunding...</>

    if (orderData?.status === 'REFUNDED') return <Navigate to={`/order/${id}`} />

    return (
        <div className='space-y-4'>
            <h1 className='text-base text-gray-600 font-bold'>
                <Link className='text-red-800 hover:text-red-600' to={'/order'}>
                    Order List
                </Link>{' '}
                &gt;{' '}
                <Link className='text-red-800 hover:text-red-600' to={`/order/${orderData?.id}`}>
                    Order Details
                </Link>{' '}
                &gt; {'Order Refund' + orderData?.externalId?.replace('DT', ' #')}{' '}
            </h1>
            <h1 className='text-red-900 text-3xl font-bold'>Refund</h1>
            <section>
                <div className='mt-10 space-y-2'>
                    <OrderTitle orderData={orderData} onLoad={setOnLoad} />
                    <OrderSummary orderData={orderData} />
                    <OrderRefundReason orderData={orderData} />
                </div>
            </section>
        </div>
    )
}
