import dayjs from 'dayjs'
import { axiosInstance } from 'services/axios'
import { QaSendNotifcationSchemaType } from './schema'

export const qaSendNotification = async (data: QaSendNotifcationSchemaType) => {
    const formData = new FormData()

    formData.append('order_no', data.order_no)
    formData.append('notification_type', data.notification_type)
    formData.append('status', data.status)
    formData.append('app_id', '1')
    formData.append('timestamp', dayjs().unix().toString())

    const response = await axiosInstance.post('/yke/sync', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })

    return response.data
}

export const qaMockPickupOrder = async (pickupCode: string, status: string) => {
    const response = await axiosInstance.post(
        `/yke/mock/pickup?code=${pickupCode}&status=${status}`,
    )

    return response.data
}
