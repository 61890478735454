import { AppAvatar } from 'components/AppAvatar'
import { AppCard } from 'components/AppCard'
import { BiCreditCard, BiPhone } from 'react-icons/bi'
import { MdEmail } from 'react-icons/md'
import { useQuery } from 'react-query'
import { OrderType } from 'services/requests/orders/schema'
import { getUserByIdApi } from 'services/requests/users'
import { twMerge } from 'tailwind-merge'

interface CustomerDetailProps {
    orderData: OrderType
}

export const CustomerDetail = (props: CustomerDetailProps) => {
    const { data: userData } = useQuery(['customer', props.orderData.userId], () =>
        getUserByIdApi(props.orderData.userId),
    )

    return (
        <section className='col-span-full'>
            <AppCard>
                <div className='divide-y divide-gray-200 space-y-4'>
                    <div>
                        <h1 className='text-gray-800 font-bold'>Customer Details</h1>
                    </div>
                    <div className='pt-4'>
                        <div className='grid grid-cols-1 divide-y divide-gray-200 2xl:grid-cols-4 md:divide-x md:divide-y-0 '>
                            <div className='p-2 col-span-full md:p-4 md:col-span-2'>
                                <div className='flex items-center justify-start space-x-4'>
                                    <AppAvatar
                                        name={`${userData?.firstName} ${userData?.lastName}`}
                                        avatar={userData?.userImage}
                                        className='w-36 h-36'
                                    />
                                    <div className=''>
                                        <p className='font-bold text-lg'>
                                            {props.orderData.customerName}
                                        </p>
                                        <p className='font-bold text-base'>{userData?.userType}</p>
                                        <span className='flex items-center text-gray-600 space-x-2'>
                                            <MdEmail className='h-4 w-4' />
                                            <span>{props.orderData.customerEmail}</span>
                                        </span>
                                        <span className='flex items-center text-gray-600 space-x-2'>
                                            <BiPhone className='h-4 w-4' />
                                            <span>{userData?.phone ?? 'N/A'}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='p-2 col-span-full md:p-4 md:col-span-2'>
                                <div className='text-gray-900 text-lg font-bold'>Other Details</div>
                                <div
                                    className={twMerge(
                                        'p-4 grid grid-cols-2',
                                        props.orderData.giftCardCode && 'grid-cols-3',
                                    )}
                                >
                                    {props.orderData.store && (
                                        <div className='col-span-full md:col-span-1'>
                                            <div className='pb-4'>
                                                <h1 className='font-bold'> Location </h1>
                                            </div>
                                            <div className='text-gray-600'>
                                                {props.orderData.store.shopName}
                                            </div>
                                            <div className='text-gray-600'>
                                                {props.orderData.store.shopAddress}
                                            </div>
                                        </div>
                                    )}

                                    <div className='col-span-full md:col-span-1'>
                                        <div className='pb-4'>
                                            <h1 className='font-bold'>Payment Method</h1>
                                        </div>
                                        <div className='flex items-center justify-start text-gray-600 space-x-2'>
                                            <BiCreditCard className='h-8 w-8' />{' '}
                                            <span>{props.orderData.cardType}</span>
                                        </div>
                                        <div className='text-gray-600'>
                                            **** **** **** {props.orderData.cardNumber}
                                        </div>
                                    </div>
                                    {props.orderData.giftCardCode && (
                                        <div className='col-span-full md:col-span-1'>
                                            <div className='pb-4'>
                                                <h1 className='font-bold'> Gift Card Number </h1>
                                            </div>
                                            <div className='text-gray-600'>
                                                **** **** ****{' '}
                                                {props.orderData.giftCardCode.slice(-4)}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AppCard>
        </section>
    )
}
