import { AxiosError } from 'axios'
import { AppButton } from 'components/AppButton'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { deleteGiftCardByIdApi } from 'services/requests/gift-card'

interface DeleteModalContentProps {
    onClose: () => void
    onCancel: () => void
    id: string
}

export const DeleteModalContent = ({ onClose, onCancel, id }: DeleteModalContentProps) => {
    const queryClient = useQueryClient()
    const { handleSubmit } = useForm<{ price: string }>({
        mode: 'onBlur',
        defaultValues: {
            price: '',
        },
    })

    const { mutate: deleteGiftCard, isLoading } = useMutation<unknown, AxiosError, string>(
        (id) => deleteGiftCardByIdApi(id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('giftCard')
                onClose()
            },
            onError: (error) => {
                console.log('ERROR', error)
            },
        },
    )

    const onSubmit = () => {
        deleteGiftCard(id)
    }

    const handleCancel = () => {
        onCancel()
    }

    return (
        <form className='py-5' onSubmit={handleSubmit(onSubmit)}>
            <div className='flex items-center'>
                <p className='text-gray-800 font-semibold text-center'>
                    Are you sure you want to delete this gift card?
                </p>
            </div>
            <div className='flex items-center justify-end mt-5'>
                <AppButton variant='primary' type='submit' isLoading={isLoading}>
                    Delete
                </AppButton>

                <AppButton
                    variant='secondary'
                    type='button'
                    onClick={handleCancel}
                    className='ml-2'
                    disabled={isLoading}
                >
                    Cancel
                </AppButton>
            </div>
        </form>
    )
}
