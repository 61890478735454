import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc' // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

export const convertUnixTimestamp = (timestamp: number) => {
    return dayjs(timestamp).format('MMMM DD YYYY, h:mma')
}

export const convertUnixTimestampSecond = (timestamp: number) => {
    return dayjs(timestamp).format('MMMM DD YYYY, h:mma')
}

export const convertUnixToDate = (timestamp: number) => {
    return dayjs(timestamp).format('MM/DD/YYYY')
}

export const convertUnixTimestampThird = (timestamp: number) => {
    return dayjs(timestamp).format('MMMM DD, YYYY [at] h:mma')
}
