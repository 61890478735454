export const AppSkeletonLoadingGiftCard = () => {
    return (
        <div className='animate-pulse'>
            <div className='h-2 w-[130px] bg-slate-200 rounded-xl'></div>
            <div className='h-6 w-[110px] bg-slate-200 rounded-md mt-5'></div>

            <div className='flex flex-col w-[64%] bg-white h-[509px] mt-5 rounded-md p-10'>
                <div className='h-2 w-[80px] bg-slate-200 rounded-xl mt-2'></div>
                <div className='h-9 w-[142px] bg-slate-200 rounded-md mt-10'></div>
                <div className='h-9 w-[142px] bg-slate-200 rounded-md mt-9'></div>
                <div className='h-9 w-[142px] bg-slate-200 rounded-md mt-9'></div>
                <div className='h-9 w-[142px] bg-slate-200 rounded-md mt-9'></div>
                <div className='h-9 w-[142px] bg-slate-200 rounded-md mt-9'></div>
                <div className='h-8 w-[142px] bg-slate-200 rounded-md mt-4'></div>
            </div>
        </div>
    )
}
