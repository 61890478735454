import z from 'zod'

export const loginSchema = z.object({
    email: z
        .string({ required_error: 'Email Address is required.' })
        .min(5, 'Email Address is required.')
        .max(50, 'Email too long. Limit to 50 characters or less.')
        .email({ message: 'Please enter a valid email address.' }),
    password: z
        .string({ required_error: 'Password is required.' })
        .min(5, 'Password is required.')
        .max(50, 'Password too long. Limit to 50 characters or less.'),
})

export const loginResponseSchema = z.object({
    accessToken: z.string(),
    expiresIn: z.number(),
    refreshExpiresIn: z.number(),
    refreshToken: z.string(),
    tokenType: z.string(),
    verified: z.boolean(),
})

export type LoginType = z.infer<typeof loginSchema>
export type LoginResponseType = z.infer<typeof loginResponseSchema>
