export default [
    {
        featureType: 'all',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'all',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'all',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'all',
        stylers: [
            {
                color: '#faf5ed',
            },
            {
                lightness: '0',
            },
            {
                gamma: '1',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#bae5a6',
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'all',
        stylers: [
            {
                weight: '1.00',
            },
            {
                gamma: '1.8',
            },
            {
                saturation: '0',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'geometry.fill',
        stylers: [
            {
                hue: '#ffb200',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry.fill',
        stylers: [
            {
                lightness: '0',
            },
            {
                gamma: '1',
            },
        ],
    },
    {
        featureType: 'transit.station.airport',
        elementType: 'all',
        stylers: [
            {
                hue: '#b000ff',
            },
            {
                saturation: '23',
            },
            {
                lightness: '-4',
            },
            {
                gamma: '0.80',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            {
                color: '#a0daf2',
            },
        ],
    },
]
