import Background from 'assets/images/background.png'
import OctoChef from 'assets/images/octo-chef.png'
import Header from '../Header'

export const OctoChefPanel = () => {
    return (
        <div className='relative hidden w-0 flex-1 md:block'>
            <Header />
            <div
                className='absolute inset-0 h-full w-full bg-cover bg-local bg-clip-content bg-no-repeat object-fill'
                style={{ backgroundImage: `url(${Background})` }}
            >
                <img className='absolute p-10 h-full w-full object-contain ' src={OctoChef} />
            </div>
        </div>
    )
}
