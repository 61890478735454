import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { ObjectProductTypeWithNoCategory } from 'services/requests/menu-category/schema'

export const initialText = {
    text: '',
}

interface ISearch {
    text: string
}

export const menuObjectProductArrayAtom = atom<ObjectProductTypeWithNoCategory[] | []>([])
export const menuSearchKeyWord = atom<ISearch>(initialText)

export const MenuCategoryPositionAtom = atomWithStorage<number>('highest-category-position', 100)
