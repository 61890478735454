/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { BsFillCircleFill } from 'react-icons/bs'
import { useQuery } from 'react-query'
import { ListGiftcardApi } from 'services/requests/gift-cards-home'
import { ListGiftcardType } from 'services/requests/gift-cards-home/schema'
import { twMerge } from 'tailwind-merge'
import images from 'utils/images'

interface IAppProps {
    duration?: number
    autoScroll?: boolean
}

const GiftCardScroll: React.FunctionComponent<IAppProps> = ({ duration, autoScroll }) => {
    const [active, setActive] = useState<number>(0)
    const [array, setArray] = useState<ListGiftcardType | []>([])
    let timeoutId: ReturnType<typeof setTimeout>
    useQuery(['giftcards'], () => ListGiftcardApi(), {
        onSuccess: (GiftCards) => setArray(GiftCards.sort((a, b) => a.price - b.price)),
    })

    useEffect(() => {
        if (autoScroll)
            timeoutId = setTimeout(() => {
                setActive((active + 1) % array.length)
            }, duration ?? 3000)
        return () => clearTimeout(timeoutId)
    }, [active, array])

    if (!Array.isArray(array)) return <React.Fragment></React.Fragment>
    return (
        <div className='flex flex-col justify-center items-center gap-5 w-full'>
            <div className='flex relative w-full max-w-[25rem]'>
                <img
                    tabIndex={0}
                    role='image'
                    aria-label={`gift card image with value of ${array[active]?.price}`}
                    src={images?.PlainGiftCard}
                    alt='giftcard image'
                    className='w-full'
                />
                <h1
                    tabIndex={0}
                    className='absolute text-white font-bold top-[50%] translate-y-[-50%] right-5 text-xl text-[45px]'
                >
                    <sup>$</sup>
                    {array[active]?.price}
                </h1>
            </div>
            <div className='mx-auto inline-flex gap-4'>
                {array.map((item, index) => (
                    <button tabIndex={0} role='button' key={index} onClick={() => setActive(index)}>
                        <p className='sr-only'>Gift Cards Carousel Buttons</p>
                        <BsFillCircleFill
                            tabIndex={0}
                            role='icon'
                            className={twMerge(
                                'w-3 h-3 text-gray-300 hover:text-primary transition-all hover:scale-105',
                                active === index && 'text-primary scale-105',
                            )}
                        />
                    </button>
                ))}
            </div>
        </div>
    )
}

export default GiftCardScroll
