import { Combobox, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { twMerge } from 'tailwind-merge'

interface OptionsProps<T> {
    name: string
    query: string
    endpoint: () => Promise<T[]>
    getLabel: (option: T) => string
}

export const Options = <T,>(props: OptionsProps<T>) => {
    const [debouncedQuery, setDebouncedQuery] = useState('')

    const {
        data: options,
        isLoading,
        isFetching,
        error,
    } = useQuery<T[]>(['async-' + props.name, debouncedQuery], props.endpoint, {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        console.log('options', options)
    }, [options])

    useEffect(() => {
        console.log('debouncedQuery')
        console.log(debouncedQuery)
    }, [debouncedQuery])

    useEffect(() => {
        const handler = setTimeout(() => setDebouncedQuery(props.query), 500)
        return () => clearTimeout(handler)
    }, [props.query, debouncedQuery])

    if (isLoading || isFetching) return <Combobox.Options>Loading...</Combobox.Options>
    if (error) return <Combobox.Options>Error...</Combobox.Options>

    return (
        <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
            afterLeave={() => setDebouncedQuery('')}
        >
            <Combobox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                {options?.length === 0 && debouncedQuery !== '' ? (
                    <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                        Nothing found.
                    </div>
                ) : (
                    options?.map((option, index) => (
                        <Combobox.Option
                            key={index}
                            value={option}
                            className={({ active }) =>
                                `relative cursor-default select-none py-2 px-2 ${
                                    active ? 'bg-red-900 text-white' : 'text-gray-900'
                                }`
                            }
                        >
                            {({ active }) => (
                                <span
                                    className={twMerge(
                                        'text-sm ',
                                        !active && 'bg-white text-black',
                                    )}
                                >
                                    {props.getLabel(option)}
                                </span>
                            )}
                        </Combobox.Option>
                    ))
                )}
            </Combobox.Options>
        </Transition>
    )
}
