import * as React from 'react'
import images from 'utils/images'

interface IProductDisplayImageProps {
    isGiftCard: boolean
    coverPhoto: string
    price: number
}

const ProductDisplayImage: React.FunctionComponent<IProductDisplayImageProps> = (props) => {
    return (
        <div className='w-fit relative'>
            <img
                src={props?.isGiftCard ? images.PlainGiftCard : props?.coverPhoto}
                className='h-20 w-20 object-contain rounded-lg'
            />
            {props?.isGiftCard && (
                <span className='text-[10px] font-medium text-white absolute right-1 top-[50%] translate-y-[-50%]'>
                    {`$${props?.price}`}
                </span>
            )}
        </div>
    )
}

export default ProductDisplayImage
