import { BiArrowBack } from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
interface AppBackButtonProps {
    className?: string
}

export const AppBackButton = (props: AppBackButtonProps) => {
    const navigate = useNavigate()
    const location = useLocation()
    const goBack = () => {
        if (location.pathname.includes('order') && !location.pathname.includes('refund')) {
            navigate('/order')
        } else {
            navigate(-1)
        }
    }
    return (
        <div
            className={twMerge(
                'bg-white border border-gray-200 rounded p-5 w-[10px] h-1 flex justify-center items-center',
                props.className,
            )}
        >
            <button onClick={goBack} className='flex items-center'>
                <BiArrowBack className='text-2xl text-gray-500' />
            </button>
        </div>
    )
}
