import { AppButton } from 'components/AppButton'
import { AppTextBox } from 'components/AppTextBox'
import React, { useCallback, useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import { AiOutlineClose } from 'react-icons/ai'
// import { components, StylesConfig, ValueContainerProps } from 'react-select'
import { twMerge } from 'tailwind-merge'
interface IAppColorPickerProps {
    className?: string
    name?: string
    value?: MyOption
    disabled?: boolean
    onChange: (value: MyOption) => void
    placeHolder?: string
}

export type MyOption = {
    label: string
    value: string
}
const hexColorPattern = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
const AppColorPicker: React.FunctionComponent<IAppColorPickerProps> = (props) => {
    const [selectedColor, setSelectedColor] = useState(props?.value)
    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)
    const [hexColorInInput, setHexColorInInput] = useState<string>(props.value?.value ?? '')
    const testStringIfHex = (string: string) => hexColorPattern.test(string)
    const handleChange = (newValue: MyOption) => {
        setHexColorInInput(newValue.value)
        if (testStringIfHex(newValue.label) && testStringIfHex(newValue.value)) {
            props.onChange(newValue)
            setSelectedColor(newValue)
        } else {
            console.log('error')
        }
    }

    // const customStyles: StylesConfig = {
    //     control: (provided) => ({
    //         ...provided,
    //         border: '1px solid #ddd',
    //         borderRadius: '4px',
    //     }),
    //     valueContainer: (provided) => ({
    //         ...provided,
    //         display: 'flex',
    //         alignItems: 'center',
    //         height: '43px',
    //         fontSize: '13px',
    //     }),
    // }

    // const CustomValueContainer = useCallback(
    //     (prop: ValueContainerProps) => (
    //         <components.ValueContainer {...prop}>
    //             <div
    //                 style={{ backgroundColor: props.value?.value }}
    //                 className={'flex text-center, mr-1 w-[33px] h-[33px] rounded-lg'}
    //             />
    //             {prop.children}
    //         </components.ValueContainer>
    //     ),
    //     [selectedColor?.label, selectedColor?.value, props.value?.value],
    // )
    const ValueContainer = useCallback(
        () => (
            <div
                onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                className='inline-flex items-center w-full border border-line py-2  rounded-md px-4 gap-2 text-sm cursor-pointer'
            >
                <div
                    style={{ backgroundColor: props.value?.value }}
                    className={
                        'flex text-center, mr-1 w-[33px] h-[33px] rounded-lg shadow-md border'
                    }
                />
                <span>{props.value?.value}</span>
            </div>
        ),
        [selectedColor?.label, selectedColor?.value, props.value?.value, isColorPickerOpen],
    )

    return (
        <div className='relative'>
            <ValueContainer />
            <div
                className={twMerge(
                    isColorPickerOpen ? 'block absolute left-0' : 'hidden',
                    'z-20 w-full',
                )}
            >
                <AppButton
                    onClick={() => setIsColorPickerOpen(false)}
                    type='button'
                    variant='secondary'
                    className='ml-auto p-1 text-xs my-1'
                >
                    <AiOutlineClose />
                </AppButton>
                <div className='shadow-lg bg-white rounded-sm'>
                    <HexColorPicker
                        style={{ width: '100%', height: '150px' }}
                        onChange={(hex) => handleChange({ label: hex, value: hex })}
                        defaultValue={props.value?.value}
                    />
                    <AppTextBox
                        type='text'
                        containerClassname='p-2'
                        labelClassname='text-xs'
                        label='HEX:'
                        errorClassName='text-xs'
                        errorMessage={testStringIfHex(hexColorInInput ?? '') ? '' : 'Invalid Color'}
                        name='hex'
                        value={props.value?.value}
                        onChange={(value) => handleChange({ label: value, value })}
                    />
                </div>
            </div>
        </div>
    )
}

export default AppColorPicker
