import { axiosInstance } from 'services/axios'
import {
    ListPartnershipType,
    ObjectPartnershipType,
    UpdatePartnershipsType,
    UpdatePositionPartnershipType,
} from './schema'

export const listPartnerships = async () => {
    const response = await axiosInstance.get('/partnerships')

    return response.data as ListPartnershipType
}

export const PartnershipsById = async (id: string) => {
    if (id !== '') {
        const response = await axiosInstance.get(`/partnerships/byID?id=${id}`)
        return response.data as ObjectPartnershipType
    }
}

export const DeletePartnershipApi = async (id: string) => {
    const response = await axiosInstance.delete(`/partnerships?id=${id}`)

    return response
}

export const UpdatePartnershipApi = async (id: string, data: UpdatePartnershipsType) => {
    const response = await axiosInstance.put(`/partnerships?id=${id}`, data)

    return response.data as ObjectPartnershipType
}
export const UpdatePartnershipPositionApi = async (data: UpdatePositionPartnershipType) => {
    const response = await axiosInstance.put(`/partnerships?id=${data.id}`, {
        position: data.position,
    })

    return response.data as ObjectPartnershipType
}

export const CreatePartnershipApi = async (data: UpdatePartnershipsType) => {
    const response = await axiosInstance.post('/partnerships', data)

    return response.data as ObjectPartnershipType
}
