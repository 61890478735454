export const StoreHeader = () => {
    return (
        <tr>
            <th scope='col' className='py-3.5 pl-4 pr-3 text-left sm:pl-6'>
                Location
            </th>
            <th scope='col' className='px-3 py-3.5 text-left'>
                Address
            </th>
            <th scope='col' className='text-left'>
                Action
            </th>
        </tr>
    )
}
