import { AppCard } from 'components/AppCard'
import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import { getMenuItemApi, getProductApi } from 'services/requests/store'
import { ProductContent } from './ProductContent'

export const ProductsDetails = () => {
    const params = useParams()
    const { data: product, isLoading: isProductLoading } = useQuery(
        ['getProductApi', params.id],
        () => getProductApi(params.id as string),
    )
    const { data: stores } = useQuery(['getMenuItemApi', params.id], () =>
        getMenuItemApi(params.id as string),
    )

    if (isProductLoading) {
        return <h1 className='text-center'>Loading...</h1>
    }

    return (
        <div className='divide-y divide-gray-300 space-y-4'>
            <section className='space-y-4'>
                <h1 className='text-base text-gray-600 font-bold'>
                    <Link
                        className='text-red-800 hover:text-red-600'
                        to={'/global-settings/products'}
                    >
                        Products
                    </Link>{' '}
                    &gt; {product?.productName}{' '}
                </h1>
                <h1 className='text-3xl text-red-900 font-bold'>{product?.productName}</h1>
            </section>
            <section className='pt-5'>
                <AppCard className='p-10 space-y-8'>
                    <ProductContent product={product} stores={stores} />
                </AppCard>
            </section>
        </div>
    )
}
