export const OffersAndDeals = () => {
    return (
        <div className='space-y-3'>
            {/** TODO create dynamic breadcrumbs */}

            <div className='flex items-center justify-between pb-4'>
                <h1 className='text-2xl text-[#831843] font-bold tracking-wide'>
                    {' '}
                    Loyalty Program Management
                </h1>
            </div>
        </div>
    )
}
