import { AppCard } from 'components/AppCard'
import { LocationType } from 'services/requests/store/schema'
import { numberFormatter } from 'utils'

interface ProductTabContentProps {
    store: LocationType
}

export const ProductTabContent = (props: ProductTabContentProps) => {
    return (
        <AppCard className='px-10'>
            <section>
                <h1 className='text-2xl font-bold'>Products</h1>
            </section>
            <section className='mt-8 flow-root'>
                <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                    <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                        <table className='min-w-full divide-y divide-gray-300 '>
                            <thead>
                                <tr>
                                    <th
                                        scope='col'
                                        className='px-3 py-3.5 text-center text-sm font-bold text-gray-900'
                                    >
                                        Product
                                    </th>
                                    <th
                                        scope='col'
                                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                    >
                                        &nbsp;
                                    </th>
                                    <th
                                        scope='col'
                                        className='px-3 py-3.5 text-left text-sm font-bold text-gray-900'
                                    >
                                        Stock
                                    </th>
                                    <th
                                        scope='col'
                                        className='px-3 py-3.5 text-left text-sm font-bold text-gray-900'
                                    >
                                        Price
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='divide divide-y divide-gray-200'>
                                {props.store.menu.map((item) => (
                                    <tr key={item.id}>
                                        <td className='whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                                            <div className='flex items-center justify-center'>
                                                <img
                                                    src={item.coverPhoto}
                                                    className='h-20 w-20 object-cover rounded-lg'
                                                />
                                            </div>
                                        </td>
                                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                            {item.name}
                                        </td>
                                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                            {item.stockRemain}
                                        </td>
                                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                            ${numberFormatter.format(item.price)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </AppCard>
    )
}
