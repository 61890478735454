import {
    closestCenter,
    DndContext,
    DragEndEvent,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { AxiosError } from 'axios'
import * as React from 'react'
import toast from 'react-hot-toast'
import { useMutation } from 'react-query'
import { UpdatePartnershipPositionApi } from 'services/requests/partnerships'
import {
    ListPartnershipType,
    ObjectPartnershipType,
    UpdatePositionPartnershipType,
} from 'services/requests/partnerships/schema'
import { twMerge } from 'tailwind-merge'
import { tableItemStyle } from 'utils/styles'
import ItemToSort from './item'

interface ISortableTable {
    selectedPartnerships: ListPartnershipType | []
    setMoved: React.Dispatch<React.SetStateAction<number>>
    array: ListPartnershipType
    handleSelect: (e: boolean, item: ObjectPartnershipType) => void
    setSortedData: React.Dispatch<React.SetStateAction<ListPartnershipType | null>>
    setSelectedPartnerships: React.Dispatch<React.SetStateAction<ListPartnershipType | []>>
}

const SortableTable: React.FunctionComponent<ISortableTable> = (props) => {
    const { mutate: updatePartnership } = useMutation<
        unknown,
        AxiosError,
        UpdatePositionPartnershipType
    >((data) => UpdatePartnershipPositionApi(data), {
        onSuccess: () => {
            props?.setMoved((prev) => prev + 1)
        },
        onError: (error) => console.log(error),
    })
    const handleDrag = (event: DragEndEvent) => {
        const { active, over } = event
        if (active.id !== over?.id) {
            const activeIndex = props.array.map((arr) => arr.id).indexOf(active.id.toString())
            const overIndex = props.array.map((arr) => arr.id).indexOf((over?.id ?? '').toString())

            const array = arrayMove(props.array, activeIndex, overIndex)
            props.setSortedData(array)
            array.forEach((element, index) => {
                updatePartnership({ id: element.id, position: index + 1 })
            })
            setTimeout(() => toast.success('Success! Partnership arrangement changed.'), 300)
        }
    }
    const pointerSensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 10,
        },
    })
    const sensors = useSensors(pointerSensor)

    return (
        <DndContext {...sensors} collisionDetection={closestCenter} onDragEnd={handleDrag}>
            <SortableContext items={props.array} strategy={verticalListSortingStrategy}>
                {props.array.map((item, index) => (
                    <div
                        className={twMerge('grid grid-cols-12 col-span-12', tableItemStyle(index))}
                        key={index}
                    >
                        <ItemToSort
                            setMoved={props?.setMoved}
                            checked={props?.selectedPartnerships.some(
                                (partnership) => partnership.id === item.id,
                            )}
                            item={item}
                            id={item.id}
                            handleSelect={props?.handleSelect}
                            key={index}
                            setSelectedPartnerships={props.setSelectedPartnerships}
                        />
                    </div>
                ))}
            </SortableContext>
        </DndContext>
    )
}

export default SortableTable
