import { Combobox } from '@headlessui/react'
import { useEffect, useState } from 'react'
import { BiChevronDown, BiX } from 'react-icons/bi'
import { useQueryClient } from 'react-query'
import { twMerge } from 'tailwind-merge'
import { Options } from './Options'

interface AsyncComboboxProps<T> {
    name: string
    label?: string
    endpoint: () => Promise<T[]>
    getLabel: (option: T) => string
    onChange: (query: string) => void
    getSelectedOption?: (option: T | null) => void
    className?: string
    icon?: JSX.Element
    placeholder?: string
    disabled?: boolean
    clearSelected?: boolean
}

export const AsyncCombobox = <T,>(props: AsyncComboboxProps<T>) => {
    const queryClient = useQueryClient()
    const [selectedOption, setSelectedOption] = useState<T | null>(null)
    const [query, setQuery] = useState('')

    const clearQuery = () => {
        setQuery('')
        setSelectedOption(null)
        props.onChange('')
        props.getSelectedOption && props.getSelectedOption(null)
        queryClient.invalidateQueries(['async-' + props.name, ''])
    }

    useEffect(() => {
        props.onChange(query)
    }, [query])

    useEffect(() => {
        if (props.getSelectedOption) {
            props.getSelectedOption(selectedOption as T)
        }
    }, [selectedOption])

    useEffect(() => {
        if (props.clearSelected) clearQuery()
    }, [props.clearSelected])

    return (
        <div className='relative xl:w-full 2xl:w-96'>
            {props.label && (
                <span className='block text-sm font-medium text-gray-700'>{props.label}</span>
            )}
            <Combobox
                disabled={props?.disabled}
                value={selectedOption}
                onChange={setSelectedOption}
            >
                <div
                    className={twMerge(
                        'relative w-full cursor-default py-2 pl-3 pr-10 border border-gray-200 bg-white overflow-ellipsis',
                    )}
                >
                    {props.icon && (
                        <span className='absolute inset-y-0 left-0 flex items-center px-2'>
                            {props.icon}
                        </span>
                    )}
                    <Combobox.Input
                        onChange={(event) => setQuery(event.target.value)}
                        displayValue={(option) => props.getLabel(option as T)}
                        placeholder={`Please select a ${props.name}`}
                        className={twMerge(
                            'text-left text-xs 2xl:text-sm focus:outline-none overflow-hidden truncate',
                            props.icon && 'pl-4',
                            props.className,
                            'w-full',
                        )}
                    />
                    {selectedOption && (
                        <button
                            className='absolute inset-y-0 right-5 flex items-center pr-2'
                            onClick={clearQuery}
                        >
                            <BiX className='h-5 w-5 text-gray-400 bg-white' aria-hidden='true' />
                        </button>
                    )}
                    <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
                        <BiChevronDown className='h-5 w-5 text-gray-400' aria-hidden='true' />
                    </Combobox.Button>
                </div>

                <Options<T>
                    name={props.name}
                    query={query}
                    endpoint={props.endpoint}
                    getLabel={props.getLabel}
                />
            </Combobox>
        </div>
    )
}
