import { AppButton } from 'components/AppButton'
import { useAtom } from 'jotai'
import { useMemo, useState } from 'react'
import { BiSolidEdit } from 'react-icons/bi'
import { FiTrash } from 'react-icons/fi'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { getPointingTierList } from 'services/requests/account'
import { tierAtom, tiersAtom } from 'store/tierAtom'
import { formatNumber } from 'utils/helper'
import { OffersAndDeals } from '..'
import giftBag from '../../../../assets/images/gift-box.svg'
import { DeleteModal } from './Modals/DeleteModal'

interface TierProps {
    id: string
    tierName: string
    tierColor: string
    lowestPoints: number
    highestPoints: number
    tierDetails: string
    promotionPercentage: number
    whoAdded: unknown
    whoUpdated: unknown
    whenAdded: unknown
    whenUpdated: unknown
}

export const Tier = () => {
    const navigate = useNavigate()

    const [, setTier] = useAtom(tierAtom)
    const [, setTierState] = useAtom(tiersAtom)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [tierId, setTierId] = useState('')

    const { data: pointingTierList } = useQuery<TierProps[]>(
        'pointingTierList',
        getPointingTierList,
    )

    const sortedTiers = pointingTierList?.sort((a, b) => a.lowestPoints - b.lowestPoints) || []
    useMemo(() => pointingTierList && setTierState(pointingTierList), [pointingTierList])

    return (
        <div>
            <OffersAndDeals />
            <div className='flex items-center justify-between w-full pb-3 relative'>
                <hr className='bg-black text-black w-full absolute top-10' />
                <div className='flex text-red-900 text-2xl font-thin'>
                    <button
                        className='z-20 mr-20'
                        onClick={() => navigate('/mobile/offersanddeals')}
                    >
                        <span> Rewards </span>
                    </button>
                    <div className='flex flex-wrap mr-20 '>
                        <span className='ml-2'> Tier </span>
                        <div className='w-full bg-red-900 z-10 py-1.5 ml-1 rounded-full'></div>
                    </div>
                </div>
            </div>

            <div className='flex-1 h-full bg-white shadow-md rounded-md border-[1px] border-gray-300 overflow-y-auto no-scrollbar'>
                <div className='flex w-full lg:w-auto justify-between flex-wrap lg:flex-nowrap'>
                    <div className='text-red-900 font-bold text-2xl flex'>
                        <div className='p-5 pt-5'>
                            <div>List of Tiers</div>
                            <div className='mt-1 font-normal text-base text-gray-400 '>
                                Upload more rewards to increase orders
                            </div>
                        </div>
                    </div>
                    <div className='flex p-5 pt-7'>
                        <AppButton
                            variant='secondary'
                            type='button'
                            className='min-w-[166px] h-[48px] bg-[#861F41] text-white rounded-md'
                            onClick={() => navigate('/mobile/offersanddeals/tier/manageTier')}
                        >
                            + Add New Tier
                        </AppButton>
                    </div>
                </div>

                <div className='grid grid-cols-2 gap-8 px-5 2xl:px-14 mb-9'>
                    {sortedTiers.map((tier: TierProps, index: number) => (
                        <div key={index} className='xl:pr-10  col-span-2 2xl:col-span-1'>
                            <div
                                className='text-white h-[250px] border border-gray-300 rounded-xl relative'
                                style={{ backgroundColor: tier.tierColor }}
                            >
                                <div className='space-y-4 py-6 pl-6 pr-0 max-w-[380px] flex flex-col justify-between h-full'>
                                    <div>
                                        <p className='  text-3xl font-bold'>{tier.tierName}</p>
                                        <p className='text-normal font-bold pb-4'>
                                            {formatNumber(tier.lowestPoints)}{' '}
                                            {tier.highestPoints === 999999
                                                ? ' Plus'
                                                : `${'- ' + formatNumber(tier.highestPoints)}`}{' '}
                                            Points
                                        </p>
                                        {tier.highestPoints >= 999999 ? (
                                            <p className=' text-sm font-normal'>
                                                Congratulations! You reached the maximum tier.
                                            </p>
                                        ) : (
                                            <p className='text-sm font-normal whitespace-normal max-w-[280px]'>
                                                {index === sortedTiers.length - 1
                                                    ? `Spend a minimum of ${formatNumber(
                                                          tier.lowestPoints,
                                                      )} up to a maximum of ${formatNumber(
                                                          tier.highestPoints,
                                                      )} to access or qualify for this tier.`
                                                    : `Spend More Than ${formatNumber(
                                                          tier.highestPoints,
                                                      )} to upgrade to the next tier.`}
                                            </p>
                                        )}
                                    </div>
                                    <div className='flex justify-between items-center'>
                                        <div className='flex items-center'>
                                            {/* <div className='pr-8'>
                                                    <div className='pb-1'>
                                                        <BsEye size={24} color='white' />
                                                    </div>
                                                    <p className='text-xs'>Hide</p>
                                                </div> */}
                                            <div
                                                className='pr-8 cursor-pointer'
                                                onClick={() => {
                                                    navigate(
                                                        `/mobile/offersanddeals/tier/manageTier/${tier.id}`,
                                                    )
                                                }}
                                            >
                                                <div className='pb-1.5'>
                                                    <BiSolidEdit size={24} color='white' />
                                                </div>

                                                <p className='text-xs ml-0.5'>Edit</p>
                                            </div>
                                            <div
                                                className='pr-8 cursor-pointer'
                                                onClick={() => {
                                                    setDeleteModalOpen(true)
                                                    setTierId(tier.id)
                                                }}
                                            >
                                                <div className='pb-1.5 ml-1'>
                                                    <FiTrash size={24} color='white' />
                                                </div>
                                                <p className='text-xs'>Delete</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <img
                                    src={giftBag}
                                    alt='Gift Background'
                                    className='absolute top-0 right-0 h-full w-[50%] py-5 object-contain'
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <DeleteModal onClose={setDeleteModalOpen} isOpen={deleteModalOpen} id={tierId} />
        </div>
    )
}
