import { AxiosError } from 'axios'
import { AppButton } from 'components/AppButton'
import { AppModal } from 'components/AppModal'
import { AppPagination } from 'components/AppPagination'
import { AppTextBox } from 'components/AppTextBox'
import { atom, useAtom } from 'jotai'
import { UserTable } from 'pages/GlobalSettings/UserManagement/UserTable'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { BiSearch } from 'react-icons/bi'
import { FaCircle } from 'react-icons/fa'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { UserType } from 'services/requests/user/schema'
import { getAllUsersApi, SearchUserApi, UpdateUserStatusApi } from 'services/requests/users/index'
import { UserResponseType } from 'services/requests/users/schema'
import { debounce } from 'utils/debounce'

export const selectedUsers = atom<UserResponseType[] | []>([])
export const searchMode = atom<boolean>(false)

export const UserManagement = () => {
    const [selected, setSelected] = useAtom(selectedUsers)
    const [, setIsSearching] = useAtom(searchMode)
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [page, setPage] = React.useState(0)
    const DebouncedValue = debounce(searchQuery, 500)
    const navigate = useNavigate()

    const pageSize = 10
    const sort = 'whenAdded,desc'

    const { data, isLoading, refetch } = useQuery(
        ['getAllUsersApi', DebouncedValue, page, pageSize],
        () => {
            if (searchQuery) {
                setIsSearching(true)
                return SearchUserApi(DebouncedValue, { page: page, size: pageSize, sort })
            } else {
                setIsSearching(false)
                return getAllUsersApi({ page: page, size: pageSize, sort: sort })
            }
        },
        {
            onError: (error: AxiosError) => {
                console.log('error: ', error.response?.data)
            },
        },
    )

    const { mutate: updateUserStatus } = useMutation<unknown, AxiosError, UserType>(
        (data) => {
            return UpdateUserStatusApi(data.id, data.status)
        },
        {
            onSuccess: () => {
                setSelected([])
                refetch()
            },
            onError: (error: AxiosError) => {
                console.log(error.message)
            },
        },
    )

    const [inactiveAccConfirmation, setInactiveAccConfirmation] = useState(false)

    const handleInactiveAccConfirmation = () => {
        setInactiveAccConfirmation(true)
    }

    const handleCloseInactiveAccConfirmation = () => {
        setInactiveAccConfirmation(false)
    }

    const handleUpdateMultiple = (status: string) => {
        selected.forEach((user) => {
            const userCopy = { ...user, status: status }
            updateUserStatus(userCopy)
        })

        if (status === 'INACTIVE') {
            toast.success(
                <div>
                    <p className='font-bold mb-1'>Account deactivated!</p>
                    <p>Account has been successfully deactivated</p>
                </div>,
                { className: 'mt-10' },
            )
        }
        setInactiveAccConfirmation(false)
    }

    const tableHead = [
        'First Name',
        'Last Name',
        'Email Address',
        'Date Created',
        'User Status',
        'Action',
    ]

    React.useEffect(() => {
        setSelected([])
    }, [page])

    return (
        <div className='space-y-4 '>
            <div className='text-2xl font-bold  text-[#861f41] '> User Management</div>
            <hr></hr>
            <div>
                <div className='sm:flex sm:justify-between items-center px-4'>
                    <div className='relative flex items-center'>
                        <AppTextBox
                            name='search'
                            type='text'
                            placeholder='Search'
                            containerClassname='w-[40vh]'
                            inputClassname='pl-10'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e)}
                        />
                        <button
                            onClick={() => refetch()}
                            className='absolute bottom-[8px] left-3 '
                            type='submit'
                        >
                            <BiSearch className='text-xl font-bold text-[#861F41]' />{' '}
                        </button>
                    </div>
                    <div>
                        {selected?.length !== 0 && (
                            <div className='pl-4 flex items-center gap-2'>
                                <p className='pr-3 text-[12px]'>Mark selected as : </p>
                                <div className='flex gap-2 w-[40vh]'>
                                    <AppButton
                                        variant='primary'
                                        type='button'
                                        onClick={() => handleUpdateMultiple('ACTIVE')}
                                    >
                                        Active
                                        <FaCircle className='text-[9px] ml-3 text-green-700' />
                                    </AppButton>
                                    <AppButton
                                        variant='secondary'
                                        type='button'
                                        onClick={handleInactiveAccConfirmation}
                                    >
                                        Inactive
                                        <FaCircle className='text-[9px] ml-3 text-red-700' />
                                    </AppButton>
                                </div>
                            </div>
                        )}
                        {
                            <AppModal
                                isOpen={inactiveAccConfirmation}
                                title={''}
                                onClose={handleCloseInactiveAccConfirmation}
                                containerClassName='rounded-lg'
                            >
                                <div className='w-[500px] px-10 pb-6'>
                                    <p className='text-2xl text-center font-bold text-[#861f41]'>
                                        Account Deactivation
                                    </p>
                                    <p className='py-10 pb-10'>
                                        Deactivating the user&apos;s account will restrict their
                                        access to the application. Are you sure you want to
                                        continue?
                                    </p>

                                    <div className='flex justify-between space-x-2'>
                                        <AppButton
                                            variant='secondary'
                                            type='button'
                                            onClick={handleCloseInactiveAccConfirmation}
                                            className='text-base border border-2 rounded-xl px-14'
                                        >
                                            Cancel
                                        </AppButton>
                                        <AppButton
                                            variant='primary'
                                            type='button'
                                            onClick={() => handleUpdateMultiple('INACTIVE')}
                                            className='text-base border border-2 rounded-xl px-14'
                                        >
                                            Deactivate
                                        </AppButton>
                                    </div>
                                </div>
                            </AppModal>
                        }
                    </div>
                </div>

                <div className='mt-4 flow-root '>
                    <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 '>
                        <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 '>
                            <div className='px-3 flex flex-col gap-2'>
                                <UserTable
                                    isLoading={isLoading}
                                    tableHead={tableHead}
                                    users={data?.content || []}
                                />

                                {isLoading ||
                                    (data?.totalElements !== 0 && (
                                        <AppPagination
                                            currentPage={page}
                                            onPageChange={setPage}
                                            perPage={pageSize}
                                            total={data?.totalElements ?? 0}
                                        />
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
