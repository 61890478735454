import { AppButton } from 'components/AppButton'
import { twMerge } from 'tailwind-merge'

interface IProps {
    imageUrl: string | File | null | undefined
    altText: string | undefined | null
    setImageUploadOpen: (value: React.SetStateAction<boolean>) => void
}
const EditImage = ({ altText, imageUrl, setImageUploadOpen }: IProps) => {
    return (
        <div className='flex flex-col w-full h-full gap-2 relative'>
            <div
                className={twMerge(
                    'mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 relative hover:cursor-pointer ',
                    imageUrl && 'p-0 border-none group',
                )}
                onClick={() => setImageUploadOpen(true)}
            >
                {imageUrl ? (
                    <>
                        <img
                            src={
                                typeof imageUrl === 'string'
                                    ? imageUrl
                                    : URL.createObjectURL(imageUrl)
                            }
                            alt={altText != null ? altText : undefined}
                            className='object-contain w-full h-full'
                        />

                        <div className='absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50 flex items-center justify-center text-white text-lg font-bold opacity-0 group-hover:opacity-100 transition-all duration-300'>
                            <AppButton
                                variant='secondary'
                                className='p-4  border-white text-white bg-transparent hover:bg-transparent outline-none'
                                type={'button'}
                            >
                                {' '}
                                Edit Image
                            </AppButton>
                        </div>
                    </>
                ) : (
                    <div className='-my-3 h-[250px] w-full bg-black bg-opacity-25 flex items-center justify-center text-white text-lg font-bold hover:bg-opacity-40 transition-all duration-300 rounded-md'>
                        <AppButton
                            variant='secondary'
                            className='p-4  border-white text-white bg-transparent hover:bg-transparent  outline-none'
                            type={'button'}
                        >
                            {' '}
                            Edit Image
                        </AppButton>
                    </div>
                )}
            </div>
        </div>
    )
}
export default EditImage
