import React, { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

interface AppButtonProps {
    children: React.ReactNode
    variant: 'primary' | 'secondary'
    className?: string
    onClick?: () => void
    disabled?: boolean
    isLoading?: boolean
    type: 'button' | 'submit' | 'reset'
}

const variantSecondary =
    'border border-red-900 bg-white text-red-900 hover:bg-red-900 hover:text-white'

export const AppButton = forwardRef<HTMLButtonElement, AppButtonProps>((props, ref) => {
    return (
        <button
            ref={ref}
            type={props.type}
            // ref={ref}
            className={twMerge(
                'flex flex-row items-center justify-center px-3 py-1 rounded border border-red-900',
                'bg-red-900 text-white font-bold disabled:bg-gray-400 disabled:cursor-not-allowed disabled:border-gray-400 disabled:text-white',
                'hover:bg-red-700  transition-all duration-300',
                props.variant !== 'primary' && variantSecondary,
                props.className,
            )}
            disabled={props.disabled}
            onClick={props.onClick}
        >
            <div className='flex flex-row items-center justify-center'>
                {props.isLoading && (
                    <svg
                        className='animate-spin h-5 w-5 mr-3 text-white-500'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                    >
                        <circle
                            className='opacity-25'
                            cx='12'
                            cy='12'
                            r='10'
                            stroke='currentColor'
                            strokeWidth='4'
                        ></circle>
                        <path
                            className='opacity-75'
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8v1a7 7 0 00-7 7h1z'
                        ></path>
                    </svg>
                )}
                {props.children}
            </div>
        </button>
    )
})

AppButton.displayName = 'AppButton'
