import * as React from 'react'
import { twMerge } from 'tailwind-merge'

interface IAppTextAreaProps {
    name?: string
    disabled?: boolean
    value?: string
    onChange?: (value: string) => void
    placeholder?: string
    label?: string
    labelPosition?: 'top' | 'left'
    containerClassname?: string
    inputContainerClassname?: string
    inputClassname?: string
    labelClassname?: string
    errorMessage?: string
    cols?: number
    errorClassName?: string
    maxLength?: number
    counter?: boolean
    labelHelper?: string
}

const AppTextArea: React.FunctionComponent<IAppTextAreaProps> = (props) => {
    const [value, setValue] = React.useState<string>(props.value || '')

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(event.target.value)
        props.onChange && props.onChange(event.target.value)
    }

    React.useEffect(() => {
        setValue(props.value || '')
    }, [props.value])

    return (
        <div>
            {props.label && (
                <label
                    htmlFor={props.name}
                    className={twMerge(
                        'block text-sm font-medium text-gray-700',
                        props.labelClassname,
                    )}
                    id={props.name}
                >
                    {props.label}
                    <span className='text-gray-500'>{props?.labelHelper ?? ''}</span>
                </label>
            )}
            <textarea
                tabIndex={0}
                id={props.name}
                name={props.name}
                autoComplete={props.name}
                value={value}
                placeholder={props.placeholder}
                disabled={props.disabled}
                onChange={handleChange}
                cols={props.cols}
                maxLength={props.maxLength}
                className={twMerge(
                    'h-20 block resize-none w-full p-2 pb-10  border border-gray-100 rounded shadow focus:outline-none focus:shadow-outline text-xs',
                    props.inputClassname,
                )}
            />

            <div
                className={twMerge(
                    props?.counter
                        ? 'flex flex-row justify-between w-full  absolute bottom-1 translate-y-[3px]'
                        : 'hidden',
                )}
            >
                <div className='flex flex-row justify-between mx-auto w-[99%] items-center bg-secondary px-2 pt-1'>
                    {' '}
                    <p tabIndex={0} className=' text-[10px] text-gray-500'>{`${
                        value?.length ?? 0
                    } / 500`}</p>
                </div>
            </div>
            {props.errorMessage && (
                <p
                    className={twMerge('text-xxs text-red-500 text-sm', props?.errorClassName)}
                    id={`${name}-error`}
                >
                    {props.errorMessage}
                </p>
            )}
        </div>
    )
}

export default AppTextArea
