import { AppButton } from 'components/AppButton'
import { EmptyTableState } from 'components/EmptyState/table-empty-state'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { ObjectProductTypeWithNoCategory } from 'services/requests/menu-category/schema'
import { menuSearchKeyWord } from 'store/productAtom'
import { twMerge } from 'tailwind-merge'
import { UseTruncate } from 'utils/constants'

interface TableContentProps {
    products: ObjectProductTypeWithNoCategory[] | undefined
}

export const TableContent = (props: TableContentProps) => {
    const navigate = useNavigate()
    const [keyword, setKeyword] = useAtom(menuSearchKeyWord)
    const handleNavigate = (id: string) => {
        navigate(`product/${id}`)
    }

    return (
        <>
            <div className='mt-4 flex text-[14px] text-[#861F41] bg-[#E3D5DA] font-bold p-4 rounded-t-lg items-center '>
                <h1 className='w-[45%]'>Product Name</h1>
                <h1 className='w-[35%]'>Description</h1>
                <h1 className='w-[30%] text-center'>Action</h1>
            </div>
            {props.products?.length === 0 && (
                <div className='my-16'>
                    <EmptyTableState
                        title={'No Data Available.'}
                        message={
                            'Currently no data available, try refreshing the page to make sure it loads properly'
                        }
                    />
                </div>
            )}
            {!!props.products?.length &&
                !props.products?.some((product) =>
                    product.productName
                        .toLocaleLowerCase()
                        .includes(keyword.text.toLocaleLowerCase()),
                ) && (
                    <div className='my-16'>
                        <EmptyTableState
                            title={'No Results Found.'}
                            message={
                                'We could not find any search results matching your search. Please try a different keyword or phrasing'
                            }
                        />
                    </div>
                )}
            {!!props.products?.length && (
                <>
                    {props.products
                        ?.filter((product) =>
                            product?.productName.toLowerCase().includes(keyword.text.toLowerCase()),
                        )
                        .map((item, index) => (
                            <div
                                className={twMerge(
                                    'inline-flex items-center w-full px-2 py-2 border',
                                    index % 2 === 0 ? 'bg-gray-100' : 'bg-white',
                                )}
                                key={item.id}
                            >
                                <div className='inline-flex items-center w-[45%] max-h-[85px] overflow-hidden'>
                                    <img
                                        className='w-[4rem] h-[4rem] object-cover rounded-full mx-9'
                                        src={item?.coverPhoto}
                                        alt=''
                                    />
                                    <div className='max-h-[65px] w-full overflow-hidden'>
                                        <h1 className='text-[14px] font-bold'>
                                            {item?.productName}
                                        </h1>
                                    </div>
                                </div>
                                <div className='w-[35%] max-h-[65px] overflow-hidden'>
                                    <h1 style={UseTruncate(2)} className='text-[12px]'>
                                        {`Description: ${item?.description}`}
                                    </h1>
                                </div>
                                <div className='w-[30%] flex text-center content-center justify-center'>
                                    <AppButton
                                        className='text-[#861F41] text-center justify-center rounded-lg'
                                        onClick={() => handleNavigate(item.id)}
                                        variant='secondary'
                                        type='button'
                                    >
                                        View
                                    </AppButton>
                                </div>
                            </div>
                        ))}
                </>
            )}
        </>
    )
}
