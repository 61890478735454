import { RouteObject } from 'react-router-dom'
import { Login } from '../../pages/Login'
import Container from './Container'

export const PublicRoutes: RouteObject = {
    element: <Container />,
    children: [
        {
            path: 'login',
            element: <Login />,
        },
        {
            path: 'forgot-password',
            element: <h1 className='text-3xl font-bold underline'>Register</h1>,
        },
    ],
}
