import { errorWhiteSpaces } from 'utils/constants'
import { WhiteSpaceDetect } from 'utils/helpers'
import { z } from 'zod'

export const CreateHomeContentSchema = z.object({
    homeId: z.string(),
    title: z
        .string({ required_error: 'Title is required.' })
        .min(1, 'Title is required.')
        .refine((val) => WhiteSpaceDetect(val), {
            message: errorWhiteSpaces,
        }),
    subtitle: z
        .string({ required_error: 'Description is required.' })
        .min(1, 'Description is required.')
        .refine((val) => WhiteSpaceDetect(val), {
            message: errorWhiteSpaces,
        }),
    buttonLabel: z.string(),
    image: z.string({ required_error: 'Image is required' }),
    icon: z.string(),
    floatingImage: z.string(),
    buttonRedirection: z.string(),
    visibility: z.string(),
    position: z.number(),
    externalTitle: z
        .string({ required_error: 'Banner name is required.' })
        .min(1, 'Banner name is required.')
        .refine((val) => WhiteSpaceDetect(val), {
            message: errorWhiteSpaces,
        }),
    file: z.instanceof(File).or(z.string({ required_error: 'Image is required.' })),
    imageAltText: z
        .string({ required_error: 'Image alt text is required.' })
        .min(1, 'Image alt text is required.')
        .refine((val) => WhiteSpaceDetect(val), {
            message: errorWhiteSpaces,
        }),
})

export const UpdateHomeContentSchema = z.object({
    homeId: z.string().optional(),
    title: z.string().optional(),
    subtitle: z.string().optional(),
    buttonLabel: z.string().optional(),
    image: z.string().optional(),
    icon: z.string().optional(),
    floatingImage: z.string().optional(),
    buttonRedirection: z.string().optional(),
    visibility: z.string().optional(),
    position: z.number().optional(),
    externalTitle: z.string().optional(),
    file: z.any(),
    imageAltText: z.string(),
})

export type CreateHomeContentType = z.infer<typeof CreateHomeContentSchema>
export type UpdateHomeContentType = z.infer<typeof UpdateHomeContentSchema>
export interface UpdateOptionalContentType extends CreateHomeContentType {
    type: string
}
