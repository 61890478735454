import { z } from 'zod'

const today = new Date()

export const UserSchema = z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    userType: z.string(),
    fromPlatform: z.string(),
    isGeneratedPassword: z.null(),
    phone: z.null(),
    gender: z.null(),
    birthday: z.null(),
    status: z.string(),
    createdAt: z.number(),
    updatedAt: z.number(),
    userImage: z.string(),
    whenAdded: z.number(),
})

export const PageListUserSchema = z.object({
    content: z.array(UserSchema),
    pageable: z.object({
        sort: z.object({
            empty: z.boolean(),
            sorted: z.boolean(),
            unsorted: z.boolean(),
        }),
        offset: z.number(),
        pageNumber: z.number(),
        pageSize: z.number(),
        unpaged: z.boolean(),
        paged: z.boolean(),
    }),
    last: z.boolean(),
    totalElements: z.number(),
    totalPages: z.number(),
    size: z.number(),
    number: z.number(),
    sort: z.object({
        empty: z.boolean(),
        sorted: z.boolean(),
        unsorted: z.boolean(),
    }),
    first: z.boolean(),
    numberOfElements: z.number(),
    empty: z.boolean(),
})

///^[a-zA-Z][a-zA-Z0-9. -]*[a-zA-Z0-9]$/ - RegEx for - only starts with letters, hyphens and periods are not allowed in the beginning and ending of string
export const CreateUserSchema = z.object({
    firstName: z
        .string({ required_error: 'First name is required.' })
        .min(1, 'First name is Required.')
        .max(50, 'First name too long. Limit to 50 characters or less.')
        .regex(/^[a-zA-Z0-9. -]*$/, {
            message: 'This field only accepts letters, numbers, short hyphens, periods and spaces.',
        }),
    lastName: z
        .string({ required_error: 'Last name is required.' })
        .min(1, 'Last name is required.')
        .max(50, 'Last name too long. Limit to 50 characters or less.')
        .regex(/^[a-zA-Z0-9. -]*$/, {
            message: 'This field only accepts letters, numbers, short hyphens, periods and spaces.',
        }),
    email: z
        .string({ required_error: 'Email Address is required.' })
        .min(5, 'Email should be longer, at least 5 character')
        .max(50, 'Email too long. Limit to 50 characters or less.')
        .email({ message: 'Please enter a valid email address.' }),
    birthday: z
        .date({
            invalid_type_error: 'Please enter a valid birthdate.',
            required_error: 'This field is required.',
        })
        .max(today, { message: 'Please enter a valid birthdate.' })
        .optional()
        .nullable(),
    gender: z.string().optional(),
    phone: z
        .string({ required_error: 'This field is Required' })
        .regex(/^\d*$/, { message: 'Phone number must contain only digits' })
        .refine((value) => value === '' || value.length === 10, {
            message: 'Phone number must be 10 digits or empty',
        })
        .optional(),
    userType: z.string().or(z.number()),
})

export const UpdateUserSchema = z.object({
    firstName: z
        .string({ required_error: 'First name is Required.' })
        .min(1, 'First name is Required.')
        .max(50, 'First name too long. Limit to 50 characters or less.')
        .regex(/^[a-zA-Z0-9. -]*$/, {
            message: 'This field only accepts letters, numbers, short hyphens, periods and spaces.',
        }),
    lastName: z
        .string({ required_error: 'Last name is Required.' })
        .min(1, 'Last name is Required.')
        .max(50, 'Last name too long. Limit to 50 characters or less.')
        .regex(/^[a-zA-Z0-9. -]*$/, {
            message: 'This field only accepts letters, numbers, short hyphens, periods and spaces.',
        }),
    birthday: z
        .date({
            invalid_type_error: 'Please enter a valid birthdate.',
            required_error: 'This field is required.',
        })
        .max(today, { message: 'Please enter a valid birthdate.' })
        .optional()
        .nullable(),
    gender: z.string({ required_error: 'This field is Required' }).optional(),
    phone: z
        .string({ required_error: 'This field is Required' })
        .regex(/^\d*$/, { message: 'Phone number must contain only digits' })
        .refine((value) => value === '' || value.length === 10, {
            message: 'Phone number must be 10 digits or empty',
        })
        .optional(),
    userType: z.string({ required_error: 'This field is Required' }).or(z.number()),
})

export type CreateUserType = z.infer<typeof CreateUserSchema>
export type UserResponseType = z.infer<typeof UserSchema>
export type UpdateUserType = z.infer<typeof UpdateUserSchema>
export type PageListUserType = z.infer<typeof PageListUserSchema>
