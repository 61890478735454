export const hexToRgba = (hex: string, opacity: number) => {
    const hexValue = hex.replace('#', '')
    const r = parseInt(hexValue.substr(0, 2), 16)
    const g = parseInt(hexValue.substr(2, 2), 16)
    const b = parseInt(hexValue.substr(4, 2), 16)
    return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`
}
export const formatNumber = (number: number | null) => {
    if (number) {
        let numberString = number.toString()

        numberString = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return numberString
    }
    return 0
}
