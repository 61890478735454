import { errorWhiteSpaces } from 'utils/constants'
import { WhiteSpaceDetect } from 'utils/helpers'
import { z } from 'zod'

export const CreateMenuCategorySchema = z.object({
    name: z
        .string({ required_error: 'Category name is required.' })
        .min(1, 'Category name is required.')
        .refine((val) => WhiteSpaceDetect(val), {
            message: errorWhiteSpaces,
        }),
    description: z.string().refine((val) => WhiteSpaceDetect(val), {
        message: errorWhiteSpaces,
    }),
    status: z.string(),
    icon: z.string().nullish(),
    styles: z.string().nullish(),
    image: z.string().nullish(),
    position: z.number(),
    iconAltText: z
        .string({ required_error: 'Alt text is required.' })
        .min(1, 'Alt text is required.')
        .refine((val) => WhiteSpaceDetect(val), {
            message: errorWhiteSpaces,
        }),
})
export type CreateMenuCategoryType = z.infer<typeof CreateMenuCategorySchema>
