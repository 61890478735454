import { AppBanner } from 'components/AppBanner'
import { ChangeEvent, DragEvent, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { IconType } from 'react-icons'
import { twMerge } from 'tailwind-merge'

interface AppFileUploadProps {
    onFileChange?: (file: File | null) => void
    acceptedFormats?: string[]
    maxSize?: number // in MB
    labelText?: string
    className?: string
    IconComponent?: IconType
    existingImage?: string
    errorMessage?: string | null
    isRefreshed?: boolean
    onClose?: () => void
    containerClassName?: string
    toastOnError?: boolean
    isLoading?: boolean
    isDisplayCloseError?: boolean
    id?: string
}

export const AppFileUpload = ({
    onFileChange,
    acceptedFormats = ['png', 'jpg', 'jpeg'],
    maxSize = 25,
    labelText = 'Upload a file',
    className,
    IconComponent,
    existingImage,
    isRefreshed,
    containerClassName,
    errorMessage,
    onClose,
    toastOnError,
    isDisplayCloseError,
    isLoading,
    id,
}: AppFileUploadProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [selectedImage, setSelectedImage] = useState<string | undefined>(existingImage)
    const [errorFile, setErrorFile] = useState<string | null>(null)
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null
        processFile(file)
    }

    const handleClick = () => {
        fileInputRef.current?.click()
    }

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault()
    }

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        const file = event.dataTransfer.files[0]
        processFile(file)
    }

    const processFile = async (file: File | null) => {
        const maxFileSizeInBytes = maxSize * 1024 * 1024
        if (file) {
            if (acceptedFormats.includes(file.type.split('/')[1].toLowerCase())) {
                if (file.size <= maxFileSizeInBytes) {
                    onFileChange && onFileChange(file)
                    const url = URL.createObjectURL(file)
                    setSelectedImage(url)

                    setErrorFile(null)
                } else {
                    toastOnError &&
                        toast.error(`File size exceeds the maximum limit of ${maxSize}MB`)
                    setErrorFile(`File size exceeds the maximum limit of ${maxSize}MB`)
                }
            } else {
                toastOnError && toast.error('Unsupported file format')
                setErrorFile('Unsupported file format')
            }
        }
    }
    useEffect(() => {
        if (isRefreshed) setSelectedImage(undefined)
    }, [isRefreshed])

    return (
        <div className={twMerge('flex flex-col w-full h-full gap-2 relative', containerClassName)}>
            <div
                className={twMerge(
                    'mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 relative hover:cursor-pointer',
                    selectedImage && 'p-0 border-none group',
                    className,
                )}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={handleClick}
            >
                {selectedImage ? (
                    <>
                        <img
                            src={selectedImage}
                            alt='Selected'
                            className='object-contain w-full h-full'
                        />
                        <input
                            ref={fileInputRef}
                            id={id + 'file-upload'}
                            name={id + 'file-upload'}
                            type='file'
                            className='sr-only z-50'
                            onChange={handleFileChange}
                        />
                        <div className='absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50 flex items-center justify-center text-white text-lg font-bold opacity-0 group-hover:opacity-100 transition-all duration-300'>
                            <span> Click to change photo</span>
                        </div>
                    </>
                ) : (
                    <div className='flex flex-col items-center justify-center text-center'>
                        {IconComponent && (
                            <IconComponent
                                className='mx-auto h-12 w-12 text-gray-800'
                                aria-hidden='true'
                            />
                        )}
                        <div className='mt-4 justify-center items-center flex text-sm leading-6 text-gray-600 flex-wrap'>
                            <label
                                htmlFor={id + 'file-upload'}
                                className='relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500 text-center px-2'
                            >
                                <span>{labelText}</span>
                                <input
                                    ref={fileInputRef}
                                    id={id + 'file-uploads'}
                                    name={id + 'file-uploads'}
                                    type='file'
                                    className='sr-only z-50'
                                    onChange={handleFileChange}
                                />
                            </label>
                            <p className='pl-1'>or drag and drop</p>
                        </div>
                        <p className='text-xs leading-5 text-gray-600'>
                            {acceptedFormats.join(', ').toUpperCase()} up to {maxSize}MB
                        </p>
                    </div>
                )}
                {errorFile ? <></> : <></>}
            </div>

            <AppBanner
                className={twMerge('bg-[#83ba85f3] animate-pulse')}
                show={isLoading}
                isHideCloseButton={true}
            >
                <span>{'Uploading...'}</span>
            </AppBanner>
            <AppBanner
                className={twMerge('bg-[#b01323f3]', toastOnError && 'hidden')}
                show={!!errorFile || !!errorMessage}
                onClose={() => {
                    onClose && onClose()
                    setErrorFile(null)
                }}
                isHideCloseButton={isDisplayCloseError}
            >
                <span>{errorFile ?? errorMessage}</span>
            </AppBanner>
        </div>
    )
}
