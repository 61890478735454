import z from 'zod'

export const tierTypeSchema = z.object({
    id: z.string(),
    tierName: z.string(),
    tierColor: z.string(),
    lowestPoints: z.number(),
    highestPoints: z.number(),
    tierDetails: z.string(),
    promotionPercentage: z.number(),
    whoAdded: z.unknown(),
    whoUpdated: z.unknown(),
    whenAdded: z.unknown(),
    whenUpdated: z.unknown(),
})

export const manageRewardSchema = z.object({
    pointingSystemTierId: z.string(),
    tierName: z.string(),
    rewardTitle: z.string(),
    rewardImage: z.instanceof(File).or(z.string()).nullish(),
    altText: z.string({ required_error: 'Alternative text is required.' }).nullish(),

    rewardDesc: z.string(),
    points: z
        .number({ required_error: 'Points is a required field.' })
        .min(1, 'Minimum allowed points is 1. Please ensure your input meets this requirement.'),
    percent: z
        .number({ required_error: 'Discount is a required field.' })
        .min(1, 'Discount must be between 1 and 100 only')
        .max(100, 'Discount must be between 1 and 100 only'),
    visibility: z.boolean().optional(),
})

export const manageTierSchema = z.object({
    tierName: z
        .string({ required_error: 'Tier name is a required field.' })
        .max(30, { message: 'Tier name must be at least 30 characters only.' }),
    tierColor: z.string({ required_error: 'Tier Color is a required field.' }),
    tierDetails: z
        .string({ required_error: 'Tier Details is a required field.' })
        .refine((val) => val.length > 0, { message: 'Tier Details is a required field.' }),
    maxPoints: z
        .number({ required_error: 'Max points is a required field.' })
        .min(2, 'The max points value must be greater than 1')
        .max(1e8, { message: 'The max points value must be less than 9 digits' }),

    promotionPercentage: z
        .number({ required_error: 'Points multiplier is a required field.' })
        .min(2, 'Points multiplier must be greater than 1.'),
    firstTier: z.boolean(),
})

export const rewardSchema = manageRewardSchema.merge(
    z.object({ pointingSystemTier: tierTypeSchema }),
)

export type ManageLoyaltyTierType = z.infer<typeof manageTierSchema>
export type ManageRewardType = z.infer<typeof manageRewardSchema>
export type RewardType = z.infer<typeof rewardSchema>
export type TierType = z.infer<typeof tierTypeSchema>
