import { AxiosError, isAxiosError } from 'axios'
import { useAtom, useAtomValue } from 'jotai'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { CreateHomeSectionApi, getHomeSectionByTitleApi } from 'services/requests/home'
import { CreateHomeSectionType, HomeContentType } from 'services/requests/home/schema'
import {
    homeCarouseIdAtom,
    HomeSelectedContentsPreviewAtom,
    HomeSelectedPreviewAtom,
} from 'store/homeAtom'
import { homeCorousel } from 'utils/constants'
import PreviewHomeContent from './DisplayPreview'
import SortableCarouselItems from './SortableCarouselItems'

const CarouselSection: React.FunctionComponent = () => {
    const queryClient = useQueryClient()
    const selectedPreview = useAtomValue(HomeSelectedPreviewAtom)
    const [, setHomeCarouselId] = useAtom(homeCarouseIdAtom)
    const [sortedData, setSortedData] = useState<HomeContentType[] | null>(null)
    const [selectedContent, setSelectedContent] = useAtom(HomeSelectedContentsPreviewAtom)
    const {
        isLoading,
        data,
        error: queryError,
    } = useQuery({
        queryKey: 'content-carousel',
        queryFn: () => getHomeSectionByTitleApi(homeCorousel.title),
        onSuccess: (data) => {
            if (!data.homeContents.some((item) => selectedContent?.homeId === item.homeId)) {
                setSelectedContent(null)
            }
            setSortedData(null)
            setHomeCarouselId(data?.homeId)
        },
    })

    const { mutate: saveContent } = useMutation<unknown, AxiosError, CreateHomeSectionType>(
        (data) => {
            return CreateHomeSectionApi(data)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(homeCorousel?.title)
            },
        },
    )

    useEffect(() => {
        if (isAxiosError(queryError) && queryError?.response?.status === 404)
            saveContent({
                bgColor: '',
                buttonLabel: 'Check Menu',
                duration: 8,
                visibility: 'ACTIVE',
                localization: 'en',
                sectionTitle: homeCorousel?.title,
            })
    }, [queryError])

    return (
        <section className=' w-full bg-white grid grid-cols-12 min-h-[408px]'>
            <aside className=' col-span-12 md:col-span-6 bg-[#861F41] flex'>
                {selectedPreview?.id ? (
                    <div className='flex flex-col gap-4 text-white max-w-[424px] m-auto w-full '>
                        <div className='w-full flex'>
                            <PreviewHomeContent id={selectedPreview?.id} />
                        </div>
                        {/* <AppButton
                            variant='secondary'
                            type='button'
                            disabled={true}
                            className='flex items-center justify-center w-[241px] h-[52px] bg-white text-[#861F41] mx-auto font-bold text-[20px] rounded-lg p-0 m-0'
                        >
                            Check Menu
                        </AppButton> */}
                    </div>
                ) : (
                    <div className='flex justify-center items-center font-bold text-white w-full'>
                        Select Item to preview
                    </div>
                )}
            </aside>
            <aside className=' col-span-12 md:col-span-6'>
                {data && (
                    <SortableCarouselItems
                        setSortedData={setSortedData}
                        isLoading={isLoading}
                        sortedData={sortedData}
                        array={data?.homeContents}
                    />
                )}
            </aside>
        </section>
    )
}

export default CarouselSection
