import { atom } from 'jotai'
import { TierRewardsType } from 'services/requests/account/schema'

export const initialReward: TierRewardsType = {
    id: '',
    rewardTitle: '',
    rewardDesc: '',
    percent: 0,
    points: 0,
    tierName: '',
    pointingSystemTier: {
        id: '',
        tierName: '',
        lowestPoints: 0,
        highestPoints: 0,
        tierColor: '',
        tierDetails: '',
        promotionPercentage: 0,
        tierBgImage: '',
        tierImage: '',
        whoAdded: '',
        whoUpdated: '',
        whenAdded: '',
        whenUpdated: '',
    },
    visibility: true,
    rewardImage: '',
    whoAdded: null,
    whoUpdated: null,
    whenAdded: null,
    whenUpdated: null,
}

export const rewardAtom = atom<TierRewardsType[]>([])

export const selectedRewardAtom = atom<TierRewardsType>({} as TierRewardsType)
