import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { AxiosError, isAxiosError } from 'axios'
import ConfirmationModal from 'components/ActionConfirmation'
import AppAltTextInput from 'components/AppAltTextInput'
import { AppButton } from 'components/AppButton'
import { AppFileUpload } from 'components/AppFileUpload'
import { AppModal } from 'components/AppModal'
import { UpdateHomeContentType, UpdateOptionalContentType } from 'pages/Schemas/home-content'
import { DraggableHandle } from 'pages/Web/Partnerships/parnership-item'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { BiPhotoAlbum } from 'react-icons/bi'
import { FiTrash } from 'react-icons/fi'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import Creatable from 'react-select/creatable'
import {
    CreateInitialContentApi,
    DeleteHomeContentByIdApi,
    getHomeContentByTitleApi,
    UpdateHomeContentApi,
} from 'services/requests/home'
import { listMenuCategoryApi } from 'services/requests/menu-category'
import { twMerge } from 'tailwind-merge'
import { badRequestError } from 'utils/constants'
interface IHomeContentImageProps {
    title: string
    type: 'mob' | 'menu' | 'partnership'
    buttonRedirection?: string
    sectiontId: string | null
    id?: string
    sectionTitle?: string
}

interface ISelect {
    value: string
    label: string
}

const HomeContentImage: React.FunctionComponent<IHomeContentImageProps> = (props) => {
    const queryClient = useQueryClient()
    const [FILE, setFILE] = React.useState<File | null>(null)
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false)
    const [imageAltText, setImageAltText] = useState('')
    const [badReqError, setBadReqError] = useState<string | null>(null)
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props?.id ?? '',
    })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    const { data: MenuCategory } = useQuery(['menu-category'], () => listMenuCategoryApi(), {
        enabled: props.type === 'menu',
    })

    const { data: Content, error: queryError } = useQuery({
        queryKey: props.title,
        queryFn: () => getHomeContentByTitleApi(props?.title),
        onSuccess(data) {
            setImageAltText(data.imageAltText)
        },
    })

    const { mutate: saveContent } = useMutation<unknown, AxiosError, UpdateHomeContentType>(
        (data) => {
            return CreateInitialContentApi(data)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(props?.title)
            },
        },
    )
    const { mutate: updateContent, isLoading: isContentUpdating } = useMutation<
        unknown,
        AxiosError,
        UpdateOptionalContentType
    >(
        (data) =>
            data.type === 'IMAGE'
                ? UpdateHomeContentApi(Content?.id ?? '', { ...data })
                : UpdateHomeContentApi(Content?.id ?? '', data),
        {
            onSuccess: () => {
                toast.success('Success! Home content saved.')
                queryClient.invalidateQueries(props?.title)
                setIsOpen(false)
            },
            onError: (error) => {
                if (error.code === 'ERR_NETWORK') setBadReqError(badRequestError)
            },
        },
    )

    const { mutate: deleteHomeContent, isLoading: deleteLoading } = useMutation<
        unknown,
        AxiosError,
        string
    >((data) => DeleteHomeContentByIdApi(data), {
        onSuccess: () => {
            queryClient.invalidateQueries(props.sectionTitle ?? '')
            toast.success('Success! Home content deleted.')
        },
        onError: (error) => console.log(error),
    })

    React.useEffect(() => {
        if (isAxiosError(queryError) && queryError?.response?.status === 404)
            if (props?.sectiontId)
                saveContent({
                    externalTitle: props.title,
                    title: '{}',
                    floatingImage: '',
                    homeId: props?.sectiontId,
                    buttonLabel: '',
                    visibility: 'ACTIVE',
                    icon: '',
                    buttonRedirection: props?.buttonRedirection ?? '',
                    position: 100,
                    image: '',
                    subtitle: '',
                    imageAltText,
                })
    }, [queryError, props?.sectiontId])

    return (
        <div className='flex flex-col gap-2' ref={setNodeRef} style={style} {...attributes}>
            {props?.id && (
                <div className='flex w-full justify-between'>
                    <DraggableHandle handleListeners={listeners} className='' />
                    <AppButton
                        variant='secondary'
                        onClick={() => setIsOpenConfirmationModal(true)}
                        type='button'
                        className='w-fit'
                    >
                        <FiTrash />
                    </AppButton>
                </div>
            )}
            <div
                className={twMerge(
                    props.type !== 'mob' ? 'w-[208px] h-[178px]' : 'w-[160px] h-[320px]',
                    'border-dotted border-[#861F41] border-2 p-1 flex items-center justify-center relative',
                )}
            >
                <div className='absolute w-full h-full flex items-center justify-center'>
                    <AppButton variant='secondary' type='button' onClick={() => setIsOpen(true)}>
                        Edit Image
                    </AppButton>
                </div>
                {Content?.image === '' ? (
                    <></>
                ) : (
                    <img
                        src={Content?.image}
                        className={twMerge(
                            props.type !== 'mob'
                                ? 'w-[176px] h-[152px] object-contain'
                                : 'w-[147px] h-[306px] object-cover',
                            'border-dotted border-[#861F41] p-2 bg-gray-300',
                        )}
                    />
                )}
            </div>
            {props?.type === 'menu' && MenuCategory && (
                <Creatable
                    placeholder={'Select Category'}
                    className='w-full text-xs border-[#861F41] rounded-none shadow-md  font-bold text-center'
                    onChange={(category) => {
                        const value = category as ISelect
                        if (value && Content) {
                            const {
                                buttonRedirection,
                                externalTitle,
                                floatingImage,
                                icon,
                                image,
                                position,
                                subtitle,
                                visibility,
                                homeId,
                            } = Content
                            updateContent({
                                buttonLabel: value?.label,
                                buttonRedirection,
                                externalTitle,
                                floatingImage,
                                homeId,
                                icon,
                                image,
                                position,
                                subtitle,
                                title: value?.label,
                                visibility,
                                type: 'TITLE',
                                imageAltText: imageAltText,
                                file: image,
                            })
                        }
                    }}
                    value={
                        new Object({
                            label: Content?.buttonLabel ?? '',
                            value: Content?.buttonLabel ?? '',
                        })
                    }
                    options={
                        MenuCategory.map(
                            (item) =>
                                new Object({
                                    label: item.name,
                                    value: item.id,
                                }),
                        ) ?? []
                    }
                />
            )}
            <AppModal
                onClose={() => setIsOpen(false)}
                isOpen={isOpen}
                closeIcon={true}
                title='Image'
                containerClassName='p-5'
            >
                <div className='w-[539px] min-h-[450px] flex flex-col'>
                    <h1 className='text-[14px]'>Preview</h1>
                    {Content && (
                        <div className='flex flex-col justify-center items-center border-dotted border-2 border-[#862F4C] w-full m-auto p-2'>
                            <AppFileUpload
                                errorMessage={badReqError}
                                existingImage={
                                    typeof Content?.image === 'string' ? Content?.image : undefined
                                }
                                maxSize={25}
                                onClose={() => setBadReqError(null)}
                                onFileChange={(file) => {
                                    setFILE(file)
                                }}
                                IconComponent={BiPhotoAlbum}
                                className='object-contain w-full m-auto hover:cursor-pointer p-2 h-[210px]'
                            />
                        </div>
                    )}
                    <div className='flex flex-col w-full text-[#707070] text-[10px] px-2 my-5 '>
                        <AppAltTextInput
                            name={`${Content?.title}`}
                            onChange={setImageAltText}
                            errorMessage={''}
                            value={imageAltText}
                        />
                    </div>
                    <div className='flex ml-auto mt-auto w-full justify-end items-center gap-6 py-7'>
                        <AppButton
                            variant='secondary'
                            className='min-w-[129px] h-[47px] flex justify-center items-center bg-white border-[#861F41] text-[#861F41] p-3 font-bold'
                            type='button'
                            onClick={() => setIsOpen(false)}
                        >
                            Cancel
                        </AppButton>
                        <AppButton
                            variant='primary'
                            className='min-w-[129px] h-[47px] flex justify-center items-center bg-[#861F41] border-[#861F41] text-white p-3 font-bold'
                            onClick={() => {
                                if (Content && props?.sectiontId) {
                                    const {
                                        buttonRedirection,
                                        externalTitle,
                                        floatingImage,
                                        icon,
                                        position,
                                        subtitle,
                                        image,
                                        visibility,
                                        buttonLabel,
                                        title,
                                    } = Content
                                    updateContent({
                                        buttonLabel,
                                        buttonRedirection,
                                        externalTitle,
                                        floatingImage,
                                        homeId: props?.sectiontId,
                                        icon,
                                        image,
                                        position,
                                        subtitle,
                                        title,
                                        visibility,
                                        type: 'IMAGE',
                                        imageAltText: imageAltText,
                                        file: FILE ?? image,
                                    })
                                }
                            }}
                            disabled={!FILE && Content?.image === ''}
                            isLoading={isContentUpdating}
                            type='submit'
                        >
                            Save
                        </AppButton>
                    </div>
                </div>
            </AppModal>
            <AppModal
                closeIcon={false}
                isOpen={isOpenConfirmationModal}
                onClose={() => setIsOpenConfirmationModal(false)}
                title=''
                containerClassName='h-fit p-0 my-0'
            >
                <ConfirmationModal
                    handle={() => deleteHomeContent(props?.id ?? '')}
                    onClose={() => setIsOpenConfirmationModal(false)}
                    yesLabel='Delete'
                    noLabel='Cancel'
                    title='Delete Content'
                    isLoading={deleteLoading}
                    subtitle='Are you sure you want to delete this content?'
                />
            </AppModal>
        </div>
    )
}

export default HomeContentImage
