import { ManageLoyaltyTierType, ManageRewardType } from 'pages/Schemas/tier'
import { axiosInstance } from 'services/axios'
import { PagedRewardType } from './schema'

export interface CreateRewardParams {
    pointingSystemTierId: string
    points: number
    percent: number
    rewardTitle: string
    rewardDesc: string
    rewardImage: string
    visibility: boolean
}

export interface CreateTierParams {
    tierName: string
    tierColor: string
    lowestPoints: number
    highestPoints: number
    tierDetails: string
}

export interface UpdateTierParams {
    id: string | undefined
    tierName: string
    tierColor: string
    tierDetails: string
}

export interface PointingRewardParams {
    page?: number
    size?: number
    sort?: string
}

export interface UpdateRewardParams {
    id: string | undefined
    points: number
    percent: number
}

export const getAccountApi = async () => {
    const response = await axiosInstance.get('/account/user/address')

    return response.data
}

export const updateTier = async (props: UpdateTierParams) => {
    const response = await axiosInstance.patch('/account/pointingSystem/update', props)
}

export const getPointingTierList = async () => {
    const response = await axiosInstance.get('/account/pointingSystem')
    return response.data
}

export const createLoyaltyTier = async (props: ManageLoyaltyTierType) => {
    const response = await axiosInstance.post('/account/pointingSystem', props)

    return response.data
}

export const updateLoyaltyTier = async (id: string, props: ManageLoyaltyTierType) => {
    const response = await axiosInstance.patch(`/account/pointingSystem/${id}`, props)
}

export const getLoyaltyTier = async (id: string) => {
    const response = await axiosInstance.get(`/account/pointingSystem?id=${id}`)
    return response.data
}

export const deleteLoyaltyTier = async (id: string) => {
    const response = await axiosInstance.delete(`/account/pointingSystem/${id}`)
    return response.data
}

export const getAllPointingRewards = async (props: PointingRewardParams) => {
    const response = await axiosInstance.get('/account/pointingSystem/Rewards/allRewards', {
        params: props,
    })
    return response.data as PagedRewardType
}

export const searchRewardsApi = async (query: string, props: PointingRewardParams) => {
    const response = await axiosInstance.get(
        `/account/pointingSystem/Rewards/allRewards/search?query=${query}`,
        {
            params: {
                page: props.page,
                size: props.size,
                sort: props.sort,
            },
        },
    )

    return response.data as PagedRewardType
}

export const deletePointingReward = async (rewardId: string) => {
    const response = await axiosInstance.delete(
        `/account/pointingSystem/Rewards/delete/${rewardId}`,
    )
    return response.data
}

export const createReward = async (props: ManageRewardType) => {
    const formData = new FormData()

    formData.append('pointingSystemTierId', props.pointingSystemTierId)
    formData.append('rewardTitle', props.rewardTitle)
    formData.append('rewardDesc', props.rewardDesc)
    formData.append('points', String(props.points))
    formData.append('percent', String(props.percent))
    formData.append('altText', String(props.altText))

    if (props.rewardImage) {
        formData.append('rewardImage', props.rewardImage)
    }

    const response = await axiosInstance.post('/account/pointingSystem/Rewards/create', props, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })

    return response.data
}

export const updateReward = async (props: ManageRewardType, rewardId: string) => {
    const formData = new FormData()

    formData.append('rewardTitle', props.rewardTitle)
    formData.append('rewardDesc', props.rewardDesc)
    formData.append('points', String(props.points))
    formData.append('percent', String(props.percent))
    formData.append('pointingSystemTierId', props.pointingSystemTierId)
    formData.append('tierName', props.pointingSystemTierId)
    formData.append('visibility', String(props.visibility))
    formData.append('altText', String(props.altText))

    if (props.rewardImage) {
        formData.append('rewardImage', props.rewardImage)
    }

    const response = await axiosInstance.patch(
        `/account/pointingSystem/Rewards/update/${rewardId}`,
        props,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    )

    return response.data
}

export const updateRewardVisibility = async (rewardId: string, visibility: boolean) => {
    const response = await axiosInstance.put(
        `/account/pointingSystem/Rewards/visibility/${rewardId}/${visibility}`,
    )
    return response.data
}

export const getSpecificReward = async (rewardId: string) => {
    const response = await axiosInstance.get(
        `/account/pointingSystem/Rewards/specific?pointingRewardId=${rewardId}`,
    )

    return response.data
}
