import { zodResolver } from '@hookform/resolvers/zod'
import { AxiosError } from 'axios'
import AppAltTextInput from 'components/AppAltTextInput'
import AppAutoComplete from 'components/AppAutocomplete'
import { AppButton } from 'components/AppButton'
import AppDatePicker from 'components/AppDatePicker'
import { AppFileUpload } from 'components/AppFileUpload'
import { AppRichTextBox } from 'components/AppRichTextBox'
import { AppTextBox } from 'components/AppTextBox'
import Loading from 'components/loading'
import { useAtom, useAtomValue } from 'jotai'
import { CreateNewsSchema, CreateNewsType } from 'pages/Schemas/news'
import React, { useCallback, useReducer, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
    CreateNewsApi,
    getNewsById,
    UpdateNewsApi,
    UploadNewsImageApi,
} from 'services/requests/news'
import { NewsToUpdateAtom } from 'store/newsAtom'
import { userAtom } from 'store/userAtom'

import toast from 'react-hot-toast'
import { BiPhotoAlbum } from 'react-icons/bi'
import { badRequestError } from 'utils/constants'
import PreviewNewsModal from './preview-news'
type IErrorImages = {
    cover: string | null
    featured: string | null
}
const AddNews: React.FunctionComponent = () => {
    const [isSetted, setIsSetted] = useState(false)
    const navigate = useNavigate()
    const params = useParams()
    const { id } = params
    const user = useAtomValue(userAtom)
    //@STATES
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [newsToUpdate] = useAtom(NewsToUpdateAtom)
    type IErrorImages = {
        coverImage: string | null
        featuredImage: string | null
    }

    type ErrorImagesAction =
        | { type: 'coverImage'; payload: string | null }
        | { type: 'featuredImage'; payload: string | null }

    const errorImagesReducer = (state: IErrorImages, action: ErrorImagesAction) => {
        switch (action.type) {
            case 'coverImage':
                return { ...state, coverImage: action.payload }
            case 'featuredImage':
                return { ...state, featuredImage: action.payload }
            default:
                return state
        }
    }

    const initialState = {
        coverImage: null,
        featuredImage: null,
    }

    const [errorImages, dispatch] = useReducer(errorImagesReducer, initialState)

    const openModal = () => {
        setIsOpen(true)
    }

    const { isLoading } = useQuery(['newsById'], () => getNewsById(id ?? ''), {
        onSuccess: (data) => {
            if (!isSetted) {
                setValue('author', data.author)
                setValue('category', data.category)
                setValue('coverImage', data.coverImage)
                setValue('description', data.description)
                setValue('featuredImage', data.featuredImage)
                data.publishDate && setValue('publishDate', new Date(data.publishDate))
                setValue('title', data.title)
                setValue('url', data.url)
                setValue('userId', data.userId)
                setValue('visibility', data.visibility)
                setValue('content', data.content)
                setValue('featuredImageAltText', data?.featuredImageAltText ?? '')
                setValue('coverImageAltText', data?.coverImageAltText ?? '')
                setIsSetted(true)
            }
        },
    })

    const { mutate: createNews } = useMutation<unknown, AxiosError, CreateNewsType>(
        (data) => (id ? UpdateNewsApi(id, data) : CreateNewsApi(data)),
        {
            onSuccess: () => {
                navigate('/web/news')
            },
            onError: (error: AxiosError) => console.log(error),
        },
    )

    //@useform
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        clearErrors,
        formState: { isDirty },
    } = useForm<CreateNewsType>({
        mode: 'onBlur',
        resolver: zodResolver(CreateNewsSchema),
        defaultValues: {
            visibility: 'Published',
            publishType: 'Immediately',
            content: id ? newsToUpdate?.content ?? '{}' : '{}',
            userId: user?.id ?? '',
            publishDate: new Date(),
        },
    })

    interface IUploadRequest {
        file: File
        type: string
    }

    const { mutate: uploadFeaturedImage, isLoading: isUploadingFeaturedImage } = useMutation<
        any,
        AxiosError,
        IUploadRequest
    >((data) => UploadNewsImageApi(data), {
        onSuccess: (data) => {
            setValue(data?.type, data[0].sizes[0].objectURL)
            dispatch({ type: data.type, payload: null })
            clearErrors('featuredImage')
        },
        onError: () => {
            dispatch({ type: 'featuredImage', payload: badRequestError })
        },
    })
    const { mutate: uploadCoverImage, isLoading: isUploadingCoverImage } = useMutation<
        any,
        AxiosError,
        IUploadRequest
    >((data) => UploadNewsImageApi(data), {
        onSuccess: (data) => {
            clearErrors('coverImage')
            setValue(data?.type, data[0].sizes[0].objectURL)
            dispatch({ type: data.type, payload: null })
        },
        onError: () => {
            dispatch({ type: 'coverImage', payload: badRequestError })
        },
    })

    const onSubmit = (data: CreateNewsType) => {
        createNews({ ...data, userId: user?.id ?? '', visibility: 'Published' })
        toast.success(
            <div className='space-y-2'>
                <span className='font-bold'>Article published successfully!</span>
                <p>The article is now live and can be accessed by users.</p>
            </div>,
        )
    }
    const onSubmitDraft = (data: CreateNewsType) => {
        createNews({ ...data, userId: user?.id ?? '', visibility: 'Draft' })
        toast.success(
            <div className='space-y-2'>
                <span className='font-bold'>Draft saved successfully!</span>
                <p>The news article is now securely stored in draft.</p>
            </div>,
        )
    }

    const RenderBreadCrumbs = useCallback(() => {
        return (
            <div className='flex items-center justify-between'>
                <button
                    onClick={() => navigate(-1)}
                    className='text-xs text-gray-400 font-bold underline'
                >
                    Web &gt; News &gt; {params?.id ? 'Update' : 'Add'}
                </button>
            </div>
        )
    }, [params?.id])

    const NewsImageUploader = useCallback(
        (props: {
            value: string
            type: 'featuredImage' | 'coverImage'
            isLoading?: boolean
            error?: string
        }) => {
            const { type, value } = props

            return (
                <AppFileUpload
                    onClose={() => dispatch({ type, payload: null })}
                    maxSize={25}
                    id={type}
                    isLoading={props.isLoading}
                    isDisplayCloseError={!!props.error}
                    errorMessage={props.error ?? errorImages[type]}
                    existingImage={typeof value === 'string' ? value : undefined}
                    onFileChange={(file) => {
                        if (file) {
                            switch (type) {
                                case 'featuredImage':
                                    uploadFeaturedImage({ file: file, type })
                                    break
                                case 'coverImage':
                                    uploadCoverImage({ file: file, type })
                                    break
                                default:
                                    break
                            }
                        }
                    }}
                    IconComponent={BiPhotoAlbum}
                    containerClassName='justify-center w-full p-5'
                />
            )
        },
        [watch().coverImage, watch().featuredImage, errorImages.coverImage],
    )

    if (id && isLoading) return <Loading />
    return (
        <div className='py-10'>
            <h1 className='text-base text-gray-600 font-bold'>
                <Link className='text-red-800 hover:text-red-600' to={'/web/news'}>
                    News
                </Link>{' '}
                &gt; {id ? 'Update' : 'Add'}
            </h1>
            <div className='w-full flex flex-col justify-center items-center my-4'>
                <div className='inline-flex w-full justify-between'>
                    <h1 className='font-bold text-[20px] text-[#861F41]'>
                        {params?.id ? 'Update news' : 'Create new post'}
                    </h1>
                    <div className='inline-flex text-[#861F41] gap-3 text-[14px]'>
                        <AppButton
                            variant='secondary'
                            type='submit'
                            onClick={handleSubmit(onSubmitDraft)}
                            className='min-w-[83px] h-[48px] shadow-none border-none bg-transparent text-[#751132]'
                        >
                            Save as Draft
                        </AppButton>
                        <AppButton
                            variant='secondary'
                            type='button'
                            disabled={!isDirty && !id}
                            onClick={() => openModal()}
                            className='min-w-[83px] h-[48px] shadow-none border-none bg-transparent text-[#751132]'
                        >
                            Preview
                        </AppButton>
                        <AppButton
                            variant='primary'
                            type='submit'
                            disabled={isUploadingCoverImage || isUploadingFeaturedImage}
                            onClick={handleSubmit(onSubmit)}
                            className='w-[88px] font-bold  block text-white bg-[#751132] rounded-md py-3'
                        >
                            Publish
                        </AppButton>
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-12 w-full gap-2'>
                <div className=' col-span-12 md:col-span-8 flex flex-col gap-3'>
                    <Controller
                        control={control}
                        name='url'
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <AppTextBox
                                type='text'
                                label={'URL: '}
                                value={value}
                                name={name}
                                autoFocus
                                containerClassname='p-2 shadow-md bg-white'
                                inputContainerClassname='shadow-none'
                                labelPosition='left'
                                labelClassname='text-sm font-bold basis-1/10 shadow-none'
                                inputClassname='w-full min-h-[31px] px-1 focus:outline-none shadown-none text-xxs basis-9/10 shadow-none'
                                onChange={onChange}
                                errorMessage={error?.message}
                            />
                        )}
                    />
                    <div className='bg-white p-5'>
                        <div className='h-fit'>
                            <Controller
                                control={control}
                                name='coverImage'
                                render={({ field: { name, value }, fieldState: { error } }) => (
                                    <NewsImageUploader
                                        error={error?.message}
                                        isLoading={isUploadingCoverImage}
                                        type={name}
                                        value={value}
                                    />
                                )}
                            />
                        </div>
                        <Controller
                            control={control}
                            name='coverImageAltText'
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <AppAltTextInput
                                    name={name}
                                    onChange={onChange}
                                    errorMessage={error?.message ?? ''}
                                    value={value}
                                />
                            )}
                        />
                    </div>

                    <Controller
                        control={control}
                        name='title'
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <AppTextBox
                                type='text'
                                value={value}
                                name={name}
                                containerClassname='p-2 shadow-md bg-white'
                                inputContainerClassname=''
                                labelPosition='left'
                                placeholder='Add Title'
                                inputClassname='w-full min-h-[31px] px-1 focus:outline-none shadown-none basis-9/10 shadow-none placeholder:text-2xl sm:text-2xl'
                                onChange={onChange}
                                errorMessage={error?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='description'
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <AppTextBox
                                type='text'
                                value={value}
                                name={name}
                                containerClassname='p-2 shadow-md bg-white'
                                inputContainerClassname=''
                                labelPosition='left'
                                placeholder='Description'
                                inputClassname='w-full min-h-[31px] px-1 focus:outline-none shadown-none basis-9/10 shadow-none'
                                onChange={onChange}
                                errorMessage={error?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='content'
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <div className='w-full bg-white p-3'>
                                <AppRichTextBox
                                    errorMessage={error?.message}
                                    name={name}
                                    onChange={onChange}
                                    value={value}
                                />
                            </div>
                        )}
                    />
                </div>
                <div className=' col-span-12 md:col-span-4 flex flex-col w-full  bg-white p-5'>
                    <Controller
                        control={control}
                        name='visibility'
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <AppTextBox
                                type='text'
                                disabled={true}
                                label={'Visibility: '}
                                value={value}
                                name={name}
                                containerClassname='bg-white p-2 shadow-none'
                                labelPosition='left'
                                inputContainerClassname='shadow-none'
                                labelClassname='text-sm font-bold shadow-none'
                                inputClassname='w-full min-h-[31px] px-1 focus:outline-none text-[#862F4C] shadow-sm'
                                onChange={onChange}
                                errorMessage={error?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='publishType'
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <AppTextBox
                                type='text'
                                label={'Publish: '}
                                value={value}
                                name={name}
                                inputContainerClassname='shadow-none'
                                containerClassname='p-2 shadow-none'
                                labelPosition='left'
                                labelClassname='text-sm font-bold shadow-none'
                                inputClassname='w-full min-h-[31px] px-1 focus:outline-none outline-none shadow-none text-[#862F4C] shadow-sm'
                                onChange={onChange}
                                errorMessage={error?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='author'
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <AppTextBox
                                type='text'
                                label={'Author: '}
                                value={value}
                                name={name}
                                inputContainerClassname='shadow-none w-full'
                                containerClassname='p-2 shadow-none w-full'
                                labelPosition='left'
                                labelClassname='text-sm font-bold shadow-none'
                                inputClassname='w-full min-h-[31px] px-1 focus:outline-none outline-none text-[#862F4C] shadow-sm'
                                onChange={onChange}
                                errorMessage={error?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='publishDate'
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <AppDatePicker
                                name={name}
                                label='Published Date: '
                                containerClassName='p-2 shadow-none'
                                inputClassName='w-full min-h-[31px] px-1 focus:outline-none outline-none text-[#862F4C] shadow-sm'
                                onChange={onChange}
                                value={value}
                                labelClassName='text-sm font-bold shadow-none'
                                arrow={true}
                                errorMessage={error?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='category'
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <AppAutoComplete
                                name={name}
                                list={['More', 'Featured', 'Review', 'Latest News']}
                                onChange={onChange}
                                label='Category:'
                                labelClassname='ml-2'
                                containerClassname='w-full mx-auto'
                                value={value}
                                inputClassname='border-none'
                                errorMessage={error?.message}
                                placeholder={'Select Category'}
                            />
                        )}
                    />
                    <div className='h-fit'>
                        <Controller
                            control={control}
                            name='featuredImage'
                            render={({ field: { name, value }, fieldState: { error } }) => (
                                <NewsImageUploader
                                    type={name}
                                    error={error?.message}
                                    isLoading={isUploadingFeaturedImage}
                                    value={value}
                                />
                            )}
                        />
                    </div>
                    <Controller
                        control={control}
                        name='featuredImageAltText'
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <AppAltTextInput
                                containerClassName='m-0'
                                name={name}
                                onChange={onChange}
                                errorMessage={error?.message ?? ''}
                                value={value}
                            />
                        )}
                    />
                </div>
            </div>
            <PreviewNewsModal
                description={watch().description}
                id={''}
                date={watch().publishDate.toString()}
                image={watch().coverImage ?? watch().featuredImage}
                category={watch().category}
                title={watch().title}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
            />
        </div>
    )
}

export default AddNews
