import AppSkeletonLoadingStores from 'components/AppSkeletonLoaders/AppSkeletonLoadingRewards'
import { useNavigate } from 'react-router-dom'
import { LocationType } from 'services/requests/store/schema'
import { twMerge } from 'tailwind-merge'
import { tableWebHeader } from 'utils/styles'
import { StoreBody } from './StoreBody'
import StoreEmptyState from './StoreEmptyState'
import { StoreHeader } from './StoreHeader'

interface ITableProps {
    content: LocationType[] | undefined
    isLoading: boolean
}

export const StoreTable = (props: ITableProps) => {
    const navigate = useNavigate()

    const navigateToEdit = (id: string) => {
        navigate(`/global-settings/stores/edit/${id}`)
    }

    if (props.isLoading) return <AppSkeletonLoadingStores />

    if (!props.content) return <>No Content</>

    return (
        <>
            {props.content.length === 0 ? (
                <StoreEmptyState />
            ) : (
                <div className='mt-8 flow-root mx-4 sm:mx-0 '>
                    <div className='-mx-4 mt-2 -my-2 overflow-x-auto sm:mx-0 rounded-lg border border-solid'>
                        <div className='inline-block min-w-full align-middle sm:px-0'>
                            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5'>
                                <table className='min-w-full divide-y divide-gray-300'>
                                    <thead
                                        className={twMerge(
                                            'bg-[#EDDFE2] h-[55px] w-full',
                                            tableWebHeader,
                                        )}
                                    >
                                        <StoreHeader />
                                    </thead>
                                    <tbody className='divide-y divide-gray-200 bg-white'>
                                        <StoreBody
                                            content={props.content}
                                            onClick={navigateToEdit}
                                        />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
