import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'

interface CheckboxProps {
    id: string
    label: string
    className?: string
    checked?: boolean // Prop to control checked state from parent
    onChange?: (checked: boolean) => void // Callback function to handle changes
    disabled?: boolean
}

export const AppCheckbox: React.FC<CheckboxProps> = ({
    id,
    label,
    className,
    checked: propChecked = false, // Default value if not provided
    onChange,
    disabled = false,
}) => {
    // If onChange is provided, component is controlled from the parent
    const isControlled = onChange !== undefined

    // Local state to manage checkbox state if it's uncontrolled
    const [localChecked, setLocalChecked] = useState(propChecked)

    // Handler for checkbox change
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked

        // If controlled, use the onChange prop
        if (isControlled && onChange) {
            onChange(isChecked)
        } else {
            // If uncontrolled, use local state
            setLocalChecked(isChecked)
        }
    }

    return (
        <div className='m-2'>
            <input
                type='checkbox'
                id={id}
                className='m-1'
                checked={isControlled ? propChecked : localChecked}
                onChange={handleCheckboxChange}
                disabled={disabled}
            />
            <label htmlFor={id} className={twMerge('text-pink-900 text-base', className)}>
                {label}
            </label>
        </div>
    )
}
