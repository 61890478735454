import { CreateMenuCategoryType } from 'pages/Schemas/menu-category'
import { axiosInstance } from 'services/axios'
import {
    CreateMenuCategoryResponseType,
    ListMenuCategoryType,
    ListProductType,
    ObjectMenuCategoryType,
    UploadResponseType,
} from './schema'

export const listMenuCategoryApi = async () => {
    const response = await axiosInstance.get('/menu/category')

    return response.data as ListMenuCategoryType
}

export const MenuCategoryApi = async (id: string) => {
    const response = await axiosInstance.get('/menu/category?id=' + id)

    return response.data as ObjectMenuCategoryType
}

export const listProductsApi = async () => {
    const response = await axiosInstance.get('/product/public/store-product?type=EDIBLE')

    return response.data as ListProductType
}

export const ObjectProductsApi = async (id: string) => {
    const response = await axiosInstance.get('/product/public/store-product?type=EDIBLE?id=' + id)

    return response.data as ListProductType
}

export const Categorize = async (categoryId: string, productId: string) => {
    const response = await axiosInstance.post(
        `/menu/category/categorize?categoryId=${categoryId}&productId=${productId}`,
    )

    return response.data as unknown
}
export const RemoveCategorize = async (categoryId: string, productId: string) => {
    const response = await axiosInstance.post(
        `/menu/category/categorize/remove?categoryId=${categoryId}&productId=${productId}`,
    )

    return response.data as unknown
}

export const CreateMenuCategoryApi = async (data: CreateMenuCategoryType) => {
    console.log(data)

    const response = await axiosInstance.post('/menu/category', data)

    return response.data as CreateMenuCategoryResponseType
}

export const UpdateMenuCategoryApi = async (id: string, data: CreateMenuCategoryType) => {
    const response = await axiosInstance.put(`/menu/category?id=${id}`, data)

    return response.data as CreateMenuCategoryResponseType
}

export const UpdatePositionWithIds = async (ids: string[]) => {
    const response = await axiosInstance.put('/menu/category/position', ids)

    return response.data
}

export const DeleteMenuCategoryApi = async (id: string) => {
    const response = await axiosInstance.put(`/menu/category?id=${id}`, {
        status: 'DELETED',
    })
    return response.data as CreateMenuCategoryResponseType
}

export const UploadImageApi = async (file: File) => {
    if (file) {
        const formData = new FormData()
        formData.append('file', file)
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
        const response = await axiosInstance.post(
            `/distribution/blogposts/${file.name}/upload`,
            formData,
            config,
        )
        return response.data as UploadResponseType
    }
}

export const BulkCategorizeApi = async (categoryId: string, productIds: string[]) => {
    const response = await axiosInstance.post(
        `/menu/category/categorize/bulk?categoryId=${categoryId}`,
        productIds,
    )

    return response as unknown
}
export const BulkRemoveCategorizeApi = async (categoryId: string, productIds: string[]) => {
    const response = await axiosInstance.post(
        `/menu/category/categorize/bulk/remove?categoryId=${categoryId}`,
        productIds,
    )

    return response as unknown
}
