import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { HomeContentType } from 'services/requests/home/schema'
import { getLocalStorageItem } from 'utils/helper/local-storage'

interface IHomePreview {
    id: string
}
export const homeCarouseIdAtom = atomWithStorage(
    'homeCarouselId',
    getLocalStorageItem('homeCarouselId'),
)

export const menuHomeContentIdAtom = atomWithStorage<string | null>('menuHomeContentId', null)

export const appHomeContentIdAtom = atomWithStorage<string | null>('appHomeContentId', null)
export const partnersHomeContentIdAtom = atomWithStorage<string | null>(
    'partnersHomeContentId',
    null,
)

export const LogoGalleryBgColorAtom = atom<string | null>(null)
export const LogoGalleryBgOpacityAtom = atom<number | null>(null)

export const HomeSelectedPreviewAtom = atom<IHomePreview | null>(null)
export const HomeSelectedContentsPreviewAtom = atom<HomeContentType | null>(null)

export const LogoGalleryLastPositionAtom = atomWithStorage<number>('logo-gallery-last-pos', 100)
