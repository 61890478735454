import { errorWhiteSpaces } from 'utils/constants'
import { WhiteSpaceDetect } from 'utils/helpers'
import { z } from 'zod'
//const today = new Date()

export const CreateNewsSchema = z.object({
    title: z
        .string({ required_error: 'Title is required.' })
        .min(1, 'Title is required.')
        .refine((val) => WhiteSpaceDetect(val), {
            message: errorWhiteSpaces,
        }),
    userId: z.string({ required_error: 'This field is required.' }),
    author: z
        .string({ required_error: 'Author is required.' })
        .min(1, 'Author is required.')
        .refine((val) => WhiteSpaceDetect(val), {
            message: errorWhiteSpaces,
        }),
    description: z
        .string({ required_error: 'Description is required.' })
        .min(1, 'Description is required is required.')
        .refine((val) => WhiteSpaceDetect(val), {
            message: errorWhiteSpaces,
        }),
    content: z
        .string({ required_error: 'Article content is required.' })
        .min(5, 'Article content is required.')
        .refine((val) => WhiteSpaceDetect(val), {
            message: errorWhiteSpaces,
        }),
    url: z
        .string({ required_error: 'URL is required.' })
        .min(1, 'URL is required.')
        .url('Invalid: Please input the URL of the article.')
        .refine((val) => WhiteSpaceDetect(val), {
            message: errorWhiteSpaces,
        }),
    featuredImage: z.string({ required_error: 'Featured image is required.' }),
    coverImage: z.string({ required_error: 'Cover image is required.' }),
    category: z.string({ required_error: 'Category is required.' }),
    visibility: z.string({ required_error: 'Visibility is required.' }),
    publishType: z.string({ required_error: 'Publish type is required.' }),
    publishDate: z
        .date({
            required_error: 'Publish date is required.',
        })
        .min(new Date('1960-01-01'), { message: 'Invalid' }),
    coverImageAltText: z
        .string({ required_error: 'Cover image alt text is required.' })
        .min(1, 'Cover image alt text is required.')
        .refine((val) => WhiteSpaceDetect(val), {
            message: errorWhiteSpaces,
        }),
    featuredImageAltText: z
        .string({ required_error: 'Featured image alt text is required.' })
        .min(1, 'Featured image alt text is required.')
        .refine((val) => WhiteSpaceDetect(val), {
            message: errorWhiteSpaces,
        }),
})

export type CreateNewsType = z.infer<typeof CreateNewsSchema>
