import React from 'react'
import { twMerge } from 'tailwind-merge'

interface AppCardProps {
    children: React.ReactNode
    className?: string
}

export const AppCard = (props: AppCardProps) => {
    return (
        <div className={twMerge('bg-white border border-gray-200 rounded p-5', props.className)}>
            {props.children}
        </div>
    )
}
