import { useLocation } from 'react-router'
import { NavigationProps } from 'types/NavigationProps'

export const extractNameInitials = (name?: string) => {
    if (!name) {
        return ''
    }

    const names = name.split(' ')

    if (names.length === 1) {
        return names[0].charAt(0)
    }

    const initials = names[0].charAt(0) + names[names.length - 1].charAt(0)

    return initials
}

export const path = (item: NavigationProps) => {
    if (item.children) {
        return item.children.find((o) => o.href === useLocation().pathname) ? true : false
    }
    return
}

export const numberFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
})
