import { Switch } from '@headlessui/react'
import React from 'react'
import { twMerge } from 'tailwind-merge'

interface IAppSwitchProps {
    isEnabled: boolean
    onChange: (enabled: boolean) => void
    className?: string // Add these two props to allow passing in additional styles
    innerClassName?: string
}

export const AppSwitch = ({
    isEnabled = false,
    onChange,
    className,
    innerClassName,
}: IAppSwitchProps) => {
    const [enabled, setEnabled] = React.useState<boolean>(isEnabled)

    const handleChange = () => {
        setEnabled(!enabled)
        onChange(!enabled)
    }

    React.useEffect(() => {
        setEnabled(isEnabled)
    }, [isEnabled])

    return (
        <Switch
            checked={enabled}
            onChange={handleChange}
            className={twMerge(
                enabled ? 'bg-red-800' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                className,
            )}
        >
            <span
                aria-hidden='true'
                className={twMerge(
                    enabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    innerClassName,
                )}
            />
        </Switch>
    )
}
