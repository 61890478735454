import { AppTextBox } from 'components/AppTextBox'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'

interface IAppAltTextInputProps {
    value: string
    name: string
    onChange: (value: string) => void
    errorMessage: string
    containerClassName?: string
}

const AppAltTextInput: React.FunctionComponent<IAppAltTextInputProps> = ({
    value,
    name,
    onChange,
    errorMessage,
    containerClassName,
}) => {
    return (
        <div className={twMerge('mt-auto flex flex-col gap-1', containerClassName ?? '')}>
            <label className='text-[14px] font-medium' htmlFor={name}>
                Alternative text (Alt Text)*
            </label>
            <AppTextBox
                type='text'
                label={'Write an image description for the visually impaired.'}
                placeholder='Describe this image'
                value={value}
                name={name}
                labelClassname='text-[12px] text-[#707070]'
                inputClassname='border rounded border-[#D8D8D8] w-full min-h-[31px] p-5 focus:outline-none focus:shadow-outline text-xxs shadow-none italic'
                onChange={onChange}
                errorClassName='font-normal'
                errorMessage={errorMessage}
            />
        </div>
    )
}

export default AppAltTextInput
