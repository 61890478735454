import React from 'react'
import { BiX } from 'react-icons/bi'
import { twMerge } from 'tailwind-merge'

interface AppBannerProps {
    children: React.ReactNode
    show?: boolean
    setShow?: React.Dispatch<React.SetStateAction<boolean>>
    className?: string
    onClose?: () => void
    isHideCloseButton?: boolean
}

export const AppBanner = (props: AppBannerProps) => {
    const handleClose = () => {
        props.setShow && props?.setShow(false)
        if (props.onClose) props?.onClose()
    }
    if (!props.show) {
        return null
    }

    return (
        <div
            className={twMerge(
                'flex items-center justify-between p-3 w-full bg-rose-800 rounded sm:before:flex-1',
                props.className,
            )}
        >
            <p className='text-xs leading-6 text-white'>
                <strong className='font-semibold'>{props.children}</strong>
            </p>
            <div className='flex flex-1 justify-end'>
                <button
                    type='button'
                    onClick={handleClose}
                    className={twMerge(
                        '-m-3 p-3 focus-visible:outline-offset-[-4px]',
                        props.isHideCloseButton && 'hidden',
                    )}
                >
                    <span className='sr-only'>Dismiss</span>
                    <BiX className='h-5 w-5 text-white' aria-hidden='true' />
                </button>
            </div>
        </div>
    )
}
