import { axiosInstance } from 'services/axios'
import {
    CreateUserType,
    PageListUserType,
    UpdateUserType,
    UserResponseType,
} from 'services/requests/users/schema'
import { ApiPaginationParams } from 'types/Commons'

export const getAllUsersApi = async (props: ApiPaginationParams) => {
    const response = await axiosInstance.get('/account/user/list', {
        params: {
            page: props.page,
            size: props.size,
            sort: props.sort,
        },
    })
    return response.data as PageListUserType
}

export const CreateUserApi = async (data: CreateUserType) => {
    const response = await axiosInstance.post('/account/user/create-user', data)
    return response.data
}

export const getUserByIdApi = async (id: string) => {
    const response = await axiosInstance.get(`/account/user/${id}`)
    // const parseData = CreateUserSchema.parse(response.data)
    return response.data as UserResponseType
}

export const UpdateUserApi = async (data: UpdateUserType, id: string) => {
    const response = await axiosInstance.put(`/account/user/update-user?id=${id}`, data)
    return response.data
}

export const UpdateUserStatusApi = async (id: string, status: string) => {
    const response = await axiosInstance.put(
        `/account/user/update-status?id=${id}&status=${status}`,
    )
    return response.data
}

export const SearchUserApi = async (query: string, props: ApiPaginationParams) => {
    const response = await axiosInstance.get(
        `/account/user/search?query=${encodeURIComponent(query)}`,
        {
            params: {
                page: props.page,
                size: props.size,
                sort: props.sort,
            },
        },
    )

    return response.data as PageListUserType
}
