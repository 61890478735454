import { BiSolidEdit } from 'react-icons/bi'
import { LocationType } from 'services/requests/store/schema'

interface StoreBodyProps {
    content: LocationType[]
    onClick: (id: string) => void
}

export const StoreBody = (props: StoreBodyProps) => {
    return (
        <>
            {props.content.map((location) => (
                <tr key={location.shopId}>
                    <td className='truncate max-w-[20vw] whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-800 sm:pl-6'>
                        {location.shopName}
                    </td>
                    <td className='truncate max-w-[40vw] whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {location.shopAddress}
                    </td>
                    <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                        <BiSolidEdit
                            className='w-5 h-5 cursor-pointer text-[#862F4C]'
                            onClick={() => props.onClick(location.id)}
                        />
                        <span className='sr-only'> {location.shopName}</span>
                    </td>
                </tr>
            ))}
        </>
    )
}
