import { AppButton } from 'components/AppButton'
import { useNavigate } from 'react-router-dom'

interface StoreFooterProps {
    isSubmitting: boolean
}

export const StoreFooter = (props: StoreFooterProps) => {
    const navigate = useNavigate()
    return (
        <div className='flex items-center justify-end mt-5'>
            <div className='grid gap-4 grid-cols-2'>
                <AppButton
                    variant='secondary'
                    type='button'
                    onClick={() => navigate(-1)}
                    disabled={props.isSubmitting}
                >
                    Cancel
                </AppButton>
                <AppButton
                    variant='primary'
                    type='submit'
                    isLoading={props.isSubmitting}
                    disabled={props.isSubmitting}
                >
                    <span className='text-center'>Save</span>
                </AppButton>
            </div>
        </div>
    )
}
