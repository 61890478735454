import Error from 'pages/Error'
// import { BrandKit } from 'pages/GlobalSettings/Brandkit'
// import { CreateBrandkit } from 'pages/GlobalSettings/Brandkit/CreateBrandkit'
import ContactUs from 'pages/GlobalSettings/ContactUs'
import { GiftCard } from 'pages/GlobalSettings/GiftCard'
import { Products } from 'pages/GlobalSettings/Products'
import { ProductsDetails } from 'pages/GlobalSettings/Products/ProductDetails'
import { Settings } from 'pages/GlobalSettings/Settings'
import { Store } from 'pages/GlobalSettings/Store'
import { StoreMenu } from 'pages/GlobalSettings/Store/StoreMenu'
import { UserManagement } from 'pages/GlobalSettings/UserManagement'
import { UserForm } from 'pages/GlobalSettings/UserManagement/UserForm'
import { Rewards } from 'pages/Mobile/OffersAndDeals/Reward'
import ManageReward from 'pages/Mobile/OffersAndDeals/Reward/ManageReward'
import { Tier } from 'pages/Mobile/OffersAndDeals/Tier'
import ManageTier from 'pages/Mobile/OffersAndDeals/Tier/ManageTier'
import { Order } from 'pages/Order'
import { OrderDetail } from 'pages/Order/OrderDetail'
import { OrderRefund } from 'pages/Order/OrderRefund'
import { QaOrder } from 'pages/QaOrder'
import Faqs from 'pages/Web/Faqs'
import AddFaqs from 'pages/Web/Faqs/AddFaqs'
import Home from 'pages/Web/Home'
import AddCarousel from 'pages/Web/Home/AddCarouselItem'
import Jobs from 'pages/Web/Jobs'
import AddJob from 'pages/Web/Jobs/AddJobs'
import JobsDescriptions from 'pages/Web/Jobs/PreviewDescriptions'
import MenuCategory from 'pages/Web/MenuCategory'
import Categories from 'pages/Web/MenuCategory/Categories'
import MenuCategoryForm from 'pages/Web/MenuCategory/Form'
import MenuCategoryProductForm from 'pages/Web/MenuCategory/ProductsForm'
import News from 'pages/Web/News'
import AddNews from 'pages/Web/News/add-news'
import Partnerships from 'pages/Web/Partnerships'
import AddUpdatePartnership from 'pages/Web/Partnerships/add-partnership'
import { RouteObject } from 'react-router-dom'
import { AccessibilityStatement } from '../../pages/GlobalSettings/AccessibilityStatement'
import { PrivacyAndPolicy } from '../../pages/GlobalSettings/PrivacyPolicy'
import { TermsAndConditions } from '../../pages/GlobalSettings/TermsAndConditions'
import Container from './Container'

export const PrivateRoutes: RouteObject = {
    path: '/',
    element: <Container />,
    children: [
        {
            path: '*',
            element: <Error />,
        },
        {
            path: 'error',
            element: <Error />,
        },
        {
            path: '/order',
            children: [
                {
                    element: <Order />,
                    index: true,
                },
                {
                    path: ':id',
                    element: <OrderDetail />,
                },
                {
                    path: ':id/refund',
                    element: <OrderRefund />,
                },
            ],
        },
        {
            path: 'qa-order',
            element: <QaOrder />,
        },
        {
            path: 'mobile',
            children: [
                // {
                //     path: 'dashboard',
                //     element: <Dashboard />,
                // },
                {
                    path: 'offersanddeals',
                    element: <Rewards />,
                },
                {
                    path: 'offersanddeals/tier',
                    element: <Tier />,
                },
                {
                    path: 'offersanddeals/rewards/manageReward',
                    children: [
                        { path: ':pointingTierId', element: <ManageReward /> },
                        { index: true, element: <ManageReward /> },
                    ],
                },
                {
                    path: 'offersanddeals/tier/manageTier',
                    children: [
                        { path: ':tierId', element: <ManageTier /> },
                        { index: true, element: <ManageTier /> },
                    ],
                },
            ],
        },
        {
            path: 'web',
            children: [
                // {
                //     path: 'dashboard',
                //     element: <h1 className='text-3xl font-bold underline'> Web Dashboard</h1>,
                // },
                {
                    path: 'home',
                    element: <Home />,
                },
                {
                    path: 'home/addContent',
                    element: <AddCarousel />,
                },
                {
                    path: 'home/updateContent/:id',
                    element: <AddCarousel />,
                },
                {
                    path: 'faqs',
                    element: <Faqs />,
                },
                {
                    path: 'faqs/add',
                    element: <AddFaqs />,
                },
                {
                    path: 'faqs/edit/:id',
                    element: <AddFaqs />,
                },
                {
                    path: 'news/add',
                    element: <AddNews />,
                },
                {
                    path: 'news',
                    element: <News />,
                },
                {
                    path: 'news/edit/:id',
                    element: <AddNews />,
                },
                {
                    path: 'jobs',
                    element: <Jobs />,
                },
                {
                    path: 'jobs/add',
                    element: <AddJob />,
                },
                {
                    path: 'jobs/edit/:id',
                    element: <AddJob />,
                },
                {
                    path: 'jobs/descriptions/:id',
                    element: <JobsDescriptions />,
                },
                {
                    path: 'partnerships',
                    element: <Partnerships />,
                },
                {
                    path: 'partnerships/add',
                    element: <AddUpdatePartnership />,
                },
                {
                    path: 'partnerships/edit/:id',
                    element: <AddUpdatePartnership />,
                },
                {
                    path: 'menu-category',
                    element: <MenuCategory />,
                    children: [
                        {
                            path: '',
                            element: <Categories />,
                        },
                        {
                            path: 'products',
                            element: <MenuCategoryProductForm />,
                        },
                    ],
                },
                {
                    path: 'menu-category/add',
                    element: <MenuCategoryForm />,
                },
                {
                    path: 'menu-category/update/:id',
                    element: <MenuCategoryForm />,
                },
            ],
        },
        {
            path: 'global-settings',
            children: [
                // {
                //     path: 'brandkit',
                //     element: <BrandKit />,
                // },
                // {
                //     path: 'brandkit/:id',
                //     element: <CreateBrandkit />,
                // },
                // {
                //     path: 'brandkit/create-new',
                //     element: <CreateBrandkit />,
                // },

                {
                    path: 'stores',
                    element: <Store />,
                },
                {
                    path: 'stores/edit/:id',
                    element: <StoreMenu />,
                },
                {
                    path: 'stores/edit/new',
                    element: <StoreMenu />,
                },
                {
                    path: 'user-management/:id',
                    element: <UserForm />,
                },
                {
                    path: 'user-management',
                    element: <UserManagement />,
                },
                {
                    path: 'user-management/add',
                    element: <UserForm />,
                },
                {
                    path: 'user-management/:id',
                    element: <UserForm />,
                },
                {
                    path: 'privacy-policy',
                    element: <PrivacyAndPolicy />,
                },
                {
                    path: 'terms-and-conditions',
                    element: <TermsAndConditions />,
                },
                {
                    path: 'accessibility-statement',
                    element: <AccessibilityStatement />,
                },
                {
                    path: 'gift-card',
                    element: <GiftCard />,
                },
                {
                    path: 'contact-us',
                    element: <ContactUs />,
                },
                {
                    path: 'settings',
                    element: <Settings />,
                },
                {
                    path: 'products',
                    children: [
                        {
                            element: <Products />,
                            index: true,
                        },
                        {
                            path: 'product/:id',
                            element: <ProductsDetails />,
                        },
                    ],
                },
            ],
        },
    ],
}
