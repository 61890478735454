import { AxiosError } from 'axios'
import AppAutoComplete from 'components/AppAutocomplete'
import { AppButton } from 'components/AppButton'
import AppEmptyTable from 'components/AppEmptyTable'
import { AppTextBox } from 'components/AppTextBox'
import React, { useCallback, useMemo, useState } from 'react'
import { HiMagnifyingGlass } from 'react-icons/hi2'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import Select from 'react-select'
import {
    BulkCategorizeApi,
    BulkRemoveCategorizeApi,
    Categorize,
    listMenuCategoryApi,
    listProductsApi,
    RemoveCategorize,
} from 'services/requests/menu-category'
import { ListProductType, ObjectProductType } from 'services/requests/menu-category/schema'
import { twMerge } from 'tailwind-merge'
import { UseTruncate } from 'utils/constants'

interface Isort {
    filter: string | null
    sort: string | null
}
const MenuCategoryProductForm: React.FunctionComponent = () => {
    const { data: products } = useQuery(['products'], () => listProductsApi())
    const { data: categories } = useQuery(['menu-category'], () => listMenuCategoryApi())
    const [sort, setSort] = useState<Isort>({ filter: 'All', sort: null })
    const [selectedCategory, setSelectedCategory] = useState('')
    const [selectedProducts, setSelectedProducts] = useState<ObjectProductType[] | []>([])

    const allProductsWithoutCategory = selectedProducts.every((product) => {
        return (
            !product.categories ||
            product.categories.length === 0 ||
            product.categories.every((category) => category.status === 'DELETED')
        )
    })

    const queryClient = useQueryClient()
    interface ICategorize {
        productId: string
        categoryId: string
        isChecked: boolean
    }

    interface IBulkCategorize {
        isPosting?: boolean
        categoryId: string
        productIds: string[]
    }
    const { mutate: bulkPostRelationship } = useMutation<unknown, AxiosError, IBulkCategorize>(
        (data) =>
            data.isPosting
                ? BulkCategorizeApi(data.categoryId, data.productIds)
                : BulkRemoveCategorizeApi(data.categoryId, data.productIds),

        {
            onSuccess: () => {
                queryClient.invalidateQueries('products')
                queryClient.invalidateQueries('productsFromId')
                queryClient.invalidateQueries('menu-category')
            },
            onError: (error) => console.log(error),
        },
    )
    const { mutate: postRelationship } = useMutation<unknown, AxiosError, ICategorize>(
        (data) =>
            !data.isChecked
                ? RemoveCategorize(data.categoryId, data.productId)
                : Categorize(data.categoryId, data.productId),

        {
            onSuccess: () => {
                queryClient.invalidateQueries('products')
                queryClient.invalidateQueries('productsFromId')
                queryClient.invalidateQueries('menu-category')
            },
            onError: (error) => console.log(error),
        },
    )

    const handleSelectAllProducts = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelectedProducts(filteredProduct ?? [])
        } else {
            setSelectedProducts([])
            setSelectedCategory('')
        }
    }

    interface ISelect {
        value: string
        label: string
    }
    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        product: ObjectProductType,
    ) => {
        event.target.checked
            ? setSelectedProducts((prev) => [...prev, product])
            : setSelectedProducts((prev) =>
                  prev.filter((productsIds) => productsIds.id !== product.id),
              )
    }
    const filteredProduct = useMemo(
        () =>
            products &&
            products.filter(
                (products) =>
                    (products.productName
                        .toLowerCase()
                        .includes((sort.sort ?? '').toLocaleLowerCase()) &&
                        sort.filter === 'All') ||
                    products.categories.some((category) =>
                        category?.name.toLowerCase().includes((sort.filter ?? '').toLowerCase()),
                    ),
            ),
        [products, categories, sort.sort, sort.filter, selectedProducts],
    )
    const isAllProductChecked = useMemo(
        () => filteredProduct?.length === selectedProducts.length && filteredProduct.length > 0,
        [products, selectedProducts, filteredProduct],
    )
    const renderProducts = useCallback(
        (products: ListProductType) => {
            if (products.length > 0) {
                return products.map((item, index) => {
                    return (
                        <div
                            className={twMerge(
                                'inline-flex items-center w-full px-6 justify-between py-1 border',
                                index % 2 === 0 ? 'bg-gray-100' : 'bg-white',
                            )}
                            key={item.id}
                        >
                            <div className='flex justify-center items-center w-[10%]'>
                                <input
                                    checked={selectedProducts.some(
                                        (productIds) => productIds.id === item.id,
                                    )}
                                    type='checkbox'
                                    className='accent-[#861F41]'
                                    onChange={(e) => handleCheckboxChange(e, item)}
                                />
                            </div>
                            <div className='inline-flex items-center gap-3 w-[55%] max-h-[85px] overflow-hidden'>
                                <img
                                    className='w-[4rem] h-[4rem] object-cover rounded-full mx-9'
                                    src={item?.coverPhoto}
                                    alt=''
                                />
                                <div className='h-[80px] xl:max-h-[65px] overflow-hidden'>
                                    <h1 className='text-[14px] font-bold'>{item?.productName}</h1>
                                    <h1
                                        style={UseTruncate(2)}
                                        className='text-[12px]'
                                    >{`Description: ${item?.description}`}</h1>
                                </div>
                            </div>
                            <div className='w-[50%] flex gap-4 px-7 items-center'>
                                <Select
                                    isMulti={true}
                                    placeholder={'Select Category'}
                                    className='w-full text-xs'
                                    onChange={(value) => {
                                        const array = value as ISelect[]
                                        item.categories.forEach((cat) => {
                                            if (
                                                !array.some((selected) => selected.value === cat.id)
                                            ) {
                                                postRelationship({
                                                    isChecked: false,
                                                    categoryId: cat.id,
                                                    productId: item.id,
                                                })
                                                return
                                            }
                                        })
                                        array.forEach((selected) => {
                                            if (
                                                !item.categories.some(
                                                    (cat) => selected.value === cat.id,
                                                )
                                            ) {
                                                postRelationship({
                                                    isChecked: true,
                                                    categoryId: selected.value,
                                                    productId: item.id,
                                                })
                                                return
                                            }
                                        })
                                    }}
                                    value={item.categories
                                        .filter((cat) => cat.status !== 'DELETED')
                                        .map(
                                            (category) =>
                                                new Object({
                                                    value: category.id,
                                                    label: category.name,
                                                }),
                                        )}
                                    options={
                                        categories
                                            ?.filter(
                                                (cat) =>
                                                    !item.categories.some(
                                                        (itemCat) => cat.id === itemCat.id,
                                                    ),
                                            )
                                            .map(
                                                (item) =>
                                                    new Object({
                                                        label: item.name,
                                                        value: item.id,
                                                    }),
                                            ) ?? []
                                    }
                                />
                            </div>
                        </div>
                    )
                })
            }
            return <AppEmptyTable containerClassName='min-h-[25rem]' />
        },
        [products, categories, sort.sort, sort.filter, selectedProducts],
    )

    const handleBulkCategorize = (value: string, isPosting: boolean) => {
        const categoryId = categories?.find((category) => category.name === value)?.id ?? ''
        const productIds = selectedProducts.map((item) => item.id)
        bulkPostRelationship({ categoryId, productIds, isPosting })
        setSelectedCategory('')
        // setSelectedProducts([])
    }
    return (
        <div className='p-2 m-2 flex flex-col w-full'>
            {selectedProducts.length > 0 ? (
                <div
                    className={twMerge(
                        'w-full py-5 justify-between items-center gap-3 inline-flex',
                    )}
                >
                    <h1 className='font-bold text-[#862F4C]'>{`${selectedProducts.length} Items Selected`}</h1>
                    <div className='inline-flex gap-4 px-7'>
                        <AppAutoComplete
                            mainContainerClassName='inline-flex justify-center items-center gap-2'
                            label={'Bulk Select'}
                            labelClassname=''
                            inputClassname='border-none'
                            value={selectedCategory}
                            name='list'
                            list={[...(categories?.map((item) => item?.name) ?? [])] ?? []}
                            onChange={(value) => setSelectedCategory(value)}
                        />

                        <AppButton
                            variant='secondary'
                            disabled={selectedCategory === ''}
                            type='button'
                            onClick={() => handleBulkCategorize(selectedCategory, true)}
                            className='px-8 py-3 bg-white font-bold text-[#862F4C] border-[#862F4C]'
                        >
                            Bulk Add
                        </AppButton>
                        <AppButton
                            variant='secondary'
                            disabled={selectedCategory === '' || allProductsWithoutCategory}
                            onClick={() => handleBulkCategorize(selectedCategory, false)}
                            type='button'
                            className='px-8 py-3 bg-[#862F4C] font-bold text-white'
                        >
                            Bulk Remove
                        </AppButton>
                    </div>
                </div>
            ) : (
                <div className='w-full inline-flex justify-between'>
                    <div className='relative h-fit my-5'>
                        <HiMagnifyingGlass className='absolute translate-y-[-50%] top-[50%] left-2' />
                        <AppTextBox
                            placeholder='Search Product'
                            type='text'
                            inputClassname='px-8'
                            name='search'
                            onChange={(value) => setSort({ ...sort, sort: value })}
                        />
                    </div>
                    <AppAutoComplete
                        mainContainerClassName='inline-flex justify-center items-center gap-2'
                        label='Filter by Category'
                        labelClassname='font-[500] text-[14px]'
                        name='list'
                        value={sort?.filter ?? 'All'}
                        list={['All', ...(categories?.map((item) => item?.name) ?? [])] ?? ['All']}
                        onChange={(value) => setSort({ ...sort, filter: value })}
                    />
                </div>
            )}

            <div className='flex text-[14px] text-[#861F41] bg-[#E3D5DA] font-bold p-4 rounded-t-lg items-center '>
                <label className='w-[10%] flex gap-1 justify-center items-center'>
                    <input
                        checked={isAllProductChecked}
                        className='accent-[#861F41]'
                        onChange={handleSelectAllProducts}
                        type='checkbox'
                    />
                </label>
                <h1 className='w-[55%]'>{`Product Name (${filteredProduct?.length ?? 0})`}</h1>
                <h1 className='w-[35%]'>Categories</h1>
            </div>
            {filteredProduct ? (
                renderProducts(filteredProduct)
            ) : (
                <AppEmptyTable
                    containerClassName='min-h-[25rem]'
                    descriptionEmptyState=''
                    isEmptyState={true}
                />
            )}
        </div>
    )
}

export default MenuCategoryProductForm
