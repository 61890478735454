import { AppCard } from 'components/AppCard'
import { useSetAtom } from 'jotai'
import { AppCheckbox } from 'pages/GlobalSettings/Brandkit/CreateBrandkit/Checkbox'
import { useEffect, useState } from 'react'
import { OrderType } from 'services/requests/orders/schema'
import { refundAtom, RefundProps } from 'store/refundAtom'
import { twMerge } from 'tailwind-merge'
import { numberFormatter } from 'utils'
import ProductDisplayImage from './ProductDisplayImage'

interface OrderSummaryProps {
    orderData: OrderType
}

export const OrderSummary = (props: OrderSummaryProps) => {
    const [checkedItems, setCheckedItems] = useState<string[]>([])
    const setRefundAtom = useSetAtom(refundAtom)
    const statusThatCannotBeRefunded = [
        'REFUNDED',
        'PENDING',
        'COMPLETED',
        'REFUND_INITIATED',
        'REFUND_COMPLETED',
    ]

    const nonRefundedItemsLength = props.orderData.items.filter(
        (item) => !statusThatCannotBeRefunded.includes(item.status) && item.type !== 'GIFT_CARD',
    ).length

    const isSelectedAllChecked =
        checkedItems.length === nonRefundedItemsLength && nonRefundedItemsLength !== 0

    const handleItemCheckChange = (itemId: string, isChecked: boolean) => {
        if (isChecked) {
            setCheckedItems((prev) => [...prev, itemId])
        } else {
            setCheckedItems((prev) => prev.filter((id) => id !== itemId))
        }
    }

    const toggleSelectAll = () => {
        if (isSelectedAllChecked) {
            setCheckedItems([])
        } else {
            const nonRefundedItemIds = props.orderData.items
                .filter(
                    (item) =>
                        !statusThatCannotBeRefunded.includes(item.status) &&
                        item.type !== 'GIFT_CARD',
                )
                .map((item) => item.id)

            setCheckedItems(nonRefundedItemIds)
        }
    }

    const handleStatus = (status: string) => {
        switch (status) {
            case 'ORDER_CREATED':
                return 'Order Placed'
            case 'PENDING':
                return 'Refund Pending'
            case 'COOKING':
                return 'Cooking'
            case 'DONE':
                return 'Completed'
            case 'REFUNDED':
                return 'Refunded'
            case 'OUT_OF_STOCK':
                return 'Out of Stock'
            case 'RECHARGE_GIFT_CARD':
                return 'Recharge Gift Card'
            case 'REFUND_INITIATED':
                return 'Refund Initiated'
            case 'REFUND_COMPLETED':
                return 'Refund Completed'
            case 'COMPLETED':
                return 'Completed'
            case 'FAILED':
                return 'Cooking failed'
            case 'NOT_STARTED':
                return 'Not Started'
            default:
                return 'Status not handled'
        }
    }

    useEffect(() => {
        if (checkedItems.length === 0) {
            setRefundAtom({} as RefundProps)
        }
        setRefundAtom({
            orderId: props.orderData.id,
            orderLineIds: checkedItems,
            reason: 'Machine Suspended',
        })
    }, [checkedItems])

    return (
        <section>
            <AppCard>
                <div className='px-4 sm:px-6 lg:px-8'>
                    <div className='flow-root'>
                        <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                            <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                                <table className='min-w-full divide-y divide-gray-300'>
                                    <thead>
                                        <tr>
                                            <th
                                                scope='col'
                                                className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                                            >
                                                <AppCheckbox
                                                    id='select-all'
                                                    label=''
                                                    checked={isSelectedAllChecked}
                                                    onChange={toggleSelectAll}
                                                    disabled={nonRefundedItemsLength === 0}
                                                />
                                            </th>
                                            <th
                                                scope='col'
                                                className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                            >
                                                Product Name
                                            </th>
                                            <th
                                                scope='col'
                                                className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                            >
                                                Order ID
                                            </th>
                                            <th
                                                scope='col'
                                                className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                            >
                                                Status
                                            </th>
                                            <th
                                                scope='col'
                                                className='relative py-3.5 pl-3 text-right text-sm pr-4 sm:pr-0'
                                            >
                                                Sub-total
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='divide-y divide-gray-200'>
                                        {props.orderData.items.map((order) => (
                                            <tr key={order.id}>
                                                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                                                    <AppCheckbox
                                                        id={order.id}
                                                        label=''
                                                        checked={checkedItems.includes(order.id)}
                                                        onChange={(checked) =>
                                                            handleItemCheckChange(order.id, checked)
                                                        }
                                                        disabled={
                                                            statusThatCannotBeRefunded.includes(
                                                                order.status,
                                                            ) || order.type === 'GIFT_CARD'
                                                        }
                                                    />
                                                </td>
                                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                                    <ProductDisplayImage
                                                        isGiftCard={order.type === 'GIFT_CARD'}
                                                        coverPhoto={order.product.coverPhoto}
                                                        price={order?.totalPrice}
                                                    />
                                                    <p>{order.product.name}</p>
                                                </td>
                                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                                    {order.externalOrderId}
                                                </td>
                                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                                    {order.type === 'GIFT_CARD' ? (
                                                        <span className='bg-slate-500 p-1 text-sm rounded-lg text-white'>
                                                            NON-REFUNDABLE
                                                        </span>
                                                    ) : (
                                                        handleStatus(order.status)
                                                    )}
                                                </td>
                                                <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0'>
                                                    <span
                                                        className={twMerge(
                                                            order.totalPrice > 0 &&
                                                                'line-through text-xs',
                                                        )}
                                                    >
                                                        $
                                                        {numberFormatter.format(
                                                            order.originalPrice,
                                                        )}
                                                    </span>
                                                    {order.totalPrice > 0 && (
                                                        <p className='text-red-700 font-bold'>
                                                            $
                                                            {numberFormatter.format(
                                                                order.basePrice,
                                                            )}
                                                        </p>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </AppCard>
        </section>
    )
}
