import Blockquote from '@tiptap/extension-blockquote'
import BulletList from '@tiptap/extension-bullet-list'
import TextColor from '@tiptap/extension-color'
import FontFamily from '@tiptap/extension-font-family'
import Link from '@tiptap/extension-link'
import OrderedList from '@tiptap/extension-ordered-list'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import { generateHTML } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import parse from 'html-react-parser'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import FontSize from 'tiptap-extension-font-size'

interface IAppRenderHtmlProps {
    contentDescription: string
    isLoading?: boolean
    containerClassName?: string
}

const AppRenderHtml: React.FunctionComponent<IAppRenderHtmlProps> = ({
    isLoading,
    contentDescription,
    containerClassName,
}) => {
    const renderHTML = (contentJSON: string) => {
        if (!contentJSON)
            return (
                <>
                    <div>
                        <span>Please contact admin for content</span>
                    </div>
                </>
            )
        return parse(
            generateHTML(JSON.parse(contentJSON), [
                StarterKit.configure({
                    orderedList: false,
                    bulletList: false,
                }),
                TextStyle,
                FontFamily,
                Link,
                TextColor,
                OrderedList.configure({
                    keepMarks: true,
                    keepAttributes: true,
                    HTMLAttributes: {
                        style: 'list-style-type: decimal; padding-left: 1em; position: relative;',
                    },
                }),
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
                BulletList.configure({
                    keepMarks: true,
                    keepAttributes: true,
                    HTMLAttributes: {
                        style: 'list-style-type: disc; padding-left: 1em; position: relative;',
                    },
                }),
                Blockquote.configure({
                    HTMLAttributes: {
                        style: 'border-left: 2px solid #ddd; padding-left: 1em; margin-left: 0; color: #888; margin-bottom: 0.5em;',
                    },
                }),
                FontSize,
            ]),
        )
    }

    if (!isLoading && contentDescription)
        return (
            <div className={twMerge(containerClassName, '')} role='container'>
                {renderHTML(contentDescription || '')}
            </div>
        )
    return <></>
}

export default AppRenderHtml
