import { AppCard } from 'components/AppCard'
import AppSkeletonLoadingProducts from 'components/AppSkeletonLoaders/AppSkeletonLoadingProducts'
import { useAtom } from 'jotai'
import { useQuery } from 'react-query'
import { listProductsApi } from 'services/requests/menu-category'
import { menuObjectProductArrayAtom, menuSearchKeyWord } from 'store/productAtom'
import { CounterAndSearch } from './CounterAndSearch'
import { TableContent } from './TableContent'

export const ProductsTable = () => {
    const [keyword, setKeyword] = useAtom(menuSearchKeyWord)
    const [productArray, setProductArray] = useAtom(menuObjectProductArrayAtom)

    const { data: products, isLoading: isProductsLoading } = useQuery(
        ['products'],
        () => listProductsApi(),
        {
            onSuccess: (data) => {
                setProductArray(data)
                setKeyword((prev) => {
                    return { ...prev, type: 'All' }
                })
            },
        },
    )

    if (isProductsLoading) {
        return <AppSkeletonLoadingProducts />
    }
    return (
        <section>
            <AppCard className='mt-10'>
                <CounterAndSearch products={products} />
                <TableContent products={productArray} />
            </AppCard>
        </section>
    )
}
