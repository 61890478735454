import { AxiosError, isAxiosError } from 'axios'
import { AppButton } from 'components/AppButton'
import AppRenderHtml from 'components/AppRenderHtml'
import { AppRichTextBox } from 'components/AppRichTextBox'
import { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { BsPencil } from 'react-icons/bs'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { createContentApi, getContentApi, updateContentApi } from 'services/requests/content'
import { ContentType, CreateContentType } from 'services/requests/content/schema'
import { twMerge } from 'tailwind-merge'

interface RichTextEditorWrapperProps {
    title: string
}

export const RichTextEditorWrapperHome = (props: RichTextEditorWrapperProps) => {
    const queryClient = useQueryClient()
    const [isEditing, setIsEditing] = useState(false)
    const {
        isFetching,
        isLoading,
        data,
        error: queryError,
    } = useQuery({
        queryKey: ['content', props.title],
        queryFn: () => getContentApi(String(props.title)),
        enabled: Boolean(props.title),
        onSuccess: (data) => {
            setValue('title', data.contentTitle)
            setValue('description', data.contentDescription)
        },
    })

    const { control, handleSubmit, setValue } = useForm<CreateContentType>({
        mode: 'onBlur',
        defaultValues: {
            title: props.title,
            description: data?.contentDescription || '',
        },
    })

    const { mutate: saveContent, isLoading: isContentSaving } = useMutation<
        ContentType,
        AxiosError,
        CreateContentType
    >(
        (data) => {
            if (isAxiosError(queryError) && queryError?.response?.status === 404)
                return createContentApi(data)
            else return updateContentApi(props.title, data)
        },
        {
            onSuccess: () => {
                toast.success('Success! Home content saved!')
                queryClient.invalidateQueries(['content', props?.title])
            },
        },
    )

    const onSubmit = (data: CreateContentType) => {
        setIsEditing(false)
        saveContent(data)
    }
    useMemo(() => setValue('description', data?.contentDescription ?? ''), [isEditing])

    if (data)
        return (
            <div className='border-[#861F41] border-[1px] p-5 rounded-[5px] relative'>
                <AppButton
                    variant='secondary'
                    type='button'
                    className={twMerge(
                        'absolute -right-4 rounded-full p-2 -top-4 hover:text-white text-[#861F41]',
                        isEditing && 'bg-[#861F41] text-white',
                    )}
                    onClick={() => setIsEditing(!isEditing)}
                >
                    <BsPencil className='w-6 h-6' />
                </AppButton>
                {isEditing ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            name='description'
                            control={control}
                            render={({ field: { onChange, value, name } }) => (
                                <AppRichTextBox value={value} name={name} onChange={onChange} />
                            )}
                        />
                        <div className='flex gap-6 py-6 ml-auto justify-end'>
                            <AppButton
                                variant='secondary'
                                type='button'
                                onClick={() => setIsEditing(false)}
                            >
                                Cancel
                            </AppButton>
                            <AppButton variant='primary' type='submit' isLoading={isContentSaving}>
                                Save
                            </AppButton>
                        </div>
                    </form>
                ) : (
                    <div className=''>
                        <AppRenderHtml
                            contentDescription={data?.contentDescription ?? ''}
                            isLoading={isLoading}
                        />
                    </div>
                )}
            </div>
        )
    return <div className='min-h-[30rem]'></div>
}
