import { atom } from 'jotai'

export type MapLocationType = {
    lat: GeolocationPosition['coords']['latitude']
    lng: GeolocationPosition['coords']['longitude']
}

export const distanceLocationAtom = atom<number>(5)

export const UserCoordinatesAtom = atom<MapLocationType | undefined>(undefined)
export const SearchedCoordinatesAtom = atom<MapLocationType | undefined>(undefined)
export const CurrentMapCenter = atom<MapLocationType | undefined>(undefined)
export const UserAddressAtom = atom<string | undefined>('')
export const isMapLoadedAtom = atom<boolean>(false)
export const isLocationServiceEnabledAtom = atom<boolean>(false)
export const UserLocationGPSAtom = atom<MapLocationType | undefined>(undefined)
