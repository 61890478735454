import { AxiosError } from 'axios'
import ConfirmationModal from 'components/ActionConfirmation'
import { AppButton } from 'components/AppButton'
import AppEmptyTable from 'components/AppEmptyTable'
import { AppModal } from 'components/AppModal'
import AppPageName from 'components/AppPageName'
import AppSeachBar from 'components/AppSearchBar'
import Loading from 'components/loading'
import { useAtom } from 'jotai'
import React, { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import {
    DeletePartnershipApi,
    listPartnerships,
    UpdatePartnershipApi,
} from 'services/requests/partnerships'
import {
    ListPartnershipType,
    ObjectPartnershipType,
    UpdatePartnershipsType,
} from 'services/requests/partnerships/schema'
import { PartnershipsFetchingDataAtom } from 'store/partnershipAtom'
import { twMerge } from 'tailwind-merge'
import { tableWebHeader } from 'utils/styles'
import SortableTable from './sortable-items'

const Partnerships: React.FunctionComponent = () => {
    const { data, isLoading } = useQuery('partnerships', () => listPartnerships(), {
        onSuccess: () => {
            setSortedData(null)
        },
    })
    const [, setIsLoadingPartnership] = useAtom(PartnershipsFetchingDataAtom)
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const [moved, setMoved] = useState<number>(0)
    const [selectedPartnerships, setSelectedPartnerships] = useState<ListPartnershipType | []>([])
    const [isOpenConfirmationModal, setIsOpenConfirmationModal] = React.useState(false)
    const [filter, setFilter] = useState<string>('')
    const [sortedData, setSortedData] = useState<ListPartnershipType | null>(null)
    const handleSelect = (checked: boolean, item: ObjectPartnershipType) => {
        checked
            ? setSelectedPartnerships((prev) => [...prev, item])
            : setSelectedPartnerships((prev) => prev.filter((selected) => selected.id !== item.id))
    }

    const Partnerships = useMemo(
        () =>
            data?.filter(
                (partnership) =>
                    partnership.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
                    partnership.description.toLocaleLowerCase().includes(filter.toLowerCase()),
            ) ?? [],
        [data, filter],
    )

    useMemo(() => setIsLoadingPartnership(isLoading), [isLoading])
    interface ItableFields {
        label: string
        colspan: string
    }
    const tableFields: ItableFields[] = [
        {
            label: '',
            colspan: '1',
        },
        {
            label: 'Partnership',
            colspan: '5',
        },
        {
            label: 'Status',
            colspan: '3',
        },
        {
            label: 'Action',
            colspan: '2',
        },
    ]
    const { mutate: deleteParnerships, isLoading: deleteLoading } = useMutation<
        unknown,
        AxiosError,
        string
    >((id) => DeletePartnershipApi(id), {
        onSuccess: () => {
            setMoved((prev) => prev + 1)
        },
        onError: (error) => {
            console.log('ERROR', error)
        },
    })
    interface IUpdatePartnershipTypeWithId extends UpdatePartnershipsType {
        id: string
    }
    const { mutate: updatePartnership } = useMutation<
        unknown,
        AxiosError,
        IUpdatePartnershipTypeWithId
    >(
        (data) =>
            UpdatePartnershipApi(data.id, {
                description: data.description,
                status: data.status,
                title: data.title,
            }),
        {
            onSuccess: () => {
                setMoved((prev) => prev + 1)
            },
            onError: (error) => console.log(error),
        },
    )

    const handleDeleteBulk = () => {
        if (selectedPartnerships.length > 0) {
            selectedPartnerships.forEach(({ id }) => deleteParnerships(id))
            setSelectedPartnerships([])
            setIsOpenConfirmationModal(false)
            toast.success('Success! Items are deleted.')
        }
    }
    const handleBulkChangeStatus = (status: string) => {
        if (selectedPartnerships.length > 0) {
            selectedPartnerships.forEach((item) => updatePartnership({ ...item, status }))
            toast.success('Success! Status updated!')
            setSelectedPartnerships([])
        }
    }

    useMemo(() => queryClient.invalidateQueries('partnerships'), [moved])
    const isSelectedMorethanOne = React.useMemo(
        () => selectedPartnerships.length > 1,
        [selectedPartnerships],
    )
    const RenderTable = useCallback(() => {
        return (
            <SortableTable
                setSortedData={setSortedData}
                selectedPartnerships={selectedPartnerships}
                setMoved={setMoved}
                array={sortedData ?? Partnerships}
                handleSelect={handleSelect}
                setSelectedPartnerships={setSelectedPartnerships}
            />
        )
    }, [Partnerships, data, isLoading, selectedPartnerships, sortedData])

    return (
        <div className='flex flex-col gap-4 text-[#464255]'>
            <AppPageName pageName='Partnership' />
            <div className='bg-white flex flex-col gap-3 py-5 min-w-full'>
                <div
                    className={twMerge(
                        selectedPartnerships.length > 0 ? 'flex' : 'hidden',
                        'w-full items-center gap-6 p-6',
                    )}
                >
                    <h1>{`Selected Items(${selectedPartnerships.length})`}</h1>
                    <AppButton
                        variant='secondary'
                        type='button'
                        className='px-5'
                        onClick={() => setIsOpenConfirmationModal(true)}
                    >
                        Delete Selected
                    </AppButton>
                    <AppButton
                        variant='secondary'
                        type='button'
                        className='px-5'
                        onClick={() => handleBulkChangeStatus('ACTIVE')}
                    >
                        Set Status: Active
                    </AppButton>
                    <AppButton
                        variant='secondary'
                        type='button'
                        className='px-5'
                        onClick={() => handleBulkChangeStatus('DRAFT')}
                    >
                        Draft All
                    </AppButton>
                </div>
                <div className='inline-flex w-full justify-between p-6'>
                    <AppSeachBar
                        onChange={setFilter}
                        placeHolder='Search Partnership'
                        inputClassName='py-2'
                    />
                    <AppButton
                        variant='primary'
                        type='button'
                        onClick={() => navigate('add')}
                        className='text-[14px] px-5'
                    >
                        Create New
                    </AppButton>
                </div>
                <div className='w-full min-h-[50vh] bg-white text-[15px] relative'>
                    <div className='grid grid-cols-12 w-full text-center overflow-visible'>
                        <div className='col-span-1 flex items-center justify-center bg-[#EDDFE2]' />
                        <div className='col-span-1 flex items-center justify-center bg-[#EDDFE2]'>
                            <input
                                type='checkbox'
                                checked={
                                    selectedPartnerships?.length === Partnerships?.length &&
                                    Partnerships.length > 0
                                }
                                className={
                                    'h-4 w-4 m-auto rounded border-gray-300 accent-[#861F41]'
                                }
                                onChange={(e) =>
                                    e.target.checked
                                        ? setSelectedPartnerships(Partnerships)
                                        : setSelectedPartnerships([])
                                }
                            />
                        </div>

                        {tableFields.splice(1).map((item, index) => (
                            <h1
                                key={index}
                                className={twMerge(
                                    `col-span-${item?.colspan}`,
                                    'h-full text-center bg-[#EDDFE2] min-h-[55px] flex justify-center items-center',
                                    item.label === 'Partnership' && 'text-start justify-start',
                                    tableWebHeader,
                                )}
                            >
                                {item?.label}
                            </h1>
                        ))}
                        <div className='col-span-12 border-y text-[15px]' />
                        {isLoading && (
                            <div className='absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%]'>
                                <Loading className='w-full h-full' />
                            </div>
                        )}
                        {Partnerships.length > 0 ? (
                            <RenderTable />
                        ) : (
                            <AppEmptyTable
                                containerClassName='col-span-12 min-h-[25rem]'
                                isEmptyState={filter.length === 0}
                            />
                        )}
                    </div>
                </div>
                <AppModal
                    closeIcon={false}
                    isOpen={isOpenConfirmationModal}
                    onClose={() => setIsOpenConfirmationModal(false)}
                    title=''
                    containerClassName='h-fit p-0 my-0'
                >
                    <ConfirmationModal
                        handle={handleDeleteBulk}
                        onClose={() => setIsOpenConfirmationModal(false)}
                        yesLabel='Delete'
                        noLabel='Cancel'
                        title='Delete Selected'
                        isLoading={deleteLoading}
                        subtitle={`Are you sure you want to delete ${
                            isSelectedMorethanOne ? 'these items' : 'this item'
                        }?`}
                    />
                </AppModal>
            </div>
        </div>
    )
}

export default Partnerships
