import { AxiosError } from 'axios'
import { AppButton } from 'components/AppButton'
import { AppModal } from 'components/AppModal'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { deleteLoyaltyTier } from 'services/requests/account'

interface DeleteModalProps {
    onClose: React.Dispatch<React.SetStateAction<boolean>>
    isOpen: boolean
    id: string
}

export const DeleteModal = ({ onClose, id, isOpen }: DeleteModalProps) => {
    const queryClient = useQueryClient()

    const { mutate: deleteTierMu, isLoading } = useMutation<unknown, AxiosError, string>(
        (id) => deleteLoyaltyTier(id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('pointingTierList')
                onClose(false)
                toast.success('Tier Deleted Successfully!')
            },
            onError: (error) => {
                console.log('ERROR', error)
            },
        },
    )
    const onSubmit = () => {
        deleteTierMu(id)
    }

    return (
        <AppModal isOpen={isOpen} onClose={() => onClose(false)}>
            <div className='space-y-8 flex-1 text-center'>
                <div>
                    <h1 className='text-xl text-red-800 font-semibold text-center pb-4'>
                        Delete Tier ?
                    </h1>
                    <p className='text-gray-800'>Are you sure you want to delete this Tier?</p>
                </div>

                <div className='flex justify-center gap-4 mt-5 px-5'>
                    <AppButton variant='secondary' type='button' onClick={() => onClose(false)}>
                        Cancel
                    </AppButton>
                    <AppButton
                        variant='primary'
                        type={'button'}
                        onClick={onSubmit}
                        isLoading={isLoading}
                        disabled={isLoading}
                    >
                        Delete
                    </AppButton>
                </div>
            </div>
        </AppModal>
    )
}
