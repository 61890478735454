import { AppSwitch } from 'components/AppSwitch'
import { Controller, useFormContext } from 'react-hook-form'
import { UpdateStoreType } from 'services/requests/store/schema'

export const StoreHeader = () => {
    const { control } = useFormContext<UpdateStoreType>()

    return (
        <section className='flex justify-between'>
            <div className='flex items-center'>
                <h1 className='text-2xl  font-bold'>Store Details</h1>
            </div>
            <div className='flex items-center space-x-4'>
                <span className='text-red-900 font-bold'>Restricted Area</span>
                <Controller
                    name='restricted'
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                        <AppSwitch isEnabled={value} onChange={onChange} key={name} />
                    )}
                />
            </div>
        </section>
    )
}
