import { Combobox, Transition } from '@headlessui/react'
import * as React from 'react'
import { AiOutlineDown } from 'react-icons/ai'
import { twMerge } from 'tailwind-merge'

interface IAppAutoCompleteProps {
    name: string
    disabled?: boolean
    value?: string
    onChange?: (value: string) => void
    placeholder?: string
    label?: string
    containerClassname?: string
    inputContainerClassname?: string
    inputClassname?: string
    labelClassname?: string
    errorMessage?: string
    requiredClassname?: string
    required?: boolean
    errorClassName?: string
    mainClassName?: string
    list: string[]
    mainContainerClassName?: string
}

const AppAutoComplete: React.FunctionComponent<IAppAutoCompleteProps> = (props) => {
    const [query, setQuery] = React.useState<string>('')
    const filteredList =
        query === ''
            ? props.list
            : props.list.filter((item: string) =>
                  item
                      .toLowerCase()
                      .replace(/\s+/g, '')
                      .includes(query.toLowerCase().replace(/\s+/g, '')),
              )
    return (
        <div className={twMerge(props?.mainContainerClassName ?? 'flex flex-col')}>
            {props.label && (
                <label
                    htmlFor={props.name}
                    className={twMerge(
                        'font-bold block text-sm text-gray-700',
                        props.labelClassname,
                    )}
                >
                    {props.label}
                </label>
            )}
            <div className={twMerge('flex flex-col space-y-1 relative', props?.mainClassName)}>
                <Combobox value={props?.value} onChange={props?.onChange}>
                    <div className='relative'>
                        <div
                            className={twMerge(
                                'relative w-full cursor-default overflow-hidden bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm',
                                props.containerClassname,
                            )}
                        >
                            <Combobox.Input
                                placeholder={props?.placeholder}
                                displayValue={(value: string) => value.toLocaleLowerCase()}
                                className={twMerge(
                                    'text-xxs block appearance-none w-full bg-white  border border-solid border-[#707070] text-[#323232] px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline capitalize',
                                    props?.inputClassname,
                                )}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    setQuery(event.target.value)
                                }
                            />
                            <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
                                <AiOutlineDown className='text-primary w-35 h-4' />
                            </Combobox.Button>
                        </div>
                        <Transition
                            as={React.Fragment}
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                            afterLeave={() => setQuery('')}
                        >
                            <Combobox.Options className='absolute mt-1 z-40  w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                                {filteredList.length === 0 && props?.value !== '' ? (
                                    <div className='relative cursor-default select-none py-2 px-4 text-primary text-xxs'>
                                        Nothing found.
                                    </div>
                                ) : (
                                    filteredList.map((item, index) => (
                                        <Combobox.Option
                                            key={index}
                                            className={({ active }) =>
                                                `relative cursor-default select-none py-1 pl-5 pr-4 text-xxs hover:cursor-pointer capitalize ${
                                                    active
                                                        ? 'bg-primary text-secondary'
                                                        : 'text-[#323232]'
                                                }`
                                            }
                                            value={item}
                                        >
                                            {({ selected, active }) => (
                                                <>
                                                    <span
                                                        className={`block truncate capitalize ${
                                                            selected ? 'font-medium' : 'font-normal'
                                                        }${active && 'bg-slate-400'}`}
                                                    >
                                                        {item.toLocaleLowerCase()}
                                                    </span>
                                                </>
                                            )}
                                        </Combobox.Option>
                                    ))
                                )}
                            </Combobox.Options>
                        </Transition>
                    </div>
                </Combobox>
                {props.errorMessage && (
                    <p className='mt-2 text-sm text-red-600' id={`${props.name}-error`}>
                        {props.errorMessage}
                    </p>
                )}
            </div>
        </div>
    )
}

export default AppAutoComplete
