import AppRenderHtml from 'components/AppRenderHtml'
import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { HomeContentType } from 'services/requests/home/schema'
import { HomeSelectedContentsPreviewAtom } from 'store/homeAtom'

interface IPreviewHomeContentProps {
    id: string
}

const PreviewHomeContent: React.FunctionComponent<IPreviewHomeContentProps> = (props) => {
    const [content, setContent] = useState<HomeContentType | null>(null)
    const [selectedContent, setSelectedContent] = useAtom(HomeSelectedContentsPreviewAtom)
    const queryClient = useQueryClient()

    useEffect(() => {
        queryClient.invalidateQueries(`content${props?.id}`)
    }, [content])

    if (selectedContent)
        return (
            <div className='flex flex-col justify-start'>
                <AppRenderHtml contentDescription={selectedContent.title} />
                <AppRenderHtml contentDescription={selectedContent.subtitle} />
            </div>
        )
    return <></>
}

export default PreviewHomeContent
