import { z } from 'zod'

export const JobDetailsSchema = z.object({
    detailsId: z.string(),
    jobId: z.null(),
    detailsDescription: z.string(),
    detailsType: z.string(),
    whoAdded: z.string().nullable(),
    whenAdded: z.number(),
    whoUpdated: z.string().nullable(),
    whenUpdated: z.number(),
})

export const JobsSchema = z.object({
    jobId: z.string(),
    jobTitle: z.string(),
    jobState: z.string(),
    jobDescription: z.string(),
    jobDetails: z.array(JobDetailsSchema),
    jobType: z.string(),
    jobEmployment: z.string(),
    whoAdded: z.string().nullable(),
    whenAdded: z.number(),
    whoUpdated: z.string().nullable(),
    whenUpdated: z.number(),
    status: z.string(),
    position: z.number(),
    jobLongDescription: z.string(),
})

export const PageListJobsSchema = z.object({
    content: z.array(JobsSchema),
    pageable: z.object({
        sort: z.object({
            empty: z.boolean(),
            sorted: z.boolean(),
            unsorted: z.boolean(),
        }),
        offset: z.number(),
        pageNumber: z.number(),
        pageSize: z.number(),
        unpaged: z.boolean(),
        paged: z.boolean(),
    }),
    last: z.boolean(),
    totalElements: z.number(),
    totalPages: z.number(),
    size: z.number(),
    number: z.number(),
    sort: z.object({
        empty: z.boolean(),
        sorted: z.boolean(),
        unsorted: z.boolean(),
    }),
    first: z.boolean(),
    numberOfElements: z.number(),
    empty: z.boolean(),
})

export const CreateDetailsSchema = z.object({
    jobId: z.string(),
    detailsDescription: z.string(),
    detailsType: z.string(),
})

export type CreateDetailsType = z.infer<typeof CreateDetailsSchema>
export type JobsDetailsType = z.infer<typeof JobDetailsSchema>
export type PageJobsNewsType = z.infer<typeof PageListJobsSchema>
export type JobsType = z.infer<typeof JobsSchema>
