import { Popover, Transition } from '@headlessui/react'
import { AppAvatar } from 'components/AppAvatar'
import { useAtom, useSetAtom } from 'jotai'
import { Fragment, useMemo } from 'react'
// import { BsPerson } from 'react-icons/bs'
import { FiLogOut } from 'react-icons/fi'
import { useQuery } from 'react-query'
// import { useNavigate } from 'react-router-dom'
import { FaBars } from 'react-icons/fa'
import { userAtom } from 'store/userAtom'
import { logout, verifyTokenApi } from '../../../services/requests/auth'
import { navAtom } from '../Sidebar'

export const Topbar = () => {
    const { data } = useQuery(['auth'], () => verifyTokenApi())
    // const navigate = useNavigate()
    const setSidebarOpen = useSetAtom(navAtom)
    const [user, setUser] = useAtom(userAtom)
    useMemo(() => data && setUser(data), [data])

    return (
        <div className='sticky text-[#464255] top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8'>
            <button
                type='button'
                className='-m-2.5 p-2.5 text-gray-700 lg:hidden'
                onClick={() => setSidebarOpen(true)}
            >
                <span className='sr-only'>Open sidebar</span>
                <FaBars className='w-6 h-6' />
            </button>

            <div className='flex flex-1 gap-x-4 justify-end self-stretch lg:gap-x-6'>
                <div className='flex items-center gap-x-2 lg:gap-x-4'>
                    {/* <Notification /> */}
                    <Popover as='div' className='my-3 mx-3 relative'>
                        <Popover.Button className='max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none '>
                            <span className='sr-only'>Open user menu</span>
                            <div className='inline-flex gap-2 justify-center items-center'>
                                <AppAvatar
                                    name={`${user?.firstName} ${user?.lastName}`}
                                    avatar={user?.userImage || undefined}
                                />
                                <div>
                                    <h1 className=''>{`${user?.firstName} ${user?.lastName}`}</h1>
                                    <h1 className=''>{user?.userType}</h1>
                                </div>
                            </div>
                        </Popover.Button>

                        <Transition
                            as={Fragment}
                            enter='transition ease-out duration-100'
                            enterFrom='transform opacity-0 scale-95'
                            enterTo='transform opacity-100 scale-100'
                            leave='transition ease-in duration-75'
                            leaveFrom='transform opacity-100 scale-100'
                            leaveTo='transform opacity-0 scale-95'
                        >
                            <Popover.Panel className='z-10 origin-top-right absolute right-0 mt-2 w-[227px] rounded-md shadow-lg py-4 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                <div className='flex font-[400] flex-col gap-3'>
                                    <p className='text-[13px] font-semibold px-5'>{`Welcome ${user.firstName}!`}</p>
                                    {/* <button
                                        onClick={() => navigate('/global-settings/user-management')}
                                        type='button'
                                        className='inline-flex gap-2 items-center text-[14px] hover:bg-[#EDDFE2] hover:text-[#861F41] py-2 px-5 hover:font-semibold transition-all'
                                    >
                                        <BsPerson className='w-5 h-5' /> <span>My Account</span>
                                    </button> */}
                                    <button
                                        onClick={logout}
                                        type='button'
                                        className='inline-flex gap-2 items-center text-[14px] hover:bg-[#EDDFE2] hover:text-[#861F41] py-2 px-5 hover:font-semibold transition-all'
                                    >
                                        <FiLogOut className='w-5 h-5' /> <span>Log-out</span>
                                    </button>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </Popover>
                </div>
            </div>
        </div>
    )
}
