import { LoginPanel } from './LoginPanel'
import { OctoChefPanel } from './OctoChefPanel'

export const Login = () => {
    return (
        <div className='flex min-h-screen'>
            <OctoChefPanel />
            <LoginPanel />
        </div>
    )
}
