import { zodResolver } from '@hookform/resolvers/zod'
import { AxiosError } from 'axios'
import AppAutoComplete from 'components/AppAutocomplete'
import { AppButton } from 'components/AppButton'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AiOutlineClose } from 'react-icons/ai'
import { useMutation } from 'react-query'
import { CreateJobsDetailsApi } from 'services/requests/jobs'
import { CreateDetailsSchema, CreateDetailsType } from 'services/requests/jobs/schema'

interface IAddDetails {
    id: string
    onClose: () => void
}
const AddDetails: React.FunctionComponent<IAddDetails> = (props) => {
    const { control, handleSubmit } = useForm<CreateDetailsType>({
        mode: 'onBlur',
        resolver: zodResolver(CreateDetailsSchema),
        defaultValues: {
            jobId: props.id,
        },
    })
    const { mutate, isLoading } = useMutation<unknown, AxiosError, CreateDetailsType>(
        (data) => CreateJobsDetailsApi(data),
        {
            onSuccess: () => {
                props.onClose()
            },
        },
    )
    const onSubmit = (data: CreateDetailsType) => {
        mutate(data)
    }
    return (
        <div className='grid grid-cols-12 w-full md:w-[30rem] mx-auto'>
            <div className='col-span-12 flex justify-between'>
                <h1 className='font-bold text-lg text-[#861F41]'>Add Details</h1>
                <button onClick={() => props.onClose()}>
                    <AiOutlineClose />
                </button>
            </div>
            <div className='col-span-12'>
                <Controller
                    control={control}
                    name='detailsType'
                    render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                        <AppAutoComplete
                            name={name}
                            list={['Responsibilities', 'Requirements']}
                            onChange={onChange}
                            label='Employment Type:'
                            containerClassname='w-full mx-auto'
                            value={value}
                            inputClassname='border-none'
                            errorMessage={error?.message}
                            placeholder={'Select Job Employment Type'}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name='detailsDescription'
                    render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                        <>
                            <div className='flex flex-col w-full col-span-12 space-y-1 my-2 relative px-1'>
                                <label
                                    tabIndex={0}
                                    className='text-sm font-bold'
                                    htmlFor={name}
                                    id='description'
                                >
                                    {'Description:'}
                                </label>
                                <textarea
                                    tabIndex={0}
                                    onChange={onChange}
                                    id='canhelp'
                                    cols={3}
                                    maxLength={500}
                                    value={value}
                                    className='h-[134px] block resize-none w-full p-2 pb-10 rounded shadow focus:outline-none focus:shadow-outline text-xxs'
                                ></textarea>
                                <div className='flex flex-row justify-between w-full  absolute bottom-1 translate-y-[3px]'>
                                    <div className='flex flex-row justify-between mx-auto w-[99%] items-center bg-secondary px-2 pt-1'>
                                        {' '}
                                        <p tabIndex={0} className=' text-[10px] text-gray-500'>{`${
                                            value?.length ?? 0
                                        } / 500`}</p>
                                    </div>
                                </div>
                                {error && (
                                    <p
                                        className='text-xxs text-red-500 text-sm absolute -bottom-5'
                                        id={`${name}-error`}
                                    >
                                        {error?.message}
                                    </p>
                                )}
                            </div>
                        </>
                    )}
                />
            </div>
            <div className='col-span-12 flex justify-around'>
                <button
                    onClick={() => props.onClose()}
                    className='min-w-[150px] h-[48px] text-[#861F41] border-2 shadow-lg border-[#861F41] rounded-xl'
                >
                    Close
                </button>

                <AppButton
                    variant='primary'
                    onClick={handleSubmit(onSubmit)}
                    type='submit'
                    isLoading={isLoading}
                    className='min-w-[150px] h-[48px] text-white bg-[#861F41] rounded-xl shadow-lg flex items-center justify-center hover:bg-slate-500 text-sm'
                >
                    Save
                </AppButton>
            </div>
        </div>
    )
}

export default AddDetails
