import Logo from 'assets/images/logo.png'

const Header = () => {
    return (
        <div className='flex items-center justify-start bg-transparent'>
            <div className='px-20 p-5 sm:px-20 sm:p-8'>
                <img src={Logo} />
            </div>
        </div>
    )
}

export default Header
