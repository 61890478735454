const AppSkeletonLoadingOrderDetails = () => {
    return (
        <div className=''>
            <div>
                <div className='h-8 w-[200px] bg-slate-200 rounded-md mt-1'></div>
            </div>

            <div className='flex flex-row h-[93px] bg-slate-50 rounded-md justify-between items-center mt-12'>
                <div className='flex flex-col ml-4'>
                    <div className='h-8 w-[160px] bg-slate-200 rounded-md mb-2'></div>
                    <div className='h-3 w-[220px] bg-slate-200 rounded-md '></div>
                </div>
                <div>
                    <div className='h-8 w-[110px] bg-slate-200 rounded-md mr-4 mb-5'></div>
                </div>
            </div>
            <div className='flex flex-row'>
                <div className='flex flex-col bg-slate-50 h-[420px] w-[75%] mt-3 p-5'>
                    <div className='h-3 w-[120px] bg-slate-200 rounded-md mb-8 '></div>
                    <div className='flex flex-row justify-between mb-8'>
                        <div className='flex flex-row'>
                            <div className='h-[45px] w-[80px] bg-slate-200 rounded-md mr-4'></div>
                            <div className='flex flex-col'>
                                <div className='h-4 w-[140px] bg-slate-200 rounded-md mb-3'></div>
                                <div className='h-3 w-[70px] bg-slate-200 rounded-md '></div>
                            </div>
                        </div>
                        <div>
                            <div className='h-4 w-[80px] bg-slate-200 rounded-md mt-7'></div>
                        </div>
                    </div>
                    <div className='flex flex-row justify-between mb-8'>
                        <div className='flex flex-row'>
                            <div className='h-[45px] w-[80px] bg-slate-200 rounded-md mr-4'></div>
                            <div className='flex flex-col'>
                                <div className='h-4 w-[140px] bg-slate-200 rounded-md mb-3'></div>
                                <div className='h-3 w-[70px] bg-slate-200 rounded-md '></div>
                            </div>
                        </div>
                        <div>
                            <div className='h-4 w-[80px] bg-slate-200 rounded-md mt-7'></div>
                        </div>
                    </div>
                    <div className='flex flex-row justify-between mb-8'>
                        <div className='flex flex-row'>
                            <div className='h-[45px] w-[80px] bg-slate-200 rounded-md mr-4'></div>
                            <div className='flex flex-col'>
                                <div className='h-4 w-[140px] bg-slate-200 rounded-md mb-3'></div>
                                <div className='h-3 w-[70px] bg-slate-200 rounded-md '></div>
                            </div>
                        </div>
                        <div>
                            <div className='h-4 w-[80px] bg-slate-200 rounded-md mt-7'></div>
                        </div>
                    </div>
                    <div className='flex flex-row justify-between mb-8'>
                        <div className='flex flex-row'>
                            <div className='flex flex-col'>
                                <div className='h-3 w-[70px] bg-slate-200 rounded-md mb-3'></div>
                                <div className='h-3 w-[90px] bg-slate-200 rounded-md '></div>
                            </div>
                        </div>
                        <div className='flex flex-col items-end'>
                            <div className='h-3 w-[80px] bg-slate-200 rounded-md mb-3'></div>
                            <div className='h-3 w-[70px] bg-slate-200 rounded-md '></div>
                        </div>
                    </div>
                    <div className='flex flex-row justify-between'>
                        <div className='h-4 w-[80px] bg-slate-200 rounded-md'></div>
                        <div className='h-4 w-[80px] bg-slate-200 rounded-md mb-3'></div>
                    </div>
                </div>
                <div className='flex flex-col bg-slate-50 h-[210px] w-[25%] mt-3 ml-3 p-6'>
                    <div>
                        <div className='h-3 w-[120px] bg-slate-200 rounded-md mb-8 '></div>
                    </div>
                    <div className='flex flex-row mb-8'>
                        <div className='h-[40px] w-[40px] bg-slate-200 rounded-full mr-4'></div>
                        <div className='flex flex-col'>
                            <div className='h-3 w-[70px] bg-slate-200 rounded-md mb-3'></div>
                            <div className='h-3 w-[140px] bg-slate-200 rounded-md'></div>
                        </div>
                    </div>
                    <div className='flex flex-row'>
                        <div className='h-[40px] w-[40px] bg-slate-200 rounded-full mr-4'></div>
                        <div className='flex flex-col'>
                            <div className='h-3 w-[70px] bg-slate-200 rounded-md mb-3'></div>
                            <div className='h-3 w-[140px] bg-slate-200 rounded-md'></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col bg-slate-50 h-[260px] w-full mt-3 p-5'>
                <div>
                    <div className='h-3 w-[140px] bg-slate-200 rounded-md mb-8 '></div>
                </div>
                <div className='flex flex-row'>
                    <div className='h-[150px] w-[150px] bg-slate-200 rounded-full mr-6'></div>
                    <div className='flex flex-col mt-8 mr-[160px]'>
                        <div className='h-3 w-[130px] bg-slate-200 rounded-md mb-3'></div>
                        <div className='h-3 w-[110px] bg-slate-200 rounded-md mb-3'></div>
                        <div className='h-3 w-[170px] bg-slate-200 rounded-md mb-3'></div>
                        <div className='h-3 w-[140px] bg-slate-200 rounded-md mb-3'></div>
                    </div>
                    <div className='flex flex-col mt-4'>
                        <div className='h-3 w-[130px] bg-slate-200 rounded-md mb-6'></div>
                        <div className='h-3 w-[130px] bg-slate-200 rounded-md mb-6 ml-4'></div>
                        <div className='h-3 w-[100px] bg-slate-200 rounded-md mb-3 ml-4'></div>
                        <div className='h-3 w-[140px] bg-slate-200 rounded-md mb-3 ml-4'></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppSkeletonLoadingOrderDetails
