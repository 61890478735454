import {
    closestCenter,
    DndContext,
    DragEndEvent,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { AxiosError, isAxiosError } from 'axios'
import Loading from 'components/loading'
import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
    CreateHomeSectionApi,
    getHomeSectionByTitleApi,
    UpdatePositionHomeContentsApi,
    UpdateSectionBackgroundApi,
} from 'services/requests/home'
import {
    CreateHomeSectionType,
    HomeContentType,
    UpdateSectionBg,
} from 'services/requests/home/schema'
import {
    LogoGalleryBgColorAtom,
    LogoGalleryBgOpacityAtom,
    LogoGalleryLastPositionAtom,
    partnersHomeContentIdAtom,
} from 'store/homeAtom'
import { partnersContent } from 'utils/constants'
import HomeContentImage from '../HomeContentImage'

import { arrayMove, horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { AppButton } from 'components/AppButton'
import AppSlider from 'components/AppSlider'
import { HexColorPicker } from 'react-colorful'
import toast from 'react-hot-toast'
import AddPartners from './AddPartners'
interface IUpdatePos {
    id: string
    pos: number
}
const colors = [
    { value: '#861F41', label: 'Primary' },
    { value: '#F0B327', label: 'Yellow' },
    { value: '#069F43', label: 'Green' },
    { value: '#FF9FBE', label: 'Pink' },
    { value: '#A5789C', label: 'Purple' },
    { value: '#1F2986', label: 'Blue' },
]
const ParnershipHomeSection: React.FunctionComponent = () => {
    const [appId, setID] = useAtom(partnersHomeContentIdAtom)
    const [bgColor, setBgColor] = useAtom(LogoGalleryBgColorAtom)
    const [bgOpacity, setBgOpacity] = useAtom(LogoGalleryBgOpacityAtom)
    const [sortedData, setSortedData] = useState<HomeContentType[] | null>(null)
    const [, setLastPositionLogoGallery] = useAtom(LogoGalleryLastPositionAtom)
    const pointerSensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 10,
        },
    })

    const sensors = useSensors(pointerSensor)

    const queryClient = useQueryClient()
    const {
        data,
        error: queryError,
        isLoading,
    } = useQuery({
        queryKey: 'content-partners-home',
        queryFn: () => getHomeSectionByTitleApi(partnersContent.title),
        onSuccess: (data) => {
            const sortedArray = data.homeContents.sort((a, b) => a.position - b.position)
            const lastItem = sortedArray[sortedArray?.length - 1]
            setLastPositionLogoGallery(lastItem.position + 1)
            setSortedData(null)
            setID(data?.homeId)
            setBgColor(data?.bgColor)
            setBgOpacity(data?.bgOpacity)
        },
    })

    const { mutate: updateSectionBg, isLoading: updatingBg } = useMutation<
        unknown,
        AxiosError,
        UpdateSectionBg
    >(
        (data) => {
            return UpdateSectionBackgroundApi(data)
        },
        {
            onSuccess: () => {
                toast.success('Success! Logo gallery background color saved.')
                queryClient.invalidateQueries('content-partners-home')
            },
        },
    )

    const { mutate: saveContent } = useMutation<unknown, AxiosError, CreateHomeSectionType>(
        (data) => {
            return CreateHomeSectionApi(data)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('content-partners-home')
            },
        },
    )

    useEffect(() => {
        if (isAxiosError(queryError) && queryError?.response?.status === 404)
            saveContent({
                bgColor: '',
                buttonLabel: 'Check Menu',
                duration: 8,
                visibility: 'ACTIVE',
                localization: 'en',
                sectionTitle: partnersContent?.title,
            })
    }, [queryError])
    const { mutate: savePosition } = useMutation<unknown, AxiosError, IUpdatePos>(
        (data) => {
            return UpdatePositionHomeContentsApi(data?.id, data?.pos)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('content-partners-home')
            },
        },
    )
    const handleDrag = (event: DragEndEvent) => {
        const { active, over } = event
        if (data?.homeContents) {
            if (active.id !== over?.id) {
                const activeIndex = data?.homeContents
                    .map((arr) => arr.id)
                    .indexOf(active.id.toString())
                const overIndex = data?.homeContents
                    .map((arr) => arr.id)
                    .indexOf((over?.id ?? '').toString())

                const array = arrayMove(data?.homeContents, activeIndex, overIndex)
                if (appId && array) {
                    array.forEach((item, index) => {
                        savePosition({ id: item?.id, pos: index + 1 })
                    })
                    setSortedData(array)

                    setTimeout(
                        () => toast.success('Success! Home logo gallery arrangement changed.'),
                        300,
                    )
                }
            }
        }
    }
    if (isLoading) return <Loading />
    if (appId && data)
        return (
            <div className='flex flex-col w-full overflow-hidden'>
                <div className='mx-auto p-4 flex flex-col bg-white shadow-xl w-fit justify-center items-center max-w-[240px] rounded-sm gap-3'>
                    <div className='w-fit flex border-b mb-2'>
                        <AppSlider
                            value={(bgOpacity ?? 100).toString()}
                            max={'100'}
                            containerClassname='flex flex-row justify-center py-2 items-center gap-2'
                            onChange={(value) => setBgOpacity(parseInt(value))}
                            name='slider'
                            inputClassname='text-[#861F41] accent-[#861F41] bg-white border-2 h-2 focus:bg-white'
                        />
                        <div className='group flex items-center justify-center relative'>
                            <div
                                style={{ backgroundColor: bgColor ?? '#ffffff' }}
                                className={
                                    'block w-5 h-5 outline-offset-4  outline-dotted outline-2 m-4  '
                                }
                            />

                            <div className='group-hover:flex hidden absolute -right-[12.44rem] top-0 z-10'>
                                <HexColorPicker
                                    onChange={(hex) => setBgColor(hex)}
                                    defaultValue={bgColor ?? '#ffffff'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap  gap-2 mx-auto w-fit'>
                        {colors.map((item) => (
                            <button
                                onClick={() => setBgColor(item?.value)}
                                key={item?.label}
                                style={{ backgroundColor: item?.value ?? '#ffffff' }}
                                className={'block w-5 h-5 rounded-md'}
                            />
                        ))}
                    </div>
                    <AppButton
                        variant='primary'
                        isLoading={updatingBg}
                        onClick={() =>
                            updateSectionBg({
                                id: appId,
                                bgColor: bgColor ?? '',
                                bgOpacity: bgOpacity ?? 100,
                            })
                        }
                        type='button'
                    >
                        {' '}
                        Save
                    </AppButton>
                </div>
                <div className='overflow-auto'>
                    <div className='flex gap-5 justify-center items-center w-fit px-8 py-6'>
                        <DndContext
                            {...sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDrag}
                        >
                            <SortableContext
                                items={data?.homeContents?.map((item) => item?.id)}
                                strategy={horizontalListSortingStrategy}
                            >
                                {sortedData
                                    ? sortedData.map((item) => (
                                          <HomeContentImage
                                              sectiontId={appId}
                                              sectionTitle='content-partners-home'
                                              id={item?.id}
                                              type='partnership'
                                              title={item?.externalTitle}
                                              key={item?.id}
                                          />
                                      ))
                                    : data.homeContents
                                          .sort((a, b) => a.position - b.position)
                                          .map((item) => (
                                              <HomeContentImage
                                                  sectiontId={appId}
                                                  sectionTitle='content-partners-home'
                                                  id={item?.id}
                                                  type='partnership'
                                                  title={item?.externalTitle}
                                                  key={item?.id}
                                              />
                                          ))}
                            </SortableContext>
                        </DndContext>

                        <AddPartners AppId={appId} />
                    </div>
                </div>
            </div>
        )
    return <></>
}

export default ParnershipHomeSection
