import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import * as React from 'react'
import { ListPartnershipType, ObjectPartnershipType } from 'services/requests/partnerships/schema'
import PartnershipItem from '../../parnership-item'

interface ItemToSortProps {
    id: string
    name?: string
    setMoved: React.Dispatch<React.SetStateAction<number>>
    item: ObjectPartnershipType
    checked: boolean
    handleSelect: (e: boolean, item: ObjectPartnershipType) => void
    setSelectedPartnerships: React.Dispatch<React.SetStateAction<ListPartnershipType | []>>
}

const ItemToSort: React.FunctionComponent<ItemToSortProps> = (props) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props.id,
    })
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }
    return (
        <div ref={setNodeRef} style={style} {...attributes} className='col-span-12'>
            <div className='col-span-12 grid grid-cols-12'>
                <PartnershipItem
                    checked={props.checked}
                    item={props.item}
                    listeners={listeners}
                    setMoved={props.setMoved}
                    handleSelect={props.handleSelect}
                    setSelectedPartnerships={props.setSelectedPartnerships}
                />
            </div>
        </div>
    )
}

export default ItemToSort
