import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Popover } from '@headlessui/react'
import { AxiosError } from 'axios'
import ConfirmationModal from 'components/ActionConfirmation'
import { AppButton } from 'components/AppButton'
import { AppModal } from 'components/AppModal'
import { AppTextBox } from 'components/AppTextBox'
import { useAtom } from 'jotai'
import { DraggableHandle } from 'pages/Web/Partnerships/parnership-item'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { BiSolidEdit } from 'react-icons/bi'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FiTrash } from 'react-icons/fi'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { DeleteHomeContentByIdApi, UpdateVisibilityHomeContentsApi } from 'services/requests/home'
import { HomeContentType } from 'services/requests/home/schema'
import { HomeSelectedContentsPreviewAtom, HomeSelectedPreviewAtom } from 'store/homeAtom'
import { twMerge } from 'tailwind-merge'
import { VisibilityStatus } from 'utils/constants'

interface ICarouselItemProps {
    id: string
    title: string
    image: string
    description: string
    visibility: string
    isLoading: boolean
    alias: string
    item: HomeContentType
}

const CarouselItem: React.FunctionComponent<ICarouselItemProps> = (props) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props?.id,
    })
    const [, setSelectedContent] = useAtom(HomeSelectedContentsPreviewAtom)
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const queryClient = useQueryClient()
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }
    const [, setSelectedPreview] = useAtom(HomeSelectedPreviewAtom)

    const { mutate: deleteHomeContent, isLoading: deleteLoading } = useMutation<
        unknown,
        AxiosError,
        string
    >((id) => DeleteHomeContentByIdApi(id), {
        onSuccess: () => {
            queryClient.invalidateQueries('content-carousel')
            setIsOpen(false)
            setSelectedContent(null)
            toast.success(
                <div>
                    <p className='font-bold mb-1'>Successfully Deleted!</p>
                    <p>Content has been deleted successfully.</p>
                </div>,
                { className: 'mt-10' },
            )
        },
        onError: (error) => console.log(error),
    })
    const { mutate: saveContent } = useMutation<unknown, AxiosError, string>(
        (data) => {
            return UpdateVisibilityHomeContentsApi(props?.id, data)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('content-carousel')
            },
        },
    )
    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            className='inline-flex gap-4 border p-3 max-h-[100px] items-center justify-between w-full'
            onClick={() => {
                setSelectedPreview({
                    id: props.id,
                })
                setSelectedContent(props.item)
            }}
        >
            <DraggableHandle handleListeners={listeners} className=' w-7 h-7' />
            <div className='flex flex-col text-[14px] font-medium px-4'>
                <AppTextBox
                    containerClassname='flex items-center justify-center gap-2'
                    name='allias'
                    inputClassname='rounded-none border-2 border-gray-400 text-[13px] text-[#464255]'
                    value={props?.alias}
                    type='text'
                    disabled={true}
                />
            </div>
            <div className='relative flex'>
                <img
                    src={props?.image}
                    alt=''
                    className='w-[180px] h-[83px] xl:w-[120px] xl:h-[83px] object-contain my-auto'
                />
                <AppButton
                    variant='secondary'
                    onClick={() => navigate(`updateContent/${props?.id}`)}
                    type='button'
                    className='absolute top-[50%] translate-y-[-50%] translate-x-[-50%] left-[50%] py-1 bg-opacity-0  text-white text-[10px]'
                >
                    <pre>Edit Image</pre>
                </AppButton>
                <AppButton
                    variant='secondary'
                    type='button'
                    onClick={() =>
                        saveContent(
                            props?.visibility === VisibilityStatus.active
                                ? VisibilityStatus.inactive
                                : VisibilityStatus.active,
                        )
                    }
                    className={twMerge(
                        props?.visibility === VisibilityStatus.active
                            ? 'bg-[#861F41] text-white'
                            : 'text-[#861F41] bg-white',
                        'rounded-full absolute p-2 text-xl -top-3 -right-3',
                    )}
                >
                    {props.visibility === VisibilityStatus.active ? (
                        <AiFillEye />
                    ) : (
                        <AiFillEyeInvisible />
                    )}
                </AppButton>
            </div>

            <div className='flex items-center justify-center'>
                <Popover>
                    {({ close }) => (
                        /* Use the `open` state to conditionally change the direction of the chevron icon. */
                        <div className='relative ml-auto'>
                            <Popover.Button>
                                <BsThreeDotsVertical className='text-[#861F41] w-5 h-11' />
                            </Popover.Button>
                            <Popover.Panel className='absolute right-8 top-0 z-20 bg-white p-4 flex flex-col gap-2 text-[#862F4C] text-sm rounded-md font-bold shadow-lg'>
                                <button
                                    onClick={() => {
                                        close()
                                        // setEditJobs(props?.item)
                                        navigate(`updateContent/${props?.id}`)
                                    }}
                                    className='inline-flex items-center gap-2 p-1'
                                >
                                    <BiSolidEdit className='h-5 w-5' />
                                    Update
                                </button>
                                <button
                                    onClick={() => {
                                        setIsOpen(true)
                                    }}
                                    className='inline-flex items-center gap-2 p-1'
                                >
                                    <FiTrash className='h-5 w-5' />
                                    Delete
                                </button>
                            </Popover.Panel>
                        </div>
                    )}
                </Popover>
            </div>
            <AppModal
                closeIcon={false}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title=''
                containerClassName='h-fit p-0 my-0'
            >
                <ConfirmationModal
                    handle={() => deleteHomeContent(props?.id)}
                    onClose={() => setIsOpen(false)}
                    yesLabel='Delete'
                    noLabel='Cancel'
                    title='Delete Content'
                    isLoading={deleteLoading}
                    subtitle='Are you sure you want to delete this content from the carousel?'
                />
            </AppModal>
        </div>
    )
}

export default CarouselItem
