import { AsyncCombobox } from 'components/AppAsyncComboBox'
import { AppListBox, ListBoxOptions } from 'components/AppListBox'
import { AppSearch } from 'components/AppSearch'
import { useMemo, useState } from 'react'
import { MdLocationPin } from 'react-icons/md'
import { searchLocationApi } from 'services/requests/store'
import { LocationType } from 'services/requests/store/schema'

const statusOptions: ListBoxOptions[] = [
    { value: '', label: 'All' },
    { value: 'pending', label: 'Pick-Up Pending' },
    { value: 'done', label: 'Complete' },
    { value: 'refund_initiated', label: 'Refund Initiated' },
    { value: 'refunded', label: 'Refunded' },
    { value: 'refund_failed', label: 'Refund Failed' },
    { value: 'partially_refunded', label: 'Partially Refunded' },
    { value: 'failed', label: 'Failed' },
    { value: 'partially_failed', label: 'Partially Failed' },
]

const platformOptions: ListBoxOptions[] = [
    { value: '', label: 'All' },
    { value: 'web', label: 'Web' },
    { value: 'mobile', label: 'Mobile' },
]

interface FilterHeaderProps {
    searchOnChange: (value: string) => void
    statusOnChange: (value: string | number) => void
    storeOnChange: (value: string | number) => void
    platformOnChange: (value: string | number) => void
}

export const FilterHeader = (props: FilterHeaderProps) => {
    const [status, setStatus] = useState<string | number>(statusOptions[0].value)
    const [platform, setPlatform] = useState<string | number>(platformOptions[0].value)
    const [query, setQuery] = useState<string>('')

    const handleSearch = (value: string) => {
        props.searchOnChange(value)
    }

    const handleStatus = (value: string | number) => {
        setStatus(value)
        props.statusOnChange(value)
    }

    const handleStore = (option: LocationType | null) => {
        if (!option) {
            props.storeOnChange('')
            return
        }
        props.storeOnChange(option.id)
    }

    const handleQuery = (value: string) => {
        setQuery(value)
    }

    const handlePlatform = (value: string | number) => {
        setPlatform(value)
        props.platformOnChange(value)
    }
    const IsFilterWeb = useMemo(() => platform === 'web', [platform])
    return (
        <div className='grid grid-cols-1 space-y-2 2xl:space-y-0 2xl:flex items-end justify-between'>
            <AppSearch onSearch={handleSearch} />
            <div className='grid grid-cols-1 sm:grid-cols-2 space-y-2 xl:space-y-0 xl:flex items-center xl:space-x-2'>
                <AppListBox
                    options={statusOptions}
                    label='Status'
                    onChange={handleStatus}
                    selectedOption={status}
                    className='z-30 w-full sm:w-[90%] pt-[6.5px] xl:pt-0 xl:w-full 2xl:w-64'
                />
                <AsyncCombobox<LocationType>
                    endpoint={() => searchLocationApi(query)}
                    getLabel={(item) => item?.shopName}
                    icon={<MdLocationPin className='w-4 h-4 text-red-900' />}
                    name='store'
                    label='Store'
                    placeholder='Please select a store'
                    className='z-20 xl:w-full 2xl:w-80'
                    onChange={handleQuery}
                    getSelectedOption={handleStore}
                />
                <AppListBox
                    options={platformOptions}
                    label='Platform'
                    onChange={handlePlatform}
                    selectedOption={platform}
                    className='z-10 w-full sm:w-[90%] xl:w-full 2xl:w-64'
                />
            </div>
        </div>
    )
}
