import { zodResolver } from '@hookform/resolvers/zod'
import { AxiosError } from 'axios'
import AppAltTextInput from 'components/AppAltTextInput'
import { AppButton } from 'components/AppButton'
import { AppFileUpload } from 'components/AppFileUpload'
import { AppRichTextBox } from 'components/AppRichTextBox'
import { AppTextBox } from 'components/AppTextBox'
import { useAtom } from 'jotai'
import { CreatePartnershipSchema, CreatePartnershipType } from 'pages/Schemas/partnerships'
import React, { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BiPhotoAlbum } from 'react-icons/bi'
import { useMutation, useQuery } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { IUploadPartnershipResponse, UploadPartnershipImageApi } from 'services/requests/news'
import {
    CreatePartnershipApi,
    PartnershipsById,
    UpdatePartnershipApi,
} from 'services/requests/partnerships'
import { UpdatePartnershipsType } from 'services/requests/partnerships/schema'
import { UpdatePartnershipAtom } from 'store/partnershipAtom'
import { badRequestError } from 'utils/constants'

const AddUpdatePartnership: React.FunctionComponent = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { id } = params
    const [partnershipToUpdate, setPartnershipToUpdate] = useAtom(UpdatePartnershipAtom)
    const [badReqError, setBadReqError] = useState<string | null>(null)

    const { isLoading: partnershipByIdLoading } = useQuery(
        'partnershipsByID',
        () => PartnershipsById(id ?? ''),
        {
            onSuccess: (partnership) => {
                if (partnership) {
                    setPartnershipToUpdate(partnership)
                    setValue('description', partnership?.description)
                    setValue('status', partnership?.status)
                    setValue('title', partnership?.title)
                    setValue('image', partnership?.image)
                    setValue('imageAltText', partnership?.imageAltText)
                }
            },
        },
    )

    const { control, handleSubmit, setValue, watch, clearErrors } = useForm<CreatePartnershipType>({
        mode: 'onBlur',
        resolver: zodResolver(CreatePartnershipSchema),
        defaultValues: {
            status: 'ACTIVE',
            description: id ? partnershipToUpdate?.description ?? '{}' : '{}',
        },
    })
    interface IUploadRequest {
        file: File
        type: string
    }

    const { mutate: updatePartnership, isLoading: createLoading } = useMutation<
        unknown,
        AxiosError,
        UpdatePartnershipsType
    >((data) => (id ? UpdatePartnershipApi(id, data) : CreatePartnershipApi(data)), {
        onSuccess: () => {
            navigate('/web/partnerships')
        },
        onError: (error) => console.log(error),
    })
    const { mutate: uploadImage } = useMutation<
        IUploadPartnershipResponse[],
        AxiosError,
        IUploadRequest
    >((data) => UploadPartnershipImageApi(data), {
        onSuccess: (data) => {
            setBadReqError(null)
            clearErrors('image')
            setValue('image', data[0].sizes[0].objectURL)
        },
        onError: () => setBadReqError(badRequestError),
    })

    const onSubmitDraft = (data: UpdatePartnershipsType) => {
        updatePartnership({ ...data, status: 'DRAFT' })
    }
    const onSubmitPublish = (data: UpdatePartnershipsType) => {
        updatePartnership({ ...data, status: 'ACTIVE' })
    }

    const FileUpload = useCallback(
        (props: { value: string; errorMessage?: string }) => {
            return (
                <AppFileUpload
                    onClose={() => setBadReqError(null)}
                    maxSize={25}
                    errorMessage={props?.errorMessage ?? badReqError ?? ''}
                    existingImage={typeof props.value === 'string' ? props.value : undefined}
                    onFileChange={(file) => {
                        if (file) uploadImage({ file: file, type: 'image' })
                    }}
                    isDisplayCloseError={!!props.errorMessage}
                    IconComponent={BiPhotoAlbum}
                    className='max-w-[198px] h-[198px]  border-dotted border-2 mx-auto'
                />
            )
        },
        [watch().image, badReqError],
    )
    return (
        <div className='p-2 m-2 flex flex-col gap-4 text-[#464255]'>
            <div>
                <h1 className='text-base text-gray-600 font-bold'>
                    <Link className='text-red-800 hover:text-red-600' to={'/web/partnerships'}>
                        Partnerships
                    </Link>{' '}
                    &gt; {id ? 'Update' : 'Add'}
                </h1>
            </div>
            <div className='inline-flex justify-between pb-2 border-b border-gray-700 w-full relative'>
                <h1 className='text-[#751132]  font-bold text-[20px]'>
                    {id ? 'Update Partnership' : 'Add Partnership'}
                </h1>
            </div>
            <div className='grid grid-cols-12 gap-6'>
                <div className='gap-y-10 col-span-12 sm:col-span-6 2xl:col-span-3 bg-white shadow-sm p-5 pt-2 flex flex-col'>
                    <span className='w-full text-center font-medium pb-2 border-b my-2'>
                        Featured Image
                    </span>
                    <Controller
                        control={control}
                        name='image'
                        render={({ field: { value }, fieldState: { error } }) => (
                            <FileUpload value={value} errorMessage={error?.message} />
                        )}
                    />
                    <Controller
                        control={control}
                        name='imageAltText'
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <AppAltTextInput
                                name={name}
                                onChange={onChange}
                                errorMessage={error?.message ?? ''}
                                value={value}
                            />
                        )}
                    />
                </div>
                <div className='col-span-12 sm:col-span-6 2xl:col-span-9 bg-white shadow-sm p-5 min-h-[503px]'>
                    <Controller
                        control={control}
                        name='title'
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <AppTextBox
                                type='text'
                                label={'Title'}
                                autoFocus
                                placeholder='Add your title here'
                                value={value}
                                name={name}
                                labelClassname='text-[12px]'
                                inputClassname='border rounded border-[#D8D8D8] w-full min-h-[31px] p-5 focus:outline-none focus:shadow-outline text-xxs shadow-none'
                                onChange={onChange}
                                errorMessage={error?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='description'
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <div className='w-full bg-white flex'>
                                {!partnershipByIdLoading && (
                                    <AppRichTextBox
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                        errorMessage={error?.message}
                                        label='Description'
                                        containerClassName='flex flex-col gap-2 py-5 w-full'
                                        labelClassname='text-[12px]'
                                    />
                                )}
                            </div>
                        )}
                    />
                </div>
                <div className='col-span-12 inline-flex gap-3 justify-end'>
                    <AppButton
                        variant='secondary'
                        className='bg-white text-[#751132] px-6 py-3'
                        type='submit'
                        onClick={() => navigate('/web/partnerships')}
                    >
                        Cancel
                    </AppButton>
                    <AppButton
                        variant='secondary'
                        className='bg-transparent text-[#751132] px-6 py-3'
                        type='submit'
                        onClick={handleSubmit(onSubmitDraft)}
                    >
                        Draft
                    </AppButton>
                    <AppButton
                        variant='primary'
                        isLoading={createLoading}
                        className='bg-[#751132] text-white px-6 py-3'
                        type='submit'
                        onClick={handleSubmit(onSubmitPublish)}
                    >
                        Publish
                    </AppButton>
                </div>
            </div>
        </div>
    )
}

export default AddUpdatePartnership
