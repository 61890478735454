import { atom } from 'jotai'
import { LoginResponseType } from 'services/requests/auth/schema'

export const initialToken: LoginResponseType = {
    accessToken: '',
    expiresIn: 0,
    refreshExpiresIn: 0,
    refreshToken: '',
    tokenType: '',
    verified: false,
}

//get the token from local storage
const getAuthenticationToken = (): LoginResponseType => {
    const token = localStorage.getItem('token')

    if (token) {
        return JSON.parse(token) as LoginResponseType
    }

    return initialToken
}

const currentAuthAtom = atom<LoginResponseType>(getAuthenticationToken())

//const [data, setData] = useState();
export const authAtom = atom<LoginResponseType, [LoginResponseType], void>(
    (get) => get(currentAuthAtom), // get = currentAuthAtom
    (get, set, update) => {
        set(currentAuthAtom, update)
        localStorage.setItem('token', JSON.stringify(update))
    },
)

export const clearAuthAtom = atom<null, [unknown], void>(
    null, // get = currentAuthAtom
    (get, set, _) => {
        set(currentAuthAtom, initialToken)
        localStorage.removeItem('token')
    },
)
