export const AppSkeletonLoadingRefund = () => {
    return (
        <div className='animate-pulse'>
            <div className='h-2 w-[130px] bg-slate-200 rounded-xl'></div>
            <div className='h-8 w-[110px] bg-slate-200 rounded-md mt-7'></div>

            <div className='flex flex-row w-full bg-white h-[94px] mt-10 rounded-md justify-between'>
                <div className='flex flex-row items-center'>
                    <div className='h-[40px] w-[40px] bg-slate-200 rounded-md ml-5'></div>
                    <div className='flex flex-col ml-5 mt-1'>
                        <div className='h-6 w-[160px] bg-slate-200 rounded-md mb-2'></div>
                        <div className='h-3 w-[220px] bg-slate-200 rounded-md '></div>
                    </div>
                </div>
                <div className='flex flex-row items-center'>
                    <div className='h-8 w-[80px] bg-slate-200 rounded-md mr-2 mb-5'></div>
                    <div className='h-8 w-[80px] bg-slate-200 rounded-md mr-5 mb-5'></div>
                </div>
            </div>
            <div className='flex flex-col w-full bg-white h-[500px] mt-2 rounded-md p-5'>
                <div className='flex flex-row w-full mt-8'>
                    <div className='flex w-[43%] justify-start pl-[160px]'>
                        <div className='h-2 w-[100px] bg-slate-200 rounded-xl'></div>
                    </div>
                    <div className='flex w-[18%] pl-2'>
                        <div className='h-2 w-[60px] bg-slate-200 rounded-xl'></div>
                    </div>
                    <div className='flex w-[20%] pl-2'>
                        <div className='h-2 w-[55px] bg-slate-200 rounded-xl'></div>
                    </div>
                    <div className='flex w-[20%] justify-end mr-8'>
                        <div className='h-2 w-[70px] bg-slate-200 rounded-xl'></div>
                    </div>
                </div>
                <div className='flex flex-row w-full mt-10 '>
                    <div className='flex flex-col w-[43%] justify-start pl-[160px]'>
                        <div className='h-[80px] w-[80px] bg-slate-200 rounded-md'></div>
                        <div className='h-3 w-[100px] bg-slate-200 rounded-xl mt-2'></div>
                    </div>
                    <div className='flex w-[18%] pl-1 items-center'>
                        <div className='h-3 w-[70px] bg-slate-200 rounded-xl'></div>
                    </div>
                    <div className='flex w-[20%] items-center'>
                        <div className='h-3 w-[75px] bg-slate-200 rounded-xl'></div>
                    </div>
                    <div className='flex items-center w-[19%] justify-end mr-8'>
                        <div className='h-2 w-[50px] bg-slate-200 rounded-xl'></div>
                    </div>
                </div>
                <div className='flex flex-row w-full mt-10 '>
                    <div className='flex flex-col w-[43%] justify-start pl-[160px]'>
                        <div className='h-[80px] w-[80px] bg-slate-200 rounded-md'></div>
                        <div className='h-3 w-[100px] bg-slate-200 rounded-xl mt-2'></div>
                    </div>
                    <div className='flex w-[18%] pl-1 items-center'>
                        <div className='h-3 w-[70px] bg-slate-200 rounded-xl'></div>
                    </div>
                    <div className='flex w-[20%] items-center'>
                        <div className='h-3 w-[75px] bg-slate-200 rounded-xl'></div>
                    </div>
                    <div className='flex items-center w-[19%] justify-end mr-8'>
                        <div className='h-2 w-[50px] bg-slate-200 rounded-xl'></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
