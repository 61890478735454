import { Provider } from 'jotai'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import App from './App'
import './index.css'

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <Provider>
            <QueryClientProvider client={queryClient}>
                <App />
                {import.meta.env.PROD ? null : (
                    <ReactQueryDevtools initialIsOpen={false} position='top-right' />
                )}
            </QueryClientProvider>
        </Provider>
    </React.StrictMode>,
)
