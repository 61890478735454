import { Switch } from '@headlessui/react'
import { AxiosError } from 'axios'
import ConfirmationModal from 'components/ActionConfirmation'
import { AppModal } from 'components/AppModal'
import { useAtom } from 'jotai'
import * as React from 'react'
import { toast } from 'react-hot-toast'
import { BiSolidEdit } from 'react-icons/bi'
import { FiTrash } from 'react-icons/fi'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { DeleteFaqApi, UpdateFaqApi } from 'services/requests/faqs'
import { FaqObjectType, UpdateFaqType } from 'services/requests/faqs/schema'
import { twMerge } from 'tailwind-merge'
import { flexCenter } from 'utils/styles'
import { FaqEditAtom } from '.'

interface IFaqsCardProps {
    item: FaqObjectType
    setMoved: React.Dispatch<React.SetStateAction<number>>
    handleSelect: (checked: boolean, item: FaqObjectType) => void
    checked: boolean
    setSelectedFaq: React.Dispatch<React.SetStateAction<FaqObjectType[] | []>>
}

const FaqsCard: React.FunctionComponent<IFaqsCardProps> = ({
    item,
    setMoved,
    handleSelect,
    checked,
    setSelectedFaq,
}) => {
    const navigate = useNavigate()
    const [, setEditFaq] = useAtom(FaqEditAtom)

    const [isOpenConfirmationModal, setIsOpenConfirmationModal] = React.useState(false)
    const { mutate: deleteFaq, isLoading: deleteLoading } = useMutation<
        unknown,
        AxiosError,
        string
    >((id) => DeleteFaqApi(id), {
        onSuccess: (data) => {
            console.log(data)
            navigate('/web/faqs')
            setMoved((prev: number) => prev + 1)
            toast.success('Success! Item deleted.')
        },
        onError: (error) => {
            console.log('ERROR', error)
        },
    })

    const { mutate: updateFaqs } = useMutation<unknown, AxiosError, UpdateFaqType>(
        (data) => {
            return UpdateFaqApi(item?.faqId, data)
        },
        {
            onSuccess: () => {
                toast.success('Success! Status updated.')
                setMoved((prev) => prev + 1)
            },
            onError: (error) => console.log(error),
        },
    )

    const handleDelete = (id: string) => {
        setSelectedFaq((prevSelectedFaqs) => prevSelectedFaqs.filter((faq) => faq.faqId !== id))
        deleteFaq(id)
    }

    const handleChangeStatus = (value: boolean) => {
        updateFaqs({
            description: item.description,
            status: value ? 'ACTIVE' : 'INACTIVE',
            visibility: item?.visibility,
            title: item?.title,
        })
    }

    return (
        <>
            <div className='col-span-1 flex items-center justify-center] min-h-[84px]'>
                <input
                    type='checkbox'
                    checked={checked}
                    className={'h-4 w-4 m-auto rounded border-gray-300 accent-[#861F41]'}
                    onChange={(e) => handleSelect(e.target.checked, item)}
                />
            </div>
            <h1 className={twMerge('col-span-5 truncate min-h-[3rem] px-2 flex')}>
                <h1 className='my-auto truncate text-[15px] font-medium'>{item?.title}</h1>
            </h1>
            <h1 className={twMerge(flexCenter, 'col-span-2 truncate min-h-[3rem]')}>
                {item?.search}
            </h1>
            {/* <div
                className={twMerge(
                    flexCenter,
                    'col-span-2 truncate min-h-[3rem] capitalize text-[#861F41]'
                )}
            >
                <button onClick={handleChangeVisibility}>
                    <p className='sr-only'>Visibility</p>
                    {item?.visibility === 'VISIBLE' ? (
                        <AiFillEye className='w-6 h-6' />
                    ) : (
                        <AiFillEyeInvisible className='w-6 h-6' />
                    )}
                </button>
            </div> */}
            <h1 className={twMerge(flexCenter, 'col-span-2 truncate min-h-[3rem] capitalize')}>
                <Switch
                    checked={item?.status === 'ACTIVE'}
                    onChange={(value: boolean) => handleChangeStatus(value)}
                    className={`${item?.status === 'ACTIVE' ? 'bg-[#861F41]' : 'bg-gray-400'}
          relative inline-flex h-[20px] w-[40px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                    <span className='sr-only'>Toggle Status</span>
                    <span
                        aria-hidden='true'
                        className={`${
                            item?.status === 'ACTIVE' ? 'translate-x-[1.2rem]' : 'translate-x-[2px]'
                        }
            pointer-events-none inline-block h-[15px] w-[15px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out top-[50%] translate-y-[0%] `}
                    />
                </Switch>
            </h1>
            <div className={twMerge(flexCenter, 'gap-4 col-span-2')}>
                <button
                    onClick={() => {
                        setEditFaq(item)
                        navigate(`/web/faqs/edit/${item.faqId}`)
                    }}
                >
                    <BiSolidEdit className='w-5 h-5 text-[#751132]' />
                </button>
                <button onClick={() => setIsOpenConfirmationModal(true)}>
                    <FiTrash
                        className={twMerge(
                            deleteLoading ? 'text-gray-600' : 'text-[#751132]',
                            'w-5 h-5',
                        )}
                    />
                </button>
            </div>
            <AppModal
                closeIcon={false}
                isOpen={isOpenConfirmationModal}
                onClose={() => setIsOpenConfirmationModal(false)}
                title=''
                containerClassName='h-fit p-0 my-0'
            >
                <ConfirmationModal
                    handle={() => handleDelete(item?.faqId)}
                    onClose={() => setIsOpenConfirmationModal(false)}
                    yesLabel='Delete'
                    noLabel='Cancel'
                    title='Delete FAQ'
                    isLoading={deleteLoading}
                    subtitle='Are you sure you want to delete this item?'
                />
            </AppModal>
        </>
    )
}

export default FaqsCard
