import { AxiosError } from 'axios'
import { useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { useQueryClient } from 'react-query'
import { RouterProvider } from 'react-router-dom'
import { refreshTokenApi } from 'services/requests/auth'
import { authAtom, clearAuthAtom } from 'store/authAtom'
import { router } from './router'

function App() {
    const queryClient = useQueryClient()
    const setAuth = useSetAtom(authAtom)
    const clearAuth = useSetAtom(clearAuthAtom)

    queryClient.setDefaultOptions({
        queries: {
            retry: (failureCount, error) => {
                const axiosError = error as AxiosError
                if (
                    (axiosError.response?.status === 401 || axiosError.response?.status === 403) &&
                    failureCount < 3
                ) {
                    refreshTokenApi()
                        .then((newAuth) => {
                            setAuth(newAuth)
                        })
                        .catch(() => {
                            clearAuth({})
                        })

                    return true
                }
                return false
            },
        },
    })

    useEffect(() => {
        if (navigator.userAgent.indexOf('iPhone') > -1) {
            document
                .querySelector('[name=viewport]')
                ?.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1')
        }
    }, [])

    return (
        <>
            <RouterProvider router={router} />
            <Toaster
                position='top-right'
                toastOptions={{
                    success: {
                        style: {
                            background: '#dcfce7',
                            color: '#22c55e',
                            borderRadius: '0.125rem',
                            outline: '1px solid #22c55e',
                            fontSize: '16px',
                        },
                        icon: null,
                    },
                    error: {
                        style: {
                            background: '#fee2e2',
                            color: '#ef4444',
                            borderRadius: '0.125rem',
                            outline: '1px solid #ef4444',
                            fontSize: '16px',
                        },
                        icon: null,
                    },
                }}
            />
        </>
    )
}

export default App
