import { AppTextBox } from 'components/AppTextBox'
import * as React from 'react'
import { BiSearch } from 'react-icons/bi'
import { twJoin } from 'tailwind-merge'

interface IAppSeachBarProps {
    containerClassName?: string
    inputClassName?: string
    placeHolder?: string
    inputContainerClassName?: string
    onChange: (value: string) => void
    iconClassName?: string
}

const AppSeachBar: React.FunctionComponent<IAppSeachBarProps> = (props) => {
    return (
        <div
            className={twJoin(
                'relative flex justify-center items-center h-fit p-0 max-w-[283px]',
                props.containerClassName,
            )}
        >
            <AppTextBox
                name='search'
                type='text'
                inputClassname={twJoin('px-8', props.inputClassName)}
                onChange={props.onChange}
                placeholder={props.placeHolder ?? 'Search'}
                containerClassname={twJoin('-mt-1', props?.inputContainerClassName)}
            />
            <BiSearch
                className={twJoin(
                    'absolute top-[50%] translate-y-[-50%] left-2 text-[#861F41] w-5 h-5',
                    props?.iconClassName,
                )}
            />
        </div>
    )
}

export default AppSeachBar
