import { axiosInstance } from 'services/axios'
import {
    LocationType,
    PageListLocationType,
    StoreDirectionType,
    UpdateStoreType,
} from 'services/requests/store/schema'
import { ApiPaginationParams } from 'types/Commons'
import { MenuItemType, ObjectProductType } from '../menu-category/schema'

export const getLocationsApi = async (props: ApiPaginationParams) => {
    const response = await axiosInstance.get('/store', {
        params: {
            keyword: props.keyword,
            page: props.page,
            size: props.size,
            sort: props.sort,
        },
    })

    return response.data as PageListLocationType
}

export const getLocationsByIdApi = async (id: string | undefined) => {
    const response = await axiosInstance.get(`/store/admin/${id}`)

    return response.data as LocationType
}

export const updateStoreAndHoursApi = async (storeData: UpdateStoreType, id: string) => {
    const formData = new FormData()

    formData.append('shop_name', storeData.shopName)
    formData.append('status', storeData.status)
    formData.append('description', storeData.description)
    formData.append('restricted', storeData.restricted.toString())

    if (storeData.file) {
        formData.append('file', storeData.file)
    }

    if (storeData.restrictNote) {
        formData.append('restrict_note', storeData.restrictNote)
    }

    const storeResponse = await axiosInstance.patch(`/store/${id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })

    const hoursResponse = await axiosInstance.post(`/store/${id}/hours`, storeData.storeHours)

    return {
        store: storeResponse.data,
        hours: hoursResponse.data,
    }
}

export const updateMenuItemStatusApi = async (status: string, menuId: string) => {
    const response = await axiosInstance.put(`/store/menu-item?status=${status}&menuId=${menuId}`)
    return response.data
}

export const createOrUpdateStoreDirectionsApi = async (
    storeId: string,
    data: StoreDirectionType,
) => {
    const response = await axiosInstance.post(`/store/store-direction?id=${storeId}`, data)
    return response.data
}

export const getStoreDirectionByIdApi = async (storeId: string) => {
    const response = await axiosInstance.get(`/store/store-direction?id=${storeId}`)
    return response.data
}

export const searchLocationApi = async (keyword: string) => {
    const response = await axiosInstance.get('/store', {
        params: {
            keyword,
        },
    })

    return response.data.content as LocationType[]
}

export const toggleRestrictStoreApi = async (storeId: string) => {
    const response = await axiosInstance.post(`/store/${storeId}/restrict`)
    return response.data as LocationType
}

export const getProductApi = async (productId: string) => {
    const response = await axiosInstance.get(
        '/product/public/store-product?type=EDIBLE&id=' + productId,
    )
    return response.data as ObjectProductType
}

export const getMenuItemApi = async (productId: string) => {
    const response = await axiosInstance.get('/product/public/getProductMenu', {
        params: { productId },
    })
    return response.data as MenuItemType
}
