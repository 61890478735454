import { AppButton } from 'components/AppButton'
import React from 'react'

interface IConfirmationModalProps {
    onClose: () => void
    title: string
    handle?: () => void
    subtitle: string
    yesLabel: string
    noLabel: string
    isLoading: boolean
}

const ConfirmationModal: React.FunctionComponent<IConfirmationModalProps> = ({
    onClose,
    title,
    handle,
    subtitle,
    yesLabel,
    noLabel,
    isLoading,
}) => {
    return (
        <div className='flex min-w-[492px] min-h-[215px] justify-center items-center rounded-md p-6 flex-col'>
            <div className='flex flex-col items-center justify-center max-w-[480px] p-2 space-y-2'>
                <h1 tabIndex={0} className='text-[23px] font-bold text-[#861F41] '>
                    {title}
                </h1>
                <p tabIndex={0} className='text-center text-[16px] text-[#707070] w-[311px]'>
                    {subtitle}
                </p>
            </div>
            <div className='flex flex-row justify-between w-full max-w-xs mx-auto mt-auto border-t border-primary p-4'>
                <AppButton
                    variant='secondary'
                    type='button'
                    onClick={() => onClose()}
                    className='min-w-[129px] h-[38px] flex justify-center items-center bg-white border-[#861F41] text-[#861F41] p-3 font-bold'
                >
                    {noLabel}
                </AppButton>
                <AppButton
                    variant='primary'
                    type='button'
                    isLoading={isLoading}
                    onClick={() => handle && handle()}
                    className='min-w-[129px] h-[38px] flex justify-center items-center text-white  bg-[#861F41] p-3 font-bold'
                >
                    {yesLabel}
                </AppButton>
            </div>
        </div>
    )
}

export default ConfirmationModal
