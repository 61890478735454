import { axiosInstance } from 'services/axios'
import { ContentType, CreateContentType, PageListContentType } from './schema'

export const getContentApi = async (title: string) => {
    const response = await axiosInstance.get('/content', {
        params: {
            title,
        },
    })

    return response.data as ContentType
}

export const listContentApi = async () => {
    const response = await axiosInstance.get('/content')

    return response.data as PageListContentType
}

export const createContentApi = async (props: CreateContentType) => {
    console.log('data', props)

    const response = await axiosInstance.post('/content', props)

    return response.data
}

export const deleteContentApi = async (id: string) => {
    const response = await axiosInstance.delete('/content', {
        params: {
            id,
        },
    })

    return response.data
}

export const updateContentApi = async (title: string, props: CreateContentType) => {
    const response = await axiosInstance.put('/content', props, {
        params: {
            title,
        },
    })

    return response.data
}
