const AppSkeletonLoadingProducts = () => {
    const skeletonRows = Array.from({ length: 10 }, (_, index) => (
        <tr key={index}>
            <td className='py-4 pl-4 pr-3'>
                <div className='h-3 w-[60%] bg-slate-200 rounded'></div>
            </td>
            <td className='px-3 py-4 w-[53%]'>
                <div className='h-3 w-[80%] bg-slate-200 rounded'></div>
            </td>
            <td className='py-4 pl-3'>
                <div className='h-3 w-[30%] bg-slate-200 rounded'></div>
            </td>
        </tr>
    ))
    return (
        <div className='mt-8 flow-root animate-pulse'>
            <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                    <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg sm:rounded-b-none'>
                        <table className='min-w-full divide-y divide-gray-300'>
                            <thead className='bg-red-100 w-full'>
                                <tr>
                                    <th
                                        scope='col'
                                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-red-800 sm:pl-6 w-[40.5%]'
                                    >
                                        Product Name
                                    </th>
                                    <th
                                        scope='col'
                                        className='px-3 py-3.5 text-left text-sm font-semibold text-red-800'
                                    >
                                        Description
                                    </th>
                                    <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-0'>
                                        <span className='sr-only'>Action</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='divide-y divide-gray-200 bg-white'>
                                {skeletonRows}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AppSkeletonLoadingProducts
