import { twMerge } from 'tailwind-merge'

type HeaderProps = {
    value: string
    className?: string
}

export const TextHeader = (props: HeaderProps) => {
    return <h1 className={twMerge('text-2xl font-bold', props.className)}> {props.value}</h1>
}
