const AppSkeletonLoadingRewards = () => {
    const skeletonRows = Array.from({ length: 9 }, (_, index) => (
        <tr key={index}>
            <td className='py-6 pl-4'>
                <div className='h-3 w-[20%] bg-slate-200 rounded'></div>
            </td>
            <td className='py-6 w-[40%]'>
                <div className='h-3 w-[50%] bg-slate-200 rounded'></div>
            </td>
            <td className=' py-6 w-[10%]'>
                <div className='h-3 w-[30%] bg-slate-200 rounded '></div>
            </td>
            <td className='py-6 w-[15%]'>
                <div className='h-3 w-[10%] bg-slate-200 rounded'></div>
            </td>
            <td className='py-6 pl-3'>
                <div className='h-3 w-[30%] bg-slate-200 rounded'></div>
            </td>
            <td className='py-6 pl-3'>
                <div className='h-3 w-[40%] bg-slate-200 rounded'></div>
            </td>
        </tr>
    ))
    return (
        <div className=' flow-root animate-pulse'>
            <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                    <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg sm:rounded-b-none'>
                        <table className='min-w-full divide-y divide-gray-300'>
                            <tbody className='divide-y divide-gray-200 bg-white'>
                                {skeletonRows}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AppSkeletonLoadingRewards
